import Moment from 'moment'
import JobRun from './JobRun'
/*


*/
class TaskRun {
  constructor(props) {
    const {
      id,
      status,
      task,
      jobrun,
      locals,
      duration,
      error_message,
      globals,
      completed,
      is_active,
      is_done,
      created,
      started,
      updated,
      deleted,

    } = props

    this.id = id
    this.key = id
    this.name = task.name || '- - -'
    this.status = status
    this.task = task // TODO : Change with Task Model
    this.jobrun = jobrun
    this.duration = duration ? duration.split('.')[0] : '- - -'
    this.error_message = error_message
    this.locals = locals
    this.globals = globals
    this.started_at = started ? Moment(started).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.completed_at = completed ? Moment(completed).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.created_at = created ? Moment(created).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.updated_at = updated ? Moment(updated).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.deleted_at = deleted ? Moment(deleted).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.is_active = is_active
    this.is_done = is_done

  }

}

export default TaskRun
