export default function getUserRole(role) {
  const roles = {
    super: 'Super',
    admin: 'Admin',
    manager: 'Manager',
    power: 'Power',
    reseller: 'Reseller',
    developer: 'Developer',
    user: 'User',
    saasuser: 'Market User',
    marketadmin : 'Market Admin',
  }
  return roles[role] || 'Unknown'
}
