import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Divider, Tooltip, Typography } from 'antd'
import { history, store } from 'index'
import { getIntl } from 'localization'
import moment from 'moment'
import 'moment/locale/tr'
import React from 'react'
import style from '../style.module.scss'

const NotificationItem = ({ data, locale }) => {
  const { id, level, payload, event_datetime, is_read } = data
  moment.locale(locale.split('-')[0])
  const fromNow = moment(event_datetime).fromNow()

  const markNotifications = async (is_read = false) => {
    store.dispatch({
      type: 'notifications/UPDATE_NOTIFICATION',
      payload: { id, data: { is_read } },
      menu: true,
    })
  }

  return (
    <>
      <li className={style.listItem}>
        <div className={style.head}>
          <time className={style.time}>
            {fromNow}
            <Tooltip
              title={getIntl(is_read ? 'notifications.markAsUnread' : 'notifications.markAsRead')}
            >
              {is_read ? (
                <EyeInvisibleOutlined
                  className={`${style.eyeIcon} ml-1`}
                  onClick={() => markNotifications(false)}
                />
              ) : (
                <EyeOutlined className={`${style.eyeIcon} ml-1`} onClick={() => markNotifications(true)} />
              )}
            </Tooltip>
          </time>
          {''}
        </div>
        <Typography.Link
          className={style[`content${is_read ? '_is_read' : ''}`]}
          onClick={() => history.push(`/notifications?view=notifications&notification=${id}`)}
        >
          {payload}
        </Typography.Link>
      </li>
      <Divider className="mt-1 mb-1" dashed />
    </>
  )
}

export default NotificationItem
