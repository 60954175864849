const actions = {
  SET_STATE: 'marketplace/SET_STATE',
  GET_CATALOG_ITEM_VERSIONS : 'marketplace/GET_CATALOG_ITEM_VERSIONS',
  GET_CATALOG_ITEM_VERSIONS_MENU : 'marketplace/GET_CATALOG_ITEM_VERSIONS_MENU',
  GET_CATALOG_ITEM_VERSION_BY_ID : 'marketplace/GET_CATALOG_ITEM_VERSION_BY_ID',
  GET_CATALOG_ITEM_VERSION_BY_KEY : 'marketplace/GET_CATALOG_ITEM_VERSION_BY_KEY',
  UPDATE_MARKET_ITEM : 'marketplace/UPDATE_MARKET_ITEM',
  ADD_SCENARIO_TO_MARKETPLACE : 'marketplace/ADD_SCENARIO_TO_MARKETPLACE',
  UPDATE_MARKET_ITEM_PHOTO : 'marketplace/UPDATE_MARKET_ITEM_PHOTO',
  ADD_SCENARIO_TO_NEW_MARKET_ITEM_VERSION : 'marketplace/ADD_SCENARIO_TO_NEW_MARKET_ITEM_VERSION',
}

export default actions
