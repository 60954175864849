import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Select, Input } from 'antd'
import { getIntl } from 'localization'
import CategoryFilterInput from './components/CategoryFilterInput'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { cloneDeep } from 'lodash'
import {
  MAX_CATALOG_DESCRIPTION_LENGTH,
  MAX_CATALOG_NAME_LENGTH,
  MAX_CATALOG_URL_LENGTH,
} from 'constantValues'

const mapStateToProps = ({ active_scenario, settings, user, marketplace }) => ({
  active_scenario,
  settings,
  user,
  marketplace,
})
const MarketplaceOperations = ({
  dispatch,
  active_scenario,
  marketplace,
  user: { userprofile },
  visibility,
  setVisibility,
  settings,
}) => {
  const { xs } = useBreakpoint()
  const [form] = Form.useForm()
  const { market_items } = marketplace
  const [catalogFilters, setCatalogFilters] = useState([])

  useEffect(() => {
    if (visibility) {
      form.setFieldsValue({ name: active_scenario.name })
      dispatch({
        type: 'marketplace/GET_CATALOG_ITEM_VERSIONS',
        payload: {
          filters: {
            is_default: true,
            pageSize: 50,
            publish_request_status: ['rejected', 'approved'],
            organization: userprofile.organisation.id,
          },
        },
      })
    }
  }, [active_scenario.id, visibility])

  useEffect(() => {
    if (market_items && market_items.length > 0) {
      // form.setFieldsValue({ market_items })
    }
  }, [market_items])

  const addScenarioToMarketplace = payload => {
    dispatch({
      type: 'marketplace/ADD_SCENARIO_TO_MARKETPLACE',
      payload,
    })
  }

  const addScenarioToNewMarketItemVersion = (item, cloud_url) => {
    dispatch({
      type: 'marketplace/ADD_SCENARIO_TO_NEW_MARKET_ITEM_VERSION',
      payload: { item, cloud_url, is_default: true },
      redirect: true,
    })
  }

  const submitCatalogItem = () => {
    form
      .validateFields()
      .then(v => {
        const values = cloneDeep(v)
        Object.assign(values, { catalog_filters: catalogFilters })

        const { cloud_url } = values
        delete values.cloud_url
        if (values.catalog_item) {
          addScenarioToNewMarketItemVersion(values, cloud_url)
        } else {
          addScenarioToMarketplace({ item: values, cloud_url })
        }
        setVisibility(false)
      })
      .catch(e => {})
  }
  return (
    <Modal
      forceRender
      title={getIntl('catalog_items.add_scenario_to_marketplace.modal.title')}
      open={visibility}
      onCancel={() => setVisibility(false)}
      onOk={submitCatalogItem}
      width={xs ? '%95' : '50%'}
    >
      <Form
        form={form}
        onFinish={submitCatalogItem}
        layout="vertical"
        initialValues={{ name: active_scenario.name }}
      >
        <Form.Item
          name="name"
          label={getIntl('Name')}
          rules={[
            {
              required: true,
              message: getIntl('formValidations.new_catalog_item.name.required'),
            },
          ]}
        >
          <Input maxLength={MAX_CATALOG_NAME_LENGTH} />
        </Form.Item>
        <Form.Item
          name="short_description"
          label={getIntl('Description')}
          rules={[
            {
              required: true,
              message: getIntl('formValidations.new_catalog_item.description.required'),
            },
          ]}
        >
          <Input maxLength={MAX_CATALOG_DESCRIPTION_LENGTH} />
        </Form.Item>

        <CategoryFilterInput active={visibility} onChange={setCatalogFilters} />
        <Form.Item name="tags" label={getIntl('Tags')}>
          <Select mode="tags" />
        </Form.Item>
        <Form.Item
          label={getIntl('Cloud URL')}
          name="cloud_url"
          rules={[
            {
              type: 'url',
            },
          ]}
          tooltip={getIntl('catalog_items.tooltip.cloudURL')}
        >
          <Input maxLength={MAX_CATALOG_URL_LENGTH} />
        </Form.Item>

        <Form.Item name="catalog_item" label={getIntl('Marketplace Items')}>
          <Select
            loading={marketplace.loading}
            showSearch
            placeholder={getIntl(
              'catalog_items.add_scenario_to_catalog.catalogItemSelector.placeholder',
            )}
            optionFilterProp="children"
          >
            {market_items.map(option => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="credit"
          label={getIntl('catalog_items.publish_price_modal.description')}
          rules={[{ required: true }]}
        >
          <Input
            name="credit"
            type="number"
            min="0"
            addonAfter={`${getIntl('credit')} / ${getIntl('shortMinute')}`}
          />
        </Form.Item>
        <Form.Item
          name="apt"
          label={getIntl('catalog_items.publish_apt_modal.description')}
          rules={[{ required: true }]}
        >
          <Input name="apt" type="number" min="0" addonAfter={`${getIntl('shortMinute')}`} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default connect(mapStateToProps)(MarketplaceOperations)
