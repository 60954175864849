import BaseModel from "./BaseModel"

class Team extends BaseModel {

  constructor(props) {
    const { leader, email, phone } = props
    super(props)

    this.leader = leader
    this.email = email
    this.phone = phone
  }
}

export default Team
