import React from 'react'

import {
  generateSmartPath,
  generateRightAnglePath,
  generateCurvePath,
} from '@mrblenny/react-flow-chart'

/* eslint-disable no-nested-ternary */
const LinkCustom = props => {
  const {
    config,
    startPos,
    endPos,
    fromPort,
    toPort,
    matrix,
    onLinkMouseEnter,
    onLinkMouseLeave,
    onLinkClick,
    link,
    isHovered,
    isSelected,
  } = props
  const points = config.smartRouting
    ? !!toPort && !!matrix
      ? generateSmartPath(matrix, startPos, endPos, fromPort, toPort)
      : generateRightAnglePath(startPos, endPos)
    : generateCurvePath(startPos, endPos)

  const linkColor = (fromPort.properties && fromPort.properties.linkColor) || '#df255a'

  return (
    <svg
      style={{
        overflow: 'visible',
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 0,
        width: 1,
        height: 1,
      }}
    >
      <circle r="4" cx={startPos.x} cy={startPos.y} fill={linkColor} />
      {/* Main line */}
      <path d={points} stroke={linkColor} strokeWidth="3" fill="none" />
      {/* Thick line to make selection easier */}
      <path
        d={points}
        stroke={linkColor}
        strokeWidth="20"
        fill="none"
        strokeLinecap="round"
        strokeOpacity={isHovered || isSelected ? 0.1 : 0}
        onMouseEnter={() => onLinkMouseEnter({ config, linkId: link.id })}
        onMouseLeave={() => onLinkMouseLeave({ config, linkId: link.id })}
        onClick={e => {
          onLinkClick({ config, linkId: link.id })
          e.stopPropagation()
        }}
      />
      <circle r="4" cx={endPos.x} cy={endPos.y} fill={linkColor} />
    </svg>
  )
}

export default LinkCustom
