import BaseModel from './BaseModel'

class Agreement extends BaseModel {
  constructor(props) {
    const { language, country, content } = props
    super(props)

    this.language = language
    this.country = country
    this.content = content
  }
}

export default Agreement
