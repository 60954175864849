import { all, put, call } from 'redux-saga/effects'
import { getTaskList } from 'services/tasks'

export function* GET_DATA() {
  try {

    const tasksData = yield call(getTaskList)
    yield put({
      type: 'tasks/SET_STATE',
      payload: {
        tasksData,
      },
    })
  } catch (e) { console.log(e)}
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
