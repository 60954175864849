import { notification } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

const NotificationWrapper = ({ internal_notification }) => {
  const [api, contextHolder] = notification.useNotification()

  const showNotification = (data, type) => {
    api[type](data)
  }

  useEffect(() => {
    if (internal_notification.data) {
      const { data, type } = internal_notification
      showNotification(data, type)
    }
  }, [internal_notification])
  return <>{contextHolder}</>
}
const mapStateToProps = ({ internal_notification }) => ({ internal_notification })
export default connect(mapStateToProps)(NotificationWrapper)
