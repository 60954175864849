import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { App, Divider } from 'antd'
import { getIntl } from 'localization'
import style from '../style.module.scss'
import { store } from 'index'
import SubscriptionChangeButton from 'components/widgets/Buttons/SubscriptionChange'
import { FormattedHTMLMessage } from 'react-intl'

const PlanCard = ({ data, selected, locale, single }) => {
  const {
    id,
    name,
    description,
    $subscription_fee,
    free_usage,
    is_annual,
    extra_info,
    noprice,
    trial_period,
  } = data

  const includes = extra_info ? extra_info[locale]?.includes : []
  const localizedName = extra_info ? extra_info[locale]?.name : name
  const localizedDescription = extra_info ? extra_info[locale]?.description : description
  const current = selected?.id === id
  const { modal } = App.useApp()

  const handleSubscribeClick = e => {
    e.preventDefault()
    if (noprice) {
      window.location = 'mailto:sales@kronnika.com'
      return
    }
    modal.confirm({
      title: getIntl('subscriptions.subscriptionPlanChange.confirm.title'),
      content: (
        <>
          {getIntl('subscriptions.subscriptionPlanChange.confirm.content')}
          <br />
          <br />
          <strong>{localizedName}</strong>
        </>
      ),
      onOk: () => {
        store.dispatch({
          type: 'organizations/CHANGE_SUBSCRIPTION_PLAN',
          payload: { plan: { id } },
        })
      },
    })
  }

  return (
    <div className={`${style.item} pt-5 pb-5 pl-5 pr-5 text-center col-sm-${single ? '12' : '3'}`}>
      <i className="fe fe-package font-size-80 mb-3 d-block" />
      <div className="text-dark font-weight-bold" style={{ fontSize: '1.8em' }}>
        {localizedName}
      </div>
      <div className="text-dark font-size-18" style={{ minHeight: 80 }}>
        {localizedDescription}
      </div>
      <div className="text-dark font-weight-boldmt-1 mb-3" style={{ fontSize: '1.6em' }}>
        {noprice ? (
          <InfoCircleOutlined />
        ) : (
          <>
            {$subscription_fee}{' '}
            <span className="align-text-top font-size-24 text-gray-6">
              /{getIntl(is_annual ? 'short.year' : 'short.month')}
            </span>
          </>
        )}
      </div>

      <div className="text-dark font-weight-boldmt-1 mb-3" style={{ fontSize: '1.2em' }}>
        {trial_period ? (
          <FormattedHTMLMessage id="subscriptions.tryForDays" values={{ days: trial_period }} />
        ) : (
          <br />
        )}
      </div>

      {current ? (
        <button type="button" className="btn btn-warning">
          {getIntl('Current Plan')}
        </button>
      ) : (
        <>
          {noprice ? (
            <SubscriptionChangeButton name={name} />
          ) : (
            <button className="btn btn-primary" type="button" onClick={handleSubscribeClick}>
              {getIntl('Subscribe')}
            </button>
          )}
        </>
      )}
      <Divider />
      <ul className="list-unstyled font-size-18 mb-5">
        <li>
          <strong>{getIntl('Free Usage')} :</strong> {free_usage} {getIntl('credit')}
        </li>
        {includes.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  )
}

export default PlanCard
