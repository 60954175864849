import { Select } from 'antd'
import { getList } from 'api/requests'
import Robot from 'models/Robot'
import React, { useEffect, useState } from 'react'

const RobotSelector = ({ value, onChange }) => {
  const [robotData, setRobotData] = useState([])
  const [loading, setLoading] = useState(false)

  const getRobotList = async () => {
    setLoading(true)
    const { success, data } = await getList('robots', { pageSize: 100 })
    if (success) {
      const robotData = data.results.map(r => new Robot(r))
      const formedRobotData = robotData.map(r => ({ value: r.id, label: r.name }))
      setRobotData(formedRobotData)
    }
    setLoading(false)
  }
  useEffect(() => {
    getRobotList()
  }, [])

  return (
    <Select
      loading={loading}
      showSearch
      filterOption={(input, option) => (option?.label ?? '').includes(input)}
      options={robotData}
      mode="multiple"
      allowClear
      value={loading ? null : value}
      onChange={value => onChange(value)}
    />
  )
}

export default RobotSelector
