import React from 'react'
import { App, Modal } from 'antd'
import { getIntl } from 'localization'
import CatalogList from 'components/widgets/Lists/CatalogList'
import { store } from 'index'

const CatalogSelectorDialog = ({ visibility, setVisibility }) => {
  const { modal } = App.useApp()

  const onConfirmCatalog = item => {
    modal.confirm({
      title: getIntl('catalogItems.getCatalogItem.confirm.title'),
      content: getIntl('catalogItems.getCatalogItem.confirm.content'),
      onOk: () => {
        store.dispatch({
          type: 'active_scenario/CATALOG_ITEM_TO_SCENARIO',
          payload: {
            scenario_version_id: item.scenario_version.id,
          },
          modal,
        })
        // onConfirmCatalog(false) // Why ?
      },
    })
    setVisibility(false)
  }

  return (
    <Modal
      title={getIntl('Catalog Items')}
      open={visibility}
      style={{
        position: 'relative',
        top: 20,
        bottom: 20,
      }}
      width="80%"
      onCancel={() => setVisibility(false)}
      footer={null}
    >
      <CatalogList onConfirmCatalog={onConfirmCatalog} />
    </Modal>
  )
}

export default CatalogSelectorDialog
