import { store } from 'index'

const ACCEPTED_KEYS = ['onCanvasDrop']
const EXCEPTION_KEYS = []

const scenarioChangeHandlerByActionKey = ({ chartActionKey }) => {
  const changeAccepted = ACCEPTED_KEYS.some(key => key === chartActionKey)
  const exceptionExists = EXCEPTION_KEYS.some(key => key === chartActionKey)

  if (changeAccepted && !exceptionExists) {
    //
  }
}

export const setModified = scope => {
  const modifyType = 'scenarioModified'
  switch (scope) {
    case 'scenario':
      break
    case 'task':
      setModified('scenario') // Set flag for scenario if task has been modified
      break
    case 'step':
      setModified('scenario') // Set flag for scenario if task has been modified
      break
    default:
      break
  }
  store.dispatch({
    type: 'scenario_editor/SET_STATE',
    payload: { [modifyType]: true },
  })
}
export const resetModified = scope => {
  let modifyType = 'scenarioModified'
  switch (scope) {
    case 'scenario':
      resetModified('step')
      resetModified('task')
      break
    case 'step':
      modifyType = 'stepModified'
      break
    default:
      break
  }
  store.dispatch({
    type: 'scenario_editor/SET_STATE',
    payload: { [modifyType]: false },
  })
}

export default scenarioChangeHandlerByActionKey
