import { App, Button, Table, Tag, Transfer, Typography } from 'antd'
import { difference } from 'lodash'
import React from 'react'
import conditionTranslator from '../utilities'
import { FormattedHTMLMessage } from 'react-intl'
import { getIntl } from 'localization'
import { DeleteOutlined } from '@ant-design/icons'
import { deleteRecord } from 'api/requests'

const ParamsComponent = ({ params }) => {
  const data = Object.keys(params).map(key => ({
    key,
    value: params[key].value,
    condition: params[key].condition,
  }))
  return (
    <>
      {data.map(item => (
        <Tag key={item.key} color="default">
          {item.key} {(!item.value || !item.condition) && '='} {conditionTranslator(item.condition)}{' '}
          {item.value}
        </Tag>
      ))}
    </>
  )
}

const RuleList = ({ rules, selectedRules, onChange, onRefresh, loading }) => {
  const { modal } = App.useApp()

  const deleteTriggerRule = async id => {
    return new Promise((resolve, reject) => {
      deleteRecord('triggeractivities', id).then(({ success, error }) => {
        if (!success) {
          modal.info({ content: error.message })
          reject(error)
        }
        onRefresh()
        resolve(true)
      })
    })
  }

  const handleDeleteTriggerRule = async id => {
    modal.confirm({
      title: getIntl('confirmation.general.areYouSure'),
      onOk: () => deleteTriggerRule(id),
    })
  }
  const leftColumns = [
    {
      title: getIntl('Name'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: getIntl('Expression'),
      dataIndex: 'expression',
      key: 'expression',
      width: 'min-content',
      render: expression => expression.split('.').join('/'),
    },
    {
      title: getIntl('Parameters'),
      dataIndex: 'params',
      key: 'params',
      width: 'min-content',
      render: params => <ParamsComponent params={params} />,
    },
    {
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      width: '30px',
      render: id => (
        <Button
          className="pull-right"
          size="small"
          type="primary"
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteTriggerRule(id)}
        />
      ),
    },
  ]
  const rightColumns = [
    {
      title: getIntl('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: getIntl('Expression'),
      dataIndex: 'expression',
      key: 'expression',
      render: expression => expression.split('.').join('/'),
    },
    {
      title: getIntl('Parameters'),
      dataIndex: 'params',
      key: 'params',
      render: params => <ParamsComponent params={params} />,
    },
  ]

  const handleOnChange = (targetKeys, direction, moveKeys) => {
    onChange(rules.filter(r => targetKeys.findIndex(i => i === r.id) !== -1))
  }

  return (
    <TableTransfer
      loading={loading}
      dataSource={rules}
      titles={[
        <Typography.Title level={5}>
          <FormattedHTMLMessage id="triggers.ruleAdd.availableRules" />
        </Typography.Title>,
        <Typography.Title level={5}>
          <FormattedHTMLMessage id="triggers.ruleAdd.selectedRules" />
        </Typography.Title>,
      ]}
      targetKeys={selectedRules.map(sr => sr.id)}
      // showSearch={showSearch}
      onChange={handleOnChange}
      filterOption={(inputValue, item) =>
        item.title.indexOf(inputValue) !== -1 || item.tag.indexOf(inputValue) !== -1
      }
      leftColumns={leftColumns}
      rightColumns={rightColumns}
    />
  )
}

export default RuleList

const TableTransfer = ({ leftColumns, rightColumns, loading, ...restProps }) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns
      const rowSelection = {
        getCheckboxProps: item => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows.filter(item => !item.disabled).map(({ key }) => key)
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys)
          onItemSelectAll(diffKeys, selected)
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected)
        },
        selectedRowKeys: listSelectedKeys,
      }
      return (
        <Table
          loading={loading}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          scroll={{ y: 300 }}
          style={{
            pointerEvents: listDisabled ? 'none' : undefined,
          }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return
              onItemSelect(key, !listSelectedKeys.includes(key))
            },
          })}
          pagination={false}
        />
      )
    }}
  </Transfer>
)
