const actions = {
  SET_STATE: 'catalog_items/SET_STATE',
  GET_CATALOG_ITEMS : 'catalog_items/GET_CATALOG_ITEMS',
  GET_CATALOG_ITEMS_MENU : 'catalog_items/GET_CATALOG_ITEMS_MENU',
  GET_CATALOG_ITEM_BY_ID : 'catalog_items/GET_CATALOG_ITEM_BY_ID',
  GET_CATALOG_ITEM_VERSION_BY_ID : 'catalog_items/GET_CATALOG_ITEM_VERSION_BY_ID',
  GET_CATALOG_ITEM_VERSION_BY_KEY : 'catalog_items/GET_CATALOG_ITEM_VERSION_BY_KEY',
  PUBLISH_ON_MARKETPLACE : 'catalog_items/PUBLISH_ON_MARKETPLACE',
  UPDATE_CATALOG_ITEM : 'catalog_items/UPDATE_CATALOG_ITEM',
  UPDATE_CATALOG_ITEM_PHOTO : 'catalog_items/UPDATE_CATALOG_ITEM_PHOTO',
  DELETE_CATALOG_ITEM : 'catalog_items/DELETE_CATALOG_ITEM',
  ADD_SCENARIO_TO_CATALOG : 'catalog_items/ADD_SCENARIO_TO_CATALOG',
  ADD_SCENARIO_TO_NEW_CATALOG_ITEM_VERSION : 'catalog_items/ADD_SCENARIO_TO_NEW_CATALOG_ITEM_VERSION',
  CREATE_NEW_SCENARIO_FROM_CATALOG_ITEM : 'catalog_items/CREATE_NEW_SCENARIO_FROM_CATALOG_ITEM',
}

export default actions
