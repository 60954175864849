import { all, takeEvery, put, call } from 'redux-saga/effects';
import { getNews } from 'services/news';
import actions from './actions';

export function* GET_NEWS() {
	const news = yield call(getNews)
	yield put ({
		type : 'news/SET_STATE',
		payload : { news }
	})
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.GET_NEWS, GET_NEWS),
		GET_NEWS()
	]);
}
