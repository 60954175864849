/* eslint-disable prefer-destructuring */
import titleCase from 'utils/titleCase'
import Moment from 'moment'
import { versionFormatter } from 'services/utilities'

class Robot {
  constructor(props) {
    const { id, name, robotconfig, email, userprofile } = props
    if (robotconfig && userprofile) {
      this.id = id
      this.key = id
      this.name = name
      this.email = email
      this.version = versionFormatter(robotconfig?.platform?.agent_version)
      this.api_key = robotconfig.key
      this.status = titleCase(robotconfig.status)
      this.is_active = robotconfig.is_active
      this.icon = robotconfig ? robotconfig.icon : ''
      this.token = robotconfig ? robotconfig.token : ''
      this.hostname = robotconfig.platform ? robotconfig.platform.hostname : '- - -'
      this.ip_address = robotconfig.platform ? robotconfig.platform.ip_address : '- - -'
      this.external_ip = robotconfig.platform ? robotconfig.platform.external_ip : '- - -'
      this.location = robotconfig.platform ? robotconfig.platform.hostname : '- - -'
      this.currently_used_by = robotconfig.current_jobrun

      this.last_used_by = ''
      this.vnc = robotconfig.platform ? robotconfig.platform.is_vnc_available : false
      this.gui = robotconfig.platform ? robotconfig.platform.is_gui_available : false
      this.created_at = Moment(robotconfig.created).format('DD.MM.YYYY HH:mm:ss')
      this.updated_at = Moment(robotconfig.updated).format('DD.MM.YYYY HH:mm:ss')
      this.deleted_at = ''
      this.os = robotconfig.platform ? robotconfig.platform.system : ''
      this.is_public = robotconfig.is_public
      this.is_active = robotconfig.is_active // !IMPORTANT NOTICE this is_active flag is coming from robotconfig DONOTCHANGE !!
      this.allowed_teams = robotconfig.allowed_teams
      this.uptime =
        robotconfig && robotconfig.status !== 'DOWN'
          ? Moment.duration(robotconfig.uptime)
              .asHours()
              .toFixed(0)
          : '-'
      this.boot_time = robotconfig && robotconfig.status !== 'DOWN' ? robotconfig.boottime : null
      this.age = Moment.duration(Moment().diff(Moment(robotconfig.created)))
        .asHours()
        .toFixed(0)

      this.created_by = {
        first_name: userprofile?.created_by?.first_name,
        last_name: userprofile?.created_by?.last_name,
      }
    }
  }
}

export default Robot
