const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOGIN_AS_USER: 'user/LOGIN_AS_USER',
  RESET_LOGIN_AS: 'user/RESET_LOGIN_AS',
  GET_CURRENT_USER: 'user/GET_CURRENT_USER',
  UPDATE_USER: 'user/UPDATE_USER',
  UPDATE_USER_PHOTO: 'user/UPDATE_USER_PHOTO',
  DELETE_USER: 'user/DELETE_USER',
  ADD_NEW_USER: 'user/ADD_NEW_USER',
  CHECK_SESSION: 'user/CHECK_SESSION',
  LOGOUT: 'user/LOGOUT',
  CHANGE_PASSWORD : 'user/CHANGE_PASSWORD'
}

export default actions
