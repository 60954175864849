import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { getIntl } from 'localization'
import {
  updateCatalogItem,
  publishCatalogItem,
  addScenarioToCatalog,
  deleteCatalogItem,
  updateCatalogItemIcon,
  getCatalogItemVersions,
  getCatalogItemVersionByID,
  addScenarioToNewCatalogItemVersion,
  getCatalogItemVersionByKey,
  getCatalogItemVersionsMenu,
} from 'services/catalog_items'
import { createNewScenarioVersion } from 'services/scenarios'
import { history } from 'index'
import actions from './actions'
import { CatalogItemVersionMenuItem, CombinedCatalogItem } from 'models/CatalogItem'

export function* GET_CATALOG_ITEMS({ payload }) {
  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: true },
  })
  try {
    const { success, data } = yield call(getCatalogItemVersions, payload || {})

    if (success) {
      const { count, results } = data
      const item_data = results.map(item => new CombinedCatalogItem(item))

      yield put({
        type: 'catalog_items/SET_STATE',
        payload: { catalog_items: item_data, total: count },
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: false },
  })
}
export function* GET_CATALOG_ITEMS_MENU({ payload }) {
  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: true },
  })
  try {
    const { success, data } = yield call(getCatalogItemVersionsMenu, payload || {})

    if (success) {
      const { count, results } = data
      const item_data = results.map(item => new CatalogItemVersionMenuItem(item))

      yield put({
        type: 'catalog_items/SET_STATE',
        payload: { catalog_items: item_data, total: count },
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: false },
  })
}

export function* GET_CATALOG_ITEM_VERSION_BY_ID({ payload }) {
  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: true },
  })

  const { success, data: item } = yield call(getCatalogItemVersionByID, payload)
  if (success) {
    const catalog_item_version = new CombinedCatalogItem(item)
    yield put({
      type: 'catalog_items/SET_STATE',
      payload: { active_item: catalog_item_version, loading: false },
    })
  } else {
    yield put({
      type: 'catalog_items/SET_STATE',
      payload: { active_item: false },
    })
  }
  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: false },
  })
}
export function* GET_CATALOG_ITEM_VERSION_BY_KEY({ payload }) {
  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: true },
  })

  const { success, data: item } = yield call(getCatalogItemVersionByKey, payload)
  if (success) {
    const catalog_item_version = new CombinedCatalogItem(item)
    yield put({
      type: 'catalog_items/SET_STATE',
      payload: { active_item: catalog_item_version, loading: false },
    })
  } else {
    yield put({
      type: 'catalog_items/SET_STATE',
      payload: { active_item: false },
    })
  }
  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: false },
  })
}

export function* ADD_SCENARIO_TO_CATALOG({ payload }) {
  try {
    const { active_scenario } = yield select()
    const scenario_version_id = active_scenario.version_id
    const scenario_id = active_scenario.id
    yield put({
      type: 'catalog_items/SET_STATE',
      payload: { loading: true },
    })
    const { success } = yield call(addScenarioToCatalog, {
      ...payload,
      scenario_id,
      scenario_version_id,
    })

    if (success) {
      const data = {
        message: getIntl('notifications.catalog_items.add_scenario_to_catalog.success'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'success',
        },
      })
    } else {
      const data = {
        message: getIntl('notifications.catalog_items.add_scenario_to_catalog.fail'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
}
export function* ADD_SCENARIO_TO_NEW_CATALOG_ITEM_VERSION({ payload }) {
  const {
    item: { short_description: description, catalog_item: catalog_item_id },
    cloud_url,
  } = payload
  const { active_scenario } = yield select()
  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: true },
  })
  // Create new scenario version
  const { success: scenarioVersionSuccess, newScenarioVersion } = yield call(
    createNewScenarioVersion,
    {
      active_scenario,
      description,
    },
  )

  if (scenarioVersionSuccess) {
    const { success } = yield call(addScenarioToNewCatalogItemVersion, {
      catalog_item_id,
      scenario_version_id: newScenarioVersion.id,
      cloud_url,
    })
    if (success) {
      const data = {
        message: getIntl('notifications.catalog_items.add_scenario_to_catalog.success'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'success',
        },
      })
    } else {
      const data = {
        message: getIntl('notifications.catalog_items.add_scenario_to_catalog.fail'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }
  }
}

export function* UPDATE_CATALOG_ITEM({ payload }) {
  const { catalog_items } = yield select()
  let { active_item } = catalog_items

  // Remove logo property from uploading because its a file not a string value.
  delete active_item.icon

  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: true },
  })

  if (payload) active_item = { ...active_item, ...payload }
  const { success, catalog_item } = yield call(updateCatalogItem, active_item)

  if (success) {
    yield put({
      type: 'catalog_items/GET_CATALOG_ITEM_VERSION_BY_ID',
      payload: { id: active_item.catalogitemversion.id },
    })
  }
  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: false },
  })
}

export function* PUBLISH_ON_MARKETPLACE({ payload }) {
  const { id, catalog_item_version_id } = payload
  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: true },
  })

  const { success } = yield call(publishCatalogItem, payload)

  if (success) {
    yield put({
      type: 'catalog_items/GET_CATALOG_ITEM_VERSION_BY_ID',
      payload: { id: catalog_item_version_id },
    })

    const data = {
      message: getIntl('notifications.catalog_items.publishOnMarketplaceRequest.success'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
  } else {
    const data = {
      message: getIntl('notifications.catalog_items.publishOnMarketplaceRequest.fail'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }

  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: false },
  })
}
export function* UPDATE_CATALOG_ITEM_PHOTO({ payload }) {
  const {
    catalog_items: { active_item },
  } = yield select()
  yield put({
    type: 'catalog_items/SET_STATE',
    payload: {
      icon_loading: true,
    },
  })
  const { success, catalog_item } = yield call(updateCatalogItemIcon, payload)
  const data = { ...active_item, icon: catalog_item.icon }

  if (success) {
    yield put({
      type: 'catalog_items/SET_STATE',
      payload: {
        active_item: data,
      },
    })
  }
  yield put({
    type: 'catalog_items/SET_STATE',
    payload: {
      icon_loading: false,
    },
  })
}

export function* DELETE_CATALOG_ITEM({ payload }) {
  const { id } = payload

  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: true },
  })
  const { success } = yield call(deleteCatalogItem, { id })

  if (success) {
    const data = {
      message: getIntl('notifications.catalog_items.delete_item.success'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
  } else {
    const data = {
      message: getIntl('notifications.catalog_items.delete_item.fail'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
    return false
  }

  yield put({
    type: 'catalog_items/SET_STATE',
    payload: { loading: false },
  })

  history.push('/catalogs')
}
/*
export function* CREATE_NEW_SCENARIO_FROM_CATALOG_ITEM({ payload }) {
  const { newScenarioName, scenario_version, modal } = payload
  const { success, scenario_version: scenarioVersion } = yield call(getScenarioVersionByID, {
    scenario_version_id: scenario_version.id,
  })
  const { flow_data, content } = scenarioVersion
  if (success && content) {
    // If related scenario version exist continue
    const {
      success: createSuccess,
      scenario_version: newScenarioVersion,
    } = yield call(createScenario, { name: newScenarioName })
    if (createSuccess) {
      const { success: dataDuplicateSuccess } = yield call(duplicateScenarioVersion, {
        content,
        flow_data,
        version_id: newScenarioVersion.id,
      })
      if (dataDuplicateSuccess) {
        history.push(`/scenario-designer/?version=${newScenarioVersion.id}`)
      }
    }
  } else {
    modal.warning({
      title: getIntl('catalogItems.createNewScenarioFromCatalogItem.warningDialog.title'),
      content: getIntl('catalogItems.createNewScenarioFromCatalogItem.warningDialog.content'),
    })
  }
} */

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CATALOG_ITEMS_MENU, GET_CATALOG_ITEMS_MENU),
    takeEvery(actions.GET_CATALOG_ITEMS, GET_CATALOG_ITEMS),
    takeEvery(actions.GET_CATALOG_ITEM_VERSION_BY_ID, GET_CATALOG_ITEM_VERSION_BY_ID),
    takeEvery(actions.GET_CATALOG_ITEM_VERSION_BY_KEY, GET_CATALOG_ITEM_VERSION_BY_KEY),
    takeEvery(actions.UPDATE_CATALOG_ITEM, UPDATE_CATALOG_ITEM),
    takeEvery(actions.UPDATE_CATALOG_ITEM_PHOTO, UPDATE_CATALOG_ITEM_PHOTO),
    takeEvery(actions.PUBLISH_ON_MARKETPLACE, PUBLISH_ON_MARKETPLACE),
    takeEvery(actions.DELETE_CATALOG_ITEM, DELETE_CATALOG_ITEM),
    takeEvery(actions.ADD_SCENARIO_TO_CATALOG, ADD_SCENARIO_TO_CATALOG),
    takeEvery(
      actions.ADD_SCENARIO_TO_NEW_CATALOG_ITEM_VERSION,
      ADD_SCENARIO_TO_NEW_CATALOG_ITEM_VERSION,
    ),
    // takeEvery(actions.CREATE_NEW_SCENARIO_FROM_CATALOG_ITEM, CREATE_NEW_SCENARIO_FROM_CATALOG_ITEM),
  ])
}
