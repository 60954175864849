import { createRecord, getFirst, getList } from 'api/requests'

const getAgreement = async filters => {
  const result = await getFirst('agreements', { is_active: 1, ...filters }, true)
  return result
}
const getAgreements = async filters => {
  const result = await getList('agreements', { is_active: 1, ...filters })
  return result
}
const getUnapprovedAgreements = async () => {
  const result = await getList('users/current/mustapprove/agreements')
  return result
}

const updateAgreement = async (data) => {
  const result = await createRecord('agreements', data)
  return result
}

export { getAgreements, getAgreement, getUnapprovedAgreements, updateAgreement }
