const actions = {
  SET_STATE: 'jobs/SET_STATE',
  GET_JOBS : 'jobs/GET_JOBS',
  ADD_NEW_JOB : 'jobs/ADD_NEW_JOB',
  UPDATE_JOB : 'jobs/UPDATE_JOB',
  CANCEL_JOB : 'jobs/CANCEL_JOB',
  DELETE_JOB : 'jobs/DELETE_JOB',
  ADD_NEW_SCHEDULED_JOB : 'jobs/ADD_NEW_SCHEDULED_JOB',
  CANCEL_SCHEDULE : 'jobs/CANCEL_SCHEDULE',
}

export default actions
