import React from 'react'
import { getIntl } from 'localization'
import { store } from 'index'

const errorCodes = ['token_not_valid']

const checkUrls = errorUrl => {
  const urls = ['api.kronnika.dev/api/token/', 'api.kronnika.io/api/token/']
  const allowed = urls.some(u => {
    return errorUrl.includes(u)
  })
  return allowed
}

const notify = (title, messages, translate = false) => {
  const data = {
    message: translate ? getIntl(title) : title,
    description: messages
      ? messages?.map(message => <p key={message}> {translate ? getIntl(message) : message}</p>)
      : undefined,
  }
  store.dispatch({
    type: 'internal_notification/SET_STATE',
    payload: {
      data,
      type: 'success',
    },
  })
}

const statusHandle = (status, error) => {
  switch (status) {
    case 403:
      notify(
        error.detail,
        error.messages?.map(m => m.message),
      )
      break
    case 401:
      notify(
        error.detail,
        error.messages?.map(m => m.message),
      )
      break
    case 400:
      Object.keys(error).forEach(key => {
        notify(key.toLocaleUpperCase(), error[key])
      })
      break
    case 500:
      Object.keys(error).forEach(key => {
        notify(key.toLocaleUpperCase(), error[key])
      })
      break
    default:
      break
  }
}

const handleAPIError = error => {
  const { response, config } = error
  if (!response) {
    return false
  }

  const { data, status, statusText } = response
  const { url, method } = config
  const { code, detail, messages } = data

  if (!checkUrls(url)) return false

  statusHandle(status, data)
}

export { notify, handleAPIError }
