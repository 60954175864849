import styled, { css } from 'styled-components'

const animation = `

@keyframes animateOutline {
  0% {
    outline-width: 1px;
    outline-offset: 0;
    outline-color: #df255a;
  }

  10% {
    outline-color: #df255acc;
  }


  50% {
    outline-width: 4px;
    outline-offset: 0px;
    outline-color: #df255aff;
  }

  100% {
    outline-width: 4px;
    outline-offset: 0px;
    outline-color: #df255a00;
  }
}`

const nodeBorderRadius = '4px'
const nodeBorderStyle = 'outline: 1px solid #80808080;'

const hoveredstyle = `
&:hover {
  cursor: move;
  outline: 1px solid #202b3f;
}
`

const selectedStyle = `
@keyframes selectedAnimation {
  0% {
    outline: 2px solid #202b3f;
  }
  50% {
    outline: 2px solid #df255aff;
  }
  100% {
    outline: 2px solid #202b3f;
  }
}
&:hover {
  outline: 2px solid #df255aff;
}
outline: 2px solid #df255aff;
// animation: selectedAnimation 1.5s infinite;


`

const IfNode = styled.div`
  position: absolute;
  width: 180px;
  height: 50;
  padding: 10px;
  background: white;
  ${nodeBorderStyle}
  border-radius: ${nodeBorderRadius};
  font-size: 13px;
  transition: 0.3s ease box-shadow, 0.3s ease margin-top;
  ${hoveredstyle}

  ${props =>
    props.isSelected &&
    css`
      ${selectedStyle}
    `}
  ${props =>
    props.isSkipped &&
    css`
      opacity: 0.8;
      background: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 10px,
        #ccc 10px,
        #ccc 20px
      );
    `}
`
const WhileNode = styled.div`
  position: absolute;
  padding: 10px;
  background: white;
  ${nodeBorderStyle}
  border-radius: ${nodeBorderRadius};
  font-size: 13px;
  transition: 0.3s ease box-shadow, 0.3s ease margin-top;
  ${hoveredstyle}

  ${props =>
    css`
      border-top: 5px solid ${props.color};
    `}
  ${props =>
    props.isSelected &&
    css`
      ${selectedStyle}
    `}
  ${props =>
    props.isSkipped &&
    css`
      opacity: 0.8;
      background: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 10px,
        #ccc 10px,
        #ccc 20px
      );
    `}
`
const ForEachNode = styled.div`
  position: absolute;
  padding: 10px;
  background: white;
  ${nodeBorderStyle}
  border-radius: ${nodeBorderRadius};
  font-size: 13px;
  transition: 0.3s ease box-shadow, 0.3s ease margin-top;
  ${hoveredstyle}
  ${props =>
    css`
      border-top: 5px solid ${props.color};
    `}

  ${props =>
    props.isSelected &&
    css`
      ${selectedStyle}
    `}
  ${props =>
    props.isSkipped &&
    css`
      opacity: 0.8;
      background: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 10px,
        #ccc 10px,
        #ccc 20px
      );
    `}
`
const TryCatchNode = styled.div`
  position: absolute;
  padding: 10px;
  background: white;
  ${nodeBorderStyle}
  border-radius: ${nodeBorderRadius};
  font-size: 13px;
  min-width: 150px;
  transition: 0.3s ease box-shadow, 0.3s ease margin-top;
  ${hoveredstyle}


  ${props =>
    props.isSelected &&
    css`
      ${selectedStyle}
    `}
  ${props =>
    css`
      border-top: 5px solid ${props.color};
    `}
  ${props =>
    props.isSkipped &&
    css`
      opacity: 0.8;
      background: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 10px,
        #ccc 10px,
        #ccc 20px
      );
    `}
`
const TaskNode = styled.div`
  ${animation}
  position: absolute;
  width: 180px;
  height: auto;
  padding: 8px;
  background: white;
  ${nodeBorderStyle}
  border-radius: ${nodeBorderRadius};
  font-size: 13px;
  transition: 0.5s ease box-shadow, 0.5s ease margin-top;
  ${hoveredstyle}

  ${props =>
    props.isSelected &&
    css`
      ${selectedStyle}
    `}
  ${props =>
    props.isSkipped &&
    css`
      opacity: 0.8;
      background: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 10px,
        #ccc 10px,
        #ccc 20px
      );
    `}
  ${props =>
    props.isSearched &&
    css`
      animation: animateOutline 1s ease infinite;
    `}
`
const IfPort = styled.div`
  position: absolute;
  width: 180px;
  height: 50;
  padding: 10px;
  background: white;
  border-radius: ${nodeBorderRadius};

  ${props =>
    props.isSelected &&
    css`
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      margin-top: -2px;
    `}
  ${props =>
    props.isSkipped &&
    css`
      opacity: 0.8;
      background: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 10px,
        #ccc 10px,
        #ccc 20px
      );
    `}
`
const PortDefaultOuter = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover > div {
    background: #df255a;
  }
`
const PortDefaultInner = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props => (props.active ? '#df255a' : 'grey')};
  cursor: pointer;
`
const PortIfOuter = styled.div`
  width: 24px;
  height: 48px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover > div {
    background: #df255a;
  }

  overflow-x: visible;
`
const PortWhileElseOuter = styled.div`
  width: 48px;
  height: 24px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover > div {
    background: #df255a;
  }

  overflow-x: visible;
`
const PortWhileElseInner = styled.div`
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background: ${props => (props.active ? '#df255a' : 'grey')};
  cursor: pointer;
`
const PortIfInner = styled.div`
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background: ${props => (props.active ? '#df255a' : 'grey')};
  cursor: pointer;
`
const PortThenText = styled.div`
  transform: translate(-50%, -50%) rotate(90deg);
  white-space: nowrap;
  top: 50%;
  left: 50%;
  position: absolute;
  text-align: center;
  cursor: pointer;
  color: white;
  font-size: smaller;
`
const PortElseText = styled.div`
  transform: translate(-50%, -50%) rotate(270deg);
  white-space: nowrap;
  top: 50%;
  left: 50%;
  position: absolute;
  text-align: center;
  cursor: pointer;
  color: white;
  font-size: smaller;
`
const PortWhileElseText = styled.div`
  transform: translate(-50%, -50%) rotate(0deg);
  white-space: nowrap;
  top: 50%;
  left: 50%;
  position: absolute;
  text-align: center;
  cursor: pointer;
  color: white;
  font-size: smaller;
`
const ContextMenu = styled.div`
  padding: 2px;
  background: white;
  border-radius: 0px 5px 5px 5px;
  font-size: 13px;
  transition: 0.5s ease box-shadow, 0.5s ease margin-top;
  outline: 1px solid #df255acc;
`

const CanvasInnerWrapper = styled.div`
  position: relative;
  outline: 1px dashed rgba(0, 0, 0, 0.1);
  width: 10000px;
  height: 10000px;
  ${props =>
    props.config.nodeAddMode
      ? css`
          cursor: copy;
        `
      : css`
          cursor: default;
        `}
`
export {
  IfNode,
  WhileNode,
  ForEachNode,
  TryCatchNode,
  TaskNode,
  IfPort,
  PortDefaultInner,
  PortDefaultOuter,
  PortElseText,
  PortIfInner,
  PortIfOuter,
  PortThenText,
  PortWhileElseInner,
  PortWhileElseOuter,
  PortWhileElseText,
  ContextMenu,
  CanvasInnerWrapper,
}
