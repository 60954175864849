import { Col, List, Row, Button, Divider } from 'antd'
import React, { useState, useEffect } from 'react'
import { getIntl } from 'localization'
import moment from 'moment'
import style from './style.module.scss'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { connect } from 'react-redux'
import FilterList from './components/FilterList'
import Resources from './components/Resources'
import Newsletter from './components/Newsletter'

const Footer = () => {
  const { xs } = useBreakpoint()

  const socialButtons = [
    { key: 'facebook', href: 'https://www.facebook.com/RpaKronnika/', icon: 'fa fa-facebook' },
    { key: 'twitter', href: 'https://twitter.com/RpaKronnika', icon: 'fa fa-twitter' },
    {
      key: 'instagram',
      href: 'https://www.instagram.com/kronnika_rpa/',
      icon: 'fa fa-instagram',
    },
    {
      key: 'linkedin',
      href: 'https://www.linkedin.com/company/kronnika',
      icon: 'fa fa-linkedin',
    },
    { key: 'google', href: 'https://g.page/kronnika?we', icon: 'fa fa-google' },
  ]

  const footerColumnSize = xs ? 12 : 4
  return (
    <div className={style.footer}>
      <Row justify="center">
        <Col xs={22} sm={18}>
          <Row gutter={[8, 8]} justify="space-between">
            <Col span={footerColumnSize}>
              <FilterList type={1} title="Industry" withAll />
            </Col>
            <Col span={footerColumnSize}>
              <FilterList type={2} title="Function" />
            </Col>
            <Col span={footerColumnSize}>
              <FilterList type={3} title="Application" />
            </Col>
            <Col span={footerColumnSize}>
              <Resources type={3} title="Application" />
            </Col>
            <Col span={footerColumnSize}>
              <Newsletter type={3} title="Application" />
            </Col>
          </Row>
          <Divider />
          <div className="mb-4">
            <Row gutter={[16, 16]} justify="center">
              <Col
                xs={{ span: 24, order: 2 }}
                sm={{ span: 12, order: 1 }}
                className={xs ? 'text-center' : undefined}
              >
                <a href="#">
                  <img
                    src="/resources/images/kronnika-logo.png"
                    className="mr-4"
                    height="30px"
                    alt="Kronnika"
                  />
                </a>
                {xs && (
                  <>
                    <br />
                    <br />
                  </>
                )}
                © 2019 - {moment().format('YYYY')} Kronnika. {getIntl('footer.copyright')}
              </Col>
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 12, order: 2 }}
                className={xs ? 'text-center' : 'text-right'}
              >
                {socialButtons.map(({ key, href, icon }) => (
                  <a key={key} href={href} target="_blank" rel="noopener noreferrer">
                    <Button
                      type="primary"
                      className="mr-2"
                      shape="circle"
                      icon={<i className={icon} />}
                    />
                  </a>
                ))}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}
const mapStateToProps = ({ settings }) => ({ settings })
export default connect(mapStateToProps)(Footer)
