import actions from './actions'

const initialState = {
  activeTour: {
    name: null,
    currentStep: null,
    finished: false,
  },
  welcomeTour: false,
  welcomeFinishTour: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case 'tours/RESET':
      return initialState
    default:
      return state
  }
}
