const actions = {
  SET_STATE: 'scenarios/SET_STATE',
  GET_SCENARIOS : 'scenarios/GET_SCENARIOS',
  GET_SCENARIO : 'scenarios/GET_SCENARIO',
  GET_SCENARIO_BY_VERSION_ID : 'scenarios/GET_SCENARIO_BY_VERSION_ID',
  GET_SCENARIO_VERSION_BY_ID : 'scenarios/GET_SCENARIO_VERSION_BY_ID',
  SAVE_SCENARIO : 'scenarios/SAVE_SCENARIO',
  SAVE_AS_NEW_SCENARIO_VERSION : 'scenarios/SAVE_AS_NEW_SCENARIO_VERSION',
  DELETE_SCENARIO : 'scenarios/DELETE_SCENARIO',
  GET_SCENARIO_LIST : 'scenarios/GET_SCENARIO_LIST',
}

export default actions
