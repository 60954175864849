import Vault from 'models/Vault'
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { getVaults, createVault, updateVault, deleteVault } from 'services/vaults'
import { dataToFileDownload } from 'services/utilities'
import slugify from 'slugify'
import actions from './actions'
import {
  encryptData,
  encryptDataWithPublicKey,
} from 'components/widgets/Vault/components/VaultForm/functions'
import { notification, Modal, App } from 'antd'
import { getIntl } from 'localization'

export function* GET_VAULTS({ payload }) {
  yield put({
    type: 'vaults/SET_STATE',
    payload: { loading: true },
  })

  try {
    const { success, data } = yield call(getVaults, payload?.filters || undefined)
    if (success) {
      const { count, results } = data
      const vaults = results.map(result => new Vault(result))
      yield put({
        type: 'vaults/SET_STATE',
        payload: { vaults: vaults.sort((a, b) => b.id - a.id), count },
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'vaults/SET_STATE',
    payload: { loading: false },
  })
}
export function* CREATE_VAULT({ payload }) {
  yield put({
    type: 'vaults/SET_STATE',
    payload: { loading: true },
  })

  try {
    const { name, description, unencryptedPayload } = payload.data
    const { encrypted, private_key, public_key } = encryptData(unencryptedPayload)
    const identifier = slugify(name, {
      lower: true,
      strict: true,
      locale: 'en',
    }).replaceAll('-', '_')
    const payload_data = {
      name,
      description,
      identifier,
      public_key,
      payload: encrypted,
    }
    const { success, data } = yield call(createVault, payload_data)

    if (success) {
      yield put({
        type: 'vaults/GET_VAULTS',
      })
      payload.modal.warning({
        title: `${getIntl('Caution')} !`,
        content: getIntl('warnings.vault.privateKeySafety'),
        onOk: () => {
          dataToFileDownload(`${identifier}.pem`, private_key)
          if (payload.onFinish) {
            payload.onFinish(data.id)
          }
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'vaults/SET_STATE',
    payload: { loading: false },
  })
}
export function* UPDATE_VAULT({ payload }) {
  yield put({
    type: 'vaults/SET_STATE',
    payload: { loading: true },
  })

  try {
    const {
      vaults: { activeItem },
    } = yield select()
    const { id, public_key, description, unencryptedPayload } = payload
    const { encrypted } = encryptDataWithPublicKey(unencryptedPayload, activeItem.public_key)
    const data = {
      description,
      payload: encrypted,
    }
    const { success } = yield call(updateVault, { id, data })
    if (success) {
      notification.success({
        message: getIntl('notifications.updateVault.success'),
      })
      yield put({
        type: 'vaults/GET_VAULTS',
      })
    } else {
      notification.error({
        message: getIntl('notifications.updateVault.error'),
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'vaults/SET_STATE',
    payload: { loading: false },
  })
}

export function* DELETE_VAULT({ payload }) {
  yield put({
    type: 'vaults/SET_STATE',
    payload: { loading: true },
  })

  try {
    const { id } = payload.data
    const { success } = yield call(deleteVault, id)
    if (success) {
      yield put({
        type: 'vaults/GET_VAULTS',
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'vaults/SET_STATE',
    payload: { loading: false },
  })
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_VAULTS, GET_VAULTS),
    takeEvery(actions.CREATE_VAULT, CREATE_VAULT),
    takeEvery(actions.UPDATE_VAULT, UPDATE_VAULT),
    takeEvery(actions.DELETE_VAULT, DELETE_VAULT),
  ])
}
