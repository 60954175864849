import Moment from 'moment'
import { convertToSlug } from 'utils/slugger'
import titleCase from 'utils/titleCase'
import BaseModel from './BaseModel'

class Library extends BaseModel {
  constructor(props) {
    const { name, libraryitems, created } = props
    super(props)
    this.name = titleCase(name, '_')
    this.key = convertToSlug(name)
    this.children = libraryitems
      .map(i => ({
        name: titleCase(i.name, '_'),
        key: convertToSlug(`${name} ${i.name}`),
        url: `${convertToSlug(name)}/${convertToSlug(i.name)}`,
        // docType: 'library',
        ...i,
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
    this.created = Moment(created).format('YYYY-MM-DD HH:mm:ss:SSSS')
  }
}

export default Library
