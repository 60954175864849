import { all, takeEvery, put, select } from 'redux-saga/effects'
import { setModified } from 'utils/scenarioChangeHandler'
import actions from './actions'
import { cloneDeep } from 'lodash'

export function* SET_ACTIVE_STEP({ payload }) {
  const { active_scenario } = yield select()
  const { stepId, taskId } = payload
  yield put({
    type: 'active_step/RESET',
  })

  const activeStep = active_scenario.content.chart.nodes[taskId].properties.chart.nodes[stepId]

  yield put({
    type: 'active_step/SET_STATE',
    payload: {
      ...activeStep.properties.detail,
      ...{
        id: stepId,
        libraryitem_version_id: activeStep.properties.detail.id,
        title: activeStep.properties.name,
        customName: activeStep.properties.customName || null,
      },
    },
  })
}

export function* SAVE_ACTIVE_STEP({ payload }) {
  const { formData, stepId, taskId } = payload
  const { active_scenario } = yield select()
  const scenario = cloneDeep(active_scenario)

  try {
    scenario.content.chart.nodes[taskId].properties.chart.nodes[
      stepId
    ].properties.detail.formData = formData

    yield put({
      type: 'active_scenario/SET_STATE',
      payload: scenario,
    })

    setModified('scenario')
  } catch (e) {
    console.log(e)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_ACTIVE_STEP, SET_ACTIVE_STEP),
    takeEvery(actions.SAVE_ACTIVE_STEP, SAVE_ACTIVE_STEP),
  ])
}
