import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import TopBar from 'components/layout/MarketplaceTopBar'
import CookieBanner from 'components/system/Cookie'
import { Helmet } from 'react-helmet'
import { getIntl } from 'localization'

const mapStateToProps = ({ settings }) => ({ settings })

const MarketplaceLayout = ({ children }) => {
  return (
    <Layout>
      <Layout.Header id="pageHeader">
        <TopBar />
      </Layout.Header>
      <Layout.Content style={{ height: '100%', position: 'sticky' }}>
        <Helmet title={getIntl('titles.publicMarketplace')} />
        {children}
      </Layout.Content>
      <CookieBanner />
    </Layout>
  )
}

export default connect(mapStateToProps)(MarketplaceLayout)
