import React from 'react'

const TryCatchNodeInner = ({ node }) => {
  const onChange = e => {
    const exceptionList = e.target.value
      .split(',')
      .map(s => s.trim())
      .filter(e => e !== '')
    node.properties.detail.condition = exceptionList
  }
  let component = null

  switch (node.properties.subtype) {
    case 'start':
      break
    case 'catch':
      component = (
        <input
          placeholder="Enter your exception type here"
          onChange={onChange}
          onClick={e => e.stopPropagation()}
          onMouseUp={e => e.stopPropagation()}
          onMouseDown={e => e.stopPropagation()}
          onKeyPress={e => e.stopPropagation()}
          onKeyDown={e => e.stopPropagation()}
          onKeyUp={e => e.stopPropagation()}
          defaultValue={node.properties.detail.condition}
        />
      )
      break
    case 'final':
      break
    default:
      break
  }

  return (
    <div style={{ padding: 0 }}>
      <p className="mb-0 text-center" style={{ color: 'black' }}>
        {node.properties.name}
      </p>
      {component}
    </div>
  )
}
export default TryCatchNodeInner
