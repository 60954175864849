const actions = {
  SET_STATE: 'documentation/SET_STATE',
  GET_DOC_MENU: 'documentation/GET_DOC_MENU',
  GET_DOCS: 'documentation/GET_DOCS',
  GET_DOC: 'documentation/GET_DOC',
  GET_LIBRARY_ITEM_DOC: 'documentation/GET_LIBRARY_ITEM_DOC',
  GET_LIBRARIES_MENU: 'documentation/GET_LIBRARIES_MENU',
  GET_DOC_VERSION: 'documentation/GET_DOC_VERSION',
  GET_DOC_TYPES: 'documentation/GET_DOC_TYPES',
  CREATE_DOC: 'documentation/CREATE_DOC',
  CREATE_DOC_VERSION: 'documentation/CREATE_DOC_VERSION',
  UPDATE_DOC : 'documentation/UPDATE_DOC',
  DELETE_DOC : 'documentation/DELETE_DOC',
  SAVE_DOCUMENT : 'documentation/SAVE_DOCUMENT',
}

export default actions
