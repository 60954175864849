import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'

const tours = {
  welcome: {
    disabled:false,
    steps: [],
  },
  interfaceTour: {
    disabled: false,
    steps: [
      {
        selector: '[data-tour="menuLeft"]',
        content: <FormattedHTMLMessage id="tours.interfaceTour.leftMenuInfo" />,
      },
      {
        selector: '[data-tour="tourElements-robotManager"]',
        content: (
          <>
            <p>
              <FormattedHTMLMessage id="tours.interfaceTour.robotManager" />
            </p>
            <ul>
              <li>
                <p>
                  <FormattedHTMLMessage id="tours.interfaceTour.robotManager.li.setup" />
                </p>
              </li>
              <li>
                <p>
                  <FormattedHTMLMessage id="tours.interfaceTour.robotManager.li.create" />
                </p>
              </li>
            </ul>
          </>
        ),
      },
      {
        selector: '[data-tour="tourElements-scenarioManager"]',
        content: <FormattedHTMLMessage id="tours.interfaceTour.scenarioManager" />,
      },
      {
        selector: '[data-tour="tourElements-infoNSupport"]',
        content: (
          <>
            <p>
              <FormattedHTMLMessage id="tours.interfaceTour.infoNSupport" />,
            </p>
            <ul>
              <li>
                <p>
                  <FormattedHTMLMessage id="tours.interfaceTour.infoNSupport.li.create" />
                </p>
              </li>
              <li>
                <p>
                  <FormattedHTMLMessage id="tours.interfaceTour.infoNSupport.li.setup" />
                </p>
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  designerTour: {
    disabled: true,
    steps: [
      {
        selector: '[data-tour="designerInitPanel"]',
        content: <FormattedHTMLMessage id="tours.designerTour.scenarioDesignerInitial" />,
      },
      {
        selector: '[data-tour="newScenarioButton"]',
        content: <FormattedHTMLMessage id="tours.designerTour.newScenarioButton" />,
      },
      {
        selector: '[data-tour="openScenarioButton"]',
        content: <FormattedHTMLMessage id="tours.designerTour.openScenarioButton" />,
      },
    ],
  },
  designerInterfaceTour: {
    disabled: true,
    steps: [
      {
        selector: '[data-tour="scenarioOperationsMenu"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.scenarioOperations" />,
      },
      {
        selector: '[data-tour="marketMenu"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.marketMenu" />,
      },
      {
        selector: '[data-tour="vaultButton"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.vaultButton" />,
      },
      {
        selector: '[data-tour="runButton"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.runButton" />,
      },
      {
        selector: '[data-tour="scheduleButton"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.scheduleButton" />,
      },
      {
        selector: '[data-tour="designerTab"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.designerTab" />,
      },
      {
        selector: '[data-tour="settingsTab"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.settingsTab" />,
      },
      {
        selector: '[data-tour="filesTab"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.filesTab" />,
      },
      {
        selector: '[data-tour="parametersTab"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.parametersTab" />,
      },
      {
        selector: '[data-tour="robotsTab"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.robotsTab" />,
      },
      {
        selector: '[data-tour="versionsTab"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.versionsTab" />,
      },
      {
        selector: '[data-tour="previewTab"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.previewTab" />,
      },
      {
        selector: '[data-tour="scenarioNameButton"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.scenarioNameButton" />,
      },
      {
        selector: '[data-tour="scenarioInfoButton"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.scenarioInfoButton" />,
      },
    ],
  },
  newScenarioTour: {
    disabled: true,
    steps: [
      {
        selector: '[data-tour="newScenarioName"]',
        content: <FormattedHTMLMessage id="tours.newScenarioTour.newScenarioName" />,
      },
      {
        selector: '[data-tour="fromCatalogCheckbox"]',
        content: <FormattedHTMLMessage id="tours.newScenarioTour.fromCatalogCheckbox" />,
      },
      {
        selector: '[data-tour="createScenarioButton"]',
        content: <FormattedHTMLMessage id="tours.newScenarioTour.createScenarioButton" />,
      },
    ],
  },
}

export default tours
