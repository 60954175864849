import actions from './actions'

const initialState = {
  jobsTableFilters : {},
  tasksTableFilters : {},
  jobsTable_SelectedRows : [],
  jobsTable_ExpandedRows : [],
  jobrunsTable_SelectedRows : [],
  taskrunsTable_SelectedRows : [],
  details_section : false,
  log_type : 'jobrun',
  streaming : true,
  newRunningJob : null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET:
      return initialState
    default:
      return state
  }
}
