import actions from './actions'

const initialState = {
  todays_time_saving: '',
  all_time_saving: '',
  last_months_saving: '',
  this_months_saving: '',
  user_total_time_saving: '',
  user_scenarios_count: 0,
  user_jobs_count: 0,
  job_statistics: {},
  task_statistics: {},
  robot_statistics: {},
  jobrun_by_status: null,
  robots: {
    loading: false,
    status: null,
  },
  scenarios: {
    loading: false,
    is_public: null,
  },
  jobruns: {
    loading: false,
    status: null,
  },
  taskruns: {
    loading: false,
    status: null,
  },
  reports: {},
}

export default function statisticsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case 'RESET_ALL':
      return initialState
    default:
      return state
  }
}
