import { history } from 'index'
import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  getDocs,
  getDoc,
  getDocMenu,
  createDoc,
  getDocTypes,
  createDocVersion,
  updateDoc,
  deleteDoc,
  getLibraryItemDoc,
} from 'services/documentation'
import { getLibraries } from 'services/libraries'
import _ from 'lodash'
import actions from './actions'
import { convertToSlug } from 'utils/slugger'
import { getIntl } from 'localization'
import Library from 'models/Library'

export function* CREATE_DOC({ payload: { docData } }) {
  try {
    const {
      settings: { locale },
    } = yield select()
    const { title, description, docType, parentTopics } = docData
    const parentKey = parentTopics[parentTopics.length - 1].key
    const data = {
      key: convertToSlug(`${parentKey}-${title}`),
      language: locale.split('-')[0],
      name: title,
      description,
      type: docType,
      parent: parentTopics[parentTopics.length - 1].id,
    }
    const { success, data: document } = yield call(createDoc, { locale, data })

    if (success) {
      const data = {
        message: getIntl('notifications.documentation.createDoc.success'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'success',
        },
      })
      history.push(`/info-support/knowledge-base/${document.key}`)
    } else {
      const data = {
        message: getIntl('notifications.documentation.createDoc.error'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
}
export function* GET_DOC({ payload: { id, document_key } }) {
  yield put({
    type: 'documentation/SET_STATE',
    payload: { loadingDoc: true },
  })

  const {
    settings: { locale },
  } = yield select()
  try {
    // If gets document key
    if (document_key) {
      const { success, data } = yield call(getDoc, { key: document_key })
      if (success) {
        yield put({
          type: 'documentation/SET_STATE',
          payload: { loadingDoc: false, activeDoc: data },
        })
      }
      return
    }

    // If gets id for documents
    const { success, doc } = yield call(getDoc, { id, locale })
    if (success) {
      yield put({
        type: 'documentation/SET_STATE',
        payload: { activeDoc: doc },
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'documentation/SET_STATE',
    payload: { loadingDoc: false },
  })
}

export function* GET_DOC_VERSION({ payload: { id } }) {
  const {
    settings: { locale },
  } = yield select()
  try {
    const data = yield call(getDoc, { id, locale })
    if (data) {
      yield put({
        type: 'documentation/SET_STATE',
        payload: { activeDoc: data },
      })
    }
  } catch (e) {
    console.log(e)
  }
}

const createMenu = docs => {
  function flatten(ms) {
    let result = []
    _.each(ms, m => {
      result.push(m)
      if (m.children) result = _.union(result, flatten(m.children))
    })
    return result
  }
  const childRec = (parentKey, source) => {
    const sChildren = []
    if (source && source.length > 0) {
      source.forEach(child => {
        const childData = docs.find(dd => dd.id === child.id)
        if (childData) {
          const { id, key, name, children } = childData
          const url = `${parentKey}/${key}`
          const d = {
            id,
            url,
            title: name,
            category: true,
          }
          if (children && children.length) {
            Object.assign(d, { children: childRec(url, children) })
          }
          sChildren.push(d)
        }
      })
    }
    return sChildren
  }

  const menu = []
  // Iterate and only get top level docs then find children
  docs.forEach(({ id, parent, name, key, children }) => {
    if (!parent) {
      menu.push({ id, title: name, url: key, children: childRec(key, children) })
    }
  })

  return menu
}
export function* GET_DOCS() {
  const {
    settings: { locale },
  } = yield select()
  try {
    const { success, docs } = yield call(getDocs, { locale: locale.split('-')[0] })
    if (success) {
      // Check if data retrieved from API
      yield put({
        type: 'documentation/SET_STATE',
        payload: { docs, menuData: createMenu(docs) },
        // payload: { docs, menuData: createMenu(docs) }
      })
    }
  } catch (e) {
    console.log(e)
  }
}
export function* GET_LIBRARIES_MENU() {
  yield put({
    type: 'documentation/SET_STATE',
    payload: { libraries: [] },
  })

  yield put({
    type: 'documentation/SET_STATE',
    payload: { loadingMenu: true },
  })
  try {
    const { success, data } = yield call(getLibraries)

    if (success) {
      const librariesData = data.results
      const libraries = librariesData
        .map(library => new Library(library))
        .sort((a, b) => a.name.localeCompare(b.name))
      yield put({
        type: 'documentation/SET_STATE',
        payload: { libraries },
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'documentation/SET_STATE',
    payload: { loadingMenu: false },
  })
}

export function* GET_LIBRARY_ITEM_DOC({ payload: { id } }) {
  const {
    settings: { locale },
  } = yield select()
  try {
    const { success, doc } = yield call(getLibraryItemDoc, { id })

    if (success) {
      const document = doc[locale]
      yield put({
        type: 'documentation/SET_STATE',
        payload: { activeDoc: { type: 'library', content: true, ...document } },
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* GET_DOC_MENU() {
  const {
    settings: { locale },
  } = yield select()
  try {
    yield put({
      type: 'documentation/SET_STATE',
      payload: { loadingMenu: true },
    })
    const { success, data } = yield call(getDocMenu, { locale: locale.split('-')[0] })

    if (success) {
      const { results, count } = data
      // Check if data retrieved from API
      const payload = {
        menuTree: results,
        // menuTree: menuFlatter(menuData),
        limitedMenuData: results.map(md => {
          return { ...md, children: md.children.map(mdc => ({ ...mdc, children: null })) }
        }),
        menuData: results,
      }
      yield put({
        type: 'documentation/SET_STATE',
        payload,
      })
    }
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: 'documentation/SET_STATE',
    payload: { loadingMenu: false },
  })

  // yield GET_LIBRARIES_MENU()
}

/**
 * Gets the document types from API
 * Sets documenation states with redux-saga
 */
export function* GET_DOC_TYPES() {
  try {
    const { success, docTypes } = yield call(getDocTypes)
    if (success) {
      yield put({
        type: 'documentation/SET_STATE',
        payload: { docTypes },
      })
    }
  } catch (e) {
    console.log(e)
  }
}
export function* CREATE_DOC_VERSION({ payload }) {
  yield put({
    type: 'documentation/SET_STATE',
    payload: { loadingDocVersion: true },
  })
  const {
    settings: { locale },
  } = yield select()
  try {
    const { doc, content } = payload
    const data = { language: locale.split('-')[0], content, doc, is_current: true }

    const { success, data: docVersion } = yield call(createDocVersion, data)

    if (success) {
      history.push(`/info-support/knowledge-base/${docVersion.key}`)
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'documentation/SET_STATE',
    payload: { loadingDocVersion: false },
  })
}

export function* SAVE_DOCUMENT({ payload }) {
  try {
    yield put({
      type: 'documentation/SET_STATE',
      payload: { loadingDocVersion: true, loadingDoc: true },
    })

    const {
      settings: { locale },
    } = yield select()

    const { id, key, name, description, content } = payload

    const document_version_data = {
      language: locale.split('-')[0],
      content,
      doc: { id },
      is_current: true,
    }
    const document_data = {
      language: locale.split('-')[0],
      content,
      id,
      key: convertToSlug(key),
      name,
      description: description && description.trim() !== '' ? description : name,
    }

    const { success: docversion_success, data: docVersion } = yield call(
      createDocVersion,
      document_version_data,
    )
    const { success: doc_success, data: doc } = yield call(updateDoc, document_data)

    if (docversion_success && doc_success) {
      yield put({
        type: 'documentation/SET_STATE',
        payload: { activeDoc: doc },
      })
      history.push(`/info-support/knowledge-base/${doc.key}`)
    }
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: 'documentation/SET_STATE',
    payload: { loadingDocVersion: false, loadingDoc: false },
  })
}

export function* DELETE_DOC({ payload: { id } }) {
  const {
    documentation: { activeDoc },
  } = yield select()
  yield put({
    type: 'documentation/SET_STATE',
    payload: { loadingDoc: true },
  })
  try {
    const { success } = yield call(deleteDoc, { id })

    if (success) {
      yield put({
        type: 'documentation/GET_DOC_MENU',
      })
      const data = {
        message: getIntl('notifications.documentation.deleteDoc.success'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'success',
        },
      })
    } else {
      const data = {
        message: getIntl('notifications.documentation.deleteDoc.error'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }
    yield put({
      type: 'documentation/SET_STATE',
      payload: { loadingDoc: false },
    })
    if (activeDoc.id === id) {
      history.push(`/info-support/knowledge-base`)
      yield put({
        type: 'documentation/SET_STATE',
        payload: { activeDoc: null },
      })
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: 'documentation/SET_STATE',
      payload: { loadingDoc: true },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DOC_MENU, GET_DOC_MENU),
    takeEvery(actions.GET_DOCS, GET_DOCS),
    takeEvery(actions.GET_DOC, GET_DOC),
    takeEvery(actions.GET_LIBRARY_ITEM_DOC, GET_LIBRARY_ITEM_DOC),
    takeEvery(actions.GET_LIBRARIES_MENU, GET_LIBRARIES_MENU),
    takeEvery(actions.GET_DOC_VERSION, GET_DOC_VERSION),
    takeEvery(actions.GET_DOC_TYPES, GET_DOC_TYPES),
    takeEvery(actions.CREATE_DOC, CREATE_DOC),
    takeEvery(actions.CREATE_DOC_VERSION, CREATE_DOC_VERSION),
    // takeEvery(actions.UPDATE_DOC, UPDATE_DOC),
    takeEvery(actions.DELETE_DOC, DELETE_DOC),
    takeEvery(actions.SAVE_DOCUMENT, SAVE_DOCUMENT),
  ])
}
