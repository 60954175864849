// import titleCase from "utils/titleCase"
import BaseModel from 'models/BaseModel'
import Moment from 'moment'

class Notification extends BaseModel {
  constructor(props) {
    const {
      level,
      action,
      channel,
      event_id,
      is_active,
      organisation,
      payload,
      is_read,
      event_datetime,
      releated_object,
      releated_object_id,
      sent_at,
    } = props
    super(props)

    this.level = level
    this.payload = payload
    this.is_read = is_read
    this.action = action
    this.channel = channel
    this.is_active = is_active
    this.event_id = event_id
    this.organization = organisation
    this.releated_object = releated_object?.split('models.')[1] || '-'
    this.releated_object_id = releated_object_id
    this.sent_at = Moment(sent_at).format('DD.MM.YYYY HH:mm:ss')
    this.event_datetime = event_datetime
    this.happened_at = Moment(event_datetime).format('DD.MM.YYYY HH:mm:ss')
  }
}

class NotificationAction extends BaseModel {
  constructor(props) {
    const { level, id, name, releated_object, template } = props.action_object
    super(props)
    this.id = id
    this.key = id
    this.level = level
    this.model = name?.split('pylatform.org.')[1].split('.')[0]
    this.type = name?.split('pylatform.org.')[1].split('.')[1]
    this.state = name?.split('pylatform.org.')[1].split('.')[2]
    this.releated_object = releated_object?.split('models.')[1] || '-'
    this.template = template
  }
}
class NotificationSubscription extends BaseModel {
  constructor(props) {
    const { action } = props
    super(props)
    this.action = action
  }
}
class NotificationChannel extends BaseModel {
  constructor(props) {
    const { default: def } = props
    super(props)
    this.default = def
  }
}
class NotificationChannelSubscription extends BaseModel {
  constructor(props) {
    const { channel, organisation, configs } = props
    super(props)
    this.channel = channel
    this.organization = organisation
    this.configs = configs
  }
}

export {
  Notification,
  NotificationAction,
  NotificationSubscription,
  NotificationChannel,
  NotificationChannelSubscription,
}
