import React, { useState } from 'react'
import { Col, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import UnpublishMarketItemDialog from 'components/widgets/Dialogs/UnpublishMarketItemDialog'
import SubscriptionChangeDialog from '../Dialogs/SubscriptionChangeDialog'

const SubscriptionChangeButton = ({ name , align = 'center' }) => {
  const [dialogVisibility, setDialogVisibility] = useState(false)

  const data = { name, description : 'Change Subscription'}

  return (
    <Col span={24} className={`text-${align}`}>
      <button
        type="button"
        className="btn btn-info btn-with-addon"
        onClick={() => setDialogVisibility(true)}
      >
        <span className="btn-addon">
          <i className="btn-addon-icon fe fe-message-circle" />
        </span>
        <FormattedMessage id="general.contactUs" />
      </button>
      {align === 'center' ? <br /> : <> </>}
      <SubscriptionChangeDialog
        data={data}
        visibility={dialogVisibility}
        setVisibility={setDialogVisibility}
      />
    </Col>
  )
}

export default SubscriptionChangeButton
