import axios from 'utils/axiosGlobal'
import { API_URL, MEDIA_URL } from 'index'
import FileSaver from 'file-saver'
import { stripTrailingSlash } from './utilities'
import { refreshTokenRequest } from './user'
import { LocalStorageService } from './auth'
import moment from 'moment'
import {
  createRecord,
  getList,
  getFileByPath,
  headers,
  deleteRecord,
  uploadFileRequest,
} from 'api/requests'

export async function getFileList({ workspace, id, path, recursive = true, publicFiles = false }) {
  const data = await getList(`files/${workspace}/${id}${publicFiles ? '/_public' : ''}`, {
    recursive,
  })
  return data
}

export async function createDirectory({ workspace, id, path, publicFiles = false }) {
  const dummyFile = new File([''], '.dirKeep', { type: 'text/plain' })
  const fileData = new FormData()
  fileData.append('file', dummyFile)
  const data = await uploadFileRequest(
    `files/${workspace}/${id}${publicFiles ? '/_public' : ''}${path ? `/${path}` : '/'}${dummyFile.name
    }`,
    fileData,
    '',
  )
  return data
}

export async function uploadFile({ workspace, id, path = '', file, publicFiles = false }) {
  const fileData = new FormData()
  fileData.append('file', file)
  const data = await uploadFileRequest(
    `files/${workspace}/${id}${publicFiles ? '/_public' : ''}${path ? `/${stripTrailingSlash(path)}` : ''
    }/${file.name}`,
    fileData,
  )
  return data
}

export async function editorUploadFile({
  workspace,
  id,
  orgId,
  path = '',
  file,
  publicFiles = false,
}) {
  return new Promise((res, rej) => {
    uploadFile({
      workspace,
      id,
      file: [file],
      path,
      publicFiles,
    })
      .then(({ success, data }) => {
        if (success) {
          const link = `${MEDIA_URL}/organisations/${orgId}/${data.path}`
          res({ data: { link } })
        }
      })
      .catch(e => {
        rej(e)
      })
  })
}

export async function deleteFile({ workspace, id, files, publicFiles = false }) {
  // Bulk delete may needed
  const fileCount = files.length
  let successCount = 0
  let failCount = 0

  return new Promise(resolve => {
    files.forEach(file => {
      deleteRecord(`files/${workspace}/${id}${publicFiles ? '/_public' : ''}`, file, '')
        .then(() => {
          successCount += 1
        })
        .catch(() => {
          failCount += 1
        })
        .finally(() => {
          if (successCount + failCount === fileCount) resolve({ success: true, failed: failCount })
        })
    })
  })
}

export async function downloadFile({ workspace, id, path = '', file, publicFiles = false }) {
  const data = await getFileByPath(
    `files/${workspace}/${id}${publicFiles ? '/_public' : ''}`,
    path,
    file,
    { raw: true },
  )
  return data
}

/**
 * Downloads the artifact file from File API
 * @param {string} file_name File name of the agent artifact
 */
export async function downloadArtifactoryFile(file_name) {
  return new Promise(resolve => {
    const url = `${API_URL}/files/_shared/artifacts/${file_name}?raw=true`
    return axios(url, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
    })
      .then(result => {
        FileSaver.saveAs(result.data, file_name, { autoBom: false })
        resolve({ success: true })
      })
      .catch(() => resolve({ success: false }))
  })
}

export async function downloadArtifactoryFileWithToken(file_name) {
  const downloadFile = filePath => {
    const link = document.createElement('a')
    link.href = filePath
    document.body.appendChild(link) // Required for Firefox
    link.click()
    link.remove()
  }

  const access_token = await refreshTokenRequest(localStorage.getItem('refresh'))
  LocalStorageService.setToken({ access_token })

  const url = `${API_URL}/files/_shared/artifacts/${file_name}?raw=true&_token=${access_token}`
  downloadFile(url)
}

/**
 * Returns the list of artifact data from File API
 */
export const getArtifactList = async () => {
  return axios
    .get(`${API_URL}/files/_shared/artifacts/files.json?raw=true&v=${moment().format('x')}`, {
      headers: headers(),
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      return false
    })
}
