import { List } from 'antd'
import React from 'react'
import EmailFormComponent from './EmailFormComponent'
import { defaultComponent, headerComponent } from './ListComponents'

const itemStyle = { fontSize: '1em', fontWeight: '500', lineHeight: '1em', border: 'none' }

const Newsletter = () => {
  const dataSource = [
    {
      key: 'title',
      style: { fontSize: '1.2em', fontWeight: 'bolder', lineHeight: undefined },
      link: '',
      component: headerComponent,
      text: 'Newsletter',
    },
    {
      key: 'newsletter',
      link: '',
      text: 'landingPage.newsletterInfo',
    },
    {
      key: 'form',
      component: <EmailFormComponent />,
      isFunction: false,
    },
  ]

  const renderItem = ({
    key,
    className = '',
    style = '',
    link = '',
    text = '',
    component = defaultComponent,
    isFunction = true,
    newPage = false,
    searchType,
  }) => (
    <List.Item item={key} style={{ ...itemStyle, ...style }}>
      {isFunction ? (
        component({ className, link, text, key, newPage, searchType })
      ) : (
        <>{component}</>
      )}
    </List.Item>
  )

  return <List size="small" bordered={false} dataSource={dataSource} renderItem={renderItem} />
}

export default Newsletter
