/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react'
import { store } from 'index'
import { connect } from 'react-redux'
import { Typography } from 'antd'

const CUSTOM_NAME_LENGTH = 64

const TaskNodeInner = props => {
  const {
    node,
    isSelected,
    scenario_editor: { renamingNode },
    dispatch,
  } = props

  const actualName = node.properties.customName || node.properties.name
  const [newName, setNewName] = useState(actualName)
  const [error, setError] = useState(null)
  const isStep = node.properties.subtype === 'step'

  const setRename = renamingNode => {
    dispatch({
      type: 'scenario_editor/SET_STATE',
      payload: { renamingNode },
    })
  }
  if (!isSelected && renamingNode) {
    // setRename(false)
  }

  const renameStep = name => {
    store.dispatch({
      type: 'active_scenario/RENAME_STEP',
      payload: { id: node.id, name },
    })
    setRename(false)
  }

  const renameTask = name => {
    store.dispatch({
      type: 'active_scenario/RENAME_TASK',
      payload: { id: node.id, name },
    })
    setRename(false)
  }

  const handleChange = e => {
    setNewName(e.target.value)
    setError(e.target.value.trim() === '')
  }

  const submitNewName = () => {
    if (error) return false
    if (isStep) {
      renameStep(newName)
    } else {
      renameTask(newName)
    }
  }

  const cancelNewName = () => {
    setRename(false)
    setNewName(actualName)
  }

  const TitleComponent = (
    <div className="text-center">
      <Typography.Text ellipsis={{ tooltip: actualName }} style={{ color: 'black' }}>
        {actualName}
      </Typography.Text>
      {node.properties.customName && (
        <p className="mt-0 mb-0 text-center">
          <small>({node.properties.name})</small>
        </p>
      )}
    </div>
  )

  const handleKeys = e => {
    e.stopPropagation()
    switch (e.code) {
      case 'Escape':
        cancelNewName()
        break
      case 'Enter':
        submitNewName()
        break
      default:
    }
  }

  return (
    <div style={{ padding: 0 }}>
      {renamingNode && isSelected ? (
        <EditComponent
          name={newName}
          handleChange={handleChange}
          handleKeys={handleKeys}
          handleSubmit={submitNewName}
          error={error}
        />
      ) : (
        TitleComponent
      )}
    </div>
  )
}
const mapStateToProps = ({ scenario_editor }) => ({ scenario_editor })
export default connect(mapStateToProps)(TaskNodeInner)

const EditComponent = ({ name, handleChange, handleKeys, handleSubmit, error }) => (
  <input
    style={{ height: '1.5em', fontSize: '1em', backgroundColor: error ? '#df255a11' : undefined }}
    placeholder={error ? 'Bir isim girin' : undefined}
    className="form-control"
    onChange={v => handleChange(v)}
    onClick={e => e.stopPropagation()}
    onDoubleClick={e => e.stopPropagation()}
    onMouseUp={e => e.stopPropagation()}
    onMouseDown={e => e.stopPropagation()}
    onKeyPress={e => e.stopPropagation()}
    onKeyDown={handleKeys}
    onKeyUp={e => e.stopPropagation()}
    onBlur={() => handleSubmit()}
    value={name}
    maxLength={CUSTOM_NAME_LENGTH}
    autoFocus
  />
)
