import { store } from 'index'
import { cloneDeep } from 'lodash'

/** This function only must be called for steps */
export const handleDevOptions = (node, devOption = 'debug', clickedNode, scene) => {
  const newNode = cloneDeep(node)
  if (newNode.properties.detail.devOptions) {
    const currentValue = clickedNode.properties.detail.devOptions[devOption]
    newNode.properties.detail.devOptions[devOption] = !currentValue
  } else {
    newNode.properties.detail.devOptions = { [devOption]: true }
  }
  store.dispatch({
    type: 'active_scenario/UPDATE_NODE',
    payload: { node: newNode, scene },
  })
}

export const handleRename = renamingNode => {
  store.dispatch({
    type: 'scenario_editor/SET_STATE',
    payload: { renamingNode },
  })
}

export const handleEditNode = node => {
  store.dispatch({
    type: 'scenario_editor/CHANGE_SCENE',
    payload: { scene: 'task', taskId: node.id },
  })
}
export const handleCopy = taskId => {
  store.dispatch({
    type: 'scenario_editor/CLIPBOARD_COPY',
    payload: { taskId },
  })
}
export const handlePaste = (position, modal) => {
  store.dispatch({
    type: 'scenario_editor/CLIPBOARD_PASTE',
    modal,
    payload: { position },
  })
}
export const handleEscape = () => {
  handleRename(false)

  store.dispatch({
    type: 'scenario_editor/SET_STATE',
    payload: { clipboard: { data: null, mode: null }, nodeAddMode: null },
  })
}

export const handleAddNode = (nodeAddMode = 'task') => {
  store.dispatch({
    type: 'scenario_editor/SET_STATE',
    payload: { nodeAddMode },
  })
}

export const handleCopyId = nodeId => {
  navigator.clipboard.writeText(nodeId)
}
