import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getIntl } from 'localization'
import { getTeams, addTeam, updateTeam, deleteTeam } from 'services/teams'
import actions from './actions'
import Moment from 'moment'
import Team from 'models/Team'

export function* GET_TEAMS({ payload }) {
  try {
    yield put({
      type: 'teams/SET_STATE',
      payload: { loading: true },
    })
    const {
      success,
      data: { results, count },
    } = yield call(getTeams, payload?.filters || {})

    if (success) {
      const teams = results.map(team => new Team(team))
      yield put({
        type: 'teams/SET_STATE',
        payload: {
          teams,
          loading: false,
          count,
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* ADD_NEW_TEAM({ payload }) {
  try {
    const { team } = payload

    yield put({
      type: 'teams/SET_STATE',
      payload: { loading: true },
    })

    const { success, teams } = yield call(addTeam, { team })
    if (success) {
      yield put({
        type: 'teams/SET_STATE',
        payload: { teams, loading: false },
      })
      const data = {
        message: getIntl('notification.teams.addNewTeam.success.message'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'success',
        },
      })
    } else {
      const data = {
        message: getIntl('notification.teams.addNewTeam.error.message'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }

    yield put({
      type: 'teams/GET_TEAMS',
    })
  } catch (e) {
    console.log(e)
  }
}

export function* UPDATE_TEAM({ payload }) {
  try {
    const { team } = payload
    yield put({
      type: 'teams/SET_STATE',
      payload: { loading: true },
    })
    // Send data to API
    const { success, teams } = yield call(updateTeam, { team })
    if (success) {
      const data = {
        message: getIntl('notification.teams.updateTeam.success.message'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'success',
        },
      })
    } else {
      const data = {
        message: getIntl('notification.teams.updateTeam.error.message'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }
    yield put({
      type: 'teams/SET_STATE',
      payload: { teams, loading: false },
    })
    yield put({
      type: 'teams/GET_TEAMS',
    })
  } catch (e) {
    console.log(e)
  }
}
export function* DELETE_TEAM({ payload }) {
  try {
    const { team_id: id } = payload

    yield put({
      type: 'teams/SET_STATE',
      payload: { loading: true },
    })

    const { success } = yield call(deleteTeam, { id })
    if (success) {
      const data = {
        message: getIntl('notification.teams.deleteTeam.success.message'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'success',
        },
      })
    } else {
      const data = {
        message: getIntl('notification.teams.deleteTeam.error.message'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }
    yield put({
      type: 'teams/SET_STATE',
      payload: { loading: false },
    })
    yield put({
      type: 'teams/GET_TEAMS',
    })
  } catch (e) {
    console.log(e)
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TEAMS, GET_TEAMS),
    takeEvery(actions.ADD_NEW_TEAM, ADD_NEW_TEAM),
    takeEvery(actions.UPDATE_TEAM, UPDATE_TEAM),
    takeEvery(actions.DELETE_TEAM, DELETE_TEAM),
  ])
}
