import { Modal } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

const mapStateToProps = ({ user, sett }) => ({ user })

const UnpublishMarketItemDialog = ({ visibility, setVisibility, data, user, dispatch }) => {
  let formURL =
    'https://forms.zohopublic.com/kronnikarpa/form/yenikredivepakettalebi/formperma/cktPpiI0LCzDlLL5eV8E7pFEERgamzLe2GochbXus_w'

  const postData = {
    SingleLine: user?.first_name || '',
    SingleLine1: user?.last_name || '',
    Email: user?.email || '',
    PhoneNumber: user?.phone || '',
    SingleLine2: user?.organizations[0]?.name || '',
    SingleLine3: data.robotName || '',
  }

  formURL = `${formURL}?${Object.keys(postData)
    .map(key => `${key}=${postData[key]}`)
    .join('&')}`

  return (
    <Modal
      title={
        <>
          <strong>
            <FormattedMessage id="Unpublish" />
          </strong>
          {' : '}
          {data.robotName}
        </>
      }
      open={visibility}
      footer={null}
      width={1000}
      onCancel={() => setVisibility(false)}
    >
      <iframe
        id="formFrame"
        title="ContactUsForm"
        frameBorder="0"
        style={{ height: 500, width: '99%', border: 'none' }}
        src={formURL}
      />
    </Modal>
  )
}

export default connect(mapStateToProps)(UnpublishMarketItemDialog)
