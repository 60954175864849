import { getIntl } from 'localization'

const conditionTranslator = value => {
  const types = {
    eq: '=',
    gt: '>',
    gte: '≥',
    lt: '<',
    lte: '≤',
    seen: getIntl('Seen'),
    unseen: getIntl('Unseen'),
  }
  return types[value] || value
}

export default conditionTranslator
