import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getOrganizationUsers } from 'services/organization_users'
import { createUser, deleteUser, updateUser } from 'services/user'
import actions from './actions'
import { getIntl } from '../../localization'
import Moment from 'moment'
import User from 'models/User'

export function* GET_ORGANIZATION_USERS({ payload = {} }) {
  yield put({
    type: 'organization_users/SET_STATE',
    payload: { loading: true },
  })
  const { success, data } = yield call(getOrganizationUsers, payload?.filters || {})
  if (success) {
    const { results, count } = data
    const users = results.map(user => {
      return {
        id: user.id,
        key: user.id,
        username: user.username,
        is_active: user.userprofile.is_active,
        photo: user.userprofile.photo
          ? user.userprofile.photo
          : '/resources/images/kronnika_icon_128x128.png',
        first_name: user.first_name,
        last_name: user.last_name,
        identity_card_id: user.userprofile.identity_card_id,
        position: user.userprofile.position,
        phone: user.userprofile.phone,
        email: user.email,
        address: user.userprofile.address,
        country: user.userprofile.country,
        state: user.userprofile.state,
        city: user.userprofile.city,
        zipcode: user.userprofile.zipcode,
        role: user.groups && user.groups.length > 0 ? user.groups[0] : { id: 999, name: 'No Role' },
        organizations: user.userprofile.organisation,
        teams: user.userprofile.teams,
        status: user.userprofile.is_active,
        last_login: user.last_login ? Moment(user.last_login).format('DD.MM.YYYY HH:mm:ss') : '-',
        is_analytics: user.is_analytics,
      }
    })

    yield put({
      type: 'organization_users/SET_STATE',
      payload: { organization_users: users, count },
    })
  }

  yield put({
    type: 'organization_users/SET_STATE',
    payload: { loading: false },
  })
}

export function* CREATE_ORGANIZATION_USER({ payload }) {
  const user = {
    first_name: payload.first_name,
    last_name: payload.last_name,
    email: payload.email.toLowerCase(),
    groups: [{ id: payload.role }],
    username: payload.email.toLowerCase(),
    password: payload.password,
    userprofile: {
      identity_card_id: payload.identity_card_id,
      position: payload.position,
      phone: payload.phone,
      email: payload.email.toLowerCase(),
      address: payload.address,
      country: payload.country,
      state: payload.state,
      city: payload.city,
      zipcode: payload.zipcode,
      organisation: {
        id: payload.organizations,
      },
      teams: payload.teams?.map(team => ({ id: team })),
    },
    is_analytics: payload.is_analytics,
  }

  const { success } = yield call(createUser, user)

  if (success) {
    yield put({
      type: 'organization_users/GET_ORGANIZATION_USERS',
    })
    const data = {
      message: getIntl('notification.userCreate.message.success'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
  } else {
    const data = {
      message: getIntl('notification.userCreate.message.fail'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
}

export function* UPDATE_ORGANIZATION_USER({ payload }) {
  yield put({
    type: 'organization_users/SET_STATE',
    payload: { loading: true },
  })
  const { user_data } = payload
  const organized_user_data = {
    user_id: user_data.user_id,
    email: user_data.email.toLowerCase(),
    first_name: user_data.first_name,
    last_name: user_data.last_name,
    groups: [{ id: user_data.role }],
    userprofile: {
      is_active: user_data.is_active,
      address: user_data.address,
      country: user_data.country,
      state: user_data.state,
      city: user_data.city,
      zipcode: user_data.zipcode,
      identity_card_id: user_data.identity_card_id,
      phone: user_data.phone,
      position: user_data.position,
      organisation: { id: user_data.organizations },
      teams: user_data.teams?.map(team => ({ id: team })),
    },
    is_analytics: user_data.is_analytics,
  }

  const { success } = yield call(updateUser, { user_data: organized_user_data })

  if (success) {
    yield put({
      type: 'organization_users/GET_ORGANIZATION_USERS',
    })
    const data = {
      message: getIntl('notification.userUpdate.message.success'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
  } else {
    const data = {
      message: getIntl('notification.userUpdate.message.fail'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
  yield put({
    type: 'organization_users/SET_STATE',
    payload: { loading: false },
  })
}

export function* DELETE_ORGANIZATION_USER({ payload }) {
  yield put({
    type: 'organization_users/SET_STATE',
    payload: { loading: true },
  })
  const { user_id } = payload
  const { success } = yield call(deleteUser, { user_id })
  if (success) {
    yield put({
      type: 'organization_users/GET_ORGANIZATION_USERS',
    })
  }
  yield put({
    type: 'organization_users/SET_STATE',
    payload: { loading: false },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ORGANIZATION_USERS, GET_ORGANIZATION_USERS),
    takeEvery(actions.CREATE_ORGANIZATION_USER, CREATE_ORGANIZATION_USER),
    takeEvery(actions.UPDATE_ORGANIZATION_USER, UPDATE_ORGANIZATION_USER),
    takeEvery(actions.DELETE_ORGANIZATION_USER, DELETE_ORGANIZATION_USER),
  ])
}
