import Library from 'models/Library'
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { compareSemVer } from 'semver-parser'
import { getLastAgentVersion } from 'services/agent'
import { getCachedLibraries, getLibraries, setCachedLibraries } from 'services/libraries'
import compareCalVersion from 'utils/compareCalVersion'
import actions from './actions'

export function* GET_LIBRARIES_FROM_API(version) {
  try {
    const { success, data } = yield call(getLibraries)
    // TODO: modelize libraries !!
    if (success) {
      const librariesData = data.results

      const libraries = librariesData
        .map(library => new Library(library))
        .sort((a, b) => a.name.localeCompare(b.name))
      setCachedLibraries(libraries, version)
      yield put({
        type: 'libraries/SET_STATE',
        payload: { loading: false, loadingMenu: true, libraries },
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* GET_LIBRARIES() {
  let hasCachedVersion = false
  const { settings } = yield select()
  const localCacheResetVersion = localStorage.getItem('app.localCacheResetVersion')

  yield put({
    type: 'libraries/SET_STATE',
    payload: { loading: true, loadingMenu: true },
  })
  try {
    const { success, data: lastAgentVersion } = yield getLastAgentVersion()
    /* Even if there is no last version can be recieved get the libraries */
    if (!success) {
      console.info('Last version of agent can not be found but will try to get libraries')
      yield call(GET_LIBRARIES_FROM_API, lastAgentVersion.version)
      return false
    }
    const cachedAgentVersion = localStorage.getItem('agent.lastKnownVersion')

    hasCachedVersion =
      cachedAgentVersion && compareCalVersion(cachedAgentVersion, lastAgentVersion.version) === 0

    if (!localCacheResetVersion) {
      hasCachedVersion = false
    }
    if (
      settings.remoteCacheResetVersion &&
      localCacheResetVersion &&
      compareSemVer(settings.remoteCacheResetVersion, localCacheResetVersion) !== 0
    ) {
      hasCachedVersion = false
    }

    if (!hasCachedVersion) {
      yield call(GET_LIBRARIES_FROM_API, lastAgentVersion.version)
      localStorage.setItem('app.localCacheResetVersion', settings.remoteCacheResetVersion)
    } else {
      const libraries = yield call(getCachedLibraries)
      if (libraries) {
        yield put({
          type: 'libraries/SET_STATE',
          payload: { libraries },
        })
      } else {
        yield call(GET_LIBRARIES_FROM_API, lastAgentVersion.version)
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'libraries/SET_STATE',
    payload: { loading: false, loadingMenu: false },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_LIBRARIES, GET_LIBRARIES)])
}
