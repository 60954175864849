import Moment from 'moment'
import cronstrue from 'cronstrue/i18n'
import Job from './Job'

class JobRun {
  constructor(props, locale = 'tr') {
    const {
      id,
      status,
      cron,
      schedule,
      job,
      duration,
      error_message,
      globals,
      completed,
      is_active,
      is_done,
      created,
      started,
      updated,
      deleted,
    } = props

    this.id = id
    this.key = id
    this.name = job.name || '- - -'
    this.status = status
    this.job = new Job(job)
    this.scenario_version = job.scenarioversion
    this.duration = duration ? duration.split('.')[0] : '- - -'
    this.error_message = error_message
    this.globals = globals
    this.schedule = cron
      ? cronstrue.toString(cron, {
          locale: locale.substring(0, 2),
          tzOffset: new Date().getTimezoneOffset() / 60,
        })
      : '- - -'
    this.next_run_at =
      schedule && schedule.next_run && cron
        ? Moment(schedule?.next_run).format('DD.MM.YYYY HH:mm:ss')
        : '- - -'
    this.started_at = started ? Moment(started).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.completed_at = completed ? Moment(completed).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.created_at = created ? Moment(created).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.updated_at = updated ? Moment(updated).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.deleted_at = deleted ? Moment(deleted).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.is_active = is_active
    this.is_done = is_done

    this.created_by = `${job.created_by?.first_name} ${job.created_by?.last_name}`
    // this.ran_by = this.created_by.trim() === '' ? job.created_by?.username : this.created_by
    this.ran_by = this.created_by.trim() === '' ? job.created_by?.username : this.created_by
  }
}

export default JobRun
