import React from 'react'
import { Button, List } from 'antd'
import { DeleteOutlined, UnlockOutlined } from '@ant-design/icons'
import { getIntl } from 'localization'
import { PermissionsTable } from 'services/permissions'
import ACL from 'components/system/Auth/ACL'

const VaultItem = ({ item, actions }) => {
  return (
    <List.Item>
      <List.Item.Meta
        title={
          <>
            {item.name}
            {
              <ACL allowed={PermissionsTable.vault.deleteButton} type="role">
                <Button
                  size="small"
                  className="ml-2 float-right"
                  icon={<DeleteOutlined />}
                  onClick={() => actions.deleteVault(item)}
                />
              </ACL>
            }

            <Button
              size="small"
              className="float-right"
              icon={<UnlockOutlined />}
              onClick={() => actions.unlockVault(item)}
            >
              {getIntl('Unlock')}
            </Button>
          </>
        }
        description={item.description}
      />
    </List.Item>
  )
}

export default VaultItem
