import { createRecord, deleteRecord, getList, updateRecord } from 'api/requests';

export async function getTeams(filters) {
  const data = await getList('teams', filters)
  return data
}
export async function addTeam({ team }) {
  const data = await createRecord('teams', team)
  return data
}
export async function updateTeam({ team }) {
  const { id } = team
  const data = await updateRecord('teams', id, team)
  return data
}
export async function deleteTeam({ id }) {
  const data = await deleteRecord('teams', id)
  return data
}
