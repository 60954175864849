import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown } from 'antd'
import { TranslationOutlined } from '@ant-design/icons'

import styles from './style.module.scss'

const LanguageSwitcher = ({ settings: { locale }, dispatch }) => {
  const changeLanguage = ({ key }) => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    })
  }

  const language = locale.substr(0, 2)

  const items = [
    {
      key: 'en-US',
      label: 'English',
    },
    {
      key: 'tr-TR',
      label: 'Türkçe',
    },
  ]

  return (
    <Dropdown
      menu={{
        items,
        onClick: changeLanguage,
      }}
      trigger={['click']}
      placement="bottomRight"
    >
      <div className={styles.dropdown}>
        <TranslationOutlined /> <span className="text-uppercase">{language}</span>
      </div>
    </Dropdown>
  )
}
const mapStateToProps = ({ settings }) => ({ settings })
export default connect(mapStateToProps)(LanguageSwitcher)
