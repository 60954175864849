import React from 'react'
import { connect } from 'react-redux'

import ACL from 'components/system/Auth/ACL'
import { PermissionsTable } from 'services/permissions'
import ScenarioOperations from './ScenarioOperations'

const mapStateToProps = ({ active_scenario, settings, router }) => ({
  settings,
  active_scenario,
  router,
})

const BasicOperations = ({ active_scenario, router }) => {
  const { pathname } = router.location

  return pathname.includes('/scenario-designer') && active_scenario.id ? (
    <ACL allowed={PermissionsTable.scenario_designer.limited} type="role">
      <ScenarioOperations />
    </ACL>
  ) : (
    <></>
  )
}
export default connect(mapStateToProps)(BasicOperations)
