/* eslint-disable no-bitwise */
import JSEncrypt from 'jsencrypt'

const stringToChunk = (str, size = 16) => {
  if (str == null) return []
  str = String(str)
  size = ~~size
  const ret = size > 0 ? str.match(new RegExp(`.{1,${size}}`, 'g')) : [str]
  return ret
}
const KEY_SIZE = 512
export const createEncryptionKeys = () => {
  const crypt = new JSEncrypt({ default_key_size: KEY_SIZE })
  const private_key = crypt.getPrivateKey()
  const public_key = crypt.getPublicKey()
  return { private_key, public_key }
}

export const encryptData = data => {
  const { private_key, public_key } = createEncryptionKeys()
  const encrypt = new JSEncrypt({ default_key_size: KEY_SIZE })
  encrypt.setPublicKey(public_key)
  const chunks = stringToChunk(data)
  const encrypted = chunks.map(chunk => encrypt.encrypt(chunk)).join('')
  return { encrypted, private_key, public_key }
}
export const encryptDataWithPublicKey = (data, public_key) => {
  const encrypt = new JSEncrypt({ default_key_size: KEY_SIZE })
  encrypt.setPublicKey(public_key)
  const chunks = stringToChunk(data)
  const encrypted = chunks.map(chunk => encrypt.encrypt(chunk)).join('')
  return { encrypted }
}

export const decryptData = (encrypted, private_key) => {
  const decrypt = new JSEncrypt({ default_key_size: KEY_SIZE })
  decrypt.setPrivateKey(private_key)
  const encryptedChunks = encrypted.split('==')
  encryptedChunks.pop()
  let decryptedString = ''
  encryptedChunks.forEach(chunk => {
    const chunkStr = decrypt.decrypt(`${chunk}==`)
    if (chunkStr) {
      decryptedString += chunkStr
    }
  })

  return decryptedString.trim() !== '' ? decryptedString : null
}

export const handleDecryption = (item, private_key) => {
  const { payload } = item
  console.log(item)
  const decryptedVault = JSON.parse(decryptData(payload, private_key))
  if (decryptedVault) {
    const keyedCryptedVault = decryptedVault.map(d => ({ key: d.key_name, ...d }))
    return keyedCryptedVault
  }
  return false
}
