const actions = {
  SET_STATE: 'files/SET_STATE',
  GET_LIST : 'files/GET_LIST',
  CREATE_DIRECTORY: 'files/CREATE_DIRECTORY',
  UPLOAD_FILE: 'files/UPLOAD_FILE',
  DOWNLOAD_FILE: 'files/DOWNLOAD_FILE',
  DELETE_FILE: 'files/DELETE_FILE',

}

export default actions
