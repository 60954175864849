import { createRecord, deleteRecord, getList, updateRecord } from "api/requests"

const getCards = async (payload) => {
  const data = await getList('creditcards', { ...payload, order_by: 'created' })
  return data
}

const createCard = async (payload) => {
  const { id } = payload
  const data = await createRecord('creditcards', { creditcardform : id})
  return data
}

const updateCard = async (payload) => {
  const { id, data: cardData } = payload
  const data = await updateRecord('creditcards', id, cardData)
  return data
}

const deleteCard = async (payload) => {
  const { id } = payload
  const data = await deleteRecord('creditcards', id)
  return data
}
const requestNewCardForm = async () => {
  const data = await createRecord('creditcardforms', { callback_url: `${window.location.origin}/payment-methods` })
  return data
}

export { getCards, updateCard, deleteCard, requestNewCardForm, createCard }
