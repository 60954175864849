import actions from './actions'

const initialState = {
  creating: false,
  loading: false,
  loading_version: false,
  saving: false,
  scenarios: []
}

export default function scenarioReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case 'RESET_ALL':
      return initialState
    default:
      return state
  }
}
