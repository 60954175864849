import { store } from 'index'
import { filterComponentsByPermissions, filterComponentsByAPIPermissions } from 'services/utilities'

const ACLFunction = ({
  children,
  allowed,
  type,
  creator,
  notAuthorizedContent,
  components = true,
}) => {
  const { user } = store.getState()
  let owner = false
  if (creator) {
    owner = user.id === creator.id
  }

  let authorized = false

  switch (type) {
    case 'role':
      authorized = filterComponentsByPermissions(allowed, user)
      break
    case 'api':
      authorized = filterComponentsByAPIPermissions(allowed, user)
      break
    default:
      break
  }
  const result = authorized || owner ? children : notAuthorizedContent
  return components ? result : authorized || owner
}

export default ACLFunction
