const actions = {
  SET_STATE: 'statistics/SET_STATE',
  GET_JOB_STATISTICS : 'statistics/GET_JOB_STATISTICS',
  GET_DASHBOARD_STATISTICS : 'statistics/GET_DASHBOARD_STATISTICS',
  GET_USER_STATISTICS : 'statistics/GET_USER_STATISTICS',
  GET_ROBOT_STATISTICS : 'statistics/GET_ROBOT_STATISTICS',
  GET_JOBRUN_STATISTICS : 'statistics/GET_JOBRUN_STATISTICS',
  GET_REPORTS_DATA : 'statistics/GET_REPORTS_DATA',
}

export default actions
