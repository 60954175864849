import slugify from "slugify"
import { message } from 'antd'
import { getIntl } from "localization"

const slugger = (value, notify = true) => {

  const slug = slugify(value, {
    lower: true,      // convert to lower case, defaults to `false`
    strict: true,     // strip special characters except replacement, defaults to `false`
    locale: 'en'
  })
  if (value.length !== slug.length && notify) {
    message.info(getIntl('message.notValidDomainString'))
  }
  return slug
}

export const convertToSlug = val => {
  return slugify(val, {
    lower: true,      // convert to lower case, defaults to `false`
    strict: true,     // strip special characters except replacement, defaults to `false`
    locale: 'en'
  })
}

export default slugger
