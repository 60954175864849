import { getIntl } from 'localization'
import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Button } from 'antd'
import style from './style.module.scss'

const CookieBanner = ({ settings: { cookieInfo }, dispatch }) => {
  const handleClick = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'cookieInfo',
        value: false,
      },
    })
  }

  return (
    <>
      {cookieInfo && (
        <div className={style.banner}>
          <FormattedHTMLMessage
            id="cookieBanner.text"
            values={{ link: '/agreements/cookie-policy' }}
          />{' '}
          <Button onClick={handleClick}>{getIntl('OK')}</Button>
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ settings }) => ({ settings })

export default connect(mapStateToProps)(CookieBanner)
