import Plan from 'models/Plan'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getPlans } from 'services/plans'
import actions from './actions'

export function* GET_PLANS({ payload = {} }) {
  yield put({
    type: 'plans/SET_STATE',
    payload: { loading: true },
  })
  const { success, data } = yield call(getPlans, payload)

  if (success) {
    const plans = data.results.map(item => new Plan(item))
    yield put({
      type: 'plans/SET_STATE',
      payload: { plans },
    })
  }

  yield put({
    type: 'plans/SET_STATE',
    payload: { loading: false },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_PLANS, GET_PLANS)])
}
