// getScenario function requests the scenario data from API with a given scenario ID.
/*
export async function getScenario(payload) {
  const { scenario_id } = payload
  const url = `https://test.kronnika.com/api/v1/scenarios/${scenario_id}`
  return axios(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then(result => {
      return {
        success: true,
        scenario : "" // TODO:  Add scenario data
      }
    })
    .catch(error => {

      return { success: false }
    })
}
*/

// eslint-disable-next-line import/prefer-default-export
export function getTaskList() {
	return [
		{
			title: 'Analytics',
			key: 'Analytics',
			children: []
		},
		{
			title: 'Api',
			key: 'Api',
			children: []
		},
		{
			title: 'Browser',
			key: 'Browser',
			children: [
				{
					id: '5bbe9875-6055-45dc-9dd3-1e729d58337b',
					version: '1.0.0',
					title: 'Bring Browser Forward',
					name: 'Bring Browser Forward',
					key: 'Bring Browser Forward',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Bring Browser Forward',
							type: 'object',
							required: [],
							properties: {
								browser_type: {
									type: 'string',
									title: 'Browser Type',
									enum: [ 'chrome', 'firefox', 'edge' ],
									enumNames: [ 'Google Chrome', 'Mozilla Firefox', 'Microsoft Edge' ]
								}
							}
						},
						ui: {
							browser_type: {}
						}
					}
				},
				{
					id: '74d1ba59-5d95-4454-841a-f9b1299937d5',
					version: '1.0.0',
					title: 'Check an Element',
					name: 'Check an Element',
					key: 'Check an Element',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Check an Element',
							description: 'Checks a checkbox or radiobutton',
							type: 'object',
							required: [],
							properties: {
								target: {
									type: 'string',
									title: 'Target Element'
								},
								action: {
									type: 'string',
									title: 'Action',
									enum: [ 'check', 'uncheck' ],
									enumNames: [ 'Check', 'Uncheck' ]
								}
							}
						},
						ui: {
							target: {
								'ui:placeholder': 'id|class|name|link|css|xpath',
								'ui:widget': 'targetWidget'
							}
						}
					}
				},
				{
					id: '65f9aedf-fffe-47bb-8f1b-451591c1ea73',
					version: '1.0.0',
					title: 'Clear Cookies',
					name: 'Clear Cookies',
					key: 'Clear Cookies',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Clear Cookies',
							description: 'Clears the cookies for the browser',
							type: 'object',
							required: [ 'browser_type' ],
							properties: {
								browser_type: {
									type: 'string',
									title: 'Browser Type',
									enum: [ 'chrome', 'firefox', 'edge' ],
									enumNames: [ 'Google Chrome', 'Mozilla Firefox', 'Microsoft Edge' ]
								}
							}
						},
						ui: {
							browser_type: {
								'ui:enumDisabled': 'edge'
							}
						}
					}
				},
				{
					id: '0a6c9f70-e293-4f80-8ad2-a2a5d03832c4',
					version: '1.0.0',
					title: 'Get Element Value',
					name: 'Get Element Value',
					key: 'Get Element Value',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Get Element Value',
							description: '',
							type: 'object',
							required: [ 'target' ],
							properties: {
								target: {
									type: 'string',
									title: 'Target Element'
								},
								'assign-to': {
									type: 'string',
									title: 'Assign To'
								}
							}
						},
						ui: {
							target: {
								'ui:placeholder': 'id|class|name|link|css|xpath',
								'ui:widget': 'targetWidget'
							}
						}
					}
				},
				{
					id: '7b9df980-2a96-4fc6-973e-44ab96bd774c',
					version: '1.0.0',
					title: 'Get Title and Validate',
					name: 'Get Title and Validate',
					key: 'Get Title and Validate',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Get Title and Validate',
							description: '',
							type: 'object',
							required: [],
							properties: {
								'validation-needed': {
									type: 'string',
									title: 'Validation Needed',
									enum: [ 'true', 'false' ],
									enumNames: [ 'Yes', 'No' ]
								},
								pattern: {
									type: 'string',
									title: 'Pattern to Match'
								},
								'assign-to': {
									type: 'string',
									title: 'Assign To'
								}
							}
						}
					}
				},
				{
					id: 'a0efce16-acb2-47bb-b6d4-4cc2544b89b6',
					version: '1.0.0',
					title: 'Minimize/Maximize Browser',
					name: 'Minimize/Maximize Browser',
					key: 'Minimize/Maximize Browser',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Minimize/Maximize Browser',
							description: '',
							type: 'object',
							required: [ 'browser_type' ],
							properties: {
								'browser-type': {
									type: 'string',
									title: 'Browser Type',
									enum: [ 'chrome', 'firefox', 'edge' ],
									enumNames: [ 'Google Chrome', 'Mozilla Firefox', 'Microsoft Edge' ]
								},
								action: {
									type: 'string',
									title: 'Selection',
									enum: [ 'min', 'max' ],
									enumNames: [ 'Minimize', 'Maximize' ]
								}
							}
						}
					}
				},
				{
					id: '56963d51-9672-495d-87ab-05806bb3d233',
					version: '1.0.0',
					title: 'Mouse Click At',
					name: 'Mouse Click At',
					key: 'Mouse Click At',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Mouse Click At',
							description: '',
							type: 'object',
							required: [ 'target' ],
							properties: {
								target: {
									type: 'string',
									title: 'Target'
								},
								action: {
									type: 'string',
									title: 'Mouse Action',
									enum: [
										'left',
										'doubleclick',
										'right',
										'middle',
										'ctrlclick',
										'shiftclick',
										'altclick'
									],
									enumNames: [
										'Left Click',
										'Double Click',
										'Right Click',
										'Middle Button',
										'Ctrl + Click',
										'Shift + Click',
										'Alt Click'
									]
								},
								area: {
									type: 'string',
									title: 'Area X-Y'
								},
								wait: {
									type: 'integer',
									title: 'Wait after click (in miliseconds)'
								}
							}
						},
						ui: {
							target: {
								'ui:placeholder': 'id|class|name|link|css|xpath',
								'ui:widget': 'targetWidget'
							},
							area: {
								'ui:placeholder': '0,0 (x,y)'
							}
						}
					}
				},
				{
					id: 'e878412c-9802-4fb7-866a-71fffd67aac5',
					version: '1.0.0',
					title: 'Mouse Click',
					name: 'Mouse Click',
					key: 'Mouse Click',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Mouse Click',
							description: '',
							type: 'object',
							required: [ 'target' ],
							properties: {
								target: {
									type: 'string',
									title: 'Target'
								},
								action: {
									type: 'string',
									title: 'Mouse Action',
									enum: [
										'left',
										'doubleclick',
										'right',
										'middle',
										'ctrlclick',
										'shiftclick',
										'altclick'
									],
									enumNames: [
										'Left Click',
										'Double Click',
										'Right Click',
										'Middle Button',
										'Ctrl + Click',
										'Shift + Click',
										'Alt Click'
									]
								},
								wait: {
									type: 'integer',
									title: 'Wait after click (in miliseconds)'
								}
							}
						},
						ui: {
							target: {
								'ui:placeholder': 'id|class|name|link|css|xpath',
								'ui:widget': 'targetWidget'
							}
						}
					}
				},
				{
					id: '2ae4a57d-20c6-4e6c-b09e-4ef730debf4d',
					version: '1.0.0',
					title: 'Mouse Move',
					name: 'Mouse Move',
					key: 'Mouse Move',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Mouse Move',
							description: '',
							type: 'object',
							required: [ 'target' ],
							properties: {
								target: {
									type: 'string',
									title: 'Target'
								},
								action: {
									type: 'string',
									title: 'Mouse Action',
									enum: [ 'mouseover', 'up', 'down' ],
									enumNames: [ 'Mouse Over', 'Up', 'Down' ]
								}
							}
						},
						ui: {
							target: {
								'ui:placeholder': 'id|class|name|link|css|xpath',
								'ui:widget': 'targetWidget'
							}
						}
					}
				},
				{
					id: 'e5f0c1d5-bead-476d-b48b-9d9f9e12548b',
					version: '1.0.0',
					title: 'Start Browser',
					name: 'Start Browser',
					key: 'Start Browser',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Start Browser',
							description: '',
							type: 'object',
							required: [ 'browser-type' ],
							properties: {
								browser_type: {
									type: 'string',
									title: 'Browser Type',
									enum: [ 'chrome', 'firefox', 'edge' ],
									enumNames: [ 'Google Chrome', 'Mozilla Firefox', 'Microsoft Edge' ]
								},
								'browser-version': {
									type: 'string',
									title: 'Browser Version'
								},
								'user-agent': {
									type: 'string',
									title: 'User Agent',
									enum: [ 'chrome', 'ie' ],
									enumNames: [ 'Google Chrome', 'Internet Explorer' ]
								},
								url: {
									type: 'string',
									title: 'URL or IP Address'
								}
							}
						},
						ui: {
							'browser-version': {
								'ui:placeholder': 'If not defined starts the latest version'
							},
							url: {
								'ui:placeholder': 'http://www.kronnika.com|www.kronnika.com|kronnika.com'
							}
						}
					}
				},
				{
					id: '7b12290a-a6c3-431a-a8c0-c556ce68995f',
					version: '1.0.0',
					title: 'Take Screen Shot',
					name: 'Take Screen Shot',
					key: 'Take Screen Shot',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Take Screen Shot',
							description: '',
							type: 'object',
							required: [ 'filename' ],
							properties: {
								filename: {
									type: 'string',
									title: 'Filename'
								},
								'only-viewport': {
									type: 'string',
									title: 'Only Viewport',
									enum: [ 'true', 'false' ],
									enumNames: [ 'Yes', 'No' ]
								}
							}
						},
						ui: {
							filename: {
								'ui:placeholder': '.png | .jpg'
							}
						}
					}
				},
				{
					id: '84e5059d-3eca-418b-b351-118aa647d895',
					version: '1.0.0',
					title: 'Type Text',
					name: 'Type Text',
					key: 'Type Text',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Type Text',
							description: '',
							type: 'object',
							required: [ 'target' ],
							properties: {
								target: {
									type: 'string',
									title: 'Target Element'
								},
								text: {
									type: 'string',
									title: 'Text'
								}
							}
						},
						ui: {
							target: {
								'ui:placeholder': 'id|class|name|link|css|xpath',
								'ui:widget': 'targetWidget'
							},
							text: {
								'ui:placeholder': 'Text to type in to element'
							}
						}
					}
				},
				{
					id: 'fc4820b1-4ef0-4624-ad55-4016842d8110',
					version: '1.0.0',
					title: 'Verify Checked',
					name: 'Verify Checked',
					key: 'Verify Checked',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Verify Checked',
							description: '',
							type: 'object',
							required: [ 'target' ],
							properties: {
								target: {
									type: 'string',
									title: 'Target Element'
								},
								value: {
									type: 'string',
									title: 'Value'
								}
							}
						},
						ui: {
							target: {
								'ui:placeholder': 'id|class|name|link|css|xpath',
								'ui:widget': 'targetWidget'
							}
						}
					}
				},
				{
					id: '55d0dfd8-4044-4b4c-9c1c-20a6871ffd52',
					version: '1.0.0',
					title: 'Verify Editable',
					name: 'Verify Editable',
					key: 'Verify Editable',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Verify Editable',
							description: '',
							type: 'object',
							required: [ 'target' ],
							properties: {
								target: {
									type: 'string',
									title: 'Target Element'
								}
							}
						},
						ui: {
							target: {
								'ui:placeholder': 'id|class|name|link|css|xpath',
								'ui:widget': 'targetWidget'
							}
						}
					}
				},
				{
					id: '7bd16b32-54ee-4e39-b2c4-07e8c78fa48a',
					version: '1.0.0',
					title: 'Verify Element Exist',
					name: 'Verify Element Exist',
					key: 'Verify Element Exist',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Verify Element Exist',
							description: '',
							type: 'object',
							required: [ 'target' ],
							properties: {
								target: {
									type: 'string',
									title: 'Target Element'
								}
							}
						},
						ui: {
							target: {
								'ui:placeholder': 'id|class|name|link|css|xpath',
								'ui:widget': 'targetWidget'
							}
						}
					}
				},
				{
					id: '9d2ee1ef-7ebe-46b8-b538-9d5a88a9eb23',
					version: '1.0.0',
					title: 'Verify Text',
					name: 'Verify Text',
					key: 'Verify Text',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Verify Text',
							description: '',
							type: 'object',
							required: [ 'target' ],
							properties: {
								target: {
									type: 'string',
									title: 'Target Element'
								},
								pattern: {
									type: 'string',
									title: 'Pattern to match'
								}
							}
						},
						ui: {
							target: {
								'ui:placeholder': 'id|class|name|link|css|xpath',
								'ui:widget': 'targetWidget'
							}
						}
					}
				}
			]
		},
		{
			title: 'Controls And Conditions',
			key: 'Controls And Conditions',
			children: []
		},
		{
			title: 'Custom Script',
			key: 'Custom Script',
			children: [
				{
					id: 'e1b9ce0f-3812-434f-bf27-b75adcf21f10',
					version: '1.0.0',
					title: 'Execute JS',
					name: 'Execute JS',
					key: 'Execute JS',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Execute JS',
							description: '',
							type: 'object',
							required: [ 'script' ],
							properties: {
								script: {
									type: 'string',
									title: 'Script'
								},
								'assign-to': {
									type: 'string',
									title: 'Assign To'
								}
							}
						},
						ui: {
							'assign-to': {
								'ui:placeholder': 'Store to a variable if returns some data'
							},
							script: {
								'ui:widget': 'textarea',
								'ui:placeholder': 'Enter your script here'
							}
						}
					}
				},
				{
					id: '469fcffe-e668-4546-8fa3-94b04e9376d5',
					version: '1.0.0',
					title: 'Execute Script',
					name: 'Execute Script',
					key: 'Execute Script',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Execute Script',
							description: '',
							type: 'object',
							required: [ 'script' ],
							properties: {
								'script-type': {
									type: 'string',
									title: 'Script Type',
									enum: [ 'javascript', 'python', 'go' ],
									enumNames: [ 'JavaScript', 'Python', 'Go' ]
								},
								script: {
									type: 'string',
									title: 'Script'
								},
								'assign-to': {
									type: 'string',
									title: 'Assign To'
								}
							}
						},
						ui: {
							'assign-to': {
								'ui:placeholder': 'Store to a variable if returns some data'
							},
							'script-type': {
								'ui:enumDisabled': [ 'python', 'go' ]
							},
							script: {
								'ui:widget': 'textarea',
								'ui:placeholder': 'Enter your script here'
							}
						}
					}
				}
			]
		},
		{
			title: 'Desktop',
			key: 'Desktop',
			children: []
		},
		{
			title: 'Email',
			key: 'Email',
			children: []
		},
		{
			title: 'Excel And Csv',
			key: 'Excel And Csv',
			children: []
		},
		{
			title: 'File',
			key: 'File',
			children: []
		},
		{
			title: 'General',
			key: 'General',
			children: [
				{
					id: '0cc70e89-80fc-4e24-b59b-932c68d2e15e',
					version: '1.0.0',
					title: 'Echo Log',
					name: 'Echo Log',
					key: 'Echo Log',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Echo Log',
							description: '',
							type: 'object',
							required: [ 'text' ],
							properties: {
								text: {
									type: 'string',
									title: 'Log Text'
								}
							}
						},
						ui: {
							text: {
								'ui:placeholder': 'Text to log'
							}
						}
					}
				},
				{
					id: '9ec5ef65-f3e5-4cf1-b157-9d968195ab1b',
					version: '1.0.0',
					title: 'Pause',
					name: 'Pause',
					key: 'Pause',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Pause',
							description: 'Pauses the operation for a while in miliseconds',
							type: 'object',
							required: [ 'duration' ],
							properties: {
								duration: {
									type: 'integer',
									title: 'Duration (in miliseconds)',
									description: 'Default is forever'
								}
							}
						},
						ui: {}
					}
				},
				{
					id: '4f959ead-0126-4eaf-a8b9-3218a15d0e53',
					version: '1.0.0',
					title: 'Set Variable',
					name: 'Set Variable',
					key: 'Set Variable',
					isLeaf: true,
					ports: {
						port1: {
							id: 'port1',
							type: 'bottom',
							properties: {
								custom: 'property'
							}
						}
					},
					detail: {
						form_schema: {
							title: 'Set Variable',
							description: '',
							type: 'object',
							required: [ 'text' ],
							properties: {
								'assign-to': {
									type: 'string',
									title: 'Assign To'
								},
								value: {
									type: 'string',
									title: 'Value'
								},
								scope: {
									type: 'string',
									title: 'Scope',
									enum: [ 'task', 'scenario' ],
									enumNames: [ 'Task', 'Scenario' ]
								}
							}
						},
						ui: {}
					}
				}
			]
		},
		{
			title: 'Gui Screen',
			key: 'Gui Screen',
			children: []
		},
		{
			title: 'Ocr',
			key: 'Ocr',
			children: []
		},
		{
			title: 'Pdf',
			key: 'Pdf',
			children: []
		}
	];
}
