/* eslint-disable no-restricted-syntax */
export const array2obj = array => {
  if (array && array.length > 0) {
    const obj = {}
    array.forEach(element => {
      Object.assign(obj, { [element.name]: element.value })
    })
    return obj
  }
  return null
}

// Entry point, Scenario Chart being extracted from here ...

const extracterV2 = scenario => {
  const tasks = getTasks(scenario)
  let parameters = scenario.content.scenario_parameters
  parameters = parameters.length > 0 ? parameters : null
  const result = {
    params: array2obj(parameters),
    tasks,
  }
  return result
}
const depFinder = (nodes, links) => {
  // Checking every link
  Object.keys(links).forEach(link_id => {
    const link = links[link_id]
    let targetNodeId
    let dependencyNodeId

    if (link.to) {
      if (link.to.portId === 'enterance') {
        // Normal connected link detection
        targetNodeId = link.to.nodeId
        dependencyNodeId = link.from.nodeId
      } else {
        // Reverse connected link detection
        targetNodeId = link.from.nodeId
        dependencyNodeId = link.to.nodeId
      }

      nodes.forEach(node => {
        if (node.id === targetNodeId) {
          node.dependencies.push(dependencyNodeId)
        }
        if (
          node.id === dependencyNodeId &&
          node.type === 'while' &&
          node.properties.subtype === 'end'
        ) {
          node.condition_else = targetNodeId
        }
        if (
          node.id === dependencyNodeId &&
          node.type === 'while' &&
          node.properties.subtype === 'start'
        ) {
          node.condition_then = targetNodeId
        }
        if (
          node.id === dependencyNodeId &&
          node.type === 'forEach' &&
          node.properties.subtype === 'end'
        ) {
          node.condition_else = targetNodeId
        }
        if (
          node.id === dependencyNodeId &&
          node.type === 'forEach' &&
          node.properties.subtype === 'start'
        ) {
          node.condition_then = targetNodeId
        }
        if (
          node.id === dependencyNodeId &&
          node.type === 'tryCatch' &&
          node.properties.subtype === 'try'
        ) {
          nodes.forEach(n => {
            if (
              n.type === 'tryCatch' &&
              n.properties.subtype === 'catch' &&
              n.properties.definers.start_id === node.properties.definers.start_id
            ) {
              // delete node.condition
              node.trycatch = {
                ...node.trycatch,
                catch_then: n.id,
                handle_exceptions: n.properties.detail.condition,
              }
              // Object.assign(node, {condition :{ catch_then: n.key, handle_exceptions: n.properties.detail.condition }})
            }
            if (
              n.type === 'tryCatch' &&
              n.properties.subtype === 'final' &&
              n.properties.definers.final_id === node.properties.definers.final_id
            ) {
              node.trycatch = { ...node.trycatch, final_then: n.id }
              // Object.assign(node, { final_then: n.key })
            }
          })
        }
      })
    }

    // Check if condition node

    if (link.from.portId === 'then' || link.from.portId === 'else') {
      nodes.forEach(node => {
        if (node.id === link.from.nodeId) {
          node[`condition_${link.from.portId}`] = link.to.nodeId
        }
      })
    }

    // Check if condition node reverse connected link

    if (link.to.portId === 'then' || link.to.portId === 'else') {
      nodes.forEach(node => {
        if (node.id === link.to.nodeId) {
          node[`condition_${link.to.portId}`] = link.from.nodeId
        }
      })
    }

    nodes.forEach(node => {
      if (node.type === 'while' && node.properties.subtype === 'end') {
        const start_node = nodes.find(n => {
          return (
            n.type === 'while' &&
            n.properties.subtype === 'start' &&
            n.properties.definers.start_id === node.properties.definers.start_id
          )
        })
        start_node.condition_else = node.id
        node.condition_then = start_node.id
        node.condition = start_node.condition
      }
    })

    nodes.forEach(node => {
      if (node.type === 'forEach' && node.properties.subtype === 'end') {
        const start_node = nodes.find(n => {
          return (
            n.type === 'forEach' &&
            n.properties.subtype === 'start' &&
            n.properties.definers.start_id === node.properties.definers.start_id
          )
        })
        start_node.condition_else = node.id
        node.condition_then = start_node.id
        node.condition = start_node.condition
      }
    })
  })

  // Clean Up to the flow data simplification!

  nodes.forEach(node => {
    // Delete Try-Catch nodes' condition key
    if (node.type === 'tryCatch') {
      delete node.condition
    }
    delete node.properties
    delete node.type
  })

  return nodes
}

const getTasks = d => {
  const tasks = []
  const task_links = []
  const { chart } = d.content
  for (const [key, task] of Object.entries(chart.nodes)) {
    let task_data
    const { id, type } = task
    if (type === 'node') {
      task_data = {
        id,
        name: task.properties.name,
        steps: getSteps(task),
        params: array2obj(task.properties.parameters),
        options: task.properties.detail.devOptions || undefined,
        dependencies: [],
      }
    } else {
      task_data = {
        id,
        name: task.properties.name,
        type: task.type,
        properties: task.properties,
        condition: task.properties.detail.condition,
        dependencies: [],
      }
    }

    tasks.push(task_data)
  }

  for (const [key, task_link] of Object.entries(chart.links)) {
    // organize
    task_links.push(task_link)
  }

  return depFinder(tasks, task_links)
  // return findDependencies(tasks, task_links)
}

const getSteps = task => {
  const steps = []
  const step_links = []

  if (task.properties.chart) {
    const step_nodes = task.properties.chart.nodes
    const step_links_data = task.properties.chart.links
    if (step_nodes) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, step] of Object.entries(step_nodes)) {
        let step_data
        if (step.type === 'node') {
          step_data = {
            id: key,
            name:
              step.properties.customName && step.properties.customName.trim !== ''
                ? `${step.properties.customName}`
                : step.properties.name,
            library_id: step.properties.detail.name,
            params: step?.properties?.detail?.formData,

            options: step.properties.detail.devOptions || undefined,
            dependencies: [],
          }
        } else {
          step_data = {
            id: key,
            name: step.properties.name,
            type: step.type,
            properties: step.properties,
            condition: step.properties.detail.condition,
            library_id: step.properties.detail.name,
            dependencies: [],
          }
        }

        steps.push(step_data)
      }
    }
    if (step_links_data) {
      for (const [key, step_link] of Object.entries(step_links_data)) {
        step_links.push(step_link)
      }
    }
  }
  return depFinder(steps, step_links)
  // return findDependencies(steps, step_links)
}
export default extracterV2
