/* eslint-disable import/prefer-default-export */

import Moment from 'moment'
import BaseModel from './BaseModel'

class Queue extends BaseModel {
  constructor(props) {
    const {
      schema,
      max_retry,
      auto_retry,
      organisation,
      total_data,
      execution_time,
      status,
      success,
      inprogress,
      pending,
      business_error,
      robot_error,
      created_by,
      is_public,
    } = props
    super(props)
    this.schema = schema
    this.max_retry = max_retry || "-"
    this.auto_retry = auto_retry
    this.organization = organisation
    this.status = status
    this.success = success
    this.inprogress = inprogress
    this.pending = pending
    this.total_data = total_data
    this.execution_time = execution_time ? parseFloat(execution_time).toFixed(2) : '-'
    this.business_error = business_error
    this.robot_error = robot_error
    this.created_by = created_by 
    this.is_public = is_public
  }
}
class QueueItem {
  constructor(props) {
    const { _source, _id, _index } = props
    this.id = _id
    this.index = _index
    this.data = _source.data || {}
    this.created = _source.created ? Moment(_source.created).format('DD.MM.YYYY HH:mm:ss') : '-'
    this.started = _source.started ? Moment(_source.started).format('DD.MM.YYYY HH:mm:ss') : '-'
    this.completed = _source.completed
      ? Moment(_source.completed).format('DD.MM.YYYY HH:mm:ss')
      : '-'
    this.queue_state = _source.queue_state || 'unknown'
    this.robot = _source.robot || '-'
    this.retry_count = _source.retry_count || '-'
    this.priority = _source.priority
    this.name = _source.name || '-'
    this.exception = _source.exception || '-'
    this.message = _source.message || '-'
    this.jobrunId = _source.jobrun_id
    this.duration = _source.duration || '-'
    this.deadline = _source.deadline || '-'
  }
}

export { Queue, QueueItem }
