import { getList, createRecord, getOneByID } from 'api/requests'
import { TriggerHandler, TriggerRuleSet, TriggerActivity, Trigger } from 'models/Trigger'
/**
 *
 * @param {string} name
 * @param {number} handler
 * @param {Object} params
 * @returns
 */
const addNewTriggerActivity = async (name, ruleset, params) => {
  return new Promise(async (resolve, reject) => {
    const { success, data, error } = await createRecord('triggeractivities', {
      name,
      ruleset,
      params,
    })
    if (success) {
      resolve(data)
    }
    reject(error)
  })
}

const getTriggers = async () => {
  const { success, data } = await getList('triggers', { pageSize: 100 })
  if (success) {
    const formedData = data.results.map(r => new Trigger(r))
    return formedData
  }
  return []
}
const getTriggerHandlers = async () => {
  const { success, data } = await getList('triggerhandlers')
  if (success) {
    const formedData = data.results.map(r => new TriggerHandler(r))
    return formedData
  }
  return []
}
const getTriggerRuleSets = async id => {
  const { success, data } = await getList('triggerrulesets', { handler: id })
  if (success) {
    const formedData = data.results.map(r => new TriggerRuleSet(r))
    return formedData
  }
  return []
}
const getTriggerRuleSetConfigForVault = async id => {
  const { success, data } = await getOneByID('triggerrulesets', id)
  if (success) {
    const triggerRuleSet = new TriggerRuleSet(data)
    return triggerRuleSet.config.vault?.requirements || null
  }
  return null
}
const getTriggerActivities = async () => {
  return new Promise(async (resolve, reject) => {
    const { success, data, error } = await getList('triggeractivities', { pageSize: 100 })
    if (success) {
      const formedData = data.results.map(r => new TriggerActivity(r))
      resolve(formedData)
    }
    reject(error)
  })
}

export {
  getTriggers,
  getTriggerHandlers,
  getTriggerRuleSets,
  getTriggerRuleSetConfigForVault,
  getTriggerActivities,
  addNewTriggerActivity,
}
