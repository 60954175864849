import { Avatar, Col, Input, Row, Table, Tag } from 'antd'
import { getIntl } from 'localization'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

const CatalogList = ({ onConfirmCatalog, catalog_items, user, dispatch }) => {
  const [searchValue, setSearchValue] = useState(null)
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    dispatch({
      type: 'catalog_items/GET_CATALOG_ITEMS',
      payload: { filters: { is_market: false, organization: user.organizations[0].id } },
    })
  }, [])

  useEffect(() => {
    setFilteredData(catalog_items.catalog_items)
  }, [catalog_items])

  useEffect(() => {
    if (searchValue) {
      onSearchChange()
    }
  }, [searchValue])

  const onSearchChange = () => {
    setFilteredData(
      catalog_items.catalog_items.filter(d => {
        const search_string = searchValue.toLowerCase()
        const lower_data = d.name.toLowerCase()
        let tagstring = ''
        d.tags.forEach(tag => {
          tagstring += `${tag.toLowerCase()},`
        })
        return lower_data.includes(search_string) || tagstring.includes(search_string)
      }),
    )
  }

  const columns = [
    {
      title: '',
      dataIndex: 'icon',
      key: 'icon',
      render: icon => <Avatar src={icon} shape="square" />,
    },
    {
      title: getIntl('Robot Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: getIntl('Description'),
      dataIndex: 'short_description',
      key: 'short_description',
    },
    {
      title: getIntl('Tags'),
      dataIndex: 'tags',
      key: 'tags',
      render: tags => {
        return tags.map(tag => (
          <Tag
            key={tag}
            color="orange"
            style={{ cursor: 'pointer' }}
            onClick={e => setSearchValue(e.target.innerText)}
          >
            {tag.toUpperCase()}
          </Tag>
        ))
      },
    },
    {
      title: 'APT',
      dataIndex: 'apt',
      key: 'apt',
      render: apt => `${apt} ${getIntl('Minutes')}`,
    },
  ]

  const confirmCatalogItem = item => {
    onConfirmCatalog(item)
  }

  const handleOnRow = record => {
    return {
      onClick: () => confirmCatalogItem(record),
    }
  }

  return (
    <Row justify="center">
      <Col span={24}>
        <Input.Search
          onChange={e => setSearchValue(e.target.value)}
          placeholder={getIntl('catalogs.main.search')}
          value={searchValue}
          enterButton
          allowClear
        />
      </Col>

      <Col span={24}>
        <div style={{ overflow: 'scroll', height: '70vh' }}>
          <Table columns={columns} dataSource={filteredData} onRow={handleOnRow} />
        </div>
      </Col>
    </Row>
  )
}
const mapStateToProps = ({ catalog_items, user }) => ({ catalog_items, user })

export default connect(mapStateToProps)(CatalogList)
