
class Usage {

  constructor(props) {
    const {
      plan,
      due_usage,
      free_minutes,
      free_minutes_remaining,
      total_package_usage,
      total_package_minutes,
      total_package_remaining,
      is_tiered_usage_available,
      can_submit_job,
      can_add_robot,
      can_add_scenario }
      = props

    this.plan = plan
    this.free_minutes = parseFloat(free_minutes).toFixed(2)
    this.free_minutes_remaining = parseFloat(free_minutes_remaining).toFixed(2)
    this.free_usage = (parseFloat(free_minutes) - parseFloat(free_minutes_remaining)).toFixed(2)
    this.total_package_usage = parseFloat(total_package_usage).toFixed(2)
    this.total_package_minutes = parseFloat(total_package_minutes).toFixed(2)
    this.total_package_remaining = parseFloat(total_package_remaining).toFixed(2)
    this.is_tiered_usage_available = is_tiered_usage_available
    this.can_submit_job = can_submit_job
    this.can_add_robot = can_add_robot
    this.can_add_scenario = can_add_scenario
  }
}

export default Usage
