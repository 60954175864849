import React from 'react'
import { connect } from 'react-redux'
import { TourProvider } from '@reactour/tour'
import ContentComponent from './components/ContentComponent'
import { store } from 'index'

const mapStateToProps = ({ user, tours }) => ({ user, tours })

const ManagerTourer = ({ children, dispatch, tours }) => {
  const previousStep = () => {
    const { activeTour } = store.getState().tours
    if (activeTour.currentStep !== 0) {
      dispatch({ type: 'tours/BACK' })
    }
  }
  const nextStep = () => {
    const { activeTour } = store.getState().tours
    if (activeTour.steps.length === activeTour.currentStep + 1) {
      dispatch({ type: 'tours/FINISH_TOUR' })
    } else {
      dispatch({ type: 'tours/FORWARD' })
    }
  }

  return (
    <TourProvider
      disableInteraction
      disableKeyboardNavigation
      styles={{
        popover: base => {
          return {
            ...base,
            '--reactour-accent': '#df255a',
            borderRadius: '5px',
            maxWidth: '600px',
          }
        },
      }}
      ContentComponent={props => (
        <ContentComponent
          {...props}
          currentStep={tours.activeTour.currentStep}
          tour={tours.activeTour.name}
          dispatch={dispatch}
        />
      )}
      showBadge={false}
      showCloseButton
      /*   onClickMask={props => {
        console.log(props)
      }} */
      onClickHighlighted={props => {
        //   console.log(props)
      }}
      keyboardHandler={(e, clickProps) => {
        if (e.key === 'ArrowLeft') {
          previousStep()
        }
        if (e.key === 'ArrowRight') {
          nextStep()
        }
      }}
    >
      {children}
    </TourProvider>
  )
}
export default connect(mapStateToProps)(ManagerTourer)
