import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getAgreements, updateAgreement } from 'services/agreements'
import actions from './actions'
import Agreement from 'models/Agreement'

export function* GET_AGREEMENTS({ payload }) {
  try {
    yield put({
      type: 'agreements/SET_STATE',
      payload: { loading: true },
    })
    const {
      success,
      data: { results, count },
    } = yield call(getAgreements, payload?.filters || {})
    if (success) {
      const agreements = results.map(r => new Agreement(r))
      yield put({
        type: 'agreements/SET_STATE',
        payload: {
          agreements,
          loading: false,
          count,
        },
      })
    }
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: 'agreements/SET_STATE',
    payload: { loading: false },
  })
}

export function* UPDATE_AGREEMENT({ payload }) {
  try {
    yield put({
      type: 'agreements/SET_STATE',
      payload: { loading: true },
    })
    const { success } = yield call(updateAgreement, payload)
    if (success) {
      const data = {
        message: 'Agreement Updated',
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'success',
        },
      })
    }
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: 'agreements/SET_STATE',
    payload: { loading: false },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_AGREEMENTS, GET_AGREEMENTS)])
}
