import React from 'react'

const IfNodeInner = ({ node }) => {
    return (
      <div style={{ padding: 0 }}>
        <p className="mb-0 text-center" style={{ color: 'black' }}>
          {node.properties.name}
        </p>
        <textarea
          placeholder="Enter your condition here"
          onChange={e => {
                    node.properties.detail.condition = e.target.value
                }}
          onClick={e => e.stopPropagation()}
          onMouseUp={e => e.stopPropagation()}
          onMouseDown={e => e.stopPropagation()}
          onKeyPress={e => e.stopPropagation()}
          onKeyDown={e => e.stopPropagation()}
          onKeyUp={e => e.stopPropagation()}
          defaultValue={node.properties.detail.condition}
        />
      </div>
    )
}

export default IfNodeInner
