import { getList, getOneByID } from 'api/requests'

const getInvoices = async filters => {
  const result = await getList('invoices', filters)
  return result
}

const getInvoiceItemsByInvoiceID = async ({ id }) => {
  const result = await getList('invoiceitems', id)
  return result
}

const getInvoiceByID = async ({ id }) => {
  const result = await getOneByID('invoices', id)
  return result
}

export { getInvoices, getInvoiceByID, getInvoiceItemsByInvoiceID }
