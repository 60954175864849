import actions from './actions'

const initialState = {
  id: null,
  name: null,
  files: [],
  save_enabled: false,
  market_save_enabled: false,
  content: {
    scenario_parameters: [],
    tasks: [],
    chart: {
      offset: {
        x: 0,
        y: 0,
      },
      nodes: {},
      links: {},
      selected: {},
      hovered: {},
      picked: {
        nodes: [],
        links: [],
        ports: [],
      },
    },
  },
}

export default function scenarioActiveReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case 'RESET_ALL':
      return initialState
    default:
      return state
  }
}
