import actions from './actions'

const initialState = {
    loading: false,
    icon_loading: false,
    catalog_items: [],
    total : 0,
    active_item: {},
}

export default function catalogItemsReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_STATE:
            return { ...state, ...action.payload }
        default:
            return state
    }
}
