import { Cascader, Col, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { addNewTriggerActivity, getTriggerHandlers, getTriggerRuleSets } from 'services/trigger'
import RuleForm from './RuleForm'
import { FormattedHTMLMessage } from 'react-intl'
import { connect } from 'react-redux'
import { getIntl } from 'localization'

const RuleAddModal = ({ open, onFinish, locale }) => {
  const [options, setOptions] = useState([])
  const [selectedRule, setSelectedRule] = useState(null)

  const onRuleSetChange = (_, selectedData) => {
    const selected = selectedData[1]
    setSelectedRule(selected)
  }

  const getHandlerOptions = async () => {
    const handlerOptions = await getTriggerHandlers()
    setOptions(
      handlerOptions.map(r => ({
        value: r.id,
        label: getIntl(r.name),
        isLeaf: false,
      })),
    )
  }

  const getRuleSetOptions = async id => {
    const ruleSetOptions = await getTriggerRuleSets(id)
    return ruleSetOptions.map(r => ({
      key: r.expression,
      label: r.config?.localization?.name[locale] || r.name,
      value: r.id,
      params: r.params,
      config: r.config,
    }))
  }

  useEffect(() => {
    if (open) {
      getHandlerOptions()
    }
  }, [open])

  const loadData = async selectedOptions => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    const childrenOptions = await getRuleSetOptions(targetOption.value)

    targetOption.children = childrenOptions
    setOptions([...options])
  }

  const handleAdd = async ({ name, params }) => {
    addNewTriggerActivity(name, selectedRule.value, params)
      .then(data => onFinish())
      .catch(e => console.log(e))
  }

  const handleFinish = () => {
    setSelectedRule(null)
    onFinish()
  }

  return (
    <Modal
      open={open}
      title={<FormattedHTMLMessage id="Add New Rule" />}
      onCancel={handleFinish}
      footer={null}
      destroyOnClose
    >
      <Row gutter={[16, 32]}>
        <Col span={24}>
          <p>
            <strong>
              <FormattedHTMLMessage id="triggers.ruleAdd.selectRuleType" />
            </strong>
          </p>
          <Cascader options={options} loadData={loadData} onChange={onRuleSetChange} />
        </Col>
        {selectedRule && (
          <Col span={24}>
            <p>
              <strong>
                <FormattedHTMLMessage id="triggers.ruleAdd.nameAndSettings" />
              </strong>
            </p>
            <RuleForm rule={selectedRule} onAdd={handleAdd} />
          </Col>
        )}
      </Row>
    </Modal>
  )
}
const mapStateToProps = ({ settings: { locale } }) => ({ locale })
export default connect(mapStateToProps)(RuleAddModal)
