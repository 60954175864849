import actions from './actions'

const initialState = {
  loading: false,
  organizations: [],
  active_organization: {
    id: null,
    key: null,
    name: '',
    legal_name: '',
    logo: '/resources/images/kronnika_icon_128x128.png',
    industry: '',
    phone: '',
    domain: '',
    address: '',
    country: '',
    state: '',
    city: '',
    zipcode: '',
    contact_person: '',
    contact_email: '',
    billing_address: '',
    billing_country: '',
    billing_state: '',
    billing_city: '',
    billing_zipcode: '',
    tax_office: '',
    tax_number: '',
  },
  suspendDialogOpen: false,
}

export default function organizationssReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET:
      return initialState
    default:
      return state
  }
}
