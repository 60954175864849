import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import TopBar from 'components/layout/MarketplaceTopBar'
import style from './style.module.scss'
import Footer from 'components/widgets/Footer/Public'
import Cookie from 'components/system/Cookie'

const mapStateToProps = ({ settings }) => ({ settings })

const PublicLayout = ({ children }) => {
  return (
    <Layout>
      <Layout.Header>
        <TopBar />
      </Layout.Header>
      <Layout.Content className={style.general}>{children}</Layout.Content>
      <Layout.Footer style={{ paddingTop: '4em', borderTop: 'solid 1px lightgray' }}>
        <Footer />
      </Layout.Footer>
      <Cookie />
    </Layout>
  )
}

export default connect(mapStateToProps)(PublicLayout)
