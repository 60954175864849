const actions = {
  RESET: 'robots/RESET',
  SET_STATE: 'robots/SET_STATE',
  GET_ROBOTS : 'robots/GET_ROBOTS',
  GET_LOCAL_ROBOTS : 'robots/GET_LOCAL_ROBOTS',
  CREATE_NEW_ROBOT : 'robots/CREATE_NEW_ROBOT',
  DELETE_ROBOT : 'robots/DELETE_ROBOT',
  UPDATE_ICON : 'robots/UPDATE_ICON',
  UPDATE_ROBOT : 'robots/UPDATE_ROBOT',
  UPDATE_ROBOT_NAME : 'robots/UPDATE_ROBOT_NAME',
  UPDATE_ROBOT_STATUS : 'robots/UPDATE_ROBOT_STATUS',
}

export default actions
