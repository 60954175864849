import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import chart from './chart/reducers'
import tasks from './tasks/reducers'
import libraries from './libraries/reducers'
import robots from './robots/reducers'
import organization_users from './organization_users/reducers'
import teams from './teams/reducers'
import files from './files/reducers'
import organizations from './organizations/reducers'
import jobs from './jobs/reducers'
import jobruns from './jobruns/reducers'
import taskruns from './taskruns/reducers'
import news from './news/reducers'
import scenarios from './scenarios/reducers'
import active_scenario from './active_scenario/reducers'
import active_step from './active_step/reducers'
import active_robot from './active_robot/reducers'
import settings from './settings/reducers'
import scenario_editor from './scenario_editor/reducers'
import scenario_runner from './scenario_runner/reducers'
import breadcrumbs from './breadcrumbs/reducers'
import statistics from './statistics/reducers'
import logs from './logs/reducers'
import reports from './reports/reducers'
import catalog_items from './catalog_items/reducers'
import marketplace from './marketplace/reducers'
import documentation from './documentation/reducers'
import invoices from './invoices/reducers'
import package_orders from './package_orders/reducers'
import packages from './packages/reducers'
import cards from './cards/reducers'
import plans from './plans/reducers'
import vaults from './vaults/reducers'
import notifications from './notifications/reducers'
import agreements from './agreements/reducers'
import tours from './tours/reducers'
import queues from './queues/reducers'
import internal_notification from './internal_notification/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    chart,
    tasks,
    libraries,
    robots,
    teams,
    files,
    organizations,
    organization_users,
    jobs,
    jobruns,
    taskruns,
    news,
    scenarios,
    active_scenario,
    active_step,
    active_robot,
    settings,
    scenario_editor,
    scenario_runner,
    breadcrumbs,
    statistics,
    logs,
    reports,
    catalog_items,
    marketplace,
    documentation,
    invoices,
    package_orders,
    packages,
    cards,
    plans,
    vaults,
    notifications,
    agreements,
    tours,
    queues,
    internal_notification,
  })
