import { Form, Col, Row, Select } from 'antd'
import { getList } from 'api/requests'
import Loading from 'components/layout/Loading'
import { getIntl } from 'localization'
import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

class CatalogFilterType {
  constructor(props) {
    const { text, filter_type } = props
    this.id = filter_type.id
    this.key = filter_type.name.toLowerCase()
    this.name = text
  }
}
class CatalogFilter {
  constructor(props) {
    const { text, catalog_filter } = props
    this.id = catalog_filter.id
    this.name = text
  }
}

const CategoryFilterInput = ({ settings, onChange, active }) => {
  const [loading, setLoading] = useState(false)
  const [types, setTypes] = useState([])
  const [options, setOptions] = useState([])
  const [selectedFilters, setSelectedFilters] = useState({})

  const getCatalogFilterTypes = async () => {
    setLoading(true)
    const { success, data, error } = await getList('catalogfiltertypetranslations', {
      language: settings.locale.split('-')[0],
    })
    if (success) {
      setTypes(data?.results.map(r => new CatalogFilterType(r)) || [])
    }
    setLoading(false)
  }

  const getCatalogFilters = async type => {
    const { success, data, error } = await getList('catalogfiltertranslations', {
      language: settings.locale.split('-')[0],
      type,
      pageSize: 100,
    })
    if (success) {
      return data?.results.map(r => new CatalogFilter(r)) || []
    }
  }

  const loadData = async v => {
    const targetOption = options.find(({ value }) => value === v)
    targetOption.loading = true
    const loadedOptions = await getCatalogFilters(v)
    const children = loadedOptions.map(({ name, id }) => ({ label: name, value: id }))
    targetOption.loading = false
    targetOption.children = children
    setOptions([...options])
  }

  useEffect(() => {
    if (active) getCatalogFilterTypes()
  }, [active])

  useEffect(() => {
    const data = []
    Object.keys(selectedFilters).forEach(key => {
      data.push(...selectedFilters[key])
    })
    onChange(data)
  }, [selectedFilters])

  useEffect(() => {
    if (types && types.length > 0) {
      const options = types.map(t => ({
        label: t.name,
        key: t.key,
        value: t.id,
        children: [],
        loading: false,
      }))
      setOptions(options)
    }
  }, [types])
  return (
    <Row gutter={[8]}>
      <Col sm={24}>
        <p>{getIntl('Filters')}</p>
      </Col>
      {options.map(option => {
        return (
          <Col key={option.value} xs={24} sm={8} className="mb-3">
            <Select
              loading={option.loading}
              notFoundContent={<Loading />}
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder={option.label}
              onFocus={() => loadData(option.value)}
              onChange={values => setSelectedFilters({ ...selectedFilters, [option.key]: values })}
              maxTagCount={1}
            >
              {option.children.map(opt => (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        )
      })}
    </Row>
  )
}
const mapStateToProps = ({ settings }) => ({ settings })
export default connect(mapStateToProps)(CategoryFilterInput)
