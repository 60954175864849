import React from 'react'

const WhileNodeInner = ({ node }) => {
  const onChange = e => {
    node.properties.detail.condition = e.target.value
  }

  return (
    <div style={{ padding: 0 }}>
      <p className="mb-0 text-center" style={{ color: 'black' }}>
        {node.properties.name}
      </p>
      {node.properties.subtype !== 'end' && (
        <textarea
          placeholder="Enter your while condition here"
          onChange={onChange}
          onClick={e => e.stopPropagation()}
          onMouseUp={e => e.stopPropagation()}
          onMouseDown={e => e.stopPropagation()}
          onKeyPress={e => e.stopPropagation()}
          onKeyDown={e => e.stopPropagation()}
          onKeyUp={e => e.stopPropagation()}
          defaultValue={node.properties.detail.condition}
        />
      )}
    </div>
  )
}
export default WhileNodeInner
