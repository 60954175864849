import { all, takeEvery, put, putResolve, call, select } from 'redux-saga/effects'
import {
  getScenario,
  saveScenario,
  deleteScenario,
  createNewScenarioVersion,
  getScenarioList,
  getScenarioByID,
  getScenarioVersionByID,
} from 'services/scenarios'
import { getIntl } from 'localization'
import { resetModified } from 'utils/scenarioChangeHandler'
import actions from './actions'
import ActiveScenario from 'models/ActiveScenario'
import { history } from 'index'
import { updateRecentlyOpenedScenario } from 'utils/recentScenarios'

export function* GET_SCENARIO({ payload, silent }) {
  if (!silent) {
    yield put({
      type: 'scenarios/SET_STATE',
      payload: {
        loading: true,
      },
    })
  }
  if (!silent) {
    yield putResolve({
      type: 'active_scenario/RESET',
    })
  }
  try {
    const { scenarioId } = payload
    const { success, scenario } = yield call(getScenario, { scenarioId }) // This actually get scenario version !!! Why

    if (success) {
      const s = {
        id: scenario.id,
        version_id: scenario.version_id,
        name: scenario.name ? scenario.name : '',
        is_public: scenario.is_public,
        allowed_teams: scenario.allowed_teams,
        created_by: scenario.created_by,
        //* version related
      }
      yield put({
        type: 'active_scenario/SET_ACTIVE_SCENARIO',
        payload: s,
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'scenarios/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_SCENARIO_LIST({ payload }) {
  yield put({
    type: 'scenarios/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const filteredScenarios = []

  function* fetcher(nextUrl) {
    const { success, data } = yield call(getScenarioList, { ...payload, nextUrl })

    if (success) {
      const { results, count, next } = data

      results.forEach(scenario => {
        const item = {
          id: scenario.scenario_id,
          version_id: scenario.id,
          name: scenario.scenario_name,
          created_by: scenario.created_by,
        }
        if (item.version_id) filteredScenarios.push(item)
      })

      yield put({
        type: 'scenarios/SET_STATE',
        payload: {
          scenarios: filteredScenarios,
          count,
        },
      })
      if (next) {
        yield fetcher(next)
      }
    }
  }

  try {
    yield call(fetcher)
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'scenarios/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_SCENARIO_BY_VERSION_ID({ payload, silent }) {
  if (!silent) {
    yield put({
      type: 'active_scenario/SET_STATE',
      payload: { loading: true },
    })
  }
  try {
    const { scenario_version_id } = payload
    const { success: versionSuccess, scenario_version } = yield call(getScenarioVersionByID, {
      scenario_version_id,
    })

    if (versionSuccess) {
      const { success: scenarioSuccess, data: scenario } = yield call(getScenarioByID, {
        scenario_id: scenario_version.scenario,
      })

      if (scenarioSuccess) {
        const active_scenario = new ActiveScenario(scenario, scenario_version)

        if (active_scenario?.content?.chart) {
          yield put({
            type: 'active_scenario/SET_ACTIVE_SCENARIO',
            payload: { active_scenario },
            silent,
          })
        } else {
          const data = {
            message: getIntl('notifications.emptyScenarioContent'),
          }
          yield put({
            type: 'internal_notification/SET_STATE',
            payload: {
              data,
              type: 'info',
            },
          })
        }
      }
    }
  } catch (e) {
    console.log(e)
  }

  // This resets default scenario changing loading state trigger from scenario versions tabs
  yield put({
    type: 'scenarios/SET_STATE',
    payload: { loading_version: false },
  })

  yield put({
    type: 'active_scenario/SET_STATE',
    payload: { loading: true },
  })
}

export function* SAVE_SCENARIO({ payload = {}, silent }) {
  // Silent prop makes no notification for auto save like application
  try {
    yield put({
      type: 'scenarios/SET_STATE',
      payload: { saving: true },
    })
    const { active_scenario } = yield select()

    if (!active_scenario.content?.newFlowType || active_scenario.content?.newFlowType === false) {
      console.info('Redirecting to new scenario version creation...')
      // Redirect to new scenario version creating if this newFlowType field does not exist or false
      yield put({
        type: 'scenarios/SAVE_AS_NEW_SCENARIO_VERSION',
        payload: { ...payload, is_default: true, description: 'New flow data type' },
        silent,
        redirect: true,
      })
      return false
    }

    const { success, scenarioVersion } = yield call(saveScenario, { active_scenario })

    if (success) {
      if (!silent) {
        yield put({
          type: 'internal_notification/SET_STATE',
          payload: {
            data: {
              message: getIntl('notification.scenario.save.success.message'),
            },
            type: 'success',
          },
        })
        resetModified('scenario')
      }
    }
    yield put({
      type: 'scenarios/SET_STATE',
      payload: { saving: false },
    })
  } catch (e) {
    console.log(e)
  } finally {
    yield put({
      type: 'scenarios/SET_STATE',
      payload: { saving: false },
    })
  }
  const { next } = payload
  if (next) {
    history.push(next)
  }
}

export function* SAVE_AS_NEW_SCENARIO_VERSION({ payload, silent = false, redirect = false }) {
  yield put({
    type: 'active_scenario/SET_STATE',
    payload: { loading: true },
  })
  try {
    const { description, is_default } = payload
    const { active_scenario } = yield select()

    const { success, newScenarioVersion } = yield call(createNewScenarioVersion, {
      active_scenario,
      description,
      is_default,
    })

    if (success) {
      updateRecentlyOpenedScenario(active_scenario.id, { version_id: newScenarioVersion.id })
      if (!silent) {
        const data = {
          message: getIntl('notification.scenario.save.success.message'),
        }
        yield put({
          type: 'internal_notification/SET_STATE',
          payload: {
            data,
            type: 'success',
          },
        })
        resetModified('scenario')

        yield put({
          type: 'scenario_editor/CHANGE_SCENE',
          payload: { scene: 'scenario' },
        })

        yield put({
          type: 'scenarios/GET_SCENARIO_LIST',
        })
      }
      if (redirect) {
        history.push(`/scenario-designer/?version=${newScenarioVersion.id}&refresh=false`)
      }
    }
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: 'active_scenario/SET_STATE',
    payload: { loading: false },
  })
}

export function* DELETE_SCENARIO({ payload }) {
  try {
    const { scenario_id } = payload
    const { success } = yield call(deleteScenario, { scenario_id })
    if (success) {
      const data = {
        message: getIntl('notification.scenario.delete.success.message'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'success',
        },
      })
      yield putResolve({
        type: 'active_scenario/RESET',
      })

      history.push('/scenario-designer')
    }
  } catch (e) {
    console.log(e)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SCENARIO, GET_SCENARIO),

    // checked
    takeEvery(actions.GET_SCENARIO_LIST, GET_SCENARIO_LIST),
    takeEvery(actions.GET_SCENARIO_BY_VERSION_ID, GET_SCENARIO_BY_VERSION_ID),
    takeEvery(actions.SAVE_SCENARIO, SAVE_SCENARIO),
    takeEvery(actions.SAVE_AS_NEW_SCENARIO_VERSION, SAVE_AS_NEW_SCENARIO_VERSION),

    takeEvery(actions.DELETE_SCENARIO, DELETE_SCENARIO),
  ])
}
