import Moment from 'moment'

export default class BaseModel {
  constructor({
    id, name, description,
    is_active,
    created, updated, deleted
  }) {

    this.id = id
    this.key = id
    this.name = name
    this.description = description
    this.is_active = is_active
    this.created_at = Moment(created).format('DD.MM.YYYY HH:mm:ss')
    this.updated_at = Moment(updated).format('DD.MM.YYYY HH:mm:ss')
    this.deleted_at = Moment(deleted).format('DD.MM.YYYY HH:mm:ss')

  }
}
