import axios from 'utils/axiosGlobal'
import { API_URL } from 'index'
import { getList } from 'api/requests'

const getLogs = async payload => {
  const { scope, id, jobrunId, last_log_date } = payload
  const iterator = () => {
    return last_log_date ? `&created_from=${last_log_date}` : ''
  }
  const jobrunInjector = jobrunId ? `&jobrun=${jobrunId}` : ''

  const url = `${API_URL}/logs/?${scope}=${id}${iterator()}${jobrunInjector}&order_by=created`

  return axios(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  })
    .then(result => {
      const logs = result.data.results
      return {
        success: true,
        logs,
      }
    })
    .catch(error => {
      return { success: false }
    })
}

const getLogsForTask = async payload => {
  const { jobrun, page } = payload
  const data = await getList('logs', { jobrun, order_by: 'log_time', pageSize: 10, page })
  return data
}

// eslint-disable-next-line import/prefer-default-export
export { getLogs, getLogsForTask }
