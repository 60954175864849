import { history } from 'index'
import { Queue } from 'models/Queue'
import { all, put, call, takeEvery } from 'redux-saga/effects'
import { getQueues, addQueue, deleteQueue } from 'services/queues'
import actions from './actions'

export function* GET_QUEUES({ payload }) {
  yield put({
    type: 'queues/SET_STATE',
    payload: { loading: true },
  })
  try {
    const { success, data } = yield call(getQueues, payload?.filters)

    if (success) {
      const { results, count } = data
      const queues = results.map(result => new Queue(result))
      yield put({
        type: 'queues/SET_STATE',
        payload: { queues, count },
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'queues/SET_STATE',
    payload: { loading: false },
  })
}

export function* ADD_QUEUE({ payload }) {
  yield put({
    type: 'queues/SET_STATE',
    payload: { loading: true },
  })
  try {
    const { success, data, error } = yield call(addQueue, payload.queueData)
    if (success) {
      history.push(`/queues/`)
    } else {
      const data = {
        message: JSON.stringify(error.response.data.detail, null, 2), // Handle better
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: 'queues/SET_STATE',
    payload: { loading: false },
  })
}
export function* DELETE_QUEUE({ payload }) {
  yield put({
    type: 'queues/SET_STATE',
    payload: { loading: true },
  })
  try {
    const { success } = yield call(deleteQueue, payload.queueId)
    if (success) {
      yield put({
        type: 'queues/GET_QUEUES',
      })
    }
  } catch (e) {
    console.log(e)

    yield put({
      type: 'queues/SET_STATE',
      payload: { loading: false },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_QUEUES, GET_QUEUES),
    takeEvery(actions.ADD_QUEUE, ADD_QUEUE),
    takeEvery(actions.DELETE_QUEUE, DELETE_QUEUE),
  ])
}
