import {
  getList,
  getOneByID,
  getFirst,
  createRecord,
  deleteRecord,
  updateRecord,
} from 'api/requests'
import axios from 'utils/axiosGlobal'
import { API_URL } from 'index'
import { filterEmptyProperties } from './utilities'
import { convertToSlug } from 'utils/slugger'

const DEFAULT_APT = 2

export async function getCatalogItems({ filters, anonymous }) {
  const result = await getList('catalogitems', filters, anonymous)
  return result
}

export async function getCatalogItemByID({ id, anonymous }) {
  const result = await getOneByID('catalogitems', id, null, '/', anonymous)
  return result
}

export async function getCatalogItemVersions({ filters, anonymous }) {
  const result = await getList('catalogitemversions', filters, anonymous)
  return result
}
export async function getCatalogItemVersionsMenu({ filters, anonymous }) {
  const result = await getList('catalogitemversionsmenu', filters, anonymous)
  return result
}
export async function getCatalogItemVersionByID({ id, anonymous }) {
  const result = await getOneByID('catalogitemversions', id, null, '/', anonymous)
  return result
}
export async function getCatalogItemVersionByKey({ filters, anonymous }) {
  const result = await getFirst('catalogitemversions', filters, anonymous)
  return result
}

export async function getDefaultCatalogItemVersion({ id }) {
  const result = await getFirst('catalogitemversions', {
    catalog_item: id,
    is_default: true,
  })
  return result
}

export async function updateCatalogItemIcon({ id, photo }) {
  return new Promise(resolve => {
    const url = `${API_URL}/catalogitems/${id}/`
    const formData = new FormData()
    formData.append('icon', photo)
    return axios(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
      data: formData,
    })
      .then(result => {
        resolve({ success: true, catalog_item: result.data })
      })
      .catch(() => resolve(false))
  })
}

export async function addScenarioToCatalog(payload) {
  const { item, scenario_id, scenario_version_id, cloud_url } = payload
  let catalog
  const { success: catalogSuccess, data } = await getFirst('catalogs')

  if (!catalogSuccess && !data) {
    const { success, data } = await createRecord('catalogs', {
      name: Math.random()
        .toString(36)
        .substr(2, 5),
    })
    if (!success) return { success }
    catalog = data
  } else {
    catalog = data
  }

  if (!catalog) return { success: false }
  const { success, data: new_item } = await createRecord('catalogitems', {
    ...item,
    key: convertToSlug(item.name),
    name: item.name,
    scenario: { id: scenario_id },
    scenarioversion: { id: scenario_version_id },
    catalog: { id: catalog.id },
    catalog_filters: item.catalog_filters
      ? item.catalog_filters.map(ci => ({ id: ci }))
      : undefined,
    apt: DEFAULT_APT,
    html_sections: {
      'tr-TR': { name: item.name, short_description: item.short_description, about_the_robot: {} },
      'en-US': { name: item.name, short_description: item.short_description, about_the_robot: {} },
    },
  })

  if (success) {
    // This means Catalog Item created successfully
    return createRecord('catalogitemversions', {
      catalogitem: { id: new_item.id },
      scenarioversion: { id: scenario_version_id },
      cloud_url,
    })
  }
  return { success: false }
}

export async function addScenarioToNewCatalogItemVersion(payload) {
  const { catalog_item_id, scenario_version_id, cloud_url } = payload
  return createRecord('catalogitemversions', {
    catalogitem: { id: catalog_item_id },
    scenarioversion: { id: scenario_version_id },
    is_default: true,
    cloud_url,
  })
}

export async function updateCatalogItem(payload) {
  const { id } = payload
  return updateRecord('catalogitems', id, filterEmptyProperties(payload))
}
export async function updateCatalogItemVersion(payload) {
  const { id } = payload
  return updateRecord('catalogitemversions', id, filterEmptyProperties(payload))
}

/**
 * This function is a 2 step updater
 *
 * 1 - Updates the catalog item version with CREDIT & APT
 *
 * 2 - Creates a publish request for the catalog item version
 *
 */

export async function publishCatalogItem(payload) {
  const { id, credit, apt, tags, short_description, cloud_url, catalog_item_version_id } = payload
  const { success, data } = await updateCatalogItem({ id, credit, apt, tags, short_description })
  const { success: versionSuccess } = cloud_url
    ? await updateCatalogItem({
        cloud_url,
      })
    : { success: true }

  return success && versionSuccess
    ? createRecord('publishrequests', { catalogitemversion: { id: catalog_item_version_id } })
    : { success: false }
}

export async function deleteCatalogItem({ id }) {
  const result = deleteRecord('catalogitems', id)
  return result
}
