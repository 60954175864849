import actions from './actions'

const initialState = {
  loading : false,
  jobruns : [

  ]
}

export default function jobrunsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return {...state, ...action.payload }
    case actions.RESET:
      return initialState
    default:
      return state
  }
}
