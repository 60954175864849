/**
 *
 * @param {string} base Base version to compare
 * @param {string} compare Version to counter of base
 * @returns
 *
 * 0 => Versions are same
 *
 * 1 => Base is greater than compare
 *
 * -1 => Base is less than compare
 */
const compareCalVersion = (base, compare) => {
  let result = null

  const compareVersionString = compare.startsWith('v') ? compare.slice(1) : compare
  const baseVersionString = base.startsWith('v') ? base.slice(1) : base

  if (compareVersionString === baseVersionString) {
    result = 0
    return result
  }

  const splittedCompareVersion = compareVersionString.split('.').map(Number)
  const splittedBaseVersion = baseVersionString.split('.').map(Number)

  if (splittedBaseVersion.length < splittedCompareVersion.length) {
    const diff = splittedCompareVersion.length - splittedBaseVersion.length
    for (let i = 0; i < diff; i++) {
      splittedBaseVersion.push(0)
    }
  }

  if (splittedBaseVersion.length > splittedCompareVersion.length) {
    const diff = splittedBaseVersion.length - splittedCompareVersion.length
    for (let i = 0; i < diff; i++) {
      splittedCompareVersion.push(0)
    }
  }

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (let i = 0; i < splittedBaseVersion.length; i++) {
    if (splittedBaseVersion[i] === splittedCompareVersion[i]) {
      // eslint-disable-next-line no-continue
      continue
    }

    if (splittedBaseVersion[i] > splittedCompareVersion[i]) {
      result = 1
      break
    }
    if (splittedBaseVersion[i] < splittedCompareVersion[i]) {
      result = -1
      break
    }
    if (
      splittedBaseVersion.length - 1 === i &&
      splittedBaseVersion[i] === splittedCompareVersion[i]
    ) {
      result = 0
      break
    }

    // eslint-disable-next-line no-continue
    continue
  }
  return result
}

export default compareCalVersion
