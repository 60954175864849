import actions from './actions'

const initialState = {
  loading : false,
  cards : [

  ],
  defaultCard : null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return {...state, ...action.payload }
    default:
      return state
  }
}
