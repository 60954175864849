import React from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import english from './locales/en-US'
import turkish from './locales/tr-TR'

const locales = {
  'en-US': english,
  'tr-TR': turkish,
}

let GlobalIntl = null
/**
 * Takes an "id" string as input & returns localized string
 *
 * @params string - id
 * @returns string  - localized string with related locale
 */
export const getIntl = (id) => {
  return GlobalIntl[id] ? GlobalIntl[id] : id
}

const Localization = ({ children, settings: { locale } }) => {

  const currentLocale = locales[locale]

  const intlProvider = new IntlProvider({ locale: currentLocale.locale, messages: currentLocale.messages })
  GlobalIntl = intlProvider.props.messages;

  return (
    <ConfigProvider locale={currentLocale.localeAntd}>
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}

const mapStateToProps = ({ settings }) => ({ settings })

export default connect(mapStateToProps)(Localization)
