import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import {
  getRobots,
  createNewRobot,
  updateRobotIcon,
  updateRobotName,
  updateRobotStatus,
  updateRobot,
  deleteRobot,
} from 'services/robots'
import Robot from 'models/Robot'
import { getIntl } from 'localization'
import actions from './actions'
import { getLocalRobots, handleReadyRobots } from 'utils/robotOperationHelper'

export function* GET_LOCAL_ROBOTS({ payload }) {
  yield put({
    type: 'robots/SET_STATE',
    payload: { localLoading: true },
  })
  const { selectedRobots, setReadyRobots } = payload
  const { success, data: localRobots } = yield call(getLocalRobots)
  if (success) {
    yield put({
      type: 'robots/SET_STATE',
      payload: { localRobots },
    })
    const readyRobots = yield call(handleReadyRobots, localRobots, selectedRobots, setReadyRobots)
    yield put({
      type: 'robots/SET_STATE',
      payload: { localLoading: false, readyRobots },
    })
  }
}
export function* GET_ROBOTS({ payload }) {
  yield put({
    type: 'robots/SET_STATE',
    payload: { loading: true },
  })

  const { success, data } = yield call(getRobots, payload)
  const { count, results } = data
  if (success) {
    const robots_data = results.map(robot => new Robot({ ...robot }))
    yield put({
      type: 'robots/SET_STATE',
      payload: { loading: false, robots: robots_data, count },
    })
  } else {
    yield put({
      type: 'robots/SET_STATE',
      payload: { loading: false },
    })
  }
}

export function* UPDATE_ICON({ payload }) {
  const { success } = yield call(updateRobotIcon, payload)
  if (success) {
    const data = { message: getIntl('notifications.robotProfile.iconUpdate.success') }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
  } else {
    const data = { message: getIntl('notifications.robotProfile.iconUpdate.error') }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
}

export function* DELETE_ROBOT({ payload }) {
  const { robot_id } = payload
  const { success } = yield call(deleteRobot, { robot_id })
  if (success) {
    const data = { message: getIntl('notifications.robotProfile.delete.success') }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
  } else {
    const data = { message: getIntl('notifications.robotProfile.delete.error') }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
}

export function* UPDATE_ROBOT_NAME({ payload }) {
  const { active_robot } = yield select()
  const { success, data: robot } = yield call(updateRobotName, payload)
  if (success) {
    yield put({
      type: 'active_robot/SET_STATE',
      payload: { ...active_robot, robot: new Robot(robot) },
    })
    const data = { message: getIntl('notifications.robotProfile.update.success') }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
  } else {
    const data = { message: getIntl('notifications.robotProfile.update.error') }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
}
export function* UPDATE_ROBOT({ payload }) {
  const { active_robot } = yield select()
  const { success, data: robot } = yield call(updateRobot, {
    data: payload,
    robot_id: active_robot.robot.id,
  })
  if (success) {
    yield put({
      type: 'active_robot/SET_STATE',
      payload: { ...active_robot, robot: new Robot(robot) },
    })
    const data = { message: getIntl('notifications.robotProfile.update.success') }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
  } else {
    const data = { message: getIntl('notifications.robotProfile.update.error') }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
}
export function* UPDATE_ROBOT_STATUS({ payload }) {
  yield put({
    type: 'active_robot/SET_STATE',
    payload: { onoffLoading: true },
  })

  const { success, data: robot } = yield call(updateRobotStatus, payload)
  if (success) {
    yield put({
      type: 'active_robot/SET_STATE',
      payload: { robot: new Robot(robot) },
    })
    const data = { message: getIntl('notifications.robotProfile.update.success') }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
  } else {
    const data = { message: getIntl('notifications.robotProfile.update.error') }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }

  yield put({
    type: 'active_robot/SET_STATE',
    payload: { onoffLoading: false },
  })
}

export function* CREATE_NEW_ROBOT({ payload }) {
  // This function is not used
  yield put({
    type: 'robots/SET_STATE',
    payload: { loading: true },
  })

  const robot_data = {
    name: payload.name,
    robotconfig: {
      platform: {
        os: payload.os,
        provider: payload.provider,
      },
      is_gui_available: payload.gui,
      is_vnc_available: payload.vnc,
      icon: payload.icon,
    },
  }

  const { success, robot } = yield call(createNewRobot, robot_data)

  if (success) {
    yield put({
      type: 'active_robot/SET_STATE',
      payload: { robot },
    })
  }

  yield put({
    type: 'robots/SET_STATE',
    payload: { loading: false },
  })
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ROBOTS, GET_ROBOTS),
    takeEvery(actions.GET_LOCAL_ROBOTS, GET_LOCAL_ROBOTS),
    takeEvery(actions.CREATE_NEW_ROBOT, CREATE_NEW_ROBOT),
    takeEvery(actions.DELETE_ROBOT, DELETE_ROBOT),
    takeEvery(actions.UPDATE_ICON, UPDATE_ICON),
    takeEvery(actions.UPDATE_ROBOT, UPDATE_ROBOT),
    takeEvery(actions.UPDATE_ROBOT_NAME, UPDATE_ROBOT_NAME),
    takeEvery(actions.UPDATE_ROBOT_STATUS, UPDATE_ROBOT_STATUS),
  ])
}
