const actions = {
  RESET: 'active_scenario/RESET',
  RESET_SELECTIONS: 'active_scenario/RESET_SELECTIONS',
  SET_STATE: 'active_scenario/SET_STATE',
  SET_TASK: 'active_scenario/SET_TASK',
  RENAME_TASK: 'active_scenario/RENAME_TASK',
  RENAME_STEP: 'active_scenario/RENAME_STEP',
  SET_SCENARIO_CHART: 'active_scenario/SET_SCENARIO_CHART',
  SET_TASK_CHART: 'active_scenario/SET_TASK_CHART',
  SET_ACTIVE_SCENARIO: 'active_scenario/SET_ACTIVE_SCENARIO',
  SET_SCENARIO_PARAMETERS: 'active_scenario/SET_SCENARIO_PARAMETERS',
  SET_ROBOTS: 'active_scenario/SET_ROBOTS',
  GET_FILES: 'active_scenario/GET_FILES',
  IMPORT_JSON: 'active_scenario/IMPORT_JSON',
  CATALOG_ITEM_TO_SCENARIO: 'active_scenario/CATALOG_ITEM_TO_SCENARIO',
  UPDATE_NODE: 'active_scenario/UPDATE_NODE',
  SET_TASK_PARAMETERS: 'active_scenario/SET_TASK_PARAMETERS',
}
export default actions
