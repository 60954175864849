import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { getJobruns } from 'services/jobruns'
import actions from './actions'
import JobRun from 'models/JobRun'

export function* GET_JOBRUNS({ payload }) {

  const { settings } = yield select()
  try {
    const { locale } = settings
    yield put({
      type: 'jobruns/SET_STATE',
      payload: { loading: true },
    })
    const { success, data: { results, count } } = yield call(getJobruns, payload || {})
    if (success) {
      const jobrunList = results.map(jobrun => new JobRun(jobrun, locale))
      yield put({
        type: 'jobruns/SET_STATE',
        payload: { jobruns: jobrunList, loading: false, count },
      })
    }
  } catch (e) { console.log(e) }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_JOBRUNS, GET_JOBRUNS)])
}
