/* eslint-disable import/prefer-default-export */
import Moment from 'moment'

const fileListFormatter = fileObj => {
  const list = []

  const gatherer = (contents, parent) => {
    Object.entries(contents).forEach(([key, value]) => {
      if (value.contents) {
        // Directory
        list.push({
          key: parent ? `${parent + key}/` : `${key}/`,
          type: value.type,
          relativeKey: value.relativeKey,
        })
        gatherer(value.contents, `${parent ? parent + key : key}/`)
      } else {
        list.push({
          key: value.path,
          type: value.type,
          modified: Moment(value.modified),
          size: value.size,
        })
      }
    })
  }
  const { contents } = fileObj
  gatherer(contents)
  return list
}

const fileListFormatterNonRecursive = fileObj => {
  const file_list = []
  const { files, directories, path } = fileObj
  files.forEach(file => {
    const f = {
      type: 'file',
      key: file.name,
      modified: Moment(file.modified),
      size: file.size,
    }
    file_list.push(f)
  })

  directories.forEach(directory => {
    const d = {
      type: 'dir',
      key: `${directory}/`,
      modified: '',
      size: 0,
    }
    file_list.push(d)
  })

  return file_list
}

export { fileListFormatter }
