import { all, takeEvery, put } from 'redux-saga/effects'
import store from 'store'
import { history, store as reduxStore } from 'index'
import actions from './actions'

export function* CHANGE_SETTING({ payload: { setting, value } }) {
  yield store.set(`app.settings.${setting}`, value)
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      [setting]: value,
    },
  })
}

export function* SETUP() {
  // detect isMobileView setting on app load and window resize
  const isMobileView = (load = false) => {
    const currentState = global.window.innerWidth < 768
    const prevState = store.get('app.settings.isMobileView')
    if (currentState !== prevState || load) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMobileView',
          value: currentState,
        },
      })
    }
  }

  // detect viewport width on app load and window resize
  const isMenuToggled = () => {
    const shouldToggle = global.window.innerWidth < 1024
    const prevState = store.get('app.settings.isMenuCollapsed')
    if (shouldToggle || prevState) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMenuCollapsed',
          value: true,
        },
      })
    }
  }

  const isDeveloperMode = (developerMode = false) => {
    const prevState = store.get('app.settings.developerMode')
    if (prevState === undefined) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'developerMode',
          value: developerMode,
        },
      })
    }
  }
  const scenarioListSetDefaults = (withCatalog = false) => {
    const withCatalogPrevState = store.get('app.settings.scenarioList_withCatalog')
    if (withCatalogPrevState === undefined) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'scenarioList_withCatalog',
          value: withCatalog,
        },
      })
    }
  }

  yield isMobileView(true)
  yield isMenuToggled()
  yield isDeveloperMode()
  yield scenarioListSetDefaults()
  yield window.addEventListener('resize', () => {
    isMobileView()
    isMenuToggled()
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_SETTING, CHANGE_SETTING),
    SETUP(), // run once on app load to init listeners
  ])
}
