import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import classNames from 'classnames'
import TopBar from 'components/layout/TopBar'
import MenuLeft from 'components/menus/MenuLeft'
import MenuTop from 'components/layout/MenuTop'
import { Helmet } from 'react-helmet'
import { getIntl } from 'localization'

const mapStateToProps = ({ settings, router }) => ({
  settings,
  router,
})

class AppLayout extends React.PureComponent {
  render() {
    const {
      children,
      settings: {
        menuLayoutType,
        isContentNoMaxWidth,
        isAppMaxWidth,
        isGrayBackground,
        isSquaredBorders,
        isCardShadow,
        isBorderless,
        isTopbarFixed,
        isGrayTopbar,
      }
    } = this.props

    return (
      <div
        data-tour="overAll"
        className={classNames({ air__layout__grayBackground: isGrayBackground })}
      >
        <Helmet title={getIntl('titles.general')} />

        <Layout
          className={classNames({
            air__layout__contentNoMaxWidth: isContentNoMaxWidth,
            air__layout__appMaxWidth: isAppMaxWidth,
            air__layout__grayBackground: isGrayBackground,
            air__layout__squaredBorders: isSquaredBorders,
            air__layout__cardsShadow: isCardShadow,
            air__layout__borderless: isBorderless,
          })}
        >
          {menuLayoutType === 'left' && <MenuLeft />}
          {menuLayoutType === 'top' && <MenuTop />}
          <Layout>
            <Layout.Header
              className={classNames('air__layout__header', {
                air__layout__fixedHeader: isTopbarFixed,
                air__layout__headerGray: isGrayTopbar,
              })}
            >
              <TopBar />
              {/* {isMobileView ? <SubBarMobile /> : <SubBar />} */}
            </Layout.Header>
            <Layout.Content style={{ height: '100%', position: 'relative' }}>
              <div className="air__utils__content">{children}</div>
            </Layout.Content>
          </Layout>
        </Layout>
      </div>
    )
  }
}

export default connect(mapStateToProps)(AppLayout)
