import React, { useEffect } from 'react'
import { Button, Col, Row } from 'antd'
import { getIntl } from 'localization'
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons'
import style from './style.module.scss'

const ContentComponent = ({ steps, setCurrentStep, currentStep, setIsOpen, tour, dispatch }) => {
  const navigationControlButtonProps = {
    type: 'primary',
  }
  const skipButtonProps = {
    style: { border: '1px solid #888', backgroundColor: 'transparent', color: '#888' },
  }

  useEffect(() => {
    setCurrentStep(currentStep)
  }, [currentStep])
  const isLastStep = currentStep === steps.length - 1
  const content = currentStep !== null ? steps[currentStep].content : null

  const handleSkipTour = () => {
    setIsOpen(false)
    dispatch({
      type: 'tours/FINISH_TOUR',
      payload: { finished: true },
    })
  }
  const handleFinishTour = () => {
    setIsOpen(false)
    dispatch({
      type: 'tours/FINISH_TOUR',
      payload: { finished: true },
    })
    dispatch({
      type: 'tours/SET_STATE',
      payload: { welcomeFinishTour: true },
    })
  }

  return (
    content && (
      <Row gutter={[8, 8]} justify="space-between">
        <Col span={24}>
          {typeof content === 'function' ? (
            content({ customText: '' })
          ) : (
            <div className={style.tourText}>{content}</div>
          )}
        </Col>
        <Col span={8}>
          {currentStep !== 0 ? (
            <Button
              {...navigationControlButtonProps}
              icon={<CaretLeftFilled />}
              onClick={() => dispatch({ type: 'tours/BACK' })}
            />
          ) : (
            <Button {...skipButtonProps} onClick={handleSkipTour}>
              {getIntl('Skip Tour')}
            </Button>
          )}
        </Col>
        <Col span={8} className="text-center">
          {isLastStep || currentStep === 0 ? null : (
            <Button {...skipButtonProps} onClick={handleSkipTour}>
              {getIntl('Skip Tour')}
            </Button>
          )}
        </Col>
        <Col span={8} className="text-right">
          {!isLastStep ? (
            <Button
              {...navigationControlButtonProps}
              icon={<CaretRightFilled />}
              onClick={() => dispatch({ type: 'tours/FORWARD' })}
            />
          ) : steps.length > 1 ? (
            <Button {...navigationControlButtonProps} onClick={handleFinishTour}>
              {getIntl('Finish Tour')}
            </Button>
          ) : null}
        </Col>
      </Row>
    )
  )
}
export default ContentComponent
