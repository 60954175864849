import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { history } from 'index'
import {
  logout,
  getCurrentUser,
  updateUser,
  updateUserPhoto,
  updatePassword,
  loginWithEmailToken,
  login,
  loginAsUser,
  loginAsUserTokenVerify,
  decodeAndSwitchTokens,
} from 'services/user'
import actions from './actions'
import { getIntl } from 'localization'
import { formatErrorDescriptions } from 'utils/notifications'
import { PUBLIC_ROUTES } from 'services/utilities'
import User from 'models/User'
import Usage from 'models/Usage'
import { getUnapprovedAgreements } from 'services/agreements'
import { handleAPIError } from 'services/errors'

export function* LOGIN({ payload }) {
  const { email, password, token } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const { success, error } = yield call(
      token ? loginWithEmailToken : login,
      token ? { email, token } : { email, password },
    )

    if (success) {
      history.push('/')

      yield put({
        type: 'user/GET_CURRENT_USER',
        payload: {
          notificationConfig: {
            message: getIntl('login.notification.message.success'),
            description: getIntl('login.notification.description.success'),
          },
          onLogin: true,
        },
      })

      const inf = localStorage.getItem('anonymousUser.market')
      if (inf) {
        history.push(`/market/${inf}`)
        localStorage.removeItem('anonymousUser.market')
      }
    } else {
      if (!token) handleAPIError(error)
      yield put({
        type: 'user/SET_STATE',
        payload: { loading: false, authorized: false },
      })
      history.push('/system/login')
    }
  } catch (e) {
    console.log(e)
  }
}

export function* GET_CURRENT_USER({ payload = {} }) {
  const params = localStorage.getItem('original_user_params')
  yield put({
    type: 'user/SET_STATE',
    payload: { logged_as: !!params },
  })

  const { notificationConfig, onLogin } = payload
  // If public route prevent request for user
  const {
    router: {
      location: { pathname },
    },
  } = yield select()
  if (PUBLIC_ROUTES.some(r => r === pathname)) return false

  yield put({
    type: 'user/SET_STATE',
    payload: { loading: true },
  })
  try {
    const { success, user_data, usage } = yield call(getCurrentUser)

    if (success) {
      const user = new User({ ...user_data })
      user.usage = new Usage(usage)
      user.authorized = true
      // const { paths } = yield call(getPermissions)
      // const permissions = permissionFormatter(paths)
      yield put({
        type: 'user/SET_STATE',
        payload: user,
      })
      if (onLogin) {
        // Try to get if new unapproved agreements exist and approve them automatically for user
        try {
          const { success, data } = yield call(getUnapprovedAgreements)
          if (success && data.results.length > 0) {
            user.agreements = data.results.map(d => ({ id: d.id }))
            const { success: updateSuccess } = yield call(user.updateUser)
            if (!updateSuccess) throw new Error('Can not update aggreements')
          }
        } catch (e) {
          console.log(e)
        }
      }
      if (notificationConfig) {
        yield put({
          type: 'internal_notification/SET_STATE',
          payload: {
            data: notificationConfig,
            type: 'success',
          },
        })
      }
    } else if (onLogin) {
      const data = {
        message: getIntl('notifications.currentUserDataError.message'),
        description: getIntl('notifications.currentUserDataError.description'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: { loading: false },
  })
}

export function* LOGOUT() {
  try {
    yield call(logout)
  } catch (error) {
    console.log(error)
  }
  yield put({
    type: 'RESET_ALL',
  })
  yield put({
    type: 'user/SET_STATE',
    payload: {
      authorized: false,
      loading: false,
    },
  })
}

export function* UPDATE_USER({ payload, silent }) {
  try {
    const { user_data } = payload
    const organized_user_data = {
      user_id: user_data.user_id,
      email: user_data.email,
      first_name: user_data.first_name,
      last_name: user_data.last_name,
      is_active: user_data.is_active,
      userprofile: {
        is_active: user_data.is_active,
        address: user_data.address,
        country: user_data.country,
        state: user_data.state,
        city: user_data.city,
        zipcode: user_data.zipcode,
        identity_card_id: user_data.identity_card_id,
        phone: user_data.phone,
        position: user_data.position,
        organisation: user_data.organizations,
        teams: user_data.teams,
        timezone: user_data.timezone,
        manager_config: user_data.manager_config,
      },
      is_analytics: user_data.is_analytics,
    }

    const { success } = yield call(updateUser, { user_data: organized_user_data })
    if (success) {
      yield put({
        type: 'user/GET_CURRENT_USER',
      })
      if (!silent) {
        const data = {
          message: getIntl('notification.userProfile.updateUser.success.message'),
        }
        yield put({
          type: 'internal_notification/SET_STATE',
          payload: {
            data,
            type: 'success',
          },
        })
      }
    } else if (!silent) {
      const data = {
        message: getIntl('notification.userProfile.updateUser.error.message'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
}
export function* UPDATE_USER_PHOTO({ payload }) {
  try {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const { success, user_data } = yield call(updateUserPhoto, payload)

    if (success) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          authorized: true,
          id: user_data.id,
          username: user_data.username,
          roles: user_data.groups,
          email: user_data.email,
          first_name: user_data.first_name,
          last_name: user_data.last_name,
          phone: user_data.userprofile.phone,
          address: user_data.userprofile.address,
          country: user_data.userprofile.country,
          state: user_data.userprofile.state,
          city: user_data.userprofile.city,
          zipcode: user_data.userprofile.zipcode,
          identity_card_id: user_data.userprofile.identity_card_id,
          position: user_data.userprofile.position,
          teams:
            user_data.userprofile.teams && user_data.userprofile.teams.length > 0
              ? user_data.userprofile.teams
              : [{ id: 999, name: '' }],
          photo: user_data.userprofile.photo
            ? user_data.userprofile.photo
            : '/resources/images/kronnika_icon_128x128.png',
          organizations: [user_data.userprofile.organisation],
          location: user_data.userprofile.country,
        },
      })
    }

    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (e) {
    console.log(e)
  }
}
export function* CHANGE_PASSWORD({ payload }) {
  try {
    const { user } = yield select()
    const { success, info } = yield call(updatePassword, {
      user_data: { user_id: user.id, ...payload },
    })

    if (success) {
      const data = {
        message: getIntl('notification.userProfile.changePassword.success.message'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'success',
        },
      })
    } else {
      const data = {
        message: getIntl('notification.userProfile.changePassword.error.message'),
        description: formatErrorDescriptions(info),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* LOGIN_AS_USER({ payload }) {
  try {
    const {
      success,
      data: { token },
    } = yield call(loginAsUser, payload.email)
    if (success) {
      const {
        success: successVerify,
        // data: { access, refresh },
        error,
      } = yield call(loginAsUserTokenVerify, payload.email, token)
      if (successVerify) {
        history.push('/')

        yield put({
          type: 'user/GET_CURRENT_USER',
          payload: {
            onLogin: true,
          },
        })
      } else {
        // console.log('Login as user failed')
      }
    }
  } catch (e) {
    console.log(e)
  }
}
export function* RESET_LOGIN_AS() {
  const user_id = yield call(decodeAndSwitchTokens)
  if (user_id) {
    history.push('/')
    yield put({
      type: 'user/GET_CURRENT_USER',
      payload: {
        onLogin: true,
      },
    })
  } else {
    // console.log('Login as user failed')
  }
}
export default function* rootSaga() {
  yield all([
    GET_CURRENT_USER({}), // This is mandotory
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGIN_AS_USER, LOGIN_AS_USER),
    takeEvery(actions.RESET_LOGIN_AS, RESET_LOGIN_AS),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.GET_CURRENT_USER, GET_CURRENT_USER),
    takeEvery(actions.UPDATE_USER, UPDATE_USER),
    takeEvery(actions.UPDATE_USER_PHOTO, UPDATE_USER_PHOTO),
    takeEvery(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
  ])
}
