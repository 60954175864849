import React, { useEffect, useState } from 'react'
import { Row, List, Col, Divider } from 'antd'
import { connect } from 'react-redux'
import VaultItem from './VaultItem'
import { getIntl } from 'localization'
import PrivateKeyModal from './VaultForm/PrivateKeyView'

const INITIAL_FILTERS = {
  page: 1,
  pageSize: 10,
}

const mapStateToProps = ({ vaults, settings }) => ({ vaults, settings })

const VaultList = ({ vaults: { vaults, loading, count }, actions }) => {
  const [filters, setFilters] = useState(INITIAL_FILTERS)

  useEffect(() => {
    actions.getVaults(filters)
  }, [filters])

  const pagination = {
    total: count || 0,
    pageSize: filters?.pageSize || 10,
    current: filters?.page || 1,
    onChange: page => {
      setFilters({ ...filters, page })
    },
  }
  return (
    <div>
      <Row style={{ maxHeight: '50vh', overflow: 'auto' }}>
        <Col span={24} style={{ padding: '1em' }}>
          <List
            size="small"
            loading={loading}
            itemLayout="vertical"
            dataSource={vaults}
            pagination={pagination}
            renderItem={item => <VaultItem item={item} actions={actions} />}
          />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <button
            disabled={loading}
            type="button"
            className="btn btn-primary btn-with-addon float-right"
            onClick={actions.addVault}
          >
            <span className="btn-addon">
              <i className="btn-addon-icon fe fe-plus-circle" />
            </span>
            {getIntl('Add New Vault')}
          </button>
        </Col>
      </Row>
    </div>
  )
}

export default connect(mapStateToProps)(VaultList)
