/* eslint-disable no-underscore-dangle */

import axios from 'utils/axiosGlobal'
import jwt_decode from 'jwt-decode'
import { API_URL, history, store } from 'index'
import setAuthorizationToken from 'utils/setAuthorizationToken'
import { handleAPIError, notify } from './errors'
import { PUBLIC_ROUTES } from './utilities'
import moment from 'moment'

const redirectToLogin = () => {
  const isPublicRoute = PUBLIC_ROUTES.some(pr => pr === window.location.pathname)
  if (!isPublicRoute) {
    history.push('/system/login')
  }
}

const LocalStorageService = (() => {
  let _service
  function _getService() {
    if (!_service) {
      _service = this
      return _service
    }
    return _service
  }

  function _setToken(tokenObj) {
    localStorage.setItem('access', tokenObj.access_token)
  }

  function _getAccessToken() {
    return localStorage.getItem('access')
  }

  function _getRefreshToken() {
    return localStorage.getItem('refresh')
  }

  function _clearToken() {
    localStorage.removeItem('access')
    localStorage.removeItem('refresh')
  }

  async function _verifyToken() {
    const refresh = localStorage.getItem('refresh')
    if (!refresh) return false

    return axios
      .post(`${API_URL}/token/verify/`, {
        verify: localStorage.getItem('refresh'),
      })
      .then(res => {
        if (res.code === 401) return false
        return refresh
      })
      .catch(err => {
        return false
      })
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    verifyToken: _verifyToken,
  }
})()

const refreshAccessToken = async () => {
  const refresh_token = LocalStorageService.getRefreshToken()
  if (!refresh_token) return false
  let response
  try {
    response = await fetch(`${API_URL}/token/refresh/`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        refresh: refresh_token,
      }),
    })
      .then(response => {
        if (response.status === 200 || response.statusText === 'OK') {
          return response.json()
        }
        return false
      })
      .then(data => {
        setAuthorizationToken(data.access)
        LocalStorageService.setToken({ access_token: data.access })
        return data.access // Important ! This must be returned to get the original request !!
      })
  } catch (e) {
    console.log(e)
  }

  return response
}

axios.interceptors.request.use(
  async config => {
    if (config.url.includes('/api/token')) return config
    const accessToken = LocalStorageService.getAccessToken()
    const anonymous = config.headers.Authorization === null
    if(anonymous) {
      config.headers.Authorization = undefined
      return config
    }
    let decodedToken
    try {
      decodedToken = jwt_decode(accessToken)
    } catch (e) {
      const refreshToken = await refreshAccessToken()
      const newAccessToken = LocalStorageService.getAccessToken()
      config.headers.Authorization = newAccessToken ? `Bearer ${newAccessToken}` : undefined
      return config
    }

    const now = moment().unix()

    if (now > decodedToken.exp - 1) {
      const refreshToken = await refreshAccessToken()
      const newAccessToken = LocalStorageService.getAccessToken()
      config.headers.Authorization = newAccessToken ? `Bearer ${newAccessToken}` : undefined
    }
    return config
  },
  error => {
    console.log(error)
    redirectToLogin()
  },
)

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return new Promise(async (resolve, reject) => {
      let r
      const originalRequest = error.config

      if (!error.response) reject(error)
      if (
        originalRequest &&
        error.response &&
        error.response.status === 401 &&
        originalRequest.url.includes('token/email/verify')
      ) {
        redirectToLogin()
        reject(error)
      }
      if (
        originalRequest &&
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        const refresh = LocalStorageService.getRefreshToken()
        if (refresh) {
          originalRequest._retry = true
          const access_token = await refreshAccessToken(refresh)
          if (access_token) {
            LocalStorageService.setToken({ access_token })
            originalRequest.headers.Authorization = `Bearer ${access_token}`
            r = await axios(originalRequest)
            resolve(r)
          } else {
            notify(
              'notifications.session.expiredSession.title',
              ['notifications.session.expiredSession.description'],
              true,
            )
            localStorage.removeItem('access')
            localStorage.removeItem('refresh')
            store.dispatch({
              type: 'user/SET_STATE',
              payload: { authorized: false },
            })
            redirectToLogin()
            reject(error)
          }
        } else {
          originalRequest._retry = true
          store.dispatch({
            type: 'user/SET_STATE',
            payload: { authorized: false },
          })
          redirectToLogin()
          reject(error)
        }
      }
      if (error) {
        // handleAPIError(error)
      }
      reject(error)
    })
  },
)

// eslint-disable-next-line import/prefer-default-export
export { LocalStorageService }
