import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { getJobs, addJob, updateJob, cancelJob, deleteJob, addScheduledJob } from 'services/jobs'
import actions from './actions'
import Moment from 'moment'
import extracter from 'services/flow_data/extracter'
import { getIntl } from 'localization'
import Job from 'models/Job'

export function* GET_JOBS({ payload }) {
  const filters = payload?.filters

  yield put({
    type: 'jobs/SET_STATE',
    payload: { loading: true },
  })
  try {
    const { success, data } = yield call(getJobs, filters || {})
    const { count, results: jobs } = data
    if (success) {
      const jobList = jobs.map(job => new Job(job))

      yield put({
        type: 'jobs/SET_STATE',
        payload: { jobs: jobList, loading: false, count },
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'jobs/SET_STATE',
    payload: { loading: false },
  })
}

export function* ADD_NEW_JOB() {
  const { active_scenario, settings } = yield select()

  const newJob = {
    scenario_version: active_scenario.version_id,
    name: active_scenario.name,
    robots:
      active_scenario.robots.length > 0 ? active_scenario.robots.map(r => ({ id: r.id })) : [],
    ...extracter(active_scenario),
    options: {
      log_api_enabled: settings.logApiEnabled,
    },
  }

  const { success, data } = yield call(addJob, newJob)
  if (success) {
    yield put({
      type: 'jobs/GET_JOBS',
    })
    yield put({
      type: 'scenario_runner/jobs/SET_SELECTED_ROWS',
      payload: { jobsTable_SelectedRows: [data.id] },
    })
    yield put({
      type: 'scenario_runner/SET_STATE',
      payload: { newRunningJob: data.id },
    })
  }
}

export function* ADD_NEW_SCHEDULED_JOB({ payload }) {
  const {
    data: { id, name, robots, params },
    type,
    schedule,
    start_date,
    end_date,
  } = payload

  const jobPayload = {
    [type]: id,
    name: `${name}_${Moment().unix()}`,
    robots: robots && robots.length > 0 ? robots.map(r => ({ id: r.id })) : undefined,
    params: params || undefined,
    start_date: start_date || undefined,
    end_date: end_date || undefined,
    cron: schedule || undefined,
  }

  const { success } = yield call(addScheduledJob, jobPayload)

  if (success) {
    yield put({
      type: 'jobs/SET_STATE',
      payload: { newScheduledJobExist: true },
    })
  }
}

export function* UPDATE_JOB({ payload }) {
  const { job_id, data } = payload
  const { success } = yield call(updateJob, { job_id, data })
  if (success) {
    yield put({
      type: 'jobs/GET_JOBS',
    })
  }
}

export function* CANCEL_JOB({ payload }) {
  const { success, error } = yield call(cancelJob, payload)
  if (success) {
    const data ={
      message: getIntl('notifications.jobs.cancel.success.message'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
  } else {
    const data ={
      message: getIntl('notifications.jobs.cancel.error.message'),
      description: error.join('-'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
}

export function* CANCEL_SCHEDULE({ payload }) {
  const { jobs, filterPayload, single } = payload
  let successCount = 0
  const failedJobs = []
  // eslint-disable-next-line no-restricted-syntax
  for (const job of jobs) {
    const { success } = yield call(updateJob, {
      job_id: job,
      data: { is_active: false },
    })
    if (success) {
      successCount += 1
    } else {
      failedJobs.push(job)
    }
  }

  if (successCount === jobs.length) {
    const data ={
      message: getIntl(`notifications.jobs.cancelSchedule.success.message`),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
  }

  if (failedJobs > 0 && successCount !== 0) {
    const data = {
      message: getIntl(`notifications.jobs.cancelSchedule.failedJobs.message`),
      description: failedJobs.join(', '),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'warning',
      },
    })
  }

  if (successCount === 0) {
    const data = {
      message: getIntl(`notifications.jobs.cancelSchedule.error.message`),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  } else {
    yield put({
      type: 'jobs/GET_JOBS',
      payload: { ...filterPayload },
    })
  }
}

export function* DELETE_JOB({ payload }) {
  const { job_id, filters } = payload
  const { success } = yield call(deleteJob, { job_id })
  if (success) {
    const data = {
      message: getIntl('notification.jobs.delete.success.message'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
    yield put({
      type: 'jobs/GET_JOBS',
      payload: { filters },
    })
  } else {
    const data = {
      message: getIntl('notification.jobs.delete.error.message'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_JOBS, GET_JOBS),
    takeEvery(actions.ADD_NEW_JOB, ADD_NEW_JOB),
    takeEvery(actions.UPDATE_JOB, UPDATE_JOB),
    takeEvery(actions.CANCEL_JOB, CANCEL_JOB),
    takeEvery(actions.DELETE_JOB, DELETE_JOB),
    takeEvery(actions.ADD_NEW_SCHEDULED_JOB, ADD_NEW_SCHEDULED_JOB),
    takeEvery(actions.CANCEL_SCHEDULE, CANCEL_SCHEDULE),
  ])
}
