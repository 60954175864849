import Package from 'models/Package';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { getPackages } from 'services/packages';
import actions from './actions';

export function* GET_PACKAGES() {
  yield put({
    type: 'packages/SET_STATE',
    payload: { loading: true }
  })
  const { success, data } = yield call(getPackages)
  if (success) {
    const { results } = data
    const packages = results.map(result => new Package(result))
    yield put({
      type: 'packages/SET_STATE',
      payload: { packages }
    })
  }
  yield put({
    type: 'packages/SET_STATE',
    payload: { loading: false }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PACKAGES, GET_PACKAGES),
  ]);
}
