import React, { useEffect, useState } from 'react'
import { Badge, Dropdown } from 'antd'
import style from './style.module.scss'

import { NotificationOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import NotificationList from './components/NotificationList'

const FETCH_INTERVAL = 1 * 1000 * 60

let notificationInterval = null

const mapStateToProps = ({ notifications, settings }) => ({ notifications, settings })

const NotificationsMenu = ({
  notifications: { notificationsMenu, unReadCount, menuLoading },
  settings: { locale, notifications_showOnlyUnread },
  dispatch,
}) => {
  const [menuVisible, setMenuVisible] = useState(false)

  const getNotifications = () => {
    dispatch({
      type: 'notifications/GET_NOTIFICATIONS',
      payload: { filters: { is_read: notifications_showOnlyUnread ? false : undefined } },
      menu: true,
    })
  }

  const markAllAsRead = async () => {
    dispatch({
      type: 'notifications/MARK_ALL_AS_READ',
      menu: true,
    })
  }

  useEffect(() => {
    getNotifications()
    notificationInterval = setInterval(() => {
      getNotifications()
    }, FETCH_INTERVAL)
    return () => {
      clearInterval(notificationInterval)
    }
  }, [notifications_showOnlyUnread])

  const actions = {
    getNotifications,
    markAllAsRead,
    closeMenu: () => setMenuVisible(false),
  }

  return (
    <div data-tour="notificationMenu">
      <Dropdown
        dropdownRender={menu => (
          <NotificationList
            loading={menuLoading}
            notifications={notificationsMenu}
            actions={actions}
            locale={locale}
          />
        )}
        trigger={['click']}
        placement="bottomRight"
        onOpenChange={setMenuVisible}
        open={menuVisible}
      >
        <div className={style.dropdown}>
          <Badge size="small" count={unReadCount}>
            <span className="d-none d-xl-inline">
              <NotificationOutlined />
            </span>
          </Badge>
        </div>
      </Dropdown>
    </div>
  )
}

export default connect(mapStateToProps)(NotificationsMenu)
