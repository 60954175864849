import { history } from 'index'

const URLParamChanger = newParams => {
  const searchParams = {}
  history.location.search
    .replace('?', '')
    .split('&')
    .forEach(param => {
      const [key, value] = param.split('=')
      Object.assign(searchParams, { [key]: value })
    })
  const lastParams = { ...searchParams, ...newParams }

  Object.keys(lastParams).map(k =>
    lastParams[k] === undefined ? delete lastParams[k] : decodeURIComponent(lastParams[k]),
  )

  const lastURL = `${history.location.pathname}?${Object.keys(lastParams)
    .map(key => `${key}=${lastParams[key]}`)
    .join('&')}`

  history.push(lastURL)
  return lastParams
}

export default URLParamChanger
