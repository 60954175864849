import { createRecord, getFirst, getList, getOneByID, updateRecord } from 'api/requests'
import axios from 'utils/axiosGlobal'
import { API_URL } from 'index'

export async function getOrganizations(payload) {
  const { filters } = payload
  const data = await getList('organisations', filters || {})
  return data
}

export async function getOrganizationByID({ organization_id }) {
  const data = await getOneByID('organisations', organization_id)
  return data
}

export async function createNewOrganization({ organization_data }) {
  const { plan } = organization_data
  const url = `${API_URL}/organisations/`
  return axios(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
    data: {
      ...organization_data,
      plan: { id: plan },
    },
  })
    .then(result => {
      const organization = result.data
      return {
        success: true,
        organization,
      }
    })
    .catch(error => {
      return { success: false }
    })
}

export async function updateOrganization({ organization_data, organization_id }) {
  const url = `${API_URL}/organisations/${organization_id}/`
  return axios(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
    data: organization_data,
  })
    .then(result => {
      const organizations = result.data
      return {
        success: true,
        organizations,
      }
    })
    .catch(error => {
      return { success: false }
    })
}
export async function changeSubscriptionPlan(payload) {
  const { plan } = payload
  const data = await updateRecord('organisations', 'current', { plan })
  return data
}

export async function updateOrganizationLogo({ organization_id, logo }) {
  return new Promise(resolve => {
    const url = `${API_URL}/organisations/${organization_id}/`
    const formData = new FormData()
    formData.append('logo', logo)
    return axios(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
      data: formData,
    })
      .then(result => {
        resolve({ success: true })
      })
      .catch(() => resolve(false))
  })
}

export const getOrganizationUsageData = async id => {
  const data = await getList(`organisations/${id || 'current'}/usage`)
  return data
}
export const getSubOrganizationUsageData = async id => {
  const data = await getFirst(`organisations/current/sub_org_usage`, { id })
  return data
}

export const getOrganizationUsersData = async (id, filters) => {
  const data = await getList(`users/current/sub_org`, { org_id: id, ...filters })
  return data
}

export const getOrganizationPackageTransfers = async filters => {
  const data = await getList(`packageordertransfers`, filters)
  return data
}
export const getOrganizationAvailablePackages = async id => {
  const data = await getList(`packages/sub_packages`, { org_id: id })
  return data
}
export const makeOrganizationPackageTransfer = async (to_org, sub_org_package) => {
  const data = await createRecord(`packageordertransfers`, { to_org, sub_org_package })
  return data
}
