import {
  Notification,
  NotificationAction,
  NotificationChannel,
  NotificationChannelSubscription,
  NotificationSubscription,
} from 'models/Notification'
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import {
  getNotificationById,
  getNotifications,
  updateNotification,
  updateSubscription,
  updateChannelSubscription,
  addSubscription,
  getActions,
  getChannels,
  getSubscriptions,
  getChannelSubcriptions,
  addChannelSubscription,
  markAllAsRead,
} from 'services/notifications'
import actions from './actions'

export function* GET_ACTIONS({ payload }) {
  try {
    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: true },
    })

    const { success, data } = yield call(getActions, payload ? payload.filters : {})
    if (success) {
      const { results, count } = data
      const actions = results.map(result => new NotificationAction(result))
      yield put({
        type: 'notifications/SET_STATE',
        payload: {
          actions,
          actionCount: count,
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'notifications/SET_STATE',
    payload: { loading: false },
  })
}
export function* GET_CHANNELS() {
  try {
    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: true },
    })

    const { success, data } = yield call(getChannels)
    if (success) {
      const { results } = data
      const channels = results.map(result => new NotificationChannel(result))
      yield put({
        type: 'notifications/SET_STATE',
        payload: {
          channels,
        },
      })
    }

    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: false },
    })
  } catch (e) {
    console.log(e)
  }
}
export function* GET_CHANNEL_SUBSCRIPTIONS() {
  try {
    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: true },
    })

    const { success, data } = yield call(getChannelSubcriptions)
    if (success) {
      const { results } = data
      const channelSubscriptions = results.map(
        result => new NotificationChannelSubscription(result),
      )

      yield put({
        type: 'notifications/SET_STATE',
        payload: {
          channelSubscriptions,
        },
      })
    }

    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: false },
    })
  } catch (e) {
    console.log(e)
  }
}
export function* ADD_CHANNEL_SUBSCRIPTION({ payload }) {
  try {
    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: true },
    })

    const { success } = yield call(addChannelSubscription, payload)
    yield put({ type: 'notifications/GET_CHANNEL_SUBSCRIPTIONS' })
    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: false },
    })
  } catch (e) {
    console.log(e)
  }
}
export function* UPDATE_CHANNEL_SUBSCRIPTION({ payload }) {
  try {
    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: true },
    })

    const { success, data } = yield call(updateChannelSubscription, payload)

    if (success) {
      const { results } = data
    }
    yield put({
      type: 'notifications/GET_CHANNEL_SUBSCRIPTIONS',
    })
    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: false },
    })
  } catch (e) {
    console.log(e)
  }
}
export function* GET_SUBSCRIPTIONS() {
  try {
    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: true },
    })

    const { success, data } = yield call(getSubscriptions)
    if (success) {
      const { results } = data
      const subscriptions = results.map(result => new NotificationSubscription(result))

      yield put({
        type: 'notifications/SET_STATE',
        payload: {
          subscriptions,
        },
      })
    }

    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: false },
    })
  } catch (e) {
    console.log(e)
  }
}
export function* ADD_SUBSCRIPTION({ payload }) {
  try {
    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: true },
    })

    const { success } = yield call(addSubscription, payload)
    yield put({ type: 'notifications/GET_SUBSCRIPTIONS' })
    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: false },
    })
  } catch (e) {
    console.log(e)
  }
}
export function* UPDATE_SUBSCRIPTION({ payload }) {
  try {
    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: true },
    })

    const { success, data } = yield call(updateSubscription, payload)

    if (success) {
      const { results } = data
      // const subscriptions = results.map(result => new NotificationSubscription(result))
    }
    yield put({
      type: 'notifications/GET_SUBSCRIPTIONS',
    })
    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: false },
    })
  } catch (e) {
    console.log(e)
  }
}
export function* GET_NOTIFICATIONS({ payload, menu = false }) {
  try {
    yield put({
      type: 'notifications/SET_STATE',
      payload: { [menu ? 'menuLoading' : 'loading']: true },
    })

    const { success, data } = yield call(getNotifications, payload)
    if (success) {
      const { results, count, unread } = data
      const notifications = results.map(result => new Notification(result))

      yield put({
        type: 'notifications/SET_STATE',
        payload: menu
          ? {
              notificationsMenu: notifications,
              unReadCount: unread || notifications.filter(n => n.is_read === false).length,
            }
          : {
              notifications,
              count,
            },
      })
    }

    yield put({
      type: 'notifications/SET_STATE',
      payload: { [menu ? 'menuLoading' : 'loading']: false },
    })
  } catch (e) {
    console.log(e)
  }
}
export function* GET_NOTIFICATION_BY_ID({ payload }) {
  try {
    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: true },
    })

    const { success, data } = yield call(getNotificationById, payload)

    const notification = new Notification(data)

    if (success) {
      yield put({ type: 'notifications/SET_STATE', payload: { active_item: notification } })
    }

    yield put({
      type: 'notifications/SET_STATE',
      payload: { loading: false },
    })
  } catch (e) {
    console.log(e)
  }
}
export function* UPDATE_NOTIFICATION({ payload, menu = false }) {
  try {
    /* yield put({
      type: 'notifications/SET_STATE',
      payload: { [menu ? 'menuLoading' : 'loading']: true },
    }) */

    const { success } = yield call(updateNotification, payload)

    if (success) {
      yield put({ type: 'notifications/GET_NOTIFICATIONS', menu })
    }

    /* yield put({
      type: 'notifications/SET_STATE',
      payload: { [menu ? 'menuLoading' : 'loading']: false },
    }) */
  } catch (e) {
    console.log(e)
  }
}
export function* MARK_ALL_AS_READ({ payload, menu = false }) {
  const {
    settings: { notifications_showOnlyUnread },
  } = yield select()
  try {
    yield put({
      type: 'notifications/SET_STATE',
      payload: { [menu ? 'menuLoading' : 'loading']: true },
    })

    const { success } = yield call(markAllAsRead, payload)

    if (success) {
      yield put({
        type: 'notifications/GET_NOTIFICATIONS',
        payload: {
          filters: { is_read: notifications_showOnlyUnread ? false : undefined },
        },
        menu,
      })
    }
  } catch (e) {
    yield put({
      type: 'notifications/SET_STATE',
      payload: { [menu ? 'menuLoading' : 'loading']: false },
    })
    console.log(e)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_NOTIFICATIONS, GET_NOTIFICATIONS),
    takeEvery(actions.GET_SUBSCRIPTIONS, GET_SUBSCRIPTIONS),
    takeEvery(actions.GET_ACTIONS, GET_ACTIONS),
    takeEvery(actions.GET_CHANNELS, GET_CHANNELS),
    takeEvery(actions.GET_CHANNEL_SUBSCRIPTIONS, GET_CHANNEL_SUBSCRIPTIONS),
    takeEvery(actions.UPDATE_CHANNEL_SUBSCRIPTION, UPDATE_CHANNEL_SUBSCRIPTION),
    takeEvery(actions.GET_NOTIFICATION_BY_ID, GET_NOTIFICATION_BY_ID),
    takeEvery(actions.UPDATE_NOTIFICATION, UPDATE_NOTIFICATION),
    takeEvery(actions.UPDATE_SUBSCRIPTION, UPDATE_SUBSCRIPTION),
    takeEvery(actions.ADD_SUBSCRIPTION, ADD_SUBSCRIPTION),
    takeEvery(actions.ADD_CHANNEL_SUBSCRIPTION, ADD_CHANNEL_SUBSCRIPTION),
    takeEvery(actions.MARK_ALL_AS_READ, MARK_ALL_AS_READ),
  ])
}
