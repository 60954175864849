import { Invoice, InvoiceItem } from 'models/Invoice'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getInvoices, getInvoiceByID, getInvoiceItemsByInvoiceID } from 'services/invoices'
import actions from './actions'

export function* GET_INVOICES({ payload }) {
  yield put({
    type: 'invoices/SET_STATE',
    payload: { loading: true },
  })
  const { filters } = payload
  const { success, data } = yield call(getInvoices, filters || null)
  if (success) {
    const { results: invoices, count } = data
    yield put({
      type: 'invoices/SET_STATE',
      payload: { invoices: invoices.map(invoice => new Invoice(invoice)), count },
    })
  }
  yield put({
    type: 'invoices/SET_STATE',
    payload: { loading: false },
  })
}

export function* GET_INVOICE_BY_ID({ payload }) {
  yield put({
    type: 'invoices/SET_STATE',
    payload: { loading: true },
  })
  const { success, data } = yield call(getInvoiceByID, payload)
  if (success) {
    const active_invoice = new Invoice(data)
    yield put({
      type: 'invoices/SET_STATE',
      payload: { active_invoice },
    })
  } else {
    yield put({
      type: 'invoices/SET_STATE',
      payload: { active_invoice: 404 },
    })
  }
  yield put({
    type: 'invoices/SET_STATE',
    payload: { loading: false },
  })
}
/*
export function* GET_INVOICE_ITEMS_BY_INVOICE_ID({ payload }) {
  const { success, data } = yield call(getInvoiceByID, payload)

  yield put({
    type: 'invoices/SET_STATE',
    // payload: { active_invoice: new InvoiceItem(data) }
  })
}
*/

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_INVOICES, GET_INVOICES),
    takeEvery(actions.GET_INVOICE_BY_ID, GET_INVOICE_BY_ID),
    // takeEvery(actions.GET_INVOICE_ITEMS_BY_INVOICE_ID, GET_INVOICE_ITEMS_BY_INVOICE_ID),
  ])
}
