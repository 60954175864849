import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { getActiveScenario } from 'services/active_scenario'
import { isEmpty, cloneDeep } from 'lodash'
import { getIntl } from 'localization'
import actions from './actions'
import { scenarioStateActions } from 'components/widgets/ScenarioDesigner/ScenarioEditor/ScenarioEditor'
import { taskStateActions } from 'components/widgets/ScenarioDesigner/ScenarioEditor/TaskEditor'
import URLParamChanger from 'utils/urlParamChanger'
import { v4 } from 'uuid'

const replaceIds = (nodes, links, minPosition = [0, 0]) => {
  const copyData = {
    nodes: {},
    links: {},
  }
  // Manipulate data with new Ids and relative positions
  Object.keys(nodes).forEach(nodeId => {
    const position = {
      x: nodes[nodeId].position.x - minPosition[0],
      y: nodes[nodeId].position.y - minPosition[1],
    }

    const newNodeId = v4()
    Object.assign(copyData.nodes, {
      [newNodeId]: {
        ...nodes[nodeId],
        id: newNodeId,
        position,
        oldId: nodeId,
      },
    })
  })

  // Condition node group definers change
  Object.keys(copyData.nodes).forEach(nodeId => {
    if (copyData.nodes[nodeId].properties.definers) {
      Object.keys(copyData.nodes[nodeId].properties.definers).forEach(key => {
        const oldDefinerId = copyData.nodes[nodeId].properties.definers[key]
        const newDefinerId = v4()
        Object.keys(copyData.nodes).forEach(nodeIdInner => {
          if (
            copyData.nodes[nodeIdInner].properties.definers &&
            oldDefinerId === copyData.nodes[nodeIdInner].properties.definers[key]
          ) {
            copyData.nodes[nodeIdInner].properties.definers[key] = newDefinerId
          }
        })
      })
    }
  })

  // Find and replace node ids in links with new ones
  Object.keys(links).forEach(linkId => {
    const newLinkId = v4()
    if (!copyData.links[linkId]) {
      Object.assign(copyData.links, { [newLinkId]: { ...cloneDeep(links[linkId]), id: newLinkId } })
    }
    Object.keys(copyData.nodes).forEach(nodeId => {
      if (copyData.links[newLinkId].from.nodeId === copyData.nodes[nodeId].oldId) {
        copyData.links[newLinkId].from.nodeId = nodeId
      }
      if (copyData.links[newLinkId].to.nodeId === copyData.nodes[nodeId].oldId) {
        copyData.links[newLinkId].to.nodeId = nodeId
      }
    })
  })
  Object.keys(copyData.nodes).forEach(nodeId => {
    //  delete copyData.nodes[nodeId].oldId
  })
  return copyData
}
export function* CLIPBOARD_COPY({ payload }) {
  const {
    active_scenario,
    scenario_editor: { scene },
  } = yield select()

  const { taskId } = payload

  const chart = cloneDeep(
    scene === 'task' && taskId
      ? active_scenario.content.chart.nodes[taskId].properties.chart
      : active_scenario.content.chart,
  )

  const data = {
    nodes: {},
    links: {},
  }

  const selectedNodes = chart?.picked?.nodes
  const nodesOnChart = chart.nodes
  const linksOnChart = chart.links

  if (selectedNodes.length > 0) {
    // Add nodes to copy list
    selectedNodes.forEach(selectedNode => {
      const relatedNode = cloneDeep(nodesOnChart[selectedNode])

      if (relatedNode?.properties?.chart && !isEmpty(relatedNode.properties.chart.nodes)) {
        const manipulatedInnerData = replaceIds(
          relatedNode.properties.chart.nodes,
          relatedNode.properties.chart.links,
        )
        relatedNode.properties.chart.nodes = manipulatedInnerData.nodes
        relatedNode.properties.chart.links = manipulatedInnerData.links
      }
      Object.assign(data.nodes, { [selectedNode]: relatedNode })
    })
    // Add links with related nodes
    Object.keys(linksOnChart).forEach(linkId => {
      if (
        selectedNodes.indexOf(linksOnChart[linkId].from.nodeId) !== -1 &&
        selectedNodes.indexOf(linksOnChart[linkId].to.nodeId) !== -1
      ) {
        Object.assign(data.links, { [linkId]: linksOnChart[linkId] })
      }
    })
    // Position tracking
    const nodeArray = Object.keys(data.nodes).map(key => data.nodes[key].position)
    const minPositionX = Math.min(...nodeArray.map(({ x }) => x))
    const minPositionY = Math.min(...nodeArray.map(({ y }) => y))

    const copyData = replaceIds(data.nodes, data.links, [minPositionX, minPositionY])

    yield put({
      type: 'scenario_editor/SET_STATE',
      payload: { clipboard: { data: copyData, mode: scene } },
    })
  }
}

export function* CLIPBOARD_PASTE({ payload, modal }) {
  const { position } = payload
  const { scenario_editor } = yield select()
  const { clipboard } = cloneDeep(scenario_editor)
  const { scene } = scenario_editor
  const chartActions = scene === 'scenario' ? scenarioStateActions : taskStateActions
  const { data, taskEditMode } = clipboard

  if (data && !isEmpty(data.nodes)) {
    if (scenario_editor.scene === clipboard.mode) {
      Object.keys(data.nodes).forEach(nodeId => {
        const nodeToPaste = data.nodes[nodeId]

        const drop = {
          ...nodeToPaste,
          position: {
            x: nodeToPaste.position.x + position.x,
            y: nodeToPaste.position.y + position.y,
          },
        }

        chartActions.onCanvasDrop({ data: drop, position: drop.position })
      })

      Object.keys(data.links).forEach(linkId => {
        const linkToCopy = data.links[linkId]
        chartActions.onLinkStart({
          linkId,
          fromNodeId: linkToCopy.from.nodeId,
          fromPortId: linkToCopy.from.portId,
        })
      })
      Object.keys(data.links).forEach(linkId => {
        const linkToCopy = data.links[linkId]

        chartActions.onLinkComplete({
          linkId,
          fromNodeId: linkToCopy.from.nodeId,
          fromPortId: linkToCopy.from.portId,
          toNodeId: linkToCopy.to.nodeId,
          toPortId: linkToCopy.to.portId,
        })
      })

      yield put({
        type: 'scenario_editor/SET_STATE',
        payload: { clipboard: { data: null, mode: null } },
      })
    } else {
      modal.warning({
        title: getIntl('scenarioEditor.copyPaste.invalidScope.title'),
        content: taskEditMode
          ? getIntl('scenarioEditor.copyPaste.invalidScope.content.taskScope')
          : getIntl('scenarioEditor.copyPaste.invalidScope.content.stepScope'),
      })
    }
  } else {
    console.info('Clipboard is empty')
  }
}

export function* CHANGE_TAB({ payload }) {
  const { active_tab } = payload
  yield put({
    type: 'scenario_editor/SET_STATE',
    payload: { active_tab },
  })
}
export function* CHANGE_SCENE({ payload }) {
  const params = cloneDeep(payload)
  const { scene, taskId } = params
  if (scene && ['scenario', 'task'].includes(scene)) {
    if (scene === 'task') {
      if (!taskId) {
        params.scene = 'scenario'
        params.tab = 'design'
      }
    }

    URLParamChanger(params)
    yield put({
      type: 'scenario_editor/SET_STATE',
      payload: params,
    })
  }
}
export function* SET_SCENARIO_TO_CATALOG_MODAL_VISIBILITY({ payload }) {
  const { scenario_editor } = select()
  const { visibility } = payload
  yield put({
    type: 'scenario_editor/SET_STATE',
    payload: { ...scenario_editor, scenario_to_catalog_modal: { visibility } },
  })
}

export function* CLEAR_DEBUG_FLAGS({ payload }) {
  const { active_scenario } = yield select()
  const scenario = cloneDeep(active_scenario)
  const { nodes } = payload
  nodes.forEach(({ taskId, stepId }) => {
    scenario.content.chart.nodes[taskId].properties.chart.nodes[
      stepId
    ].properties.detail.devOptions.debug = false
  })
  yield put({
    type: 'active_scenario/SET_STATE',
    payload: scenario,
  })
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_TAB, CHANGE_TAB),
    takeEvery(actions.CHANGE_SCENE, CHANGE_SCENE),
    takeEvery(actions.CLIPBOARD_COPY, CLIPBOARD_COPY),
    takeEvery(actions.CLIPBOARD_PASTE, CLIPBOARD_PASTE),
    takeEvery(actions.CLEAR_DEBUG_FLAGS, CLEAR_DEBUG_FLAGS),
    takeEvery(
      actions.SET_SCENARIO_TO_CATALOG_MODAL_VISIBILITY,
      SET_SCENARIO_TO_CATALOG_MODAL_VISIBILITY,
    ),
    // GET_DATA(), // run once on app load to fetch menu data
  ])
}
