import localeAntd from 'antd/es/locale/tr_TR'

import _uncategorized from './data/_uncategorized.json'
import address from './data/address.json'
import agreements from './data/agreements.json'
import alerts from './data/alerts.json'
import applications from './data/applications.json'
import cardManager from './data/cardManager.json'
import cardSavingWarning from './data/cardSavingWarning.json'
import catalogItems from './data/catalogItems.json'
import catalog_items from './data/catalog_items.json'
import catalog_operations_menu from './data/catalog_operations_menu.json'
import catalogs from './data/catalogs.json'
import categories from './data/categories.json'
import confirm from './data/confirm.json'
import confirmation from './data/confirmation.json'
import confirmations from './data/confirmations.json'
import constants from './data/constants.json'
import cookieBanner from './data/cookieBanner.json'
import customWidgets from './data/customWidgets.json'
import dashboard from './data/dashboard.json'
import dialogs from './data/dialogs.json'
import documentEditor from './data/documentEditor.json'
import documentation from './data/documentation.json'
import footer from './data/footer.json'
import forgotMyPasswordPage from './data/forgotMyPasswordPage.json'
import formData from './data/formData.json'
import formExtras from './data/formExtras.json'
import formItemHelpText from './data/formItemHelpText.json'
import formValidations from './data/formValidations.json'
import functions from './data/functions.json'
import general from './data/general.json'
import infoSupport from './data/infoSupport.json'
import infoText from './data/infoText.json'
import initialScenario from './data/initialScenario.json'
import inspector from './data/inspector.json'
import invoice from './data/invoice.json'
import jobActions from './data/jobActions.json'
import knowledgeBase from './data/knowledgeBase.json'
import krm from './data/krm.json'
import kronnika_news from './data/kronnika_news.json'
import landingPage from './data/landingPage.json'
import live_log from './data/live_log.json'
import login from './data/login.json'
import manageUsers from './data/manageUsers.json'
import market from './data/market.json'
import marketItem from './data/marketItem.json'
import marketplace from './data/marketplace.json'
import marketplaceItemDetail from './data/marketplaceItemDetail.json'
import marketplaceRobot from './data/marketplaceRobot.json'
import message from './data/message.json'
import messages from './data/messages.json'
import modal from './data/modal.json'
import newScenarioDialog from './data/newScenarioDialog.json'
import newScenarioModal from './data/newScenarioModal.json'
import noData from './data/noData.json'
import noDataDescriptions from './data/noDataDescriptions.json'
import notification from './data/notification.json'
import notificationMenu from './data/notificationMenu.json'
import notifications from './data/notifications.json'
import organization_management from './data/organization_management.json'
import packageValidityGroups from './data/packageValidityGroups.json'
import packages from './data/packages.json'
import parameter_list from './data/parameter_list.json'
import payments from './data/payments.json'
import payouts from './data/payouts.json'
import phone from './data/phone.json'
import placeholder from './data/placeholder.json'
import placeholders from './data/placeholders.json'
import propertySidebar from './data/propertySidebar.json'
import publicPricingPage from './data/publicPricingPage.json'
import queues from './data/queues.json'
import register from './data/register.json'
import registerScreen from './data/registerScreen.json'
import robotEarnings from './data/robotEarnings.json'
import robotInfo from './data/robotInfo.json'
import robotManager from './data/robotManager.json'
import runScript from './data/runScript.json'
import scenarioDesigner from './data/scenarioDesigner.json'
import scenarioEditor from './data/scenarioEditor.json'
import scenarioManager from './data/scenarioManager.json'
import scenarioVersionList from './data/scenarioVersionList.json'
import scenario_designer from './data/scenario_designer.json'
import scenario_manager from './data/scenario_manager.json'
import scenario_runner from './data/scenario_runner.json'
import scheduler from './data/scheduler.json'
import short from './data/short.json'
import step_properties from './data/step_properties.json'
import subscriptions from './data/subscriptions.json'
import summaryCard from './data/summaryCard.json'
import systemPages from './data/systemPages.json'
import task_operations from './data/task_operations.json'
import teamManagementList from './data/teamManagementList.json'
import titles from './data/titles.json'
import tooltip from './data/tooltip.json'
import topBar from './data/topBar.json'
import tours from './data/tours.json'
import transactionsTable from './data/transactionsTable.json'
import triggers from './data/triggers.json'
import userProfile from './data/userProfile.json'
import userProfileCard from './data/userProfileCard.json'
import vault from './data/vault.json'
import verifyScreen from './data/verifyScreen.json'
import warnings from './data/warnings.json'

const messageData = {
  ..._uncategorized,
  ...address,
  ...agreements,
  ...alerts,
  ...applications,
  ...cardManager,
  ...cardSavingWarning,
  ...catalog_items,
  ...catalog_operations_menu,
  ...catalogItems,
  ...catalogs,
  ...categories,
  ...confirm,
  ...confirmation,
  ...confirmations,
  ...constants,
  ...cookieBanner,
  ...customWidgets,
  ...dashboard,
  ...dialogs,
  ...documentation,
  ...documentEditor,
  ...footer,
  ...forgotMyPasswordPage,
  ...formData,
  ...formExtras,
  ...formItemHelpText,
  ...formValidations,
  ...functions,
  ...general,
  ...infoSupport,
  ...infoText,
  ...initialScenario,
  ...inspector,
  ...invoice,
  ...jobActions,
  ...knowledgeBase,
  ...krm,
  ...kronnika_news,
  ...landingPage,
  ...live_log,
  ...login,
  ...manageUsers,
  ...market,
  ...marketItem,
  ...marketplace,
  ...marketplaceItemDetail,
  ...marketplaceRobot,
  ...message,
  ...messages,
  ...modal,
  ...newScenarioDialog,
  ...newScenarioModal,
  ...noData,
  ...noDataDescriptions,
  ...notificationMenu,
  ...notifications,
  ...notification,
  ...organization_management,
  ...packages,
  ...packageValidityGroups,
  ...parameter_list,
  ...payments,
  ...payouts,
  ...phone,
  ...placeholder,
  ...placeholders,
  ...propertySidebar,
  ...publicPricingPage,
  ...queues,
  ...register,
  ...registerScreen,
  ...robotEarnings,
  ...robotInfo,
  ...robotManager,
  ...runScript,
  ...scenario_designer,
  ...scenario_manager,
  ...scenario_runner,
  ...scenarioDesigner,
  ...scenarioEditor,
  ...scenarioManager,
  ...scenarioVersionList,
  ...scheduler,
  ...short,
  ...step_properties,
  ...subscriptions,
  ...summaryCard,
  ...systemPages,
  ...task_operations,
  ...teamManagementList,
  ...titles,
  ...tooltip,
  ...topBar,
  ...tours,
  ...transactionsTable,
  ...triggers,
  ...userProfile,
  ...userProfileCard,
  ...vault,
  ...verifyScreen,
  ...warnings,
}
export default {
  locale: 'tr-TR',
  localeAntd,
  messages: messageData,
}
