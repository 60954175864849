import TaskRun from 'models/TaskRun'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getTaskRuns } from 'services/taskruns'
import actions from './actions'

export function* GET_TASKRUNS({ payload }) {
  try {
    yield put({
      type: 'taskruns/SET_STATE',
      payload: { loading: true },
    })

    const { success, data } = yield call(getTaskRuns, payload)
    const { count, results } = data
    if (success) {
      const taskruns = results.map(taskRun => new TaskRun(taskRun))

      yield put({
        type: 'taskruns/SET_STATE',
        payload: { taskruns, count, loading: false },
      })

    }
  } catch (e) { console.log(e) }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_TASKRUNS, GET_TASKRUNS)])
}
