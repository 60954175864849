const actions = {
  SET_STATE: 'tours/SET_STATE',
  ACTIVATE_TOUR: 'tours/ACTIVATE_TOUR',
  START_TOUR: 'tours/START_TOUR',
  FORWARD: 'tours/FORWARD',
  BACK: 'tours/BACK',
  FINISH_TOUR: 'tours/FINISH_TOUR',
}

export default actions
