import actions from './actions'

const initialState = {
  total_time_saving : 0,
  total_tasks: 0,
  total_duration : 0,
  loading : false,
  onoffLoading : false,
  robot : null,
  allowed_teams : [],
  is_public : false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return {...state, ...action.payload }
    case actions.RESET:
      return initialState
    case 'RESET_ALL':
      return initialState
    default:
      return state
  }
}
