import ButtonGeneral from 'components/widgets/Buttons/ButtonGeneral'
import { store } from 'index'
import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'

const NewQueueItemButton = ({ pathname }) => {

  const onClick = () => {
    store.dispatch({
      type: 'queues/SET_STATE',
      payload: { newQueueItemModalVisibility: true },
    })
  }

  return pathname.includes('/queue-items') ? (
    <ButtonGeneral
      text={<FormattedHTMLMessage id="Add Queue Data" />}
      onClick={onClick}
      additionalClass="pull-right"
    />
  ) : null
}

export default NewQueueItemButton
