import { INITIAL_DEV_OPTIONS } from 'services/utilities';
import actions from './actions';

const initialState = {
	id: null,
	config: {
		ui: {}
	},
	form_schema: {},
	formData: {},
  devOptions : INITIAL_DEV_OPTIONS
};

export default function stepActiveReducer(state = initialState, action) {
	switch (action.type) {
    case actions.SET_STATE:
      return {...state, ...action.payload }
    case 'RESET_ALL':
      return initialState
    case actions.RESET:
      return initialState
		default:
			return state;
	}
}
