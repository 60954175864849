/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import ACLFunction from 'components/system/Auth/ACL/ACLFunction'
import React from 'react'
import { connect } from 'react-redux'
import { PermissionsTable } from 'services/permissions'
import style from '../style.module.scss'

const AnalyticsLoginButton = ({ user }) => {
  return user.is_analytics ? (
    <>
      {ACLFunction({
        children: (
          <a
            href="/redirect/analytics"
            rel="noopener noreferrer"
            target="_blank"
            className={style.air__menuLeft__link}
          >
            {<i className={`icmn-stats-dots ${style.air__menuLeft__icon}`} />}
            Kronnika Analytics
          </a>
        ),
        allowed: PermissionsTable.analytics.limited,
        type: 'role',
      })}
    </>
  ) : (
    <></>
  )
}
const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(AnalyticsLoginButton)
