import { Button, Col, Form, Table } from 'antd'
import { getIntl } from 'localization'
import React, { useState, useRef, useEffect } from 'react'
import { v4 } from 'uuid'
import EditableCell from './components/EditableCell'

const KeyValueList = ({ data, onChange }) => {
  const [form] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')
  const scrollableRef = useRef()
  const isEditing = record => record.key === editingKey

  const edit = record => {
    form.setFieldsValue({
      key_name: record.key_name || '',
      value: record.value || '',
    })
    setEditingKey(record.key)
  }

  const cancel = key => {
    setEditingKey('')
    onChange(data.filter(d => d.key !== key))
  }

  const save = async key => {
    try {
      const row = await form.validateFields()
      const newData = [...data]
      const index = newData.findIndex(item => key === item.key)

      if (index > -1) {
        const item = newData[index]
        item.initialSave = false // Important : This prevents accidental empty data save
        newData.splice(index, 1, { ...item, ...row })
        onChange(newData)
        setEditingKey('')
      } else {
        newData.push(row)
        onChange(newData)
        setEditingKey('')
      }
    } catch (errInfo) {
      // console.log(errInfo)
    }
  }

  const columns = [
    {
      dataIndex: 'key_name',
      key: 'key_name',
      title: getIntl('KeyName'),
      editable: true,
      width: '30%',
    },
    { dataIndex: 'value', key: 'value', title: getIntl('Value'), editable: true },
  ]

  if (onChange) {
    columns.push({
      dataIndex: 'operation',
      width: '15%',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <div className="btn-group pull-right">
            <button type="button" className="btn btn-success" onClick={() => save(record.key)}>
              <i className="fe fe-save" />
            </button>
            <button type="button" className="btn btn-danger" onClick={() => cancel(record.key)}>
              <i className="fe fe-x" />
            </button>
          </div>
        ) : (
          <button
            disabled={editingKey !== ''}
            type="button"
            className="btn btn-primary pull-right"
            onClick={() => edit(record)}
          >
            <i className="fe fe-edit" />
          </button>
        )
      },
    })
  }

  const handleAdd = () => {
    const key = v4()
    const newRecord = { key, key_name: '', value: '', initialSave: true }
    onChange([...data, newRecord])
    edit(newRecord)
  }

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  useEffect(() => {
    if (editingKey !== '') {
      const div = scrollableRef.current
      const a = div?.scrollHeight - div?.clientHeight + 100
      div.scrollTop = a
      // scrollableRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [data])

  return (
    <>
      <Col ref={scrollableRef} span={24} style={{ maxHeight: '30vh', overflow: 'auto' }}>
        <Form form={form} component={false}>
          <Table
            size="small"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={data}
            columns={mergedColumns}
            pagination={false}
            rowClassName="editable-row"
          />
        </Form>
      </Col>
      <Col span={24}>
        {onChange && (
          <Button className="mt-2" disabled={editingKey !== ''} onClick={handleAdd} type="primary">
            {getIntl('Add New Key-Value Pair')}
          </Button>
        )}
      </Col>
    </>
  )
}

export default KeyValueList
