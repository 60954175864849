import theme from 'theme'

const customToken = {
  colorPrimary: theme['primary-color'],
  colorLink: theme['primary-color'],
  colorText: '#786fa4',
  fontFamily: theme['font-family'],
  borderRadius: '2',
}

export const components = {
  Breadcrumb: {
    fontSize: 16,
    itemColor: '#786fa4', // Gray-6 in mixin
    lastItemColor: '#786fa4',
    linkColor: '#786fa4',
    linkHoverColor: theme['primary-color'],
    separatorColor: '#786fa4',
    separatorMargin: 8,
  },
  Form: {
    labelColor: '#786fa4',
  },
}
export default customToken
