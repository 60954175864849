export const addScenarioVersionToRecentlyOpenedList = (scenario, userId) => {
  connectToIndexedDB()
    .then(db => {
      const objectStore = db
        .transaction(['recent_scenarios'], 'readwrite')
        .objectStore('recent_scenarios')

      const recentScenarios = objectStore.put({
        ...scenario,
        userId,
        opened_at: new Date().getTime(),
      })
      recentScenarios.onsuccess = data => {
        // console.log(data)
      }
    })
    .catch(error => console.log(error))
}

export const clearRecentlyOpenedScenarios = () => {
  return new Promise((resolve, reject) => {
    connectToIndexedDB().then(db => {
      const objectStore = db
        .transaction(['recent_scenarios'], 'readwrite')
        .objectStore('recent_scenarios')
        .openCursor()

      objectStore.onsuccess = event => {
        const cursor = event.target.result
        if (cursor) {
          cursor.delete()
          cursor.continue()
        }
      }
    })
    resolve([])
  })
}
export const deleteRecentlyOpenedScenario = id => {
  connectToIndexedDB().then(db => {
    const objectStore = db
      .transaction(['recent_scenarios'], 'readwrite')
      .objectStore('recent_scenarios')
      .openCursor()

    objectStore.onsuccess = event => {
      const cursor = event.target.result
      if (cursor) {
        if (cursor.value.id === id) {
          cursor.delete()
        }
        cursor.continue()
      }
    }
  })
}
export const updateRecentlyOpenedScenario = (id, data) => {
  connectToIndexedDB()
    .then(db => {
      const objectStore = db
        .transaction(['recent_scenarios'], 'readwrite')
        .objectStore('recent_scenarios')

      const request = objectStore.get(id)

      request.onsuccess = () => {
        const scenario = request.result

        Object.keys(data).forEach(key => {
          scenario[key] = data[key]
        })
        const updateRequest = objectStore.put(scenario)
        updateRequest.onsuccess = () => {}
      }
    })
    .catch(error => console.log(error))
}

export const getRecentyOpenedScenarios = async userId => {
  return new Promise((resolve, reject) => {
    connectToIndexedDB()
      .then(db => {
        const objectStore = db
          .transaction(['recent_scenarios'], 'readwrite')
          .objectStore('recent_scenarios')
        const recentScenarioIndex = objectStore.index('user')
        const recentScenarios = recentScenarioIndex.getAll([userId])
        recentScenarios.onsuccess = () => {
          const orderedScenarios = recentScenarios.result.sort((a, b) => b.opened_at - a.opened_at)
          resolve(orderedScenarios.slice(0, 5))
        }
      })
      .catch(error => reject(error))
  })
}

const connectToIndexedDB = () => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open('KronnikaManagerDB', 2)
    request.onerror = event => {
      reject(event)
    }
    request.onupgradeneeded = event => {
      const db = event.target.result
      try {
        db.deleteObjectStore('recent_scenarios')
      } catch (e) {
        //
      }
      const objectStore = db.createObjectStore('recent_scenarios', {
        keyPath: 'id',
        unique: true,
      })
      objectStore.createIndex('user', ['userId'], { unique: false })
      objectStore.createIndex('id', ['id'], { unique: false })
    }
    request.onsuccess = () => {
      resolve(request.result)
    }
  })
}
