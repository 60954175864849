

const actions = {
  SET_STATE: 'agreements/SET_STATE',
  GET_AGREEMENTS : 'agreements/GET_AGREEMENTS',
  UPDATE_AGREEMENT : 'agreements/UPDATE_AGREEMENT',
  GET_AGREEMENT : 'agreements/GET_AGREEMENT',
}

export default actions
