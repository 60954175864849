import { createRecord, deleteRecord, getList, updateRecord } from 'api/requests'

const getVaults = async (filters) => {
  const data = await getList('vault', filters)
  return data
}
const createVault = async (payload) => {
  const result = await createRecord('vault', payload)
  return result
}
const updateVault = async ({ id, data }) => {
  const result = await updateRecord('vault', id, data)
  return result
}
const deleteVault = async (id) => {
  const result = await deleteRecord('vault', id)
  return result
}

export { getVaults, createVault, updateVault, deleteVault }
