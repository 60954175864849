import { List } from 'antd'
import { getList } from 'api/requests'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  categoryComponent,
  defaultComponent,
  headerComponent,
  linkComponent,
} from './ListComponents'

const itemStyle = { fontSize: '1em', fontWeight: '500', lineHeight: '1em', border: 'none' }

const FilterList = ({ type, title, withAll = false, settings: { locale } }) => {
  const initialData = () => {
    const d = [
      {
        key: 'title',
        style: { fontSize: '1.2em', fontWeight: 'bolder', lineHeight: undefined },
        link: '',
        component: headerComponent,
        text: `Robots by ${title}`,
      },
    ]
    if (withAll) d.push({ key: 'all', component: linkComponent, text: 'All Robots', link:'/marketplace?all=true' })
    return d
  }

  const [dataSource, setDataSource] = useState(initialData())
  const [loading, setLoading] = useState(false)

  const renderItem = ({
    key,
    className = '',
    style = '',
    link = '',
    text = '',
    component = defaultComponent,
    isFunction = true,
    newPage = false,
    type,
  }) => (
    <List.Item item={key} style={{ ...itemStyle, ...style }}>
      {isFunction ? component({ className, link, text, key, newPage, type }) : <>{component}</>}
    </List.Item>
  )

  const getByCategory = async type => {
    setLoading(true)
    const { success, data } = await getList(
      'catalogfiltertranslations',
      { type, language: locale.split('-')[0] },
      true,
    )
    if (success) {
      const queryData = data.results.map(d => {
        return {
          key: d.catalog_filter.id,
          component: categoryComponent,
          text: d.text,
          type,
        }
      })

      setDataSource([...initialData(), ...queryData])
    }
    setLoading(false)
  }
  useEffect(() => {
    if (type) getByCategory(type)
  }, [locale, type])

  return (
    <List
      size="small"
      loading={loading}
      bordered={false}
      dataSource={dataSource}
      renderItem={renderItem}
    />
  )
}

const mapStateToProps = ({ settings }) => ({ settings })
export default connect(mapStateToProps)(FilterList)
