class Organization {
  constructor(props) {
    const {
      id,
      name,
      logo,
      industry,
      phone,
      domain,
      website,
      address,
      country,
      state,
      city,
      zipcode,
      contact_person,
      contact_email,
      manager_email,
      legal_name,
      billing_currency,
      billing_address,
      billing_country,
      billing_state,
      billing_city,
      billing_zipcode,
      tax_office,
      tax_number,
      plan, is_suspended
    } = props

    this.id = id
    this.key = id
    this.name = name
    this.logo = logo || '/resources/images/kronnika_icon_128x128.png'
    this.industry = industry
    this.phone = phone
    this.domain = domain
    this.website = website
    this.address = address
    this.country = country
    this.state = state
    this.city = city
    this.zipcode = zipcode
    this.contact_person = contact_person
    this.contact_email = contact_email
    this.manager_email = manager_email


    // Billing related fields
    this.legal_name = legal_name
    this.billing_currency = billing_currency
    this.billing_address = billing_address
    this.billing_country = billing_country
    this.billing_state= billing_state
    this.billing_city = billing_city
    this.billing_zipcode = billing_zipcode
    this.tax_office = tax_office
    this.tax_number = tax_number
    this.plan = plan

    this.is_suspended = is_suspended
  }
}

export default Organization
