import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import {
  getActiveScenario,
  setScenarioParameters,
  setActiveScenarioRobots,
  resetActiveScenario,
} from 'services/active_scenario'
import { getScenarioVersionByID } from 'services/scenarios'

import { cloneDeep, merge } from 'lodash'
import { getIntl } from 'localization'
import actions from './actions'
import { setModified } from 'utils/scenarioChangeHandler'
import { checkIfScenarioVersionHasPublishRequest } from 'services/utilities'

export function* GET_DATA() {
  try {
    const data = yield call(getActiveScenario)
    yield put({
      type: 'active_scenario/SET_STATE',
      payload: {
        data,
      },
    })
  } catch (e) {
    console.log(e)
  }
}
export function* RENAME_TASK({ payload }) {
  const { name, id } = payload
  const { active_scenario } = yield select()
  const chart = cloneDeep(active_scenario.content.chart)
  if (chart.nodes[id] && chart.picked.nodes.length === 1 && chart.picked.nodes[0] === id) {
    chart.nodes[id].properties.name = name
    yield put({
      type: 'active_scenario/SET_STATE',
      payload: { content: { ...active_scenario.content, chart } },
    })
  }

  setModified('scenario')
}

export function* SET_ROBOTS({ payload }) {
  const { robots } = payload
  const { active_scenario } = yield select()
  const data = yield call(setActiveScenarioRobots, { active_scenario, robots })
  yield put({
    type: 'active_scenario/SET_STATE',
    payload: data,
  })
}

export function* GET_ACTIVE_SCENARIO() {
  const { active_scenario } = yield select()
  yield put({
    type: 'active_scenario/SET_STATE',
    payload: active_scenario,
  })
}

export function* SET_SCENARIO_PARAMETERS({ payload }) {
  const { parameters } = payload
  const { active_scenario } = yield select()

  const data = yield call(setScenarioParameters, { parameters, active_scenario })
  yield put({
    type: 'active_scenario/SET_STATE',
    payload: data,
  })
  setModified('scenario')
}

export function* GET_FILES() {
  yield put({
    type: 'files/GET_SCENARIO_FILES',
  })
}

export function* RESET() {
  yield
  const { active_scenario } = yield select()
  const data = yield call(resetActiveScenario, { active_scenario })
  yield put({
    type: 'active_scenario/SET_STATE',
    payload: data,
  })
}
export function* RESET_SELECTIONS() {
  const { active_scenario } = yield select()
  if (!active_scenario.id) return false
  try {
    const scenario = cloneDeep(active_scenario)
    // Picked step reset
    if (scenario.content.chart.nodes.length >= 0) {
      Object.keys(scenario.content.chart.nodes).forEach(key => {
        scenario.content.chart.nodes[key].properties.chart.picked = {
          nodes: [],
          links: [],
          ports: [],
        }
      })
    }
    yield put({
      type: 'active_scenario/SET_STATE',
      payload: { content: scenario.content },
    })
  } catch (e) {
    console.log(e)
  }
}
export function* IMPORT_JSON({ payload }) {
  const { scenario_content } = payload
  yield put({
    type: 'active_scenario/SET_STATE',
    payload: { content: scenario_content },
  })
}
export function* CATALOG_ITEM_TO_SCENARIO({ payload, modal }) {
  try {
    const { active_scenario } = yield select()
    const { scenario_version_id } = payload
    const { success, scenario_version: scenarioVersion } = yield call(getScenarioVersionByID, {
      scenario_version_id,
    })
    const scenario = cloneDeep(active_scenario)
    const { flow_data, content } = scenarioVersion
    if (success && content) {
      merge(scenario.flow_data, flow_data)
      merge(scenario.content, content)
      yield put({
        type: 'active_scenario/SET_STATE',
        payload: scenario,
      })
    } else {
      modal.warning({
        title: getIntl('catalogItems.createNewScenarioFromCatalogItem.warningDialog.title'),
        content: getIntl('catalogItems.createNewScenarioFromCatalogItem.warningDialog.content'),
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* SET_ACTIVE_SCENARIO({ payload: { active_scenario }, silent }) {
  if (!silent) {
    yield put({
      type: 'active_scenario/SET_STATE',
      payload: { loading: true },
    })
  }

  const { exist } = yield call(checkIfScenarioVersionHasPublishRequest, active_scenario.version_id)

  yield put({
    type: 'active_scenario/SET_STATE',
    payload: { ...active_scenario, save_enabled: !exist, market_save_enabled: !exist },
  })
  yield put({
    type: 'active_scenario/GET_FILES',
  })
  yield put({
    type: 'active_scenario/SET_STATE',
    payload: { loading: false },
  })
}

export function* UPDATE_NODE({ payload }) {
  const { scene, node } = payload
  const { active_scenario, router } = yield select()
  const scenario = cloneDeep(active_scenario)
  const { taskId } = router.location.query
  if (!taskId) {
    scenario.content.chart.nodes[node.id] = node
  } else {
    scenario.content.chart.nodes[taskId].properties.chart.nodes[node.id] = node
  }
  yield put({
    type: 'active_scenario/SET_STATE',
    payload: { content: scenario.content },
  })
}

export function* SET_SCENARIO_CHART({ payload }) {
  const { active_scenario } = yield select()
  const { chart } = payload

  yield put({
    type: 'active_scenario/SET_STATE',
    payload: { ...active_scenario, content: { ...active_scenario.content, chart } },
  })
}
export function* SET_TASK_CHART({ payload }) {
  const { active_scenario } = yield select()
  const { chart, taskId } = payload
  const currentScenario = cloneDeep(active_scenario)

  if (currentScenario.content.chart.nodes[taskId]) {
    currentScenario.content.chart.nodes[taskId].properties.chart = chart
  }

  yield put({
    type: 'active_scenario/SET_STATE',
    payload: currentScenario,
  })
}

export function* SET_TASK_PARAMETERS({ payload }) {
  const { active_scenario } = yield select()
  const scenario = cloneDeep(active_scenario)
  const { parameters, taskId } = payload
  scenario.content.chart.nodes[taskId].properties.parameters = parameters
  console.log(scenario.content.chart.nodes[taskId])
  yield put({
    type: 'active_scenario/SET_STATE',
    payload: scenario,
  })

  setModified('scenario')
}

export function* RENAME_STEP({ payload }) {
  const { name, id } = payload
  const { active_scenario, router } = yield select()
  const { taskId } = router.location.query
  const scenario = cloneDeep(active_scenario)

  Object.assign(scenario.content.chart.nodes[taskId].properties.chart.nodes[id].properties, {
    customName: name,
  })

  yield put({
    type: 'active_scenario/SET_STATE',
    payload: scenario,
  })

  // TODO : Check this after renaming update
  yield put({
    type: 'active_step/SET_ACTIVE_STEP',
    payload: { stepId: id, taskId },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_TASK_PARAMETERS, SET_TASK_PARAMETERS),
    takeEvery(actions.SET_TASK_CHART, SET_TASK_CHART),
    takeEvery(actions.SET_SCENARIO_CHART, SET_SCENARIO_CHART),
    takeEvery(actions.SET_ACTIVE_SCENARIO, SET_ACTIVE_SCENARIO),
    takeEvery(actions.RENAME_TASK, RENAME_TASK),
    takeEvery(actions.RENAME_STEP, RENAME_STEP),
    takeEvery(actions.SET_SCENARIO_PARAMETERS, SET_SCENARIO_PARAMETERS),
    takeEvery(actions.GET_FILES, GET_FILES),
    takeEvery(actions.SET_ROBOTS, SET_ROBOTS),
    takeEvery(actions.IMPORT_JSON, IMPORT_JSON),
    takeEvery(actions.CATALOG_ITEM_TO_SCENARIO, CATALOG_ITEM_TO_SCENARIO),

    takeEvery(actions.UPDATE_NODE, UPDATE_NODE),
    takeEvery(actions.RESET, RESET),
    takeEvery(actions.RESET_SELECTIONS, RESET_SELECTIONS),
  ])
}
