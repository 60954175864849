import { createRecord, deleteRecord, getList, getOneByID, updateRecord } from 'api/requests'

const getActions = async filters => {
  const data = await getList('notificationusergroups', { is_active: true, ...filters })
  return data
}
const getChannels = async () => {
  const data = await getList('notifychannels')
  return data
}
const getChannelSubcriptions = async () => {
  const data = await getList('notifyuserchannels')
  return data
}
const getSubscriptions = async filters => {
  const data = await getList('notifysubscribers', { pageSize: 1000 })
  return data
}
const getNotifications = async payload => {
  const filters = payload?.filters || {}
  const data = await getList('notifications', {
    order_by: '-created',
    channel: 'WEB',
    aggregate: true,
    ...filters,
  })
  return data
}
const getNotificationById = async ({ id }) => {
  const data = await getOneByID('notifications', id)
  return data
}
const updateNotification = async payload => {
  const { id, data: postData } = payload
  const data = await updateRecord('notifications', id, postData)
  return data
}
const markAllAsRead = async () => {
  const data = await updateRecord('notifications', 'all/markasread')
  return data
}
const addSubscription = async payload => {
  const { data: postData } = payload
  const data = await createRecord('notifysubscribers', postData)
  return data
}
const addChannelSubscription = async payload => {
  const { data: postData } = payload
  const data = await createRecord('notifyuserchannels', postData)
  return data
}
const updateSubscription = async payload => {
  const { id, data: postData } = payload
  const data = await updateRecord('notifysubscribers', id, postData)
  return data
}
const updateChannelSubscription = async payload => {
  const { id, data: postData } = payload
  const data = await updateRecord('notifyuserchannels', id, postData)
  return data
}

export {
  getActions,
  getChannels,
  getChannelSubcriptions,
  getSubscriptions,
  getNotifications,
  getNotificationById,
  updateNotification,
  addSubscription,
  addChannelSubscription,
  updateSubscription,
  updateChannelSubscription,
  markAllAsRead,
}
