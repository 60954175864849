import { Col, Form, Input, Row, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import RobotSelector from './components/RobotSelector'
import VaultSelector from './components/VaultSelector'
import ButtonGeneral from 'components/widgets/Buttons/ButtonGeneral'
import { getIntl } from 'localization'
import { FormattedHTMLMessage } from 'react-intl'
import { getTriggerRuleSetConfigForVault } from 'services/trigger'

const RuleSettingsStep = ({ values, onSave, onChange, loading, rules, onCancel }) => {
  const [form] = Form.useForm()
  const [vaultKeys, setVaultKeys] = useState([])
  const handleChange = (cv, v) => {
    onChange(v)
  }

  const handleSave = () => {
    form
      .validateFields()
      .then(values => {
        onSave(values)
      })
      .catch(e => console.log(e))
  }

  const formLayouts = {
    wrapperCol: { span: 12 },
    labelCol: { span: 8 },
  }

  const getRequiredVaultFields = async rules => {
    let keys = []

    Promise.all(rules.map(rule => getTriggerRuleSetConfigForVault(rule.ruleset)))
      .then(configs => {
        configs.forEach(config => {
          keys = [...keys, ...config]
        })
      })
      .finally(() => {
        const s = new Set(keys)
        setVaultKeys([...s])
      })
  }

  useEffect(() => {
    if (rules && rules.length > 0) getRequiredVaultFields(rules)
  }, [rules])

  const handleVaultChange = vault => {
    form.setFieldsValue({ vault })
  }
  return (
    <Row justify="center">
      <Col xs={24} span={12}>
        <Form form={form} {...formLayouts} onValuesChange={handleChange} initialValues={values}>
          <Form.Item
            label={<FormattedHTMLMessage id="trigger.newTriggerForm.triggerName" />}
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="execute_by_owner"
            label={<FormattedHTMLMessage id="Execute By Owner" />}
            valuePropName="checked"
            extra={<FormattedHTMLMessage id="trigger.newTriggerForm.execute_by_owner.info" />}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="robots"
            label={<FormattedHTMLMessage id="trigger.newTriggerForm.robots" />}
            rules={[{ required: true }]}
            extra={<FormattedHTMLMessage id="trigger.newTriggerForm.robots.info" />}
          >
            <RobotSelector />
          </Form.Item>
          {vaultKeys.length > 0 && (
            <Form.Item
              name="vault"
              label={<FormattedHTMLMessage id="Vault" />}
              extra={<FormattedHTMLMessage id="trigger.newTriggerForm.vault.info" />}
            >
              <VaultSelector vaultKeys={vaultKeys} onChange={handleVaultChange} />
            </Form.Item>
          )}
          <Form.Item
            name="operator"
            label={<FormattedHTMLMessage id="trigger.newTriggerForm.operator" />}
          >
            <Select
              style={{ width: 100 }}
              options={[
                { value: 'AND', label: <FormattedHTMLMessage id="AND" /> },
                { value: 'OR', label: <FormattedHTMLMessage id="OR" /> },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="control_interval"
            label={<FormattedHTMLMessage id="trigger.newTriggerForm.control_interval" />}
          >
            <Input type="number" style={{ width: 100 }} min={1} />
          </Form.Item>
        </Form>
      </Col>
      <Col span={24} className="text-right">
        <ButtonGeneral
          text={getIntl('Cancel')}
          type="secondary"
          onClick={onCancel}
          icon="x"
          additionalClass="mr-2"
        />
        <ButtonGeneral
          text={getIntl(loading ? 'Saving' : 'Save')}
          disabled={loading}
          onClick={() => handleSave()}
          icon="chevrons-right"
        />
      </Col>
    </Row>
  )
}

export default RuleSettingsStep
