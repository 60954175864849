import { Modal } from 'antd'
import { getOneByID } from 'api/requests'
import { getIntl } from 'localization'
import Plan from 'models/Plan'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PlanCard from './components/PlanCard'

const mapStateToProps = ({ settings }) => ({ settings })

const CurrentPlanModal = ({ visibility, setVisibility, plan, settings }) => {
  return plan ? (
    <Modal
      footer={null}
      title={getIntl('Current Plan')}
      open={visibility}
      onCancel={() => setVisibility(false)}
    >
      <PlanCard data={plan} locale={settings.locale} single selected={plan} />
    </Modal>
  ) : null
}

export default connect(mapStateToProps)(CurrentPlanModal)
