import Moment from 'moment'
import cronstrue from 'cronstrue/i18n'

class Job {
  constructor(props, locale = 'tr-TR') {
    const {
      id,
      cron,
      schedule,
      job,
      robots,
      robot_filters,
      name,
      scenarioversion,
      completed,
      is_active,
      is_done,
      created,
      started,
      updated,
      deleted,
      created_by,
    } = props

    this.id = id
    this.key = id
    this.name = name || '- - -'
    this.status = is_active
    this.scenario_version = scenarioversion
    this.schedule = cron
      ? cronstrue.toString(cron, {
          locale: locale.substring(0, 2),
          tzOffset: -new Date().getTimezoneOffset() / 60,
        })
      : '- - -'
    this.next_run_at =
      schedule && schedule.next_run && cron
        ? Moment(schedule?.next_run).format('DD.MM.YYYY HH:mm:ss')
        : '- - -'
    this.started_at = started ? Moment(started).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.completed_at = completed ? Moment(completed).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.created_at = created ? Moment(created).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.updated_at = updated ? Moment(updated).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.deleted_at = deleted ? Moment(deleted).format('DD.MM.YYYY HH:mm:ss') : '- - -'
    this.is_active = is_active
    this.is_done = is_done
    this.robots = robots
    this.robot_filters = robot_filters

    this.created_by = `${created_by?.first_name} ${created_by?.last_name}`
    // this.ran_by = this.created_by.trim() === '' ? created_by.username : this.created_by
    this.ran_by = this.created_by.trim() === '' ? created_by.username : this.created_by

    //
  }
}

export default Job
