import { App, Card, Col, Row, Steps } from 'antd'
import React, { useState } from 'react'
import RuleSelector from './components/RuleSelector'
import { FormattedHTMLMessage } from 'react-intl'
import RuleSettingsStep from './components/RuleSettings'
import { createRecord } from 'api/requests'

const InitialSettings = {
  name: null,
  robots: [],
  vault: null,
  execute_by_owner: false,
  operator: 'AND',
  control_interval: 1,
}

const NewTriggerSection = ({ postSave, scenarioVersion, cancelNewTrigger }) => {
  const { notification } = App.useApp()
  const [rulesData, setRulesData] = useState([])
  const [loading, setLoading] = useState(false)
  const [settingsData, setSettingsData] = useState(InitialSettings)
  const [current, setCurrent] = useState(0)

  const handleSave = async () => {
    setLoading(true)
    const payload = {
      activities: rulesData.map(r => r.id),
      ...settingsData,
      scenarioversion: scenarioVersion,
    }
    const { success, data } = await createRecord('triggers', {
      ...payload,
    })

    if (success) {
      notification.success({
        message: <FormattedHTMLMessage id="triggers.save.success.title" />,
      })
      postSave()
    } else {
      notification.error({
        message: <FormattedHTMLMessage id="triggers.save.error.title" />,
      })
    }
    setLoading(false)
  }

  const steps = [
    {
      title: <FormattedHTMLMessage id="triggers.steps.pickOrAdd" />,
      content: (
        <RuleSelector
          selectedRules={rulesData}
          onChange={setRulesData}
          onContinue={() => setCurrent(current => current + 1)}
          onCancel={cancelNewTrigger}
        />
      ),
    },
    {
      title: <FormattedHTMLMessage id="triggers.steps.triggerSettings" />,
      content: (
        <RuleSettingsStep
          values={settingsData}
          onChange={setSettingsData}
          onSave={() => handleSave()}
          onCancel={cancelNewTrigger}
          loading={loading}
          rules={rulesData}
        />
      ),
      disabled: rulesData.length === 0,
    },
  ]

  return (
    <Card>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Steps size="small" current={current} items={steps} onChange={setCurrent} />
        </Col>
        <Col span={24}>{steps[current].content}</Col>
      </Row>
    </Card>
  )
}

export default NewTriggerSection
