import { Form, Select } from 'antd'
import React from 'react'
import conditionTranslator from '../../utilities'

const ConditionPicker = ({ conditions, name, label }) => {
  const options = conditions.map(condition => ({
    value: condition,
    label: conditionTranslator(condition),
  }))
  return (
    <Form.Item
      name={name}
      label={label || 'Conditions'}
      noStyle={!label}
      initialValue={conditions[0]}
    >
      <Select defaultActiveFirstOption options={options} />
    </Form.Item>
  )
}

export default ConditionPicker
