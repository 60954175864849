import FileSaver from 'file-saver'
import Moment from 'moment'
import { history, store } from 'index'

/**
 * This function finds the pair node for the while nodes
 */

export const findSelectedPair = chart => {
  const pairFinder = (nodes, node, type) => {
    const nodeSubType = type.split('_')[0]
    let final_node

    Object.keys(nodes).forEach(nKey => {
      if (
        nodes[nKey].properties.subtype === nodeSubType &&
        nodes[nKey].properties.definers[type] === node.properties.definers[type]
      ) {
        final_node = nodes[nKey]
      }
    })

    return final_node?.id
  }
  let pairs = []

  chart.picked.nodes.forEach(nodeId => {
    const node = chart.nodes[nodeId]
    if (node && node.type === 'tryCatch') {
      const { subtype } = node.properties
      switch (subtype) {
        case 'try':
          pairs.push(pairFinder(chart.nodes, node, 'catch_id'))
          pairs.push(pairFinder(chart.nodes, node, 'final_id'))
          break
        case 'catch':
          pairs.push(pairFinder(chart.nodes, node, 'try_id'))
          pairs.push(pairFinder(chart.nodes, node, 'final_id'))
          break
        case 'final':
          pairs.push(pairFinder(chart.nodes, node, 'try_id'))
          pairs.push(pairFinder(chart.nodes, node, 'catch_id'))
          break
        default:
          break
      }

      return pairs
    }
    if (node && node.type === 'while') {
      const { subtype } = node.properties
      const pair =
        subtype === 'start'
          ? pairFinder(chart.nodes, node, 'end_id')
          : pairFinder(chart.nodes, node, 'start_id')
      pairs = [pair]
    }
    if (node && node.type === 'forEach') {
      const { subtype } = node.properties
      const pair =
        subtype === 'start'
          ? pairFinder(chart.nodes, node, 'end_id')
          : pairFinder(chart.nodes, node, 'start_id')

      pairs = [pair]
    }
  })
  const unifiedPairs = [...new Set(pairs)]
  return unifiedPairs
}

export const deletePairNode = (chart, pairs) => {
  // Delete the links for the pair node
  pairs.forEach(nodeId => {
    Object.keys(chart.links).forEach(linkId => {
      const link = chart.links[linkId]
      if (link.from.nodeId === nodeId || link.to.nodeId === nodeId) {
        delete chart.links[link.id]
      }
    })
    // Delete the pair node
    delete chart.nodes[nodeId]
  })
}

/**
 * This function handles opening a new scenario with loading from API
 * @param {Number} scenario_version_id Scenario Version Id to request
 * @param {String} source
 */
export const loadScenario = (scenario_version_id, silent) => {
  if (!silent) {
    /*     store.dispatch({
      type: 'active_scenario/RESET',
    }) */
    store.dispatch({
      type: 'scenario_editor/CHANGE_TAB',
      payload: { active_tab: 'design', scene: 'scenario' },
    })
  }
  store.dispatch({
    type: 'scenarios/GET_SCENARIO_BY_VERSION_ID',
    payload: {
      scenario_version_id,
    },
    silent,
  })
}

// Export scenario as JSON Files
export const exportScenario = active_scenario => {
  const { content } = active_scenario
  const json = JSON.stringify(content, null, 4)
  const blob = new Blob([json], { type: 'octet/stream' })
  FileSaver.saveAs(blob, `${active_scenario.name}_${Moment().format('DDMMYYYYHHmmss')}.json`)
}
