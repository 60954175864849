import { all, takeEvery, put, call } from 'redux-saga/effects';
import { getRobotByID } from 'services/robots';
import Robot from 'models/Robot'
import actions from './actions';

export function* GET_ROBOT_BY_ID({ payload }) {
  yield put({
    type: 'active_robot/SET_STATE',
    payload: { loading: true }
  });
  yield put({
    type: 'robots/SET_STATE',
    payload: { loading: true }
  });
  try {
    const { robot_id } = payload;
    const { success, data: robot } = yield call(getRobotByID, { robot_id });
    if (success) {
      yield put({
        type: 'active_robot/SET_STATE',
        payload: { robot: new Robot(robot) }
      });
      yield put({
        type: 'statistics/GET_ROBOT_STATISTICS',
        payload: { id: robot_id }
      })
    }
  } catch (e) {
    // console.log(e)
  }
  yield put({
    type: 'active_robot/SET_STATE',
    payload: { loading: false }
  });
  yield put({
    type: 'robots/SET_STATE',
    payload: { loading: false }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ROBOT_BY_ID, GET_ROBOT_BY_ID),
  ]);
}
