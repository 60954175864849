import { store } from 'index'

let listener = null

const attachShortCutListeners = (scene, actions) => {
  if (listener) {
    return null
  }
  const body = document.querySelector('body')
  listener = body.addEventListener('keydown', ev => {
    const { scenario_editor } = store.getState()

    if (ev.key === 'Escape') {
      if (scenario_editor.nodeAddMode) {
        store.dispatch({ type: 'scenario_editor/SET_STATE', payload: { nodeAddMode: null } })
        return true
      }
      if (scenario_editor.clipboard.data && scenario_editor.clipboard.mode === scene) {
        store.dispatch({
          type: 'scenario_editor/SET_STATE',
          payload: { nodeAddMode: null, clipboard: { data: null, mode: null } },
        })
        return true
      }
    }
  })
}

export const detachListeners = () => {
  const body = document.querySelector('body')
  body.removeEventListener('keydown', listener)
}

export default attachShortCutListeners
