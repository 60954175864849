import Moment from 'moment'

import BaseModel from './BaseModel'

const CATALOG_ITEM_DAYCOUNT_FOR_NEW_TAG = 3

class CatalogItem extends BaseModel {
  constructor(props) {
    const {
      catalog,
      scenario,
      tags,
      icon,
      similar_catalogitems,
      credit,
      organisations_in_use,
      apt,
      usage_fee,
      initial_fee,
      is_public,
      is_market,
      short_description,
      html_sections,
      description,
      created_by,
      key,
      robots,
      params,
      params_spec,
      organisation,
      created,
    } = props

    super(props)
    this.short_description = short_description
    this.catalog = catalog
    this.scenario = scenario
    this.tags = tags || []
    this.key = key
    this.icon = icon || '/resources/images/default_catalog_icon.png'
    this.similar_items =
      similar_catalogitems && similar_catalogitems.length > 0
        ? similar_catalogitems.map(s => new SimilarCatalogItem(s))
        : []
    this.organizations_in_use = organisations_in_use // ! with "s"
    this.apt = apt
    this.credit = credit
    this.usage_fee = parseFloat(usage_fee).toFixed(2) // This unit is KRONNIKA PLAN CREDIT
    this.initial_fee = parseFloat(initial_fee).toFixed(2) // This unit is KRONNIKA PLAN CREDIT
    this.is_public = is_public
    this.is_market = is_market
    this.html_sections = html_sections || {
      'en-US': { about_the_robot: {} },
      'tr-TR': { about_the_robot: {} },
    } // Empty data failsafe
    this.description = description
    this.created_by = created_by
    this.robots = robots || []
    this.params = params || {}
    this.params_spec = params_spec || []
    this.is_new = Moment.utc().diff(Moment(created), 'days') < CATALOG_ITEM_DAYCOUNT_FOR_NEW_TAG
    this.default_catalog_item_version = null
    this.catalogitemversion = null
    this.organization = organisation
  }
}

class CatalogItemVersion extends BaseModel {
  constructor(props) {
    const {
      catalogitem,
      params,
      params_spec,
      is_public,
      is_default,
      is_implemented,
      is_cloud_available,
      scenarioversion,
      created_by,
      is_publish_pending,
    } = props
    super(props)

    this.catalogitem = catalogitem ? new CatalogItem(catalogitem) : null
    this.scenario_version = scenarioversion
    this.created_by = created_by
    this.is_default = is_default
    this.is_public = is_public
    this.is_implemented = is_implemented
    this.is_cloud_available = is_cloud_available
    this.is_publish_pending = is_publish_pending
    this.params = params || {}
    this.params_spec = params_spec || []
  }
}

class CombinedCatalogItem extends CatalogItem {
  constructor(props) {
    const { catalogitem, ...rest } = props
    super(catalogitem)
    this.catalogitemversion = new CatalogItemVersion(rest)
    this.scenario_version = this.catalogitemversion.scenario_version
    this.params = this.catalogitemversion.params
    this.params_spec = this.catalogitemversion.params_spec
  }
}
class CatalogItemVersionMenuItem extends CatalogItem {
  constructor(props) {
    const { id, catalogitem, created, ...rest } = props
    super(catalogitem)
    this.id = id
    this.catalogitem = new CatalogItem(rest)
    this.is_new = Moment.utc().diff(Moment(created), 'days') < CATALOG_ITEM_DAYCOUNT_FOR_NEW_TAG
  }
}

class SimilarCatalogItem extends BaseModel {
  constructor(props) {
    const { key, icon, description, short_description, organisation } = props
    super(props)
    this.key = key
    this.description = description
    this.short_description = short_description
    this.organization = organisation
    this.icon = icon || '/resources/images/default_catalog_icon.png'
  }
}

export {
  CatalogItem,
  CatalogItemVersion,
  CombinedCatalogItem,
  SimilarCatalogItem,
  CatalogItemVersionMenuItem,
}
