const actions = {
  SET_STATE: 'notifications/SET_STATE',
  GET_ACTIONS : 'notifications/GET_ACTIONS',
  GET_CHANNELS : 'notifications/GET_CHANNELS',
  GET_CHANNEL_SUBSCRIPTIONS : 'notifications/GET_CHANNEL_SUBSCRIPTIONS',
  GET_SUBSCRIPTIONS : 'notifications/GET_SUBSCRIPTIONS',
  GET_NOTIFICATIONS : 'notifications/GET_NOTIFICATIONS',
  GET_NOTIFICATION_BY_ID : 'notifications/GET_NOTIFICATION_BY_ID',
  MARK_ALL_AS_READ : 'notifications/MARK_ALL_AS_READ',
  UPDATE_NOTIFICATION : 'notifications/UPDATE_NOTIFICATION',
  UPDATE_SUBSCRIPTION : 'notifications/UPDATE_SUBSCRIPTION',
  UPDATE_CHANNEL_SUBSCRIPTION : 'notifications/UPDATE_CHANNEL_SUBSCRIPTION',
  ADD_SUBSCRIPTION : 'notifications/ADD_SUBSCRIPTION',
  ADD_CHANNEL_SUBSCRIPTION : 'notifications/ADD_CHANNEL_SUBSCRIPTION',
}

export default actions
