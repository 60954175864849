import actions from './actions'

const initialState = {
  loading: false,
  scene: 'scenario',
  scenarioPanelOpen: false,
  scenarioPanelMode: 'empty',
  librarySidebarOpen: true,
  librarySidebarLastUserState: true,
  scenarioModified: false,
  scheduleModalVisibility: false,
  stepModified: false,
  currentTask: {
    id: null,
    name: null,
  },
  // selectedTask : null,
  selectedStep: null,
  scenario_scene: {
    active_tab: 'design',
  },
  scenario_to_catalog_modal: {
    visibility: false,
  },
  nodeAddMode: false,
  clipboard: {
    data: null,
    mode: 'scenario',
  },
  infoModalVisibility: false,
  debugEnabledNodes: {},
  highlightedNode: null,
}

export default function scenarioEditorReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
