import actions from './actions'

const initialState = {
  loading : false,
  localLoading : false,
  robots : [

  ],
  readyRobots : [],
  localRobots : []
}

export default function robotsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESET:
      return initialState
    case actions.SET_STATE:
      return {...state, ...action.payload }
    default:
      return state
  }
}
