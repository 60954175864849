import { createRecord, deleteRecord, getContinuousList, getFirst, getOneByID } from 'api/requests'
import axios from 'utils/axiosGlobal'
import { API_URL, history, store } from 'index'
import extracter from './flow_data/extracter'
import { getIntl } from 'localization'
import { createInitialScenarioFile } from './utilities'
import { cloneDeep } from 'lodash'

const DEFAULT_FTE = 3

export async function createScenarioVersion({ name, id, content }) {
  const data = {
    name,
    scenario: id,
    content: content || {
      newFlowType: true,
      scenario_parameters: [],
      tasks: [],
      chart: {
        offset: {
          x: 0,
          y: 0,
        },
        nodes: {},
        links: {},
        selected: {},
        hovered: {},
        picked: {
          nodes: [],
          links: [],
          ports: [],
        },
      },
    },
    fte: DEFAULT_FTE,
    flow_data: content ? extracter({ content }) : {},
  }
  const result = await createRecord('scenarioversions', data)
  return result
}

export async function createScenario({ name, description, content }) {
  return new Promise(resolve => {
    createRecord('scenarios', { name, description }).then(async ({ success, data }) => {
      if (success) {
        const { id } = data

        createScenarioVersion({ id, name, description, content }).then(result => {
          resolve(result)
        })
      } else {
        resolve({ success: false })
      }
    })
  })
}

export const handleCreateScenario = async (name, callback) => {
  callback(true, null)

  store.dispatch({
    type: 'active_scenario/RESET',
  })

  const { success, data } = await createScenario({ name })

  if (success) {
    const { scenario, id } = data

    callback(null, {
      message: getIntl('notification.scenario.create.success.message'),
    })

    history.push(`/scenario-designer/?version=${id}`)

    // This yield for creating an initial file in related scenario
    store.dispatch({
      type: 'files/UPLOAD_FILE',
      payload: {
        workspace: 'scenarios',
        id: scenario,
        file: createInitialScenarioFile({ scenario_name: name }),
      },
    })
  }

  callback(false, null)
  store.dispatch({
    type: 'scenario_editor/SET_STATE',
    payload: { scenarioPanelOpen: false, scenarioPanelMode: 'empty' },
  })
}
export async function saveScenario(payload) {
  const { active_scenario } = payload

  const {
    id,
    version_id,
    name,
    content,
    robots,
    is_public,
    allowed_teams,
    fte,
    sync_stepruns,
    is_production,
    log_api_enabled,
  } = active_scenario

  const url_scenario_version = `${API_URL}/scenarioversions/${version_id}/`

  const url_scenario = `${API_URL}/scenarios/${id}/`

  const data_scenario_version = {
    name,
    scenario: id,
    flow_data: extracter(active_scenario),
    fte: parseInt(fte, 10) || DEFAULT_FTE,
    content: {
      ...content,
      chart: {
        ...content.chart,
        offset: { x: 0, y: 0 },
        selected: {},
      },
    },
    sync_stepruns,
    robots: robots.map(robot => ({ id: robot.id })),
  }

  const removeDocs = scenarioData => {
    const data = cloneDeep(scenarioData)
    const tasks = data.content.chart.nodes
    Object.keys(tasks).forEach(key => {
      const task = tasks[key]
      const steps = task.properties?.chart?.nodes
      if (steps) {
        Object.keys(steps).forEach(stepKey => {
          const step = steps[stepKey]
          delete step.properties.detail.docs
        })
      }
    })
    return data
  }

  const data_scenario = {
    name,
    is_public,
    is_production,
    log_api_enabled,
    allowed_teams:
      allowed_teams && allowed_teams.length > 0 ? allowed_teams.map(id => ({ id })) : [],
  }

  const q_scenario_version = await axios(url_scenario_version, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
    data: removeDocs(data_scenario_version),
  })
    .then(result => {
      return { success: true, scenario: result.data }
    })
    .catch(error => {
      return { success: false }
    })

  const q_scenario = await axios(url_scenario, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
    data: data_scenario,
  })
    .then(result => {
      return { success: true }
    })
    .catch(error => {
      return { success: false }
    })

  return new Promise(async (resolve, reject) => {
    if (q_scenario.success && q_scenario_version.success) {
      resolve({ success: true, scenarioVersion: q_scenario_version.scenario })
    } else {
      reject()
    }
  })
}
export async function duplicateScenarioVersion(payload) {
  const { flow_data, content, version_id } = payload

  const url_scenario_version = `${API_URL}/scenarioversions/${version_id}/`

  const data_scenario_version = {
    flow_data,
    content,
  }
  const q_scenario_version = await axios(url_scenario_version, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
    data: data_scenario_version,
  })
    .then(result => {
      return { success: true, scenario: result.data }
    })
    .catch(error => {
      return { success: false }
    })

  return new Promise(async (resolve, reject) => {
    if (q_scenario_version.success) {
      resolve({ success: true, scenarioVersion: q_scenario_version.scenario })
    } else {
      reject()
    }
  })
}

export async function createNewScenarioVersion(payload) {
  const { active_scenario, description, is_default } = payload

  const { id, version_id, name, content, robots, is_public, allowed_teams, fte } = active_scenario
  const url_scenario_version = `${API_URL}/scenarioversions/`

  const url_scenario = `${API_URL}/scenarios/${id}/`

  const data_scenario_version = {
    name,
    scenario: id,
    flow_data: extracter(active_scenario),
    fte: parseInt(fte, 10) || DEFAULT_FTE,
    content: { ...content, newFlowType: true },
    is_default,
    robots: robots.map(robot => ({ id: robot.id })),
    description,
  }

  const data_scenario = {
    is_public,
    allowed_teams:
      allowed_teams && allowed_teams.length > 0 ? allowed_teams.map(id => ({ id })) : [],
  }

  const q_scenario_version = await axios(url_scenario_version, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
    data: data_scenario_version,
  })
    .then(result => {
      return { success: true, newScenarioVersion: result.data }
    })
    .catch(error => {
      return { success: false }
    })

  const q_scenario = await axios(url_scenario, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
    data: data_scenario,
  })
    .then(result => {
      return { success: true }
    })
    .catch(error => {
      return { success: false }
    })

  return new Promise(async (resolve, reject) => {
    if (q_scenario.success && q_scenario_version.success) {
      resolve({ success: true, newScenarioVersion: q_scenario_version.newScenarioVersion })
    } else {
      reject()
    }
  })
}

export async function getScenario(payload) {
  const { scenarioId } = payload

  if (scenarioId !== 0) {
    const url = `${API_URL}/scenarios/${scenarioId}/`
    return axios(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
    })
      .then(result => {
        const scenario = result.data
        return {
          success: true,
          scenario,
        }
      })
      .catch(error => {
        return { success: false }
      })
  }
}

export async function getScenarioVersions(payload) {
  const { scenario_id } = payload

  const url = `${API_URL}/scenarioversions/?scenario=${scenario_id}`
  return axios(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  })
    .then(result => {
      const scenario_versions = result.data.results
      return {
        success: true,
        scenario_versions,
      }
    })
    .catch(error => {
      return { success: false }
    })
}

export async function getScenarioVersionByID(payload) {
  const { scenario_version_id } = payload

  const url = `${API_URL}/scenarioversions/${scenario_version_id}/`
  return axios(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  })
    .then(result => {
      const scenario_version = result.data
      return {
        success: true,
        scenario_version,
      }
    })
    .catch(error => {
      return { success: false }
    })
}
/**
 * @param {{scenario_id : string}} payload object which includes scenario_id
 * @returns {{success: boolean}} object with success key
 */
export async function deleteScenario(payload) {
  const { scenario_id } = payload
  const data = await deleteRecord('scenarios', scenario_id)
  return data
}

/**
 * Gets all scenarios from API with continuous request
 */
export async function getScenarioList(payload) {
  const { filters, nextUrl } = payload
  const res = await getContinuousList(
    'scenarioversionsmenu',
    { is_active: true, ...filters, pageSize: 500, order_by: 'name', is_default: true },
    nextUrl,
  )
  return res
}

export async function getScenarioByID(payload) {
  const { scenario_id } = payload
  const data = await getOneByID('scenarios', scenario_id)
  return data
}
