import axios from 'utils/axiosGlobal'
import { API_URL } from 'index'
import { requestFromURL } from 'api/requests'

export const getReportsData = async filters => {
  let filterPayload = {
    order_by: 'created',
    all_with_deleted: 'true',
    aggregate: 'true',
    ...filters,
  }

  Object.keys(filterPayload).forEach(k => filterPayload[k] === undefined && delete filterPayload[k])

  try {
    const data = await requestFromURL(
      API_URL +
        `/jobruns/?` +
        Object.keys(filterPayload)
          .map(key => `${key}=${filterPayload[key]}`)
          .join('&'),
    )
    return data
  } catch (e) {
    console.log(e)
  }
}

export const getReportsTableData = ({
  page,
  name,
  ranby,
  type,
  started_between,
  pagination_url,
}) => {
  const page_query = () => {
    return page ? encodeURI(`&page=${page}`) : ''
  }

  const name_query = () => {
    return name ? encodeURI(`&name=${name}`) : ''
  }
  const ran_by_query = () => {
    return ranby ? encodeURI(`&ranby=${ranby}`) : ''
  }

  const started_between_query = () => {
    return started_between
      ? encodeURI(`&from_date=${started_between.from}&to_date=${started_between.to}`)
      : ''
  }

  const url = `${API_URL}/${type}/?order_by=-created&all_with_deleted=true${name_query()}${started_between_query()}`

  const query_url = pagination_url || url

  return new Promise(async resolve => {
    const { data } = await axios(query_url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
    })
    resolve(data)
  })
}
