import React, { useState } from 'react'
import { Dropdown, Button, App } from 'antd'
import { PlusOutlined, ShopOutlined } from '@ant-design/icons'
import { getIntl } from 'localization'
import MarketplaceOperations from './MarketplaceOperations'
import { connect } from 'react-redux'
import { FormattedHTMLMessage } from 'react-intl'

const CatalogOperationsMenu = ({ active_scenario }) => {
  const [visibilityMarketplaceModal, setVisibilityMarketplaceModal] = useState(false)
  const { modal } = App.useApp()

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case 'add2market':
        if (active_scenario.market_save_enabled) {
          setVisibilityMarketplaceModal(true)
        } else {
          modal.info({
            title: getIntl('dialogs.scenario_designer.saveDisabled.title'),
            content: getIntl('dialogs.scenario_designer.saveDisabled.content'),
          })
        }
        break

      default:
    }
  }

  const items = [
    {
      key: 'add2market',
      icon: <PlusOutlined />,
      label: getIntl('catalog_operations_menu.add_to_marketplace'),
    },
  ]

  return (
    <>
      <Dropdown menu={{ items, onClick: handleMenuClick }}>
        <Button icon={<ShopOutlined />}>
          <FormattedHTMLMessage id="Marketplace" />
        </Button>
      </Dropdown>

      <MarketplaceOperations
        visibility={visibilityMarketplaceModal}
        setVisibility={setVisibilityMarketplaceModal}
      />
    </>
  )
}
const mapStateToProps = ({ active_scenario }) => ({ active_scenario })
export default connect(mapStateToProps)(CatalogOperationsMenu)
