import { Col, Divider, List, Modal, Row, Table } from 'antd'
import VaultIcon from 'components/CustomIcons/Vault'
import { store } from 'index'
import { getIntl } from 'localization'
import React, { useState } from 'react'
import VaultContainer from './VaultContainer'

const VaultModal = ({ visibility, vaults, onCancel }) => {
  const [header, setHeader] = useState()

  return (
    <>
      <Modal
        width="60vw"
        title={
          <>
            <VaultIcon /> {header}
          </>
        }
        open={visibility}
        onCancel={onCancel}
        footer={null}
      >
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <VaultContainer vaults={vaults} setHeader={setHeader} />
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default VaultModal
