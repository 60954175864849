let activeTask

export function initialTask() {
  return {
    task_id: null,
    task_name: null,
    properties : {
      chart: {
        offset: {
          x: 0,
          y: 0,
        },
        nodes: {},
        links: {},
        selected: {},
        hovered: {},
        picked: {
          nodes: [],
          links: [],
          ports: [],
        },
      },
      parameters : []
    }

  }
}

export function setActiveTask(payload) {
  activeTask = payload
  return activeTask
}

export function getActiveTask() {
  return activeTask
}

export function resetActiveTask() {
  activeTask = initialTask()
  return activeTask
}
export function setTaskParameters(payload) {
  return activeTask
}

export function renameActiveTask(payload) {
  activeTask.task_name = payload
  return activeTask
}

export function getEmptyTaskChart() {
  return initialTask().properties.chart
}
