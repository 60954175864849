import actions from './actions'

const initialState = {
  loading: false,
  last_log_date: null,
  logs: [],
  tasks: {},
  page: 1,
}

export default function logsReducer(state = initialState, action) {
  switch (action.type) {
    case 'logs/RESET':
      return initialState
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
