import { Button, Divider, Modal } from 'antd'
import React from 'react'

const DebugListModal = ({ open, onCancel, onOk, data }) => {
  return (
    <Modal title="Debug Nodes" open={open} onCancel={onCancel} onOk={onOk} footer={null}>
      <ul>
        {data?.debugEnabledNodes.map(node => (
          <li key={`dbgnm_${node.id}`}>{node.name || node.name}</li>
        ))}
      </ul>
      <Divider />
      <Button size="small">Clear Debug Flags</Button>
    </Modal>
  )
}

export default DebugListModal
