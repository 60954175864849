import BaseModel from './BaseModel'

class Card extends BaseModel {

  constructor(props) {

    const { token, month, year, card_type, card_family, card_association, card_currency,
      last_four_digits,
      first_six_digits, is_default, organisation } = props

    super(props)

    this.type = card_type.toLowerCase()
    this.association = card_association.toLowerCase()
    this.logo = `/resources/images/payment_providers/${this.association}.svg`
    this.family = card_family
    this.last_four_digits = last_four_digits
    this.first_six_digits = first_six_digits
    this.card_number = `${first_six_digits}******${last_four_digits}`
    this.is_default = is_default
    this.organization = organisation

  }
}

export default Card
