import { createRecord, deleteRecord, getList, getOneByID, updateRecord } from 'api/requests'

export async function getRobots({ filters }) {
  const result = await getList('robots', filters)
  return result
}

export async function getRobotByID(payload) {
  const { robot_id } = payload
  const result = await getOneByID('robots', robot_id)
  return result
}

export async function getRobotByName({ robot_name }) {
  const result = await getList('robots', { name: robot_name })
  return result
}
export async function getRobotByExactName({ robot_name }) {
  const { success, data } = await getList('robots', { name: robot_name })
  if (success) {
    const { results } = data
    const robot = results.find(robot => robot.name === robot_name)
    if (robot) {
      return {
        success: true,
        robot,
      }
    }
  }
  return {
    success: false,
  }
}

export async function createNewRobot(payload) {
  const data = await createRecord('robots', payload)
  return data
}

export async function updateRobotIcon({ icon, robot_id }) {
  const result = await updateRecord('robots', robot_id, { robotconfig: { icon } })
  return result
}

export async function updateRobotName({ name, robot_id }) {
  const result = await updateRecord('robots', robot_id, { name })
  return result
}

export async function updateRobot({ data, robot_id }) {
  const result = await updateRecord('robots', robot_id, { robotconfig: data })
  return result
}

export async function updateRobotStatus({ is_active, robot_id }) {
  const result = await updateRecord('robots', robot_id, { robotconfig: { is_active } })
  return result
}

export async function deleteRobot({ robot_id }) {
  const result = await deleteRecord('robots', robot_id)
  return result
}
