import axios from 'axios'
import store from 'store'
import { parse, stringify } from 'qs'

const axiosGlobal = axios.create({
  paramsSerializer: {
    indexes: null,
  },
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosGlobal.interceptors.request.use(
  config => {
    config.headers = {
      ...config.headers,
      'Accept-Language': store.get('app.settings.locale') || 'tr-TR',
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)
export default axiosGlobal
