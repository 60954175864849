import actions from './actions'

const initialState = {
  loadingMenu : false,
  loadingDoc : false,
  loadingVersion : false,
  menuTree: [],
  menuState : {
    openKeys : [],
    selectedKeys : [],
  },
  limitedMenuData : [],
  limitedMenuTree : [],
  menuData : [],
  activeDoc : null,
  docs : [],
  docTypes : [],
  libraries : [],
  librariesMenuTree : []
}

export default function documentationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
