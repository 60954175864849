import React, { useState, useEffect } from 'react'
import LanguageSwitcher from 'components/layout/LanguageSwitcher'
import style from './style.module.scss'
import { Row, Col, Affix } from 'antd'
import { FormattedMessage } from 'react-intl'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { history } from 'index'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SearchBox from 'components/widgets/SearchBox'
import MenuItems from './MenuItems'
import { filterRoutesByDisabledURLs } from 'services/utilities'

const TopBar = ({
  settings: { locale },
  marketplace: { loading },
  router: { location },
  dispatch,
}) => {
  const { xs } = useBreakpoint()
  const [searchValue, setSearchValue] = useState('')

  const { search, language } = location.query

  const loginButtonDisabled = location.pathname.includes('system/login')

  const onSearch = (clear = false) => {
    if (clear) {
      history.push(`${location.pathname}?all=true`)
      return false
    }
    history.push(`${location.pathname}?language=${locale}&search=${searchValue}`)
  }

  useEffect(() => {
    if (language) {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'locale',
          value: language,
        },
      })
    }
    setSearchValue(search || '')
  }, [location])
  const onChange = value => {
    setSearchValue(value)
    if (searchValue.trim() !== '' && value.trim() === '') onSearch(true)
  }

  return xs ? (
    <Affix offsetTop={0}>
      <Row gutter={[16, 16]} justify="center" className={style.topbar}>
        <Col sm={14} className="text-center">
          <Link to="/marketplace">
            <img height="45px" src="/resources/images/kronnika-logo.png" alt="Kronnika" />
          </Link>
          p
        </Col>
        <Col span={8} className="text-center" style={{ alignItems: 'center' }}>
          <button
            type="button"
            className="btn btn-primary  ml-4"
            onClick={() => history.push('/system/login')}
          >
            <FormattedMessage id="loginScreen.loginButton" />
          </button>
        </Col>
        <Col span={22} className="text-center">
          <SearchBox
            placeholder="market.main.search"
            value={searchValue}
            onChange={onChange}
            onSearch={() => onSearch(false)}
            loading={loading}
          />
        </Col>
      </Row>
    </Affix>
  ) : (
    <Affix offsetTop={0}>
      <Row gutter={[16, 16]} justify="center" className={style.topbar}>
        <Col xs={24} sm={18}>
          <Row>
            <Col span={12}>
              <Row gutter={[8, 8]}>
                <Col>
                  <Link to="/marketplace">
                    <img height="45px" src="/resources/images/kronnika-logo.png" alt="Kronnika" />
                  </Link>
                </Col>
                <Col flex="auto" className="ml-4">
                  <SearchBox
                    placeholder="market.main.search"
                    value={searchValue}
                    onChange={onChange}
                    onSearch={() => onSearch(false)}
                    width="40%"
                    loading={loading}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <MenuItems options={{ loginButtonDisabled }} />
            </Col>
          </Row>
        </Col>
      </Row>

      <div style={{ position: 'fixed', top: 10, right: 10 }}>
        <LanguageSwitcher />
      </div>
    </Affix>
  )
}

const mapStateToProps = ({ marketplace, settings, router }) => ({ marketplace, settings, router })
export default connect(mapStateToProps)(TopBar)
