import actions from './actions';

const initialState = {
	loading: false,
	authorized: false,
  permissions :[],
  first_name: '',
	last_name: '',
	username: '',
	email: '',
	phone: '',
	address : '',
	country : '',
	state: '',
	city :'',
	zipcode : '',
	identity_card_id: '',
	position: '',
	roles: [ {} ],
	id: '',
	teams: [],
	photo: '',
	organizations: [],
	location: '',
  timezone: '',
  is_analytics : false,
};

export default function userReducer(state = initialState, action) {
	switch (action.type) {
		case actions.SET_STATE:
			return { ...state, ...action.payload };
		default:
			return state;
	}
}
