import { createRecord, deleteRecord, getList, putRecord, updateRecord } from 'api/requests'
import Moment from 'moment'

export async function getJobs(filters) {
  const res = await getList('jobs', { order_by: '-created', ...filters })
  return res
}

export async function addJob(payload) {
  const {
    name,
    scenario_version,
    robots,
    cron,
    start_date,
    end_date,
    params,
    tasks,
    options,
  } = payload

  const data = {
    scenarioversion: scenario_version,
    name: `${name}_${Moment().unix()}`,
    robots,
    params,
    options,
  }

  Object.assign(
    data,
    cron ? { cron } : null,
    start_date ? { start_date } : null,
    end_date ? { end_date } : null,
  )

  const response = await createRecord('jobs', data)
  return response
}

export async function addScheduledJob(payload) {
  const result = await createRecord('jobs', payload)
  return result
}

export async function updateJob({ job_id, data }) {
  const response = await updateRecord('jobs', job_id, data)
  return response
}

export async function cancelJob({ id }) {
  const result = await putRecord('jobruns', id, null, 'cancel')
  return result
}

export async function deleteJob({ job_id }) {
  const result = await deleteRecord('jobs', job_id)
  return result
}
