import actions from './actions'

const initialState = {
  loading : false,
  teams : [

  ],
  count : 0,
}

export default function teamsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return {...state, ...action.payload }
    default:
      return state
  }
}
