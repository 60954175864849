import {
  createRecord,
  deleteRecord,
  getList,
  getOneByID,
  requestFromURL,
  updateRecord,
} from 'api/requests'
import { API_URL } from 'index'

export const getQueues = async filters => {
  const data = await getList('queues', filters)
  return data
}
export const getQueueById = async id => {
  const data = await getOneByID('queues', id)
  return data
}

export const addQueue = async queueData => {
  const data = await createRecord('queues', queueData)
  return data
}

export const updateQueue = async (queueId, queueData) => {
  const data = await updateRecord('queues', queueId, queueData)
  return data
}
export const deleteQueue = async queueId => {
  const data = await deleteRecord('queues', queueId)
  return data
}

// Queue Items

export const getQueueItems = async (queueId, filters) => {
  const data = await getList(`queueitems/${queueId}/list_queue_items`, filters)
  return data
}

export const createQueueItem = async (queueId, queueData, bulk = false) => {
  const keyRemovedData = queueData.length
    ? queueData.map(({ key, ...rest }) => rest)
    : { ...queueData, key: undefined }
  let url = `queueitems/${queueId}/create_queue_items`
  if (bulk) {
    url += '/?bulk=true'
  }
  const data = await createRecord(url, queueData.length ? keyRemovedData : [queueData])
  return data
}

export const deleteQueueItems = async (queueId, queueItemIdList) => {
  const data = await requestFromURL(
    `${API_URL}/queueitems/${queueId}/delete_queue_items/?bulk=true`,
    'DELETE',
    queueItemIdList,
  )
  return data
}
