import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Dropdown, Avatar, Badge, Switch, Col, Row } from 'antd'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'
import ACLFunction from 'components/system/Auth/ACL/ACLFunction'
import { PermissionsTable } from 'services/permissions'
import { getIntl } from 'localization'
import LanguageSwitcherMenu from 'components/layout/LanguageSwitcherMenu'

const mapStateToProps = ({ user, settings }) => ({ user, settings })

const ProfileMenu = ({ user, settings, dispatch }) => {
  const developerPermitted = ACLFunction({
    allowed: PermissionsTable.developerModeMembers,
    type: 'role',
    components: false,
  })
  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const handleDevModeChange = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'developerMode',
        value: !settings?.developerMode,
      },
    })
  }
  const items = [
    {
      key: 'main',
      label: (
        <Row gutter={[8, 8]}>
          <Col span={4}>
            <Avatar className={styles.avatar} shape="square" size="large" src={user.photo} />
          </Col>
          <Col span={2} />
          <Col span={18}>
            <strong>{`${user.first_name} ${user.last_name}` || 'Anonymous'}</strong>
            <br />
            <small>{user.email}</small>
          </Col>
        </Row>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'role',
      label: (
        <>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{' '}
          </strong>
          {getIntl(user.role)}
        </>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'profile',
      label: (
        <Link to="/user/profile">
          <i className={`${styles.menuIcon} fe fe-user`} />
          <FormattedMessage id="topBar.profileMenu.profile" />
        </Link>
      ),
    },
    {
      type: 'divider',
      show: developerPermitted,
    },
    {
      key: 'language',
      label: <LanguageSwitcherMenu />,
    },
    {
      type: 'divider',
    },
    {
      key: 'developerMode',
      show: developerPermitted,
      label: (
        <>
          <Switch checked={settings?.developerMode} onChange={handleDevModeChange} />{' '}
          {getIntl('Developer Mode')}
        </>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: (
        <a href="#" onClick={logout}>
          <i className={`${styles.menuIcon} fe fe-log-out`} />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      ),
    },
  ]

  const handleMenuClick = ({ key }) => {
    if (key === 'developerMode') {
      handleDevModeChange()
    }
  }
  return (
    <div data-tour="userMenu">
      <Dropdown
        menu={{
          items: items.filter(item => item.show !== false).map(({ show, ...item }) => item),
          onClick: handleMenuClick,
        }}
        trigger={['click']}
      >
        <div className={styles.dropdown}>
          <Badge count={null}>
            <Avatar className={styles.avatar} shape="square" size="large" src={user.photo} />
          </Badge>
        </div>
      </Dropdown>
    </div>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
