import { getFirst, getList, getOneByID } from 'api/requests'

const getPlans = async (payload = {}) => {
  const { filters, anonymous } = payload
  const data = await getList('plans', filters, anonymous)
  return data
}
const getPlan = async (payload = {}) => {
  const { filters, anonymous } = payload
  const data = await getFirst('plans', filters, anonymous)
  return data
}
const getPlanById = async id => {
  const data = await getOneByID('plans', id)
  return data
}

export { getPlans, getPlan, getPlanById }
