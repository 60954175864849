import { history } from 'index'
import { getIntl } from 'localization'
import PackageOrder from 'models/PackageOrder'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { addPackageOrder, getPackageOrders } from 'services/package_orders'
import actions from './actions'

export function* GET_PACKAGE_ORDERS({ payload }) {
  yield put({
    type: 'package_orders/SET_STATE',
    payload: { loading: true },
  })

  const {
    success,
    data: { results, count },
  } = yield call(getPackageOrders, payload?.filters)

  if (success) {
    yield put({
      type: 'package_orders/SET_STATE',
      payload: { package_orders: results.map(po => new PackageOrder(po)), count },
    })
  }

  yield put({
    type: 'package_orders/SET_STATE',
    payload: { loading: false },
  })
}
export function* ADD_PACKAGE_ORDER({ payload }) {
  yield put({
    type: 'packages/SET_STATE',
    payload: { loading: true },
  })
  try {
    const { success, data } = yield call(addPackageOrder, payload)
    if (success) {
      const data = {
        message: getIntl('notifications.packagePurchase.success'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'success',
        },
      })
      history.push('/my-packages')
    } else {
      const data = {
        message: getIntl('notifications.packagePurchase.error'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }
    yield put({
      type: 'user/GET_CURRENT_USER',
    })
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'packages/SET_STATE',
    payload: { loading: false },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PACKAGE_ORDERS, GET_PACKAGE_ORDERS),
    takeEvery(actions.ADD_PACKAGE_ORDER, ADD_PACKAGE_ORDER),
  ])
}
