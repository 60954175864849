import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Select, Input, Checkbox } from 'antd'
import { getIntl } from 'localization'
import { MAX_CATALOG_DESCRIPTION_LENGTH, MAX_CATALOG_NAME_LENGTH } from 'constantValues'

const mapStateToProps = ({ active_scenario, settings, catalog_items }) => ({
  active_scenario,
  settings,
  catalog_items,
})
const CatalogOperations = ({
  dispatch,
  active_scenario,
  catalog_items,
  visibility,
  setVisibility,
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (visibility) {
      form.setFieldsValue({ name: active_scenario.name })
      dispatch({
        type: 'catalog_items/GET_CATALOG_ITEMS',
        payload: { filters: { pageSize: 50, is_default: true, is_market: false } },
      })
    }
  }, [active_scenario.id, visibility])

  useEffect(() => {
    if (catalog_items && catalog_items.length > 0) {
      form.setFieldsValue({ catalog_items: catalog_items.catalog_items })
    }
  }, [catalog_items])

  const addScenarioToCatalog = (item, cloud_url) => {
    dispatch({
      type: 'catalog_items/ADD_SCENARIO_TO_CATALOG',
      payload: { item, cloud_url },
    })
  }

  const addScenarioToNewCatalogItemVersion = (item, cloud_url) => {
    dispatch({
      type: 'catalog_items/ADD_SCENARIO_TO_NEW_CATALOG_ITEM_VERSION',
      payload: { item, cloud_url },
    })
  }

  const submitCatalogItem = () => {
    form
      .validateFields()
      .then(values => {
        const { cloud_url } = values
        delete values.cloud_url
        if (values.catalog_item) {
          addScenarioToNewCatalogItemVersion(values, cloud_url)
        } else {
          addScenarioToCatalog(values, cloud_url)
        }
        setVisibility(false)
      })
      .catch(e => {})
  }

  return (
    <>
      <Modal
        forceRender
        title={getIntl('catalog_items.add_scenario_to_catalog.modal.title')}
        open={visibility}
        onCancel={() => setVisibility(false)}
        onOk={submitCatalogItem}
      >
        <Form
          form={form}
          onFinish={submitCatalogItem}
          layout="vertical"
          initialValues={{ name: active_scenario.name }}
        >
          <Form.Item
            name="name"
            label={getIntl('Name')}
            rules={[
              {
                required: true,
                message: getIntl('formValidations.new_catalog_item.name.required'),
              },
            ]}
          >
            <Input maxLength={MAX_CATALOG_NAME_LENGTH} />
          </Form.Item>
          <Form.Item
            name="short_description"
            label={getIntl('Description')}
            rules={[
              {
                required: true,
                message: getIntl('formValidations.new_catalog_item.description.required'),
              },
            ]}
          >
            <Input maxLength={MAX_CATALOG_DESCRIPTION_LENGTH} />
          </Form.Item>
          <Form.Item name="tags" label={getIntl('Tags')}>
            <Select mode="tags" />
          </Form.Item>
          <Form.Item
            label={getIntl('Cloud URL')}
            name="cloud_url"
            rules={[
              {
                type: 'url',
              },
            ]}
            tooltip={getIntl('catalog_items.tooltip.cloudURL')}
          >
            <Input />
          </Form.Item>
          <Form.Item name="catalog_item" label={getIntl('Catalog Items')}>
            <Select
              showSearch
              placeholder={getIntl(
                'catalog_items.add_scenario_to_catalog.catalogItemSelector.placeholder',
              )}
              optionFilterProp="children"
            >
              {catalog_items?.catalog_items.map(option => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(CatalogOperations)
