import React from 'react'
import Icon from '@ant-design/icons'

const RenameSVG = () => (
  <svg width="1.1em" height="1.1em" fill="currentColor" viewBox="0 0 32 32">
    <g>
      <path d="M 17 4 L 17 6 C 18.464583 6 19.560835 6.2905522 20.25 6.625 C 20.594583 6.7922239 20.855745 6.9799375 20.96875 7.09375 C 20.990482 7.115637 20.990707 7.1097764 21 7.125 L 21 8 L 21 24 L 21 24.875 C 20.990707 24.890224 20.990483 24.884363 20.96875 24.90625 C 20.855745 25.020062 20.594583 25.207776 20.25 25.375 C 19.560835 25.709448 18.464583 26 17 26 L 17 28 C 18.735417 28 20.114165 27.646802 21.125 27.15625 C 21.464213 26.991632 21.740763 26.790835 22 26.59375 C 22.259237 26.790835 22.535787 26.991632 22.875 27.15625 C 23.885835 27.646802 25.264583 28 27 28 L 27 26 C 25.535417 26 24.439165 25.709448 23.75 25.375 C 23.405417 25.207776 23.144255 25.020062 23.03125 24.90625 C 23.009518 24.884363 23.009293 24.890224 23 24.875 L 23 24 L 23 7.125 C 23.009293 7.1097764 23.009517 7.115637 23.03125 7.09375 C 23.144255 6.9799375 23.405417 6.7922239 23.75 6.625 C 24.439165 6.2905522 25.535417 6 27 6 L 27 4 C 25.264583 4 23.885835 4.3531978 22.875 4.84375 C 22.535787 5.008368 22.259237 5.2091652 22 5.40625 C 21.740763 5.2091652 21.464213 5.008368 21.125 4.84375 C 20.114165 4.3531978 18.735417 4 17 4 z M 1 10 L 1 11 L 1 21 L 1 22 L 2 22 L 19 22 L 19 20 L 3 20 L 3 12 L 19 12 L 19 10 L 2 10 L 1 10 z M 25 10 L 25 12 L 29 12 L 29 20 L 25 20 L 25 22 L 30 22 L 31 22 L 31 21 L 31 11 L 31 10 L 30 10 L 25 10 z M 5 14 L 5 18 L 13 18 L 13 14 L 5 14 z" />
    </g>
  </svg>
)
const RenameIcon = props => <Icon component={RenameSVG} {...props} />

export default RenameIcon
