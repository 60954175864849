import { updateRecord } from 'api/requests'
import getUserRole from 'utils/userRoles'
import BaseModel from './BaseModel'
import Moment from 'moment'
import tours from 'components/ManagerTourer/tours'

class User extends BaseModel {
  constructor(props) {
    const {
      id,
      username,
      groups,
      email,
      first_name,
      last_name,
      last_login,
      is_analytics,
      userprofile,
    } = props
    super(props)

    const {
      phone,
      address,
      country,
      state,
      city,
      zipcode,
      identity_card_id,
      position,
      teams,
      photo,
      organisation,
      timezone,
      agreements,
      manager_config,
    } = userprofile

    this.loading = false
    this.authorized = false
    this.usage = null
    this.id = id
    this.username = username
    this.roles = groups
    this.email = email
    this.first_name = first_name
    this.last_name = last_name
    this.phone = phone && phone !== '' ? phone : ' - '
    this.address = address
    this.country = country
    this.state = state
    this.city = city
    this.zipcode = zipcode
    this.identity_card_id = identity_card_id
    this.role = getUserRole(this.roles[0]?.name) || '-'
    this.position = position
    this.teams = teams && teams.length > 0 ? teams : [{ id: 999, name: '' }]
    this.photo = photo || '/resources/images/kronnika_icon_128x128.png'
    this.organizations = [organisation]
    this.location = country
    this.timezone = timezone
    this.agreements = agreements
    this.userprofile = userprofile
    this.last_login = last_login ? Moment(last_login).format('DD.MM.YYYY HH:mm:ss') : '-'
    this.is_analytics = is_analytics
    this.manager_config = manager_config || INITIAL_MANAGER_CONFIG
  }

  updateUser = async (id = this.id) => {
    const { success, data: user } = await updateRecord('users', id, {
      groups: this.groups,
      email: this.email,
      first_name: this.first_name,
      last_name: this.last_name,
      userprofile: {
        phone: this.phone,
        address: this.address,
        country: this.country,
        state: this.state,
        city: this.city,
        zipcode: this.zipcode,
        identity_card_id: this.identity_card_id,
        position: this.position,
        teams: this.teams.filter(t => t.id !== 999),
        organisation: this.organizations[0],
        timezone: this.timezone,
        agreements: this.agreements,
        manager_config: this.manager_config,
      },
      is_analytics: this.is_analytics,
    })

    return { success, data: new User(user) }
  }
}
const initialTours = Object.assign(
  {},
  ...Object.keys(tours).map(key => ({ [key]: { finished: false } })),
)
const INITIAL_MANAGER_CONFIG = {
  tours: initialTours,
}

export default User
