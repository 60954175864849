import { convertCurrencyToSymbol, priceWithCurrencySymbol } from 'services/utilities'
import BaseModel from './BaseModel'

class Plan extends BaseModel {
  constructor(props) {
    const {
      subscription_fee,
      billing_subscription_fee,
      currency,
      billing_currency,
      is_annual,
      is_default,
      is_public,
      free_usage,
      usage_fee,
      billing_usage_fee,
      extra_info,
      sort_order,
      trial_period,
      name,
    } = props
    super(props)

    this.currency = billing_currency
    this.key = name.toLowerCase().replace(' ', '_')
    this.currency_symbol = convertCurrencyToSymbol(this.currency)
    this.usage_fee = parseFloat(usage_fee)
    this.trial_period = trial_period > 0 ? trial_period : null
    this.billing_usage_fee = parseFloat(billing_usage_fee)
    this.usage_fee = parseFloat(billing_usage_fee)
    this.$usage_fee = priceWithCurrencySymbol(this.billing_usage_fee, billing_currency)
    this.subscription_fee = parseFloat(subscription_fee)
    this.billing_subscription_fee = parseFloat(billing_subscription_fee)
    this.free_usage = parseFloat(free_usage)
    this.$subscription_fee = `${this.subscription_fee} $` /* priceWithCurrencySymbol(
      this.billing_subscription_fee,
      billing_currency,
    ) */
    this.extra_info = extra_info || null
    this.is_annual = is_annual
    this.is_default = is_default
    this.is_public = is_public
    this.noprice = parseFloat(billing_subscription_fee) === 0.0
    this.order = sort_order
  }
}

export default Plan
