import { getIntl } from 'localization'
import React from 'react'
import { Link } from 'react-router-dom'
import style from '../style.module.scss'

class Error404 extends React.Component {
  render() {
    return (
      <div className={style.errors}>
        <div className="pt-4 pb-4 d-flex align-items-end mt-auto">
          <img src="/resources/images/kronnika-logo.png" height={50} alt="Kronnika" />
        </div>
        <div className={`${style.container} pl-5 pr-5 pt-5 pb-5 mb-auto text-dark font-size-30`}>
          <div className="font-weight-bold mb-3">{getIntl('systemPages.404.pageNotFound')}</div>
          <div>{getIntl('systemPages.404.info')}</div>
          <div className="font-weight-bold font-size-70 mb-1">404 —</div>
          <Link to="/" className="btn btn-outline-primary width-100">
            {getIntl('Go Back')}
          </Link>
        </div>

      </div>
    )
  }
}

export default Error404
