import React, { useState, useEffect } from 'react'
import { App, Col, Row, Tooltip } from 'antd'

import { connect } from 'react-redux'
import { getIntl } from 'localization'
import { matchUpRobots } from 'services/utilities'
import URLParamChanger from 'utils/urlParamChanger'
import { FormattedHTMLMessage } from 'react-intl'

const mapStateToProps = ({ settings, organizations, router, user, active_scenario }) => ({
  settings,
  organizations,
  router,
  user,
  active_scenario,
})

const JobActions = ({ active_scenario, router, user, dispatch, settings, organizations }) => {
  // States
  const [visibility, setVisibility] = useState(false)
  const { modal, notification } = App.useApp()

  const { pathname } = router.location
  const {
    usage: { can_submit_job },
  } = user
  const showNoRobotDialog = () => {
    // This function show a dialog about the scenario has no robots selected to use
    modal.warning({
      title: getIntl('jobActions.noRobots.title'),
      content: getIntl('jobActions.noRobots.content'),
    })
  }
  useEffect(() => {
    setVisibility(pathname.includes('/scenario-designer/') && active_scenario.id)
  }, [router, active_scenario])

  const showExecuteConfirm = async () => {
    if (active_scenario.robots.length === 0) {
      modal.warning({
        title: getIntl('jobActions.noRobotSelected.title'),
        content: getIntl('jobActions.noRobotSelected.content'),
      })
      return false
    }
    if (can_submit_job) {
      const runnableRobotsExists = await matchUpRobots(active_scenario.robots)
      if (runnableRobotsExists) {
        modal.confirm({
          title: getIntl('scenario_designer.execute_confirm.title'),
          content: getIntl('scenario_designer.execute_confirm.description'),
          okText: getIntl('Execute'),
          onOk: handleExecuteJob,
        })
      } else {
        showNoRobotDialog()
      }
    } else {
      modal.warning({
        title: getIntl('warnings.canNotSubmitJob.title'),
        content: getIntl('warnings.canNotSubmitJob.content'),
      })
    }
  }

  const handleExecuteJob = () => {
    dispatch({
      type: 'scenario_runner/RESET',
    })
    dispatch({
      type: 'jobruns/RESET',
    })

    dispatch({
      type: 'jobs/ADD_NEW_JOB',
    })

    URLParamChanger({ tab: 'preview', scene: 'scenario' })
  }

  const buttonStyle = settings.isMobileView
    ? 'btn btn-secondary btn-with-addon text-nowrap btn-block'
    : 'btn btn-secondary btn-with-addon text-nowrap d-md-block'

  const handleButtonClick = e => {
    if (organizations.active_organization.is_suspended) {
      notification.warning({
        message: getIntl('dialogs.suspendedOrganization.title'),
        description: <FormattedHTMLMessage id="dialogs.suspendedOrganization.robotContent" />,
      })
      return
    }
    showExecuteConfirm()
  }
  const element = (
    <Row gutter={[8, 8]}>
      <Col span={12}>
        <div className="btn-group" style={{ height: 32 }}>
          <button
            data-tour="runButton"
            type="button"
            style={{ lineHeight: '1rem' }}
            className="btn btn-secondary btn-with-addon pull-right"
            onClick={handleButtonClick}
          >
            <span className="btn-addon">
              <i className="btn-addon-icon fe fe-play" />
            </span>
            {getIntl('Run')}
          </button>
          <Tooltip title={getIntl('Schedule')}>
            <button
              data-tour="scheduleButton"
              type="button"
              style={{ lineHeight: '1rem' }}
              className="btn btn-warning pull-right"
              onClick={() => {
                if (organizations.active_organization.is_suspended) {
                  notification.warning({
                    message: getIntl('dialogs.suspendedOrganization.title'),
                    description: (
                      <FormattedHTMLMessage id="dialogs.suspendedOrganization.scenarioContent" />
                    ),
                  })
                }
                dispatch({
                  type: 'scenario_editor/SET_STATE',
                  payload: { scheduleModalVisibility: true },
                })
              }}
            >
              <span className="btn-addon">
                <i className="btn-addon-icon fe fe-clock" />
              </span>
            </button>
          </Tooltip>
        </div>
      </Col>
    </Row>
  )
  return visibility ? element : null
}
export default connect(mapStateToProps)(JobActions)
