import { SaveOutlined } from '@ant-design/icons'
import React from 'react'

import styles from './style.module.scss'

const BlinkingSaveIcon = () => {
  return <SaveOutlined className={styles.blinkingIcon} />
}

export default BlinkingSaveIcon
