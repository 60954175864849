import { StopOutlined, UnlockOutlined, UploadOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Divider, Input, Row, Upload } from 'antd'
import { getIntl } from 'localization'
import React, { useState } from 'react'

const PrivateKeyView = ({ decryptVault, onCancel }) => {
  const [keyData, setKeyData] = useState(null)
  const [fileList, setFileList] = useState([])
  const [warning, setWarning] = useState(false)

  const readFile = (file) => {
    const reader = new FileReader()
    reader.onload = (evt) => {
      const content = evt.target.result
      setKeyData(content || '')
    }
    reader.readAsText(file)
  }

  const handleDrop = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          readFile(file)
        }
      }
    }
  }
  const handleOK = () => {
    if (keyData) {
      const decrypted = decryptVault(keyData)
      setWarning(!decrypted)
    }
    setKeyData(null)
    setFileList([])

  }
  const handleCancel = () => {
    setKeyData(null)
    setFileList([])
    setWarning(false)
    onCancel()
  }

  const fileProps = {
    onRemove: file => {
      setFileList([])
    },
    beforeUpload: file => {
      setFileList([file])
      readFile(file)
      return false;
    },
    fileList,
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Upload {...fileProps} className="mb-1 float-right">
            <Button icon={<UploadOutlined />}>{getIntl('Select File')}</Button>
          </Upload>
          <Input.TextArea
            className="mt-2"
            placeholder={getIntl('vault.privateKey.placeholder')}
            onDrop={handleDrop}
            value={keyData}
            onChange={e => setKeyData(e.target.value)}
          >
          </Input.TextArea>
          {warning ?
            <Alert
              className="mt-2"
              message={getIntl('vault.privateKey.warning.invalidKey')}
              type="error"
              closable
              afterClose={() => setWarning(false)}
            />
            : null}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <div className="pull-right">
            <Button onClick={handleCancel} icon={<StopOutlined />}>{getIntl('Cancel')}</Button>
            <Divider type="vertical" />
            <Button type="primary" onClick={handleOK} disabled={keyData === null || keyData.trim() === ''} icon={<UnlockOutlined />}>{getIntl('Unlock')}</Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default PrivateKeyView
