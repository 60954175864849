/* eslint-disable import/no-mutable-exports */
import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import 'services/auth' // Includes necessary interceptors
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import Router from './router'
import * as serviceWorker from './serviceWorker'
// app styles
import './global.scss'
import { configureStore } from '@reduxjs/toolkit'

// API Address
const url = window.location.hostname
const dev_url = 'kronnika.dev'
let API_URL = `https://api.${url}/api`
let MEDIA_URL = `https://api.${url}/media`

if (process.env.NODE_ENV !== 'production') {
  API_URL = `https://api.${dev_url}/api`
  MEDIA_URL = `https://api.${dev_url}/media`
}

// Middlewares
const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]

// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }

const store = configureStore({
  reducer: reducers(history),
  middleware: () => {
    return [...middlewares]
  },
  devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(sagas)

const container = document.getElementById('root')

// Create a root.
const root = ReactDOM.createRoot(container)

root.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history, API_URL, MEDIA_URL }
