import actions from './actions'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { setChart, getChart } from 'services/chart'

export function* GET_CHART() {
  const chart = yield call(getChart)
  yield put({
    type: 'chart/SET_STATE',
    payload: {
      offset: chart.offset,
      nodes: chart.nodes,
      links: chart.links,
      selected: chart.selected,
      hovered: chart.hovered,
    },
  })
}

export function* SET_CHART({ payload }) {
  const chart = yield call(setChart, payload)
  yield put({
    type: 'chart/SET_STATE',
    payload: {
      offset: chart.offset,
      nodes: chart.nodes,
      links: chart.links,
      selected: chart.selected,
      hovered: chart.hovered,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_CHART, SET_CHART),
    takeEvery(actions.GET_CHART, GET_CHART),
    // GET_CHART(), // run once on app load to fetch menu data
  ])
}
