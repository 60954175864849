import axios from 'utils/axiosGlobal'
import { API_URL } from 'index'
import slugger, { convertToSlug } from 'utils/slugger'
import {
  createRecord,
  deleteRecord,
  getFirst,
  getList,
  getOneByID,
  updateRecord,
} from 'api/requests'

export default async function getMenuData({ locale }) {
  // getOnlineDocumentation(locale)
  return [
    {
      title: 'Quick Start',
      category: true,
    },
    {
      title: 'Create Your Account',
      key: 'createYourAccount',
      url: '/createYourAccount',
    },
    {
      title: 'Create Your First Robot',
      key: 'createYourFirstRobot',
      url: '/createYourFirstRobot',
    },
    {
      title: 'Connect To Your Robot',
      key: 'connectToYourRobot',
      url: '/connectToYourRobot',
    },
    {
      title: 'Create A Scenario',
      key: 'createAScenario',
      url: '/createAScenario',
    },
    {
      title: 'Run Your First Scenario',
      key: 'runYourFirstScenario',
      url: '/runYourFirstScenario',
    },
    {
      title: 'Platform Overview',
      category: true,
    },
    {
      title: 'Architecture & Components',
      key: 'archNComponents',
      url: '/archNComponents',
    },
    {
      title: 'Technology',
      key: 'technology',
      url: '/archNComponents',
    },
    {
      title: 'Manager',
      category: true,
    },
    {
      title: 'Dashboard',
      key: 'dashboard',
      url: '/dashboard',
    },

    {
      title: 'Designer',
      key: 'designer',
      children: [
        {
          title: 'Designer',
          key: 'designer',
          url: '/designer',
        },
        {
          title: 'Settings',
          key: 'settings',
          url: '/settings',
        },
        {
          title: '...',
          key: 'extra',
          url: '/extra',
        },

        {
          title: '...',
          key: 'extra2',
          url: '/extra2',
        },
      ],
    },
    {
      title: 'Runner',
      key: 'runner',
      url: '/runner',
    },
    {
      title: 'Robot Manager',
      key: 'robotManager',
      url: '/robotManager',
    },
    {
      title: 'Catalog',
      key: 'catalog',
      url: '/catalog',
    },
    {
      title: 'Marketplace',
      key: 'marketplace',
      url: '/marketplace',
    },
    {
      title: 'Reports',
      key: 'reports',
      url: '/reports',
    },
    {
      title: 'Organization Management',
      key: 'organizationManagement',
      url: '/organizationManagement',
    },

    {
      title: 'PyAgent',
      category: true,
    },
    {
      title: 'Robot Manager',
      key: 'robotManager',
      url: '/robotManager',
    },
    {
      title: 'Robots',
      key: 'robots',
      url: '/robots',
    },
    {
      title: 'Inspector',
      key: 'inspector',
      url: '/inspector',
    },
    {
      title: 'Files',
      key: 'files',
      url: '/files',
    },
    {
      title: 'Logs',
      key: 'logs',
      url: '/logs',
    },
    {
      title: '3rd Party Libraries',
      key: 'thirdPartyLibraries',
      url: '/thirdPartyLibraries',
    },
    {
      title: 'Kronnika Library',
      key: 'kronnikaLibrary',
      category: true,
    },
    {
      title: 'Analytics',
      key: 'analytics',
      url: '/analytics',
    },
    {
      title: 'API',
      key: 'api',
      url: '/api',
    },
    {
      title: 'Barcode',
      key: 'barcode',
      url: '/barcode',
    },
    {
      title: 'Browser',
      key: 'browser',
      url: '/browser',
    },
    {
      title: 'Captha',
      key: 'captcha',
      url: '/captcha',
    },
    {
      title: '...',
      key: 'lib1',
      url: '/lib1',
    },
    {
      title: '...',
      key: 'lib2',
      url: '/lib2',
    },
    {
      title: 'For Advanced Users',
      category: true,
    },
    { title: 'Custom Scripting', key: 'customScripting', url: '/customScripting' },
    { title: 'Using Analytics', key: 'usingAnalytics', url: '/usingAnalytics' },
    { title: 'Running Parallel Jobs', key: 'runningParallelJobs', url: '/runningParallelJobs' },
    {
      title: 'Using External Libraries',
      key: 'usingExternalLibraries',
      url: '/usingExternalLibraries',
    },
    { title: 'Using External API Apps', key: 'usingExternalAPIApps', url: '/usingExternalAPIApps' },
    {
      title: 'Examples',
      category: true,
    },

    { title: 'Login Form', key: 'ex1', url: '/ex1' },
    { title: 'Google Search', key: 'ex2', url: '/ex2' },
    { title: 'Get Product List', key: 'ex3', url: '/ex2' },
    { title: 'Create and Fill Excel File', key: 'ex4', url: '/ex4' },
    { title: 'Search Text in PDF File', key: 'ex5', url: '/ex5' },
    { title: '...', key: 'ex6', url: '/ex6' },
    {
      title: 'Kronnika Analytics',
      category: true,
    },
    { title: 'Creating Data Sets', key: 'Creating Data Sets', url: '/Creating Data Sets' },
    {
      title: 'Using Dashboard Templates',
      key: 'usingDashboardTemplates',
      url: '/usingDashboardTemplates',
    },
    { title: 'Creating Dashboards', key: 'creatingDashboards', url: '/creatingDashboards' },

    {
      title: 'Marketplace',
      category: true,
    },
    { title: 'Publishing Robots On Marketplace', key: 'publishing', url: '/publishing' },
  ]
}

const getDocMenu = async ({ locale }) => {
  const data = await getList('docs', {
    language: locale,
    parent: 'null',
    tree_mode: true,
    order_by: 'created',
    pageSize: 50,
  })
  return data
}
const getDocs = async ({ locale }) => {
  return axios(`${API_URL}/docs/?language=${locale}&page_size=150`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  })
    .then(result => {
      const { results: docs } = result.data
      return {
        success: true,
        docs,
      }
    })
    .catch(error => {
      return { success: false, error }
    })
}
const getDoc = async ({ id, key, locale }) => {
  if (key) {
    const result = await getFirst('docs', { key })
    return result
  }
}
const getLibraryItemDoc = async ({ id }) => {
  return axios(`${API_URL}/libraryitems/${id}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  })
    .then(result => {
      const doc = result.data.current_version.docs
      return {
        success: true,
        doc,
      }
    })
    .catch(error => {
      return { success: false, error }
    })
}
/**
 * Queries API doctypes endpoint to retrive the document types
 */
const getDocTypes = async () => {
  return axios(`${API_URL}/doctypes/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  })
    .then(result => {
      const { results: docTypes } = result.data
      return {
        success: true,
        docTypes,
      }
    })
    .catch(error => {
      return { success: false, error }
    })
}

const createDoc = async ({ locale, data }) => {
  const res = await createRecord('docs', data)
  return res
}
const createDocVersion = async data => {
  const res = await createRecord('docversions', data)
  return res
}

const updateDoc = async ({ id, ...rest }) => {
  const res = await updateRecord('docs', id, rest)
  return res
}

const deleteDoc = async ({ id }) => {
  const res = await deleteRecord('docs', id)
  return res
}

export {
  getDoc,
  getDocs,
  getDocMenu,
  createDocVersion,
  updateDoc,
  getDocTypes,
  createDoc,
  deleteDoc,
  getLibraryItemDoc,
}
