import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  getTodaysTimeSaving,
  getJobStatistics,
  getAllTimeSaving,
  getThisMonthsTimeSaving,
  getLastMonthsTimeSaving,
  getUserTotalTimeSaving,
  getUserScenariosCount,
  getUserJobsCount,
  getRobotTotalTimeSaving,
  getJobrunStatistics,
  getRobotsByStatus,
  getTaskRunsByStatus,
  getJobRunsByStatus,
  getScenariosByPublicity,
} from 'services/statistics'
import actions from './actions'
import {
  convertSavingTimeToHour,
  convertSavingTimeToMinute,
  calculateTimeGainEfficiencyRate,
} from 'services/utilities'

export function* GET_JOB_STATISTICS() {
  yield put({
    type: 'statistics/SET_STATE',
    payload: { loading: true },
  })
  try {
    const { success, data } = yield call(getJobStatistics)
    if (success) {
      const {
        count,
        total_fte,
        total_duration,
        total_savings,
        total_jobruns,
        total_taskruns,
      } = data
      yield put({
        type: 'statistics/SET_STATE',
        payload: {
          job_statistics: {
            count,
            total_fte: convertSavingTimeToMinute(total_fte),
            total_duration: convertSavingTimeToMinute(total_duration),
            total_savings: convertSavingTimeToMinute(total_savings),
            efficiency: calculateTimeGainEfficiencyRate({ total_savings, total_fte }),
            total_jobruns,
            total_taskruns,
          },
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'statistics/SET_STATE',
    payload: { loading: false },
  })
}

export function* GET_JOBRUN_STATISTICS() {
  yield put({
    type: 'statistics/SET_STATE',
    payload: { loading: true },
  })
  try {
    const {
      success,
      data: { status },
    } = yield call(getJobrunStatistics)
    if (success) {
      yield put({
        type: 'statistics/SET_STATE',
        payload: {
          jobrun_by_status: status,
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'statistics/SET_STATE',
    payload: { loading: false },
  })
}

export function* GET_DASHBOARD_STATISTICS() {
  /**
   * Robots - Scenarios - Jobs - Tasks --> Circular Charts
   *
   * Today's saving card
   * All time saving card
   */
  yield put({
    type: 'statistics/SET_STATE',
    payload: { loading: true },
  })
  try {
    const {
      success,
      data: { total_savings },
    } = yield call(getTodaysTimeSaving)
    if (success) {
      yield put({
        type: 'statistics/SET_STATE',
        payload: {
          todays_time_saving: convertSavingTimeToHour(total_savings),
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
  try {
    const {
      success,
      data: { total_savings },
    } = yield call(getAllTimeSaving)
    if (success) {
      yield put({
        type: 'statistics/SET_STATE',
        payload: {
          all_time_saving: convertSavingTimeToHour(total_savings),
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
  try {
    const {
      success,
      data: { total_savings },
    } = yield call(getThisMonthsTimeSaving)
    if (success) {
      yield put({
        type: 'statistics/SET_STATE',
        payload: {
          this_months_saving: convertSavingTimeToHour(total_savings),
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
  try {
    const {
      success,
      data: { total_savings },
    } = yield call(getLastMonthsTimeSaving)
    if (success) {
      yield put({
        type: 'statistics/SET_STATE',
        payload: {
          last_months_saving: convertSavingTimeToHour(total_savings),
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
  try {
    // Robots by status
  } catch (e) {
    console.log(e)
  }
  try {
    const {
      success,
      data: { robotconfig__status: status },
    } = yield call(getRobotsByStatus)
    yield put({
      type: 'statistics/SET_STATE',
      payload: {
        robots: {
          status: success ? status : [],
        },
      },
    })
  } catch (e) {
    console.log(e)
  }
  // Scenarios by publicty
  try {
    const {
      success,
      data: { is_public },
    } = yield call(getScenariosByPublicity)

    if (success) {
      yield put({
        type: 'statistics/SET_STATE',
        payload: {
          scenarios: { is_public },
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
  try {
    // Jobruns by status

    const {
      success,
      data: { status },
    } = yield call(getJobRunsByStatus)
    yield put({
      type: 'statistics/SET_STATE',
      payload: {
        jobruns: {
          status: success ? status : [],
        },
      },
    })
  } catch (e) {
    console.log(e)
  }
/*   try {
    // Taskruns by status
    const {
      success,
      data: { status },
    } = yield call(getTaskRunsByStatus)

    yield put({
      type: 'statistics/SET_STATE',
      payload: {
        taskruns: {
          status: success ? status : [],
        },
      },
    })
  } catch (e) {
    console.log(e)
  } */

  yield put({
    type: 'statistics/SET_STATE',
    payload: { loading: false },
  })
}

export function* GET_USER_STATISTICS({ payload }) {
  const { user_id } = payload

  yield put({
    type: 'statistics/SET_STATE',
    payload: { loading: true },
  })
  try {
    const { success, data } = yield call(getUserTotalTimeSaving, { user_id })
    if (success) {
      const { total_savings } = data
      yield put({
        type: 'statistics/SET_STATE',
        payload: {
          user_total_time_saving: convertSavingTimeToHour(total_savings),
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
  try {
    const { success, data } = yield call(getUserScenariosCount, { user_id })
    const { count: user_scenarios_count } = data
    if (success) {
      yield put({
        type: 'statistics/SET_STATE',
        payload: {
          user_scenarios_count,
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
  try {
    const { success, data } = yield call(getUserJobsCount, { user_id })
    const { count: user_jobs_count } = data
    if (success) {
      yield put({
        type: 'statistics/SET_STATE',
        payload: {
          user_jobs_count,
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'statistics/SET_STATE',
    payload: { loading: false },
  })
}

export function* GET_ROBOT_STATISTICS({ payload }) {
  const { id } = payload
  yield put({
    type: 'statistics/SET_STATE',
    payload: { loading: true },
  })
  try {
    const { success, data } = yield call(getRobotTotalTimeSaving, id)
    if (success) {
      const { total_savings, total_duration, total_taskruns, total_jobruns } = data
      const convertedData = {
        total_time_saving: total_savings ? convertSavingTimeToMinute(total_savings) : 0,
        total_duration: total_duration ? convertSavingTimeToHour(total_duration) : 0,
        total_tasks: total_taskruns || 0,
      }
      yield put({
        type: 'active_robot/SET_STATE',
        payload: { ...convertedData },
      })
    }
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: 'statistics/SET_STATE',
    payload: { loading: false },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_JOB_STATISTICS, GET_JOB_STATISTICS),
    takeEvery(actions.GET_DASHBOARD_STATISTICS, GET_DASHBOARD_STATISTICS),
    takeEvery(actions.GET_USER_STATISTICS, GET_USER_STATISTICS),
    takeEvery(actions.GET_ROBOT_STATISTICS, GET_ROBOT_STATISTICS),
    takeEvery(actions.GET_JOBRUN_STATISTICS, GET_JOBRUN_STATISTICS),
  ])
}
