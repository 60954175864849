import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { getFileList, createDirectory, uploadFile, downloadFile, deleteFile } from 'services/files'
import actions from './actions'
import { fileListFormatter } from 'utils/file'

export function* GET_LIST({ payload }) {
  const { success, data } = yield call(getFileList, payload)

  if (success && data) {
    yield put({
      type: 'files/SET_STATE',
      payload: { loading: false, files: fileListFormatter(data) },
    })
  }
}

export function* CREATE_DIRECTORY({ payload }) {
  const { workspace, id, path, publicFiles } = payload
  const { success } = yield call(createDirectory, { workspace, id, path, publicFiles })
  if (success) {
    yield put({
      type: 'files/GET_LIST',
      payload: { workspace, id, recursive: true, path, publicFiles },
    })
  }
}
/**
 * Expects  workspace, id, path, file
 */
export function* UPLOAD_FILE({ payload }) {
  const {
    settings: { locale },
  } = yield select()

  const { workspace, id, path, file, publicFiles } = payload
  try {
    const renamedFile = new File([file], file.name.toLocaleLowerCase(locale), {
      type: file.type,
    })

    const { success } = yield call(uploadFile, {
      workspace,
      id,
      path,
      file: renamedFile,
      publicFiles,
    })

    if (success) {
      yield put({
        type: 'files/GET_LIST',
        payload: { workspace, id, recursive: true, path, publicFiles },
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* DOWNLOAD_FILE({ payload }) {
  const { workspace, id, file, path, publicFiles } = payload
  const { success } = yield call(downloadFile, { workspace, id, file, publicFiles })

  if (success) {
    yield put({
      type: 'files/GET_LIST',
      payload: { workspace, id, recursive: true, path, publicFiles },
    })
  }
}

export function* DELETE_FILE({ payload }) {
  const { workspace, id, publicFiles } = payload
  const { success } = yield call(deleteFile, payload)

  if (success) {
    yield put({
      type: 'files/GET_LIST',
      payload: { workspace, id, recursive: true, publicFiles },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_LIST, GET_LIST),
    takeEvery(actions.UPLOAD_FILE, UPLOAD_FILE),
    takeEvery(actions.DOWNLOAD_FILE, DOWNLOAD_FILE),
    takeEvery(actions.DELETE_FILE, DELETE_FILE),
    takeEvery(actions.CREATE_DIRECTORY, CREATE_DIRECTORY),
  ])
}
