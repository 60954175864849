import actions from './actions'

const initialState = {
    loading: false,
    processing: false,
    market_items: [],
    active_item: null,
    searchKeyword : null,
}

export default function marketItemsReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_STATE:
            return { ...state, ...action.payload }
        default:
            return state
    }
}
