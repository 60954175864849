import { all, takeEvery, put, call } from 'redux-saga/effects'
// import {  } from 'services/scanario_runner';
import actions from './actions'

export function* SET_JOBS_FILTERS({ payload }) {
  const { jobsTableFilters } = payload
  yield put({
    type: 'scenario_runner/SET_STATE',
    payload: { jobsTableFilters },
  })
}

export function* JOBRUNS_SET_SELECTED_ROWS({ payload }) {
  const { jobrunsTable_SelectedRows } = payload
  yield put({
    type: 'scenario_runner/SET_STATE',
    payload: { jobrunsTable_SelectedRows },
  })
}
export function* JOBS_SET_SELECTED_ROWS({ payload }) {
  const { jobsTable_SelectedRows } = payload
  yield put({
    type: 'scenario_runner/SET_STATE',
    payload: { jobsTable_SelectedRows },
  })
}
export function* TASKRUNS_SET_SELECTED_ROWS({ payload }) {
  const { taskrunsTable_SelectedRows } = payload
  yield put({
    type: 'scenario_runner/SET_STATE',
    payload: { taskrunsTable_SelectedRows },
  })
}
export function* JOBS_SET_EXPANDED_ROWS({ payload }) {
  const { jobsTable_ExpandedRows } = payload
  yield put({
    type: 'scenario_runner/SET_STATE',
    payload: { jobsTable_ExpandedRows },
  })
}

export function* DELETE_JOBS_FILTERS() {
  yield put({
    type: 'scenario_runner/SET_STATE',
    payload: {
      jobsTableFilters: {
        filteredInfo: null,
        sortedInfo: null,
      },
    },
  })
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_JOBS_FILTERS, SET_JOBS_FILTERS),
    takeEvery(actions.DELETE_JOBS_FILTERS, DELETE_JOBS_FILTERS),
    takeEvery(actions.JOBS_SET_SELECTED_ROWS, JOBS_SET_SELECTED_ROWS),
    takeEvery(actions.JOBS_SET_EXPANDED_ROWS, JOBS_SET_EXPANDED_ROWS),
    takeEvery(actions.JOBRUNS_SET_SELECTED_ROWS, JOBRUNS_SET_SELECTED_ROWS),
    takeEvery(actions.TASKRUNS_SET_SELECTED_ROWS, TASKRUNS_SET_SELECTED_ROWS),
  ])
}
