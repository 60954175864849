import BaseModel from "./BaseModel"
import Moment from 'moment'

class PackageOrder extends BaseModel {
  constructor(props) {
    const {
      id,
      package_name,
      package_price,
      billing_price,
      billing_currency,
      package_minutes,
      usage,
      expires,
      invoice,
      organisation,
      created_by
    } = props
    super(props)
    this.name = package_name
    this.price = billing_price || 0.00
    this.currency = billing_currency
    this.minutes = package_minutes
    this.usage = usage
    this.expires = Moment(expires).format('DD.MM.YYYY HH:mm:ss')
    this.invoice = invoice
    this.organization = organisation
    this.created_by = created_by
  }
}

export default PackageOrder
