import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Loader from 'components/layout/Loader'
import { PUBLIC_ROUTES } from 'services/utilities'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import AppLayout from './App'
import MarketplaceLayout from './Marketplace'
import RedirectLayout from './Redirect'
import { App } from 'antd'
import Notification from './Notification'
import { useTour } from '@reactour/tour'
import tourData from 'components/ManagerTourer/tours'
import { Helmet } from 'react-helmet'

const Layouts = {
  public: PublicLayout,
  marketplace: MarketplaceLayout,
  auth: AuthLayout,
  app: AppLayout,
  redirect: RedirectLayout,
}
const mapStateToProps = ({ user, tours, router }) => ({
  user,
  tours,
  router,
})

const Layout = ({
  children,
  router: {
    location: { pathname, search },
  },

  user,
  tours,
}) => {
  // Tour Configuration and Listening

  const { setIsOpen, setSteps } = useTour()
  useEffect(() => {
    window.scrollTo(0, 0)
    if (tours.activeTour.name && !tours.activeTour.finished) {
      setSteps(tourData[tours.activeTour.name].steps)
      setTimeout(() => {
        setIsOpen(true)
      }, 2000)
    } else {
      setIsOpen(false)
      setSteps([])
    }
  }, [tours.activeTour])

  // Layout Rendering
  const getLayout = () => {
    if (window.location.href.includes(`${window.location.host}/marketplace`)) {
      return 'marketplace'
    }
    if (PUBLIC_ROUTES.find(r => pathname.includes(r))) {
      return 'public'
    }
    if (/^\/system(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    if (/^\/redirect(?=\/|$)/i.test(pathname)) {
      return 'redirect'
    }
    return 'app'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading
  const isAuthLayout = !['public', 'marketplace'].some(l => l === getLayout())

  const userRelatedLandingRedirections = () => {
    let redirection = '/dashboard' // Default redirection route
    const role = user?.roles[0]?.name
    switch (role) {
      case 'saasuser':
        redirection = '/market'
        break
      default:
        break
    }
    return redirection
  }
  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized) {
      return <Loader />
    }
    if (pathname === '/') {
      if (isUserAuthorized) {
        return <Redirect to={userRelatedLandingRedirections()} /> // Authorized Landing Page
      }
      return <Redirect to="/marketplace" /> // Non-Authorized Landing Page
    }
    // redirect to login page if current is not login page and user not authorized
    if (isAuthLayout && !isUserAuthorized) {
      return <Redirect to="/system/login/" />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  const isProd = window.location.hostname === 'kronnika.io'

  return (
    <>
      {isProd && (
        <Helmet>
          <script>
            {`
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "o5li03axng");
      `}
          </script>
        </Helmet>
      )}

      <App>{BootstrappedLayout()}</App>
      <Notification />
    </>
  )
}

export default connect(mapStateToProps)(Layout)
