const theme = {
  'body-background': '#ccc',
  'font-size-base': '15px',
  'font-size-sm': '13px',
  'font-size-lg': '17px',

  'font-family': 'DM Sans, sans-serif',
  'code-family': 'Source Sans Pro, sans-serif',

  'border-color-base': '#e4e9f0',

  'background-color-light': '#f2f4f8',
  'background-color-base': '#f2f4f8',

  'primary-color': '#df255aff',
  'secondary-color': '#202b3f',
  'info-color': '#0887c9',
  'success-color': '#46be8a',
  'error-color': '#fb434a',
  'highlight-color': '#fb434a',
  'warning-color': '#f39834',
  'normal-color': '#e4e9f0',

  'input-color': '#161537',
  'input-padding-horizontal': '13px',
  'input-padding-vertical-base': '7px',
  'input-padding-vertical-sm': '11px',
  'input-padding-vertical-lg': '11px',
  'input-border-color': '#e4e9f0',
  'input-hover-border-color': '#0190fe',

  'shadow-color': 'rgba(0,0,0,.15)',
  'box-shadow-base': '0 10px 35px -5px rgba(0,0,0,.15)',
  'shadow-1-up': '0 -10px 35px -5px rgba(0,0,0,.15)',
  'shadow-1-down': '0 10px 35px -5px rgba(0,0,0,.15)',
  'shadow-1-left': '-10px 10px 35px -5px rgba(0,0,0,.15)',
  'shadow-1-right': '10px 0 35px -5px rgba(0,0,0,.15)',
  'shadow-2': '0 0px 35px -5px rgba(0,0,0,.15)',

  'table-header-bg': '#f2f4f8',
  'table-header-sort-bg': '#f2f4f8',
  'table-row-hover-bg': '#e4e9f0',
  'table-padding-vertical': '12px',
  'table-padding-horizontal': '8px',

  'modal-mask-bg': 'rgba(15, 12, 41, 0.5)',

  'layout-body-background': '#fff',
  'layout-header-background': 'transparent',
  'layout-sider-background': 'transparent',

  'layout-content-width': '90%',
}
export default theme
