import { all, takeEvery, put, select } from 'redux-saga/effects'
import actions from './actions'
import toursData from 'components/ManagerTourer/tours'
import { API_URL } from 'index'

export function* ACTIVATE_TOUR({ payload }) {

  if(toursData[payload.name].disabled && API_URL.includes('.io')) {
    return
  }
  const { user } = yield select()
  const { tours: userTours } = user.manager_config
  const tourConfig = userTours[payload.name]
  if (!tourConfig || !tourConfig.finished) {
    if (payload.name === 'welcome') {
      yield put({
        type: 'tours/SET_STATE',
        payload: { welcomeTour: true },
      })
    } else {
      yield put({
        type: 'tours/SET_STATE',
        payload: {
          activeTour: { name: payload.name, steps: toursData[payload.name].steps, currentStep: 0 },
        },
      })
    }
  }
}
export function* START_TOUR({ payload }) {

  if(toursData[payload.name].disabled && API_URL.includes('.io')) {
    return
  }
  yield put({
    type: 'tours/SET_STATE',
    payload: {
      activeTour: { name: payload.name, steps: toursData[payload.name].steps, currentStep: 0 },
    },
  })
}
export function* FINISH_TOUR() {
  const { user, tours: toursData } = yield select()
  const { name: tourName } = toursData.activeTour
  let tours = {}
  if (toursData.welcomeTour) {
    tours = {
      ...user.manager_config.tours,
      welcome: { ...user.manager_config.tours.welcome, finished: true },
    }
  } else {
    tours = {
      ...user.manager_config.tours,
      [tourName]: { ...user.manager_config.tours[tourName], finished: true },
    }
  }
  try {
    yield put({
      type: 'tours/RESET',
    })
  } catch (e) {
    console.log(e)
  }
  try {
    yield put({
      type: 'user/UPDATE_USER',
      payload: {
        user_data: {
          user_id: user.id,
          manager_config: { ...user.manager_config, tours },
        },
      },
      silent: true,
    })
  } catch (e) {
    console.log(e)
  }
}

export function* FORWARD() {
  const { tours } = yield select()
  yield put({
    type: 'tours/SET_STATE',
    payload: { activeTour: { ...tours.activeTour, currentStep: tours.activeTour.currentStep + 1 } },
  })
}

export function* BACK() {
  const { tours } = yield select()
  yield put({
    type: 'tours/SET_STATE',
    payload: { activeTour: { ...tours.activeTour, currentStep: tours.activeTour.currentStep - 1 } },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.ACTIVATE_TOUR, ACTIVATE_TOUR)])
  yield all([takeEvery(actions.START_TOUR, START_TOUR)])
  yield all([takeEvery(actions.FINISH_TOUR, FINISH_TOUR)])
  yield all([takeEvery(actions.FORWARD, FORWARD)])
  yield all([takeEvery(actions.BACK, BACK)])
}
