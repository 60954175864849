import React from 'react'
import TaskNodeInner from './TaskNode/Inner'
import IfNodeInner from './If/Inner'
import WhileNodeInner from './WhileNode/Inner'
import TryCatchNodeInner from './TryCatchNode/Inner'
import ForEachNodeInner from './ForEachNode/Inner'

const NodeInnerCustom = React.forwardRef((props, ref) => {
  let component = <TaskNodeInner ref={ref} {...props} />
  switch (props.node.type) {
    case 'if':
      component = <IfNodeInner {...props} />
      break
    case 'while':
      component = <WhileNodeInner {...props} />
      break
    case 'forEach':
      component = <ForEachNodeInner {...props} />
      break
    case 'tryCatch':
      component = <TryCatchNodeInner {...props} />
      break
    default:
      break
  }
  return component
})

export default NodeInnerCustom
