import { StopOutlined } from '@ant-design/icons'
import { App, Button, Col, Row, Table, Tooltip, Typography } from 'antd'
import ButtonGeneral from 'components/widgets/Buttons/ButtonGeneral'
import { getIntl } from 'localization'
import Job from 'models/Job'
import React from 'react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { updateJob } from 'services/jobs'

const ScheduledJobList = ({ jobs, onAction }) => {
  const { notification } = App.useApp()

  const columns = [
    {
      title: <FormattedMessage id="Name" />,
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: <FormattedMessage id="Created At" />,
      dataIndex: 'created_at',
      key: 'created_at',
    },

    {
      title: <FormattedMessage id="Next Run At" />,
      dataIndex: 'next_run_at',
      key: 'next_run_at',
    },
    {
      title: <FormattedMessage id="Schedule" />,
      dataIndex: 'schedule',
      key: 'schedule',
    },
  ]

  const jobsData = jobs.map(result => new Job(result))

  const cancelSchedules = async jobs => {
    const promises = await Promise.all(
      jobs.map(async job => {
        return new Promise((resolve, reject) => {
          updateJob({ job_id: job.id, data: { is_active: false } })
            .then(({ success }) => {
              resolve(success)
            })
            .catch(e => reject(e))
        })
      }),
    )

    const promiseResult = promises.every(success => success === true)
    return promiseResult
  }
  return (
    <Row gutter={[8, 8]} justify="center">
      <Col span={24}>
        <Table
          size="small"
          columns={columns}
          dataSource={jobsData}
          scroll={{ x: 500 }}
          pagination={false}
        />
      </Col>
      <Col span={24} className="text-center">
        <Typography.Paragraph style={{ fontSize: '1.1em' }}>
          <FormattedHTMLMessage id="dialogs.existingActiveScheduledJobs.content" />
        </Typography.Paragraph>
      </Col>
      <Col span={24}>
        <Row gutter={[32, 32]} justify="center">
          <Col span={6} className="text-center">
            <Button type="primary" block onClick={() => onAction()}>
              {getIntl('dialogs.existingActiveScheduledJobs.cancelButtonText')}
            </Button>
          </Col>
          <Col span={6} className="text-center">
            <Button
              type="primary"
              block
              onClick={async () => {
                const success = await cancelSchedules(jobs)
                if (success) {
                  notification.success({
                    message: getIntl(
                      'notifications.existingActiveScheduledJobs.canceled.success.message',
                    ),
                    description: getIntl(
                      'notifications.existingActiveScheduledJobs.canceled.success.description',
                    ),
                  })
                } else {
                  notification.info({
                    message: getIntl(
                      'notifications.existingActiveScheduledJobs.canceled.info.message',
                    ),
                    description: getIntl(
                      'notifications.existingActiveScheduledJobs.canceled.info.description',
                    ),
                  })
                }
                onAction()
              }}
            >
              {getIntl('dialogs.existingActiveScheduledJobs.okButtonText')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ScheduledJobList
