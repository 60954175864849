import { Col, Divider, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { getTriggerActivities } from 'services/trigger'
import RuleList from './RuleList'
import ButtonGeneral from 'components/widgets/Buttons/ButtonGeneral'
import RuleAddModal from './RuleAddModal'
import { getIntl } from 'localization'

const RuleSelector = ({ selectedRules, onChange, onContinue, onCancel }) => {
  const [addMode, setAddMode] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rules, setRules] = useState([])
  const [_selectedRules, setSelectedRules] = useState(selectedRules)

  useEffect(() => {
    if (addMode) {
      setModalOpen(true)
    }
  }, [addMode])

  const getActivities = () => {
    setLoading(true)
    getTriggerActivities()
      .then(data => {
        setRules(data)
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getActivities()
  }, [])

  const handleSaveFinish = () => {
    setAddMode(false)
    setModalOpen(false)
    getActivities()
  }
  const handleContinue = () => {
    if (_selectedRules.length > 0) {
      onContinue(_selectedRules)
    }
  }
  const handleSelectedChange = rules => {
    onChange(rules)
    setSelectedRules(rules)
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} className="text-right">
        <ButtonGeneral text={getIntl('Add New Rule')} size="sm" onClick={() => setAddMode(true)} />
      </Col>
      <Col span={24} className="text-center">
        <RuleList
          rules={rules}
          selectedRules={_selectedRules}
          onChange={handleSelectedChange}
          onRefresh={getActivities}
          loading={loading}
        />
        <Divider />
      </Col>
      <Col span={24} className="text-right">
        <ButtonGeneral
          text={getIntl('Cancel')}
          type="secondary"
          onClick={onCancel}
          icon="x"
          additionalClass="mr-2"
        />
        <ButtonGeneral
          text={getIntl('Continue')}
          onClick={() => handleContinue()}
          icon="chevrons-right"
          disabled={_selectedRules.length === 0}
        />
      </Col>
      <RuleAddModal open={modalOpen} onFinish={handleSaveFinish} />
    </Row>
  )
}
export default RuleSelector
