import { history } from 'index'
import { getIntl } from 'localization'
import Card from 'models/Card'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getCards, updateCard, deleteCard, createCard } from 'services/cards'
import actions from './actions'

export function* GET_CARDS() {
  yield put({
    type: 'cards/SET_STATE',
    payload: { loading: true },
  })
  try {
    const { success, data } = yield call(getCards)
    if (success) {
      const { results } = data
      const cards = results.map(result => new Card(result))
      const defaultCard = cards.find(card => card.is_default)
      yield put({
        type: 'cards/SET_STATE',
        payload: { cards, defaultCard },
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'cards/SET_STATE',
    payload: { loading: false },
  })
}
export function* UPDATE_CARD({ payload }) {
  yield put({
    type: 'cards/SET_STATE',
    payload: { loading: true },
  })
  const { success, data } = yield call(updateCard, payload)
  if (success) {
    yield put({
      type: 'cards/GET_CARDS',
    })
  }
  yield put({
    type: 'cards/SET_STATE',
    payload: { loading: false },
  })
}
export function* ADD_CARD({ payload }) {
  const { fromNewPackagePage } = payload
  yield put({
    type: 'cards/SET_STATE',
    payload: { loading: true },
  })
  const { success, data } = yield call(createCard, payload)

  if (success) {
    const data = {
      message: getIntl('notifications.cardManager.newCard.success'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
    yield put({
      type: 'cards/GET_CARDS',
    })
  } else {
    const data = {
      message: getIntl('notifications.cardManager.newCard.error'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
  if (fromNewPackagePage === '1') {
    history.replace('/buy-new-package')
    localStorage.setItem('fromNewPackagePage', 0)
  } else {
    history.replace('/payment-methods')
  }
  yield put({
    type: 'cards/SET_STATE',
    payload: { loading: false },
  })
}
export function* DELETE_CARD({ payload }) {
  yield put({
    type: 'cards/SET_STATE',
    payload: { loading: true },
  })
  const { success } = yield call(deleteCard, payload)
  if (success) {
    const data = {
      message: getIntl('notifications.cardManager.deleteCard.success'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
    yield put({
      type: 'cards/GET_CARDS',
    })
  } else {
    const data = {
      message: getIntl('notifications.cardManager.deleteCard.error'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
  yield put({
    type: 'cards/SET_STATE',
    payload: { loading: false },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CARDS, GET_CARDS),
    takeEvery(actions.ADD_CARD, ADD_CARD),
    takeEvery(actions.UPDATE_CARD, UPDATE_CARD),
    takeEvery(actions.DELETE_CARD, DELETE_CARD),
  ])
}
