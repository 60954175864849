import React from 'react'
import NotificationItem from './NotificationItem'
import style from '../style.module.scss'
import { Col, Divider, Empty, Row, Skeleton, Switch, Tooltip, Typography } from 'antd'
import { EyeOutlined, SettingOutlined } from '@ant-design/icons'
import { getIntl } from 'localization'
import { history } from 'index'
import { connect } from 'react-redux'

const NotificationList = ({
  notifications,
  loading,
  settings: { notifications_showOnlyUnread },
  locale,
  actions,
  dispatch,
}) => {
  const handleShowOnlyUnread = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'notifications_showOnlyUnread',
        value: !notifications_showOnlyUnread,
      },
    })
  }

  return (
    <div className="card air__utils__shadow width-400">
      <div className="card-body p-3">
        <div className="text-gray-6" style={{ overflowY: 'hidden' }}>
          <ul className="list-unstyled">
            <li className={style.listItemHeader}>
              <div>
                <Row justify="space-between">
                  <Col span={8}>
                    <Typography.Link
                      onClick={() => {
                        history.push('/notifications')
                        actions.closeMenu()
                      }}
                      className={style.showAll}
                    >
                      {getIntl('notificationMenu.showAll')}
                    </Typography.Link>
                  </Col>
                  <Col span={14} className="text-right">
                    <Typography.Link className={style.allreadlink} onClick={actions.markAllAsRead}>
                      <EyeOutlined style={{ fontSize: '1em', cursor: 'pointer' }} />{' '}
                      {getIntl('notificationMenu.markAllAsRead')}
                    </Typography.Link>
                  </Col>
                  <Col span={2} className="text-right">
                    <Tooltip title={getIntl('Settings')}>
                      <SettingOutlined
                        className={style.settingsIcon}
                        onClick={() => {
                          history.push('/notifications?view=settings')
                          actions.closeMenu()
                        }}
                      />
                    </Tooltip>
                  </Col>
                  <Col span={24} className="text-right">
                    <Typography.Link
                      onClick={handleShowOnlyUnread}
                      className={style.showOnlyUnread}
                    >
                      {getIntl('notificationMenu.showOnlyUnread')}
                    </Typography.Link>
                    <Switch
                      size="small"
                      checked={notifications_showOnlyUnread}
                      onChange={handleShowOnlyUnread}
                    />
                  </Col>
                </Row>
              </div>
            </li>
            <Divider className="mt-1 mb-1" />
            {loading ? (
              <Skeleton active />
            ) : (
              <div style={{ height: 300, overflowY: 'scroll' }}>
                {notifications && notifications.length > 0 ? (
                  notifications.map(notification => (
                    <NotificationItem
                      key={notification.key}
                      data={notification}
                      fetchNotifications={actions.getNotifications}
                      locale={locale}
                    />
                  ))
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={getIntl(
                      notifications_showOnlyUnread
                        ? 'notificationMenu.noUnreadNotifications'
                        : 'notificationMenu.noNotifications',
                    )}
                  />
                )}
              </div>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = ({ settings }) => ({ settings })
export default connect(mapStateToProps)(NotificationList)
