import ButtonGeneral from 'components/widgets/Buttons/ButtonGeneral'
import { history } from 'index'
import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'

const NewQueueButton = ({ pathname }) => {
  return pathname === '/queues' ? (
    <ButtonGeneral
      onClick={() => history.push('/queues/new-queue')}
      text={<FormattedHTMLMessage id="queues.addNewQueue" />}
    />
  ) : null
}

export default NewQueueButton
