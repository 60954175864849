import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { getIntl } from 'localization'
import { history } from 'index'
import Organization from 'models/Organization'
import actions from './actions'
import {
  createNewOrganization,
  getOrganizations,
  getOrganizationByID,
  updateOrganization,
  updateOrganizationLogo,
  changeSubscriptionPlan,
} from 'services/organizations'

export function* GET_ORGANIZATIONS({ payload }) {
  yield put({
    type: 'organizations/SET_STATE',
    payload: { loading: true },
  })
  const { success, data } = yield call(getOrganizations, payload)
  if (success) {
    const { results, count } = data
    const organizationsData = results.map(organization => new Organization(organization))
    yield put({
      type: 'organizations/SET_STATE',
      payload: {
        organizations: organizationsData,
        count,
        loading: false,
      },
    })
  }
}

export function* GET_ORGANIZATION_BY_ID({ payload }) {
  yield put({
    type: 'organizations/RESET',
  })
  yield put({
    type: 'organizations/SET_STATE',
    payload: { loading: true },
  })

  const { organization_id } = payload
  const { success, data } = yield call(getOrganizationByID, { organization_id })

  if (success) {
    const active_organization = new Organization(data)
    yield put({
      type: 'organizations/SET_STATE',
      payload: {
        active_organization,
        loading: false,
      },
    })
    // Organization Suspension Check
    const suspendWarningSeen = localStorage.getItem('utils.suspendWarningSeen') === 'true'
    if (active_organization.is_suspended && !suspendWarningSeen) {
      yield put({
        type: 'organizations/SET_STATE',
        payload: { suspendDialogOpen: true },
      })
    } 
    if(active_organization.is_suspended === false) {
      localStorage.setItem('utils.suspendWarningSeen', 'false')
    }
    // Organization Suspension Check *
  }
  yield {
    type: 'organizations/SET_STATE',
    payload: { loading: false },
  }
}

export function* CREATE_NEW_ORGANIZATION({ payload }) {
  const organization_data = { ...payload }

  yield {
    type: 'organizations/SET_STATE',
    payload: { loading: true },
  }
  const { success, organization } = yield call(createNewOrganization, { organization_data })
  if (success) {
    history.push(`/organization-management/manage-organization/${organization.id}`)
    const data = {
      message: getIntl('notification.organization_create.message.success'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
  } else {
    const data = {
      message: getIntl('notification.organization_create.message.fail'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
  yield {
    type: 'organizations/SET_STATE',
    payload: { loading: false },
  }
}

export function* UPDATE_ORGANIZATION({ payload }) {
  yield {
    type: 'organizations/SET_STATE',
    payload: { loading: true },
  }
  const { organization_data, organization_id } = payload
  const { success, organizations } = yield call(updateOrganization, {
    organization_data,
    organization_id,
  })
  if (success) {
    yield put({
      type: 'organizations/GET_ORGANIZATION_BY_ID',
      payload: { organization_id },
    })
    const data = {
      message: getIntl('notification.organization_update.message.success'),
      description: getIntl('notification.organization_update.detail.success'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
    const { router } = yield select()
    const { query } = router?.location

    if (query && 'cardManager' in query) {
      history.push('/payment-methods')
    }
  } else {
    yield {
      type: 'organizations/SET_STATE',
      payload: { loading: false },
    }
    const data = {
      message: getIntl('notification.organization_update.message.fail'),
      description: getIntl('notification.organization_update.detail.fail'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
}

export function* UPDATE_ORGANIZATION_LOGO({ payload }) {
  yield {
    type: 'organizations/SET_STATE',
    payload: { loading: true },
  }
  const { logo, organization_id } = payload
  const { success, organizations } = yield call(updateOrganizationLogo, { logo, organization_id })

  if (success) {
    yield put({
      type: 'organizations/GET_ORGANIZATION_BY_ID',
      payload: { organization_id },
    })
  } else {
    yield {
      type: 'organizations/SET_STATE',
      payload: { loading: false },
    }
  }
}
export function* CHANGE_SUBSCRIPTION_PLAN({ payload }) {
  yield {
    type: 'plans/SET_STATE',
    payload: { loading: true },
  }
  const { success, data } = yield call(changeSubscriptionPlan, payload)

  if (success) {
    const data = {
      message: getIntl('notifications.subscriptionChange.success'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'success',
      },
    })
    setTimeout(() => window.location.reload(), 3000)
  } else {
    const data = {
      message: getIntl('notifications.subscriptionChange.error'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
  yield {
    type: 'plans/SET_STATE',
    payload: { loading: false },
  }
}

export default function* rootSaga() {
  yield all([
    // GET_ORGANIZATIONS(),
    takeEvery(actions.GET_ORGANIZATIONS, GET_ORGANIZATIONS),
    takeEvery(actions.GET_ORGANIZATION_BY_ID, GET_ORGANIZATION_BY_ID),
    takeEvery(actions.CREATE_NEW_ORGANIZATION, CREATE_NEW_ORGANIZATION),
    takeEvery(actions.UPDATE_ORGANIZATION, UPDATE_ORGANIZATION),
    takeEvery(actions.CHANGE_SUBSCRIPTION_PLAN, CHANGE_SUBSCRIPTION_PLAN),
    takeEvery(actions.UPDATE_ORGANIZATION_LOGO, UPDATE_ORGANIZATION_LOGO),
  ])
}
