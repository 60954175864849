import { cloneDeep } from 'lodash'

let activeScenario

const initActiveScenario = {
  id: null,
  name: null,
  content: {
    scenario_parameters: [],
    tasks: [],
    chart: {
      offset: {
        x: 0,
        y: 0,
      },
      nodes: {},
      links: {},
      selected: {},
      hovered: {},
      picked: {
        nodes: [],
        links: [],
        ports: [],
      },
    },
  },
  flow_data: {},
  robots: [],
}

export function setActiveScenario(payload) {
  activeScenario = payload
  return activeScenario
}
/*
export function setActiveScenarioJSON({ active_scenario, code }) {
  active_scenario.content = code
  return active_scenario;
}
*/

export function setActiveScenarioStep(payload) {
  const { selectedTask, selectedStep, formData } = payload
  activeScenario.content.chart.nodes[selectedTask].properties.detail.chart.nodes[
    selectedStep
  ].properties.detail.formData = formData
  return activeScenario
}
export function setActiveScenarioRobots(payload) {
  const { active_scenario, robots } = payload
  active_scenario.robots = robots
  return active_scenario
}
export function resetActiveScenario({ active_scenario }) {
  active_scenario = initActiveScenario
  return active_scenario
}
export function getActiveScenario() {
  return activeScenario
}

export function setScenarioParameters(payload) {
  const { active_scenario, parameters } = payload
  const scenario = cloneDeep(active_scenario)
  scenario.content.scenario_parameters = parameters
  return scenario
}
