import { createRecord, getList } from 'api/requests'

const getPackageOrders = async filters => {
  const data = await getList('packageorders', filters)
  return data
}
const addPackageOrder = async ({ id }) => {
  const data = await createRecord('packageorders', { package: id })
  return data
}

export { getPackageOrders, addPackageOrder }
