import { Divider } from 'antd'
import { getIntl } from 'localization'
import React from 'react'

export const dividerComponent = () => (
  <Divider className="w-100" style={{ padding: 0, margin: 0 }} />
)

export const defaultComponent = ({ className, text }) => (
  <div className={`w-100 ${className}`}>{getIntl(text)}</div>
)

export const headerComponent = ({ className, text }) => (
  <div className={`w-100 ${className}`}>{getIntl(text)}</div>
)

export const categoryComponent = ({ className, key, type, text }) => (
  <a className={`w-100 ${className}`} href={`/marketplace?category=${key}` || '#'}>
    {getIntl(text)}
  </a>
)
export const linkComponent = ({ className, link, text, newPage }) => (
  <a
    className={`w-100 ${className}`}
    href={getIntl(link)}
    target={newPage ? '_blank' : undefined}
    rel="noopener noreferrer"
  >
    {getIntl(text)}
  </a>
)
