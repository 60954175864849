const actions = {
  SET_STATE: 'scenario_editor/SET_STATE',
  CHANGE_SCENE: 'scenario_editor/CHANGE_SCENE',
  CHANGE_TAB: 'scenario_editor/CHANGE_TAB',
  SET_SCENARIO_TO_CATALOG_MODAL_VISIBILITY:
    'scenario_editor/SET_SCENARIO_TO_CATALOG_MODAL_VISIBILITY',
  CLIPBOARD_COPY: 'scenario_editor/CLIPBOARD_COPY',
  CLIPBOARD_PASTE: 'scenario_editor/CLIPBOARD_PASTE',
  CLEAR_DEBUG_FLAGS: 'scenario_editor/CLEAR_DEBUG_FLAGS',
}

export default actions
