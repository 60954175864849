import Icon, { FileProtectOutlined, LockOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import VaultIcon from 'components/CustomIcons/Vault'
import { getIntl } from 'localization'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import VaultModal from './VaultModal'

const mapStateToProps = ({ active_scenario, router, vaults, settings }) => ({
  active_scenario,
  router,
  vaults,
  settings,
})

const VaultScenarioDesignerMenu = ({ active_scenario, router, vaults }) => {
  const pathData = router.location.pathname
  const [vaultModalVisibility, setVaultModalVisibility] = useState(false)

  const handleClick = () => {
    setVaultModalVisibility(true)
  }
  const handleCancel = () => {
    setVaultModalVisibility(false)
  }

  if (pathData.includes('scenario-designer') && active_scenario.id) {
    return (
      <div data-tour="vaultButton">
        <Button onClick={handleClick} icon={<VaultIcon />}>
          {getIntl('Vaults')}
        </Button>
        <VaultModal
          vaults={vaults}
          visibility={vaultModalVisibility}
          setVisibility={setVaultModalVisibility}
          onCancel={handleCancel}
        />
      </div>
    )
  }
  return <></>
}
export default connect(mapStateToProps)(VaultScenarioDesignerMenu)
