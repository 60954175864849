import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';


export function* SET_CRUMBS({ payload }) {
	yield put({
		type: 'breadcrumbs/SET_STATE',
		payload: { breadcrumbs : []}
	});
	yield put({
		type: 'breadcrumbs/SET_STATE',
		payload: { breadcrumbs: payload }
	});
}

export default function* rootSaga() {
	yield all([ takeEvery(actions.SET_CRUMBS, SET_CRUMBS) ]);
}
