import axios from 'utils/axiosGlobal'
import FileSaver from 'file-saver'
import { API_URL } from 'index'
import { handleAPIError } from 'services/errors'

const showError = error => {
  handleAPIError(error)
}

export const headers = (anonymous = false) => ({
  Authorization: anonymous ? undefined : `Bearer ${localStorage.getItem('access')}`,
})

const paramConverter = params => {
  if (!params) return null

  const convertedParameters = {}

  const convertableParameters = {
    pageSize: 'page_size',
    catalog_item: 'catalogitem',
    with_deleted: 'all_with_deleted',
    action_level: 'action__level',
    notification_model: 'action_name',
    notification_type: 'action_name',
    notification_state: 'action_name',
    organization: 'organisation',
    organization_name: 'organisation_name',
  }

  Object.keys(params).forEach(key => {
    Object.assign(convertedParameters, { [convertableParameters[key] || key]: params[key] })
  })

  return convertedParameters
}

export const getContinuousList = async (model, filters = null, nextUrl) => {
  const continuousUrl = nextUrl ? nextUrl.split('?')[0] : null
  const params = {}
  if (continuousUrl) {
    const url = new URL(nextUrl)
    const searchParams = new URLSearchParams(url.search)
    searchParams.forEach((value, key) => {
      Object.assign(params, { [key]: value })
    })
  }
  const url = `${API_URL}/${model}/`

  try {
    const response = await axios(continuousUrl || url, {
      method: 'GET',
      headers: headers(),
      params: paramConverter({ ...filters, ...params }),
    })
    const { data } = response
    return {
      success: true,
      data,
    }
  } catch (error) {
    showError(error)
    return { success: false }
  }
}

export const getList = async (model, filters = null, anonymous = false, tail = '/') => {
  const url = `${API_URL}/${model}${tail}`
  const config = {
    method: 'GET',
    headers: headers(anonymous),
    params: paramConverter(filters),
  }
  try {
    const response = await axios(url, config)
    const { data } = response
    return {
      success: true,
      data,
    }
  } catch (error) {
    showError(error)
    return { success: false }
  }
}

export const getOneByID = async (model, id, filters = null, tail = '/', anonymous = false) => {
  const url = `${API_URL}/${model}/${id}${tail}`
  try {
    const response = await axios(url, {
      method: 'GET',
      headers: headers(anonymous),
      params: paramConverter(filters),
    })
    const { data } = response
    return {
      success: true,
      data,
    }
  } catch (error) {
    showError(error)
    return { success: false, error }
  }
}

export const getFileByPath = async (
  model,
  path,
  file,
  filters = null,
  anonymous = false,
  save = true,
) => {
  const url = [API_URL, model, path, file].filter(d => d).join('/')
  try {
    const response = await axios(url, {
      method: 'GET',
      headers: headers(anonymous),
      responseType: 'blob',
      params: paramConverter(filters),
    })
    const { data } = response
    if (save) {
      FileSaver.saveAs(data, file)
    }
    return {
      success: true,
      data,
    }
  } catch (error) {
    showError(error)
    return { success: false }
  }
}

export const getFirst = async (model, filters = null, anonymous = false) => {
  const url = `${API_URL}/${model}/`
  try {
    const response = await axios(url, {
      method: 'GET',
      headers: headers(anonymous),
      params: paramConverter(filters),
    })
    const { data } = response
    if (data.results.length === 0) return { success: false, data: false }
    return {
      success: true,
      data: data.results[0],
    }
  } catch (error) {
    showError(error)
    return { success: false }
  }
}

export const createRecord = async (
  model,
  payload,
  tail = '/',
  anonymous = false,
  options = { showError: true },
) => {
  const url = `${API_URL}/${model}${tail}`
  try {
    const response = await axios(url, {
      method: 'POST',
      headers: headers(anonymous),
      data: payload,
    })
    const { data } = response
    return {
      success: true,
      data,
    }
  } catch (error) {
    if (options?.showError) {
      showError(error)
    }
    return { success: false, error }
  }
}

export const updateRecord = async (model, id, payload, anonymous = false) => {
  const url = `${API_URL}/${model}/${id}/`
  try {
    const response = await axios(url, {
      method: 'PATCH',
      headers: headers(anonymous),
      data: payload,
    })
    const { data } = response
    return {
      success: true,
      data,
    }
  } catch (error) {
    showError(error)
    return { success: false }
  }
}

export const putRecord = async (model, id, payload, args, anonymous = false) => {
  const url = `${API_URL}/${model}/${id}/${args}/`
  try {
    const response = await axios(url, {
      method: 'PUT',
      headers: headers(anonymous),
      data: payload || undefined,
    })
    const { data } = response
    return {
      success: true,
      data,
    }
  } catch (error) {
    showError(error)
    return { success: false, error: error.response?.data || error }
  }
}

export const deleteRecord = async (model, id, tail = '/', anonymous = false) => {
  const url = `${API_URL}/${model}/${id}${tail}`
  try {
    const response = await axios(url, {
      method: 'DELETE',
      headers: headers(anonymous),
    })
    return {
      success: true,
    }
  } catch (error) {
    showError(error)
    return { success: false, error }
  }
}

export const requestFromURL = async (url, method = 'GET', payload, anonymous) => {
  try {
    const response = await axios(url, {
      method,
      headers: headers(anonymous),
      data: payload,
    })
    const { data } = response
    return {
      success: true,
      data,
    }
  } catch (error) {
    showError(error)
    return { success: false }
  }
}

export const uploadFileRequest = async (model, payload, anonymous) => {
  const url = `${API_URL}/${model}`
  try {
    const preHeaders = headers(anonymous)
    const response = await axios(url, {
      method: 'POST',
      headers: { ...preHeaders, 'Content-Type': 'multipart/form-data' },
      data: payload,
    })
    const { data } = response
    return {
      success: true,
      data,
    }
  } catch (error) {
    console.log(error)
    return { success: false, error }
  }
}
