import { ConsoleSqlOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, message } from 'antd'
import { getIntl } from 'localization'
import React, { useState } from 'react'

import style from './style.module.scss'

const SearchBox = ({
  value,
  onChange,
  onSearch,
  onClear,
  placeholder,
  size = 'medium',
  width,
  style: customStyle,
  buttonStyle,
  loading,
}) => {
  const [_value, setValue] = useState(value || '')

  const handleChange = e => {
    if (onChange) onChange(e.target.value)
    if (onClear && e.target.value === '' && _value.trim().length > 0) {
      onClear()
    }
    setValue(e.target.value)
  }
  const handleSearch = e => {
    if (!_value) return false
    if (_value.trim().length < 3) {
      message.info(getIntl('messages.searchBox.min3letters'), 3)
      return false
    }
    if (onSearch) onSearch(_value)
  }

  return (
    <Input
      className={style.searchBox}
      style={{ paddingLeft: '1em', ...customStyle }}
      placeholder={placeholder ? getIntl(placeholder) : ''}
      allowClear
      suffix={
        <Button
          style={buttonStyle?.style || undefined}
          type={buttonStyle?.type || 'primary'}
          shape="circle"
          className="ml-3"
          onClick={onSearch}
          icon={<SearchOutlined />}
          loading={loading}
        />
      }
      size={size}
      onChange={handleChange}
      onPressEnter={handleSearch}
      value={_value}
      width={width}
    />
  )
}

export default SearchBox
