import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { FormattedHTMLMessage } from 'react-intl'

const mapStateToProps = ({ user }) => ({ user })

const ResetLoginButton = ({ user, dispatch }) => {
  const resetLogin = e => {
    e.preventDefault()
    dispatch({
      type: 'user/RESET_LOGIN_AS',
    })
  }

  return (
    <Button type="default" onClick={resetLogin}>
      <FormattedHTMLMessage id="topBar.loggedAsButton" values={{ username: user.email }} />
    </Button>
  )
}
export default connect(mapStateToProps)(ResetLoginButton)
