import styled, { css } from 'styled-components'

export const CanvasOuterCustom = styled.div`
  position: relative;
  background-size: 30px 30px;
  background-color: #d0d0d0;
  background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0.3) 1px, transparent 0),
    linear-gradient(180deg, hsla(0, 0%, 100%, 0.3) 1px, transparent 0);
  width: 10000px;
  height: 10000px;
`
export const CanvasInnerCustom = styled.div`
  position: relative;
  outline: 1px dashed rgba(0, 0, 0, 0.1);
  width: 10000px;
  height: 10000px;
  ${props =>
    props.config.nodeAddMode
      ? css`
          cursor: copy;
        `
      : css`
          cursor: default;
        `}
`
