import moment from 'moment'
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { getLogs } from 'services/logs'
import actions from './actions'

export function* CLEAR_LOGS() {
  yield put({
    type: 'logs/SET_STATE',
    payload: { last_log_date: null, logs: [], tasks: {}, page: 1, jobrun: null },
  })
}
export function* GET_LOGS({ payload }) {
  yield put({
    type: 'logs/SET_STATE',
    payload: { loading: true },
  })

  const { scope, jobrunId, id } = payload

  const { logs: current_log_state } = yield select()

  const { success, logs } = yield call(getLogs, {
    scope,
    id,
    jobrunId,
    last_log_date: current_log_state.last_log_date,
  })
  if (success) {
    const last_log = logs.pop()
    if (last_log) {
      const last_record_date = moment(last_log.created).format('YYYY-MM-DD HH:mm:ss')

      if (current_log_state.last_log_date !== last_record_date) {
        yield put({
          type: 'logs/SET_STATE',
          payload: {
            logs: [...current_log_state.logs, ...logs],
            loading: false,
            last_log_date: last_record_date,
          },
        })
      }
    }
  }
}
export default function* rootSaga() {
  yield all([takeEvery(actions.GET_LOGS, GET_LOGS), takeEvery(actions.CLEAR_LOGS, CLEAR_LOGS)])
}
