import { App, Button, Col, Modal, Row, Select } from 'antd'
import { getList, getOneByID } from 'api/requests'
import VaultForm from 'components/widgets/Vault/components/VaultForm'
import { store } from 'index'
import Vault from 'models/Vault'
import React, { useEffect, useState } from 'react'
import { FormattedHTMLMessage } from 'react-intl'

const VaultSelector = ({ onChange, value, vaultKeys }) => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [vaultModalOpen, setVaultModalOpen] = useState(false)
  const { modal } = App.useApp()

  const getVaults = async () => {
    setLoading(true)

    const { success, data } = await getList('vault', { pageSize: 100 })

    if (success) {
      const modelData = data.results.map(r => new Vault(r))
      const formedData = modelData.map(r => ({ value: r.id, label: r.name }))
      setOptions(formedData)
    }
    setLoading(false)
  }

  const handleCreate = data => {
    store.dispatch({
      type: 'vaults/CREATE_VAULT',
      payload: {
        data,
        modal,
        onFinish: vaultId => {
          setVaultModalOpen(false)
          getVaults().then(() => {
            onChange(vaultId)
          })
        },
      },
    })
  }

  useEffect(() => {
    getVaults()
  }, [])

  return (
    <Row>
      <Col span={18}>
        <Select
          showSearch
          allowClear
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          options={options}
          onChange={onChange}
          value={loading ? null : value}
        />
      </Col>
      <Col span={6}>
        <Button block onClick={() => setVaultModalOpen(true)}>
          <FormattedHTMLMessage id="triggers.createNewVault" />
        </Button>
        <Modal closeIcon={false} open={vaultModalOpen} footer={null}>
          <VaultForm
            onCreate={handleCreate}
            handleCancel={() => setVaultModalOpen(false)}
            predefinedValues={vaultKeys.map(vaultKey => ({
              key_name: vaultKey,
              key: vaultKey,
              value: '',
            }))}
          />
        </Modal>
      </Col>
    </Row>
  )
}

export default VaultSelector
