/* eslint-disable import/prefer-default-export */
const getNews = () => {
  return {
    "en-US": [
      {
        id: 1,
        title: 'Kronnika 2.0 has been released 🎉',
        link: '#',
        category: 'Releases',
        content:
          'No code / Low code scenario designer, user & team permissions, scenario & robot sharing... Kronnika v2 has a lot of new features',
      },
      {
        id: 2,
        title: 'Kronnika is at Product Hunt',
        link: 'https://www.producthunt.com/posts/kronnika-io',

        category: 'News',
        content: '<a href="https://www.producthunt.com/posts/kronnika-io?utm_source=badge-review&utm_medium=badge&utm_souce=badge-kronnika-io#discussion-body" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/review.svg?post_id=291563&theme=light" alt="Kronnika.io - Low-code robotic process automation tool | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a><br/><br/><iframe style="border: none;" src="https://cards.producthunt.com/cards/posts/291563?v=1" width="500" height="405" frameborder="0" scrolling="no" allowfullscreen></iframe>',
      },
    ],
    "tr-TR": [
      {
        id: 1,
        title: 'Kronnika 2.0 yayınlandı 🎉',
        link: '#',
        category: 'Yayınlar',
        content:
          'Kod yok / düşük kod senaryo tasarlayıcısı, kullanıcı & ekip yetkilendirme, senaryo & robot paylaşımı... Kronnika v2 yepyeni özelliklere sahip',
      },
      {
        id: 2,
        title: 'Kronnika Product Hunt\'ta',
        link: 'https://www.producthunt.com/posts/kronnika-io',
        category: 'Haberler',
        content: '<a href="https://www.producthunt.com/posts/kronnika-io?utm_source=badge-review&utm_medium=badge&utm_souce=badge-kronnika-io#discussion-body" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/review.svg?post_id=291563&theme=light" alt="Kronnika.io - Low-code robotic process automation tool | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a><br/><br/><iframe style="border: none;" src="https://cards.producthunt.com/cards/posts/291563?v=1" width="500" height="405" frameborder="0" scrolling="no" allowfullscreen></iframe>',
      },
    ]
  }
}

export { getNews }
