
import BaseModel from 'models/BaseModel'
import Moment from 'moment'
import { sumObjectsKeyValue } from 'services/utilities'

class Invoice extends BaseModel {
  constructor(props) {
    const {
      total,
      currency,
      is_paid,
      paid,
      invoiceitems,
      payment_ref,
      payment_method,
      payment_remarks,
      number_of_attempts,
      last_error_message,
      last_attempt,
      billing_address,
      billing_city,
      billing_country,
      billing_state,
      billing_zipcode,
      billing_phone,
      tax_number,
      tax_office,
      created,
      invoice_file,
      invoice_number // This number is official invoice id
    } = props

    super(props)
    this.currency = currency
    this.is_paid = is_paid
    this.paid = paid
    this.payment_ref = payment_ref
    this.payment_method = payment_method
    this.payment_remarks = payment_remarks
    this.number_of_attempts = number_of_attempts
    this.items = invoiceitems.map(item => new InvoiceItem(item))

    this.country = billing_country
    this.address = billing_address
    this.state = billing_state
    this.city = billing_city
    this.zipcode = billing_zipcode
    this.phone = billing_phone
    this.tax_number = tax_number
    this.tax_office = tax_office

    this.total = parseFloat(total)
    this.tax = sumObjectsKeyValue(this.items, 'tax')
    this.grand_total = this.total + this.tax

    this.last_error_message = last_error_message
    this.last_attempt = Moment(last_attempt).format('DD.MM.YYYY HH:mm:ss')
    this.created_at = Moment(created).format('DD.MM.YYYY')

    this.invoiceFile = invoice_file
    this.invoiceNumber = invoice_number
  }
}

class InvoiceItem extends BaseModel {
  constructor(props) {
    const { invoice, cost, quantity, tax, tax_rate, total } = props
    super(props)
    this.invoice = invoice
    this.cost = cost
    this.quantity = quantity
    this.tax = tax
    this.tax_rate = tax_rate
    this.total = total
  }
}
export { Invoice, InvoiceItem }
