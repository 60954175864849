import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dropdown, Badge, Tooltip, Button } from 'antd'
import styles from './style.module.scss'
import { getIntl } from 'localization'
import CurrentPlanModal from 'pages/subscription-plans/CurrentPlanModal'
import { Link } from 'react-router-dom'
import { WarningOutlined } from '@ant-design/icons'
import { FormattedHTMLMessage } from 'react-intl'
import { getPlanById } from 'services/plans'
import Plan from 'models/Plan'

const mapStateToProps = ({ organizations, user, settings }) => ({ organizations, user, settings })

const UsageMenu = ({ organizations, user, settings }) => {
  const [currentPlanModalVisibility, setCurrentPlanModalVisibility] = useState(false)
  const [plan, setPlan] = useState(null)
  const { usage } = user

  const getPlanData = async planId => {
    const planData = await getPlanById(planId)
    if (planData.success) {
      setPlan(new Plan(planData.data))
    }
  }
  useEffect(() => {
    getPlanData(usage.plan.id)
  }, [])

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case 'currentSubscriptionPlan':
        setCurrentPlanModalVisibility(true)
        break

      default:
        break
    }
  }
  const items = [
    {
      key: 'currentSubscriptionPlan',
      label: (
        <>
          <strong>{`${getIntl('topBar.usageMenu.currentSubscriptionPlan')} : `}</strong>
          {`${plan?.extra_info[settings.locale].name || usage.name}`}
        </>
      ),
    },
    { type: 'divider' },
    {
      key: 'freeUsage',
      label: (
        <>
          {' '}
          <strong>{`${getIntl('topBar.usageMenu.freeUsage')} : `}</strong>
          {`${usage.free_usage} / ${usage.free_minutes} ${getIntl('credit')}`}
        </>
      ),
    },
    {
      key: 'packageUsage',
      label: (
        <>
          <strong>{`${getIntl('topBar.usageMenu.packageUsage')} : `}</strong>
          {`${usage.total_package_usage} / ${usage.total_package_minutes} ${getIntl('credit')}`}
        </>
      ),
    },
    { type: 'divider' },

    {
      key: 'robots',
      label: (
        <>
          <strong>{`${getIntl('Robots')} : `}</strong> -/-
        </>
      ),
    },
    {
      key: 'scenario',
      show: user.roles[0].name !== 'saasuser',
      label: (
        <>
          <strong>{`${getIntl('Scenario')} : `}</strong> -/-
        </>
      ),
    },
    { type: 'divider' },
    {
      key: 'buyNewPackage',
      label: (
        <>
          <Link to="/buy-new-package" className={styles.actionLink}>
            <i className={`${styles.menuIcon} fe fe-shopping-cart`} />
            {getIntl('topBar.usageMenu.buyNewPackage')}
          </Link>
        </>
      ),
    },
  ]
  return (
    <div data-tour="usageMenu">
      <Dropdown
        menu={{
          items: items.filter(item => item.show !== false).map(({ show, ...item }) => item),
          onClick: handleMenuClick,
        }}
        trigger={['click']}
        onOpenChange={() => {}}
      >
        <div className={styles.dropdown}>
          {organizations.active_organization.is_suspended && (
            <Tooltip title={<FormattedHTMLMessage id="dialogs.suspendedOrganization.content" />}>
              <Button type="text" shape="circle" danger icon={<WarningOutlined />} />
            </Tooltip>
          )}
          <Badge count={null}>{getIntl('Subscription & Usage')}</Badge>
        </div>
      </Dropdown>
      <CurrentPlanModal
        visibility={currentPlanModalVisibility}
        setVisibility={setCurrentPlanModalVisibility}
        plan={plan}
      />
    </div>
  )
}

export default connect(mapStateToProps)(UsageMenu)
