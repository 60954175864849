import { headers } from 'api/requests'
import axios from 'utils/axiosGlobal'
import { API_URL } from 'index'
import { ROBOT_MANAGER_WORKER_URL } from 'constantValues'

/**
 * Checks robot manager availability on localhost
 */
const checkRobotManager = () => {
  return axios
    .get(ROBOT_MANAGER_WORKER_URL)
    .then(res => {
      if (res.status === 200) {
        return true
      }
    })
    .catch(err => {
      // console.log(err)
    })
}

/**
 * Checks if robot available on local robot manager
 * Makes a request to local robot manager and searches the array of robots without checking its status
 * @param {string} robot_name - Look up robot name
 */

const checkRobotStatus = robot_name => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${ROBOT_MANAGER_WORKER_URL}/api/robots/`)
      .then(res => {
        const robots = res.data
        const robot_available = robots.some(robot => robot.name === robot_name) // TODO [LOW] we may need additional online status checking
        resolve(robot_available)
      })
      .catch(err => {
        resolve(false)
      })
  })
}

const getRobot = robot_id => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/robots/${robot_id}`, {
        headers: headers(),
      })
      .then(res => {
        const robot = res.data

        resolve(robot)
      })
      .catch(err => {
        resolve(false)
      })
  })
}
export const getLocalRobots = async () => {
  return new Promise((resolve, reject) => {
    // setTimeout(() => { }, 2000)
    axios
      .get(`${ROBOT_MANAGER_WORKER_URL}/api/robots/`)
      .then(({ data }) => resolve({ success: true, data }))
      .catch(error => {
        resolve({ success: false })
      })
  })
}

export const handleReadyRobots = (localRobots, selectedRobots, setRobotList) => {
  /* const upRobots = localRobots.filter(robot => robot.status === 'UP')
  const readyRobotFiltered = upRobots.filter(ur => selectedRobots.some(sr => sr.name === ur.name))
  */
  const readyRobotFiltered = selectedRobots
  return readyRobotFiltered
}
const addRobot = async data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ROBOT_MANAGER_WORKER_URL}/api/robots/`, data)
      .then(() => resolve())
      .catch(error => {
        reject(error)
      })
  })
}
const removeRobot = async ({ id }) => {
  return axios
    .delete(`${ROBOT_MANAGER_WORKER_URL}/api/robots/${id}/`)
    .then(({ data }) => ({ success: true }))
    .catch(error => {
      // console.log(error)
      return { success: false, error }
    })
}

const syncRobot = async ({ id }) => {
  return axios
    .put(`${ROBOT_MANAGER_WORKER_URL}/api/robots/${id}/sync_libraries`)
    .then(({ data }) => ({ success: true }))
    .catch(error => {
      // console.log(error)
      return { success: false, error }
    })
}

const startRobot = async ({ id }) => {
  return axios
    .put(`${ROBOT_MANAGER_WORKER_URL}/api/robots/${id}/start/`)
    .then(({ data }) => ({ success: true }))
    .catch(error => {
      // console.log(error)
      return { success: false, error }
    })
}

const stopRobot = async ({ id }) => {
  return axios
    .put(`${ROBOT_MANAGER_WORKER_URL}/api/robots/${id}/stop/`)
    .then(({ data }) => ({ success: true }))
    .catch(error => {
      // console.log(error)
      return { success: false, error }
    })
}

export {
  checkRobotManager,
  checkRobotStatus,
  addRobot,
  syncRobot,
  stopRobot,
  startRobot,
  removeRobot,
  getRobot,
}
