const actions = {
  RESET : 'organizations/RESET',
  SET_STATE: 'organizations/SET_STATE',
  GET_ORGANIZATIONS : 'organizations/GET_ORGANIZATIONS',
  GET_ORGANIZATION_BY_ID : 'organizations/GET_ORGANIZATION_BY_ID',
  CREATE_NEW_ORGANIZATION : 'organizations/CREATE_NEW_ORGANIZATION',
  CHANGE_SUBSCRIPTION_PLAN : 'organizations/CHANGE_SUBSCRIPTION_PLAN',
  UPDATE_ORGANIZATION : 'organizations/UPDATE_ORGANIZATION',
  UPDATE_ORGANIZATION_LOGO: 'organizations/UPDATE_ORGANIZATION_LOGO',
}

export default actions
