/* eslint-disable react/button-has-type */
import React from 'react'

const ButtonGeneral = ({
  onClick = () => {},
  icon = 'plus-circle',
  size,
  block,
  type = 'primary',
  text = '',
  additionalClass,
  submit = false,
  tour,
  disabled = false,
  ...restProps
}) => {
  return (
    <button
      disabled={disabled}
      data-tour={tour}
      onClick={onClick}
      type={submit ? 'submit' : 'button'}
      className={`btn btn-${type} ${size && `btn-${size}`} ${block &&
        `btn-block`} btn-with-addon text-nowrap ${additionalClass}`}
      {...restProps}
    >
      <span className="btn-addon">
        <i className={`btn-addon-icon fe fe-${icon}`} />
      </span>
      {text}
    </button>
  )
}

export default ButtonGeneral
