import { Form, Input, Button, Modal } from 'antd'
import React, { useState } from 'react'
import { getIntl } from 'localization'
import { RightOutlined } from '@ant-design/icons'

const FORM_URL =
  'https://forms.zohopublic.com/kronnikarpa/form/Newsletter/formperma/qJy1_dNGyqpOirngQejMFjkr2mG6MHzdmt2Rid0E6h4'

const EmailFormComponent = ({ className, text }) => {
  const [visibility, setVisibility] = useState(false)
  const [form] = Form.useForm()

  return (
    <div className={`w-100${className}`}>
      <Modal
        title={getIntl('Subscribe')}
        open={visibility}
        onCancel={() => setVisibility(false)}
        footer={null}
      >
        <iframe
          title="newsletter"
          frameBorder="0"
          style={{ height: 200, width: '99%', border: 'none' }}
          src={FORM_URL}
        />
      </Modal>
      <Button
        size="large"
        block
        type="primary"
        style={{ fontWeight: 700, fontSize: '1.2em', borderRadius: '5px' }}
        onClick={() => setVisibility(true)}
      >
        {getIntl('Subscribe')} <RightOutlined />
      </Button>
    </div>
  )
}

export default EmailFormComponent
