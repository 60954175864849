import React from 'react'
import style from '../style.module.scss'
import { FormattedHTMLMessage } from 'react-intl'

const MenuFooter = () => {
  return (
    <div className={style.air__menuLeft__banner}>
      <p style={{ marginBottom: 5 }}>
        &copy; 2019 - {new Date().getFullYear()}{' '}
        <a
          className="style.air__menuLeft__banner__link"
          href="https://kronnika.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kronnika
        </a>
        <br />
        <FormattedHTMLMessage id="footer.copyright" />
      </p>
      <hr />
      <ul className={`${style.footerNav} list-unstyled d-flex flex-wrap justify-content-center`}>
        <li>
          <a href="/agreements/terms-of-service">
            <FormattedHTMLMessage id="footer.termsOfService" />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default MenuFooter
