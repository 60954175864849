import { getList } from 'api/requests'
import Moment from 'moment'

export const getJobStatistics = async () => {
  const data = await getList('jobs', { aggregate: true })
  return data
}

export const getJobrunStatistics = async () => {
  const data = await getList('jobruns', { annotate: true })
  return data
}

export const getTodaysTimeSaving = async () => {
  const from_date = Moment().format('YYYY-MM-DD')
  const to_date = Moment()
    .add(1, 'day')
    .format('YYYY-MM-DD')
  const data = await getList('jobruns', {
    with_deleted: true,
    aggregate: true,
    noop: true,
    from_date,
    to_date,
  })
  return data
}

export const getThisMonthsTimeSaving = async () => {
  const from_date = Moment()
    .startOf('month')
    .format('YYYY-MM-DD')
  const to_date = Moment()
    .add(1, 'day')
    .format('YYYY-MM-DD')
  const data = await getList('jobruns', {
    with_deleted: true,
    aggregate: true,
    noop: true,
    from_date,
    to_date,
  })
  return data
}

export const getLastMonthsTimeSaving = async () => {
  const from_date = Moment()
    .subtract(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD')
  const to_date = Moment()
    .startOf('month')
    .format('YYYY-MM-DD')

  const data = await getList('jobruns', {
    with_deleted: true,
    aggregate: true,
    noop: true,
    from_date,
    to_date,
  })
  return data
}

export const getUserTotalTimeSaving = async payload => {
  const { user_id } = payload
  const data = await getList('jobs', { created_by: user_id, aggregate: true, noop: true })
  return data
}

export const getUserScenariosCount = async payload => {
  const { user_id } = payload
  const data = await getList('scenarios', { created_by: user_id })
  return data
}
export const getScenariosByPublicity = async () => {
  const data = await getList('scenarios', { annotate: true, noop: true })
  return data
}

export const getUserJobsCount = async payload => {
  const { user_id } = payload
  const data = await getList('jobs', { created_by: user_id })
  return data
}

export const getRobotTotalTimeSaving = async id => {
  const data = await getList('jobruns', { aggregate: true, robot: id })
  return data
}

export const getRobotsByStatus = async () => {
  const data = await getList('robots', { annotate: true, noop: true })
  return data
}

export const getTaskRunsByStatus = async () => {
  const data = await getList('taskruns', { annotate: true, noop: true })
  return data
}

export const getJobRunsByStatus = async () => {
  const data = await getList('jobruns', { with_deleted: true, annotate: true, noop: true })
  return data
}

export const getAllTimeSaving = async () => {
  const data = await getList('jobruns', { with_deleted: true, aggregate: true, noop: true })
  return data
}

export const getScenarioCounts = async filters => {
  const data = await getList('scenarios', { aggregate: true, ...filters })
  return data
}

export const getJobCounts = async () => {
  const data = await getList('jobs', { aggregate: true })
  return data
}
