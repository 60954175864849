import { getFirst, getList } from 'api/requests'
import { API_URL } from 'index'

export const getLastAgentVersion = async ({ latest = true } = {}) => {
  const data = await getFirst('agentversions', { order_by: '-created', latest })
  return data
}
/**
 * Returns the list of artifact data from File API
 */
export const getArtifactList = async ({ latest = true } = {}) => {
  const tempWinLauncherArtifact = {
    key: 'WindowsLauncher',
    os_name: 'Windows Launcher',
    file: 'WindowsLauncher/KronnikaRobotManager_Launcher.exe',
    arch: 'x64',
    os: 'windows',
    agent_version: { version: '', id: 1293091203 } || '',
    link: `${API_URL}/files/_shared/artifacts/${'WindowsLauncher/KronnikaRobotManager_Launcher.exe'}?raw=true`,
    launcher: true,
  }
  const tempMacLauncherArtifact = {
    key: 'MacOSLauncher',
    os_name: 'MacOS Launcher',
    file: 'MacLauncher/KronnikaRobotManager_Launcher.dmg',
    arch: 'x64',
    os: 'darwin',
    agent_version: { version: '', id: 1293091203 } || '',
    link: `${API_URL}/files/_shared/artifacts/${'MacLauncher/KronnikaRobotManager_Launcher.dmg'}?raw=true`,
    launcher: true,
  }

  const data = await getList('agentartifacts', { latest })
  if (data.success) {
    data.data.results.push(tempWinLauncherArtifact)
    data.data.results.push(tempMacLauncherArtifact)
  }
  return data
}

export const getReleaseNotes = async ({ locale = 'tr-TR', filters } = {}) => {
  const data = await getList('agentversiontranslations', {
    language: locale.split('-')[0],
    ...filters,
  })
  return data
}
