import BaseModel from './BaseModel'

class Package extends BaseModel {

  constructor(props) {

    const { price, billing_price, billing_currency, currency, minutes, validity} = props

    super(props)

    this.price = billing_price
    this.currency = billing_currency
    this.minutes = minutes
    this.validity = validity

  }
}

export default Package
