import { Button, Col, Row } from 'antd'
import { history } from 'index'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { filterRoutesByDisabledURLs } from 'services/utilities'

const MenuItems = ({ options = { loginButtonDisabled: false } }) => {
  const enabled = filterRoutesByDisabledURLs({ disabledUrls: ['kronnika.io'] })
  const { loginButtonDisabled } = options
  const buttonTextStyle = { fontWeight: '500', fontSize: 15 }

  return (
    <Row justify="end">
      <Col span={24} className="text-right">
        {enabled && (
          <>
            <Button
              type="link"
              size="large"
              style={{ ...buttonTextStyle }}
              onClick={() => history.push('/marketplace')}
            >
              <FormattedMessage id="Home" />
            </Button>
            <Button
              type="link"
              size="large"
              onClick={() => history.push('/pricing')}
              style={{ ...buttonTextStyle }}
            >
              <FormattedMessage id="Subscription Plans" />
            </Button>
          </>
        )}
        {!loginButtonDisabled && (
          <button
            type="button"
            className="btn btn-primary  ml-4"
            onClick={() => history.push('/system/login')}
          >
            <FormattedMessage id="loginScreen.loginButton" />
          </button>
        )}
      </Col>
    </Row>
  )
}

export default MenuItems
