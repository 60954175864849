import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import actions from './actions'
import {
  CatalogItemVersion,
  CatalogItemVersionMenuItem,
  CombinedCatalogItem,
} from 'models/CatalogItem'
import {
  getCatalogItemVersionByID,
  getCatalogItems,
  getCatalogItemVersions,
  addScenarioToCatalog,
  publishCatalogItem,
  getCatalogItemVersionByKey,
  updateCatalogItem,
  updateCatalogItemIcon,
  addScenarioToNewCatalogItemVersion,
  getCatalogItemVersionsMenu,
} from 'services/catalog_items'
import { getIntl } from 'localization'
import { history } from 'index'
import { createNewScenarioVersion } from 'services/scenarios'

export function* GET_CATALOG_ITEM_VERSIONS({ payload }) {
  yield put({
    type: 'marketplace/SET_STATE',
    payload: { loading: true },
  })

  try {
    const { success, data } = yield call(getCatalogItemVersions, payload || {})

    if (success) {
      const market_items = data.results.map(item => new CombinedCatalogItem(item))
      yield put({
        type: 'marketplace/SET_STATE',
        payload: {
          market_items,
          total: data.count,
        },
      })
    }
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: 'marketplace/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* GET_CATALOG_ITEM_VERSIONS_MENU({ payload }) {
  yield put({
    type: 'marketplace/SET_STATE',
    payload: { loading: true },
  })

  try {
    const { success, data } = yield call(getCatalogItemVersionsMenu, payload || {})

    if (success) {
      const market_items = data.results.map(item => new CatalogItemVersionMenuItem(item))
      yield put({
        type: 'marketplace/SET_STATE',
        payload: {
          market_items,
          total: data.count,
        },
      })
    }
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: 'marketplace/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_CATALOG_ITEM_VERSION_BY_ID({ payload }) {
  yield put({
    type: 'marketplace/SET_STATE',
    payload: { loading: true },
  })
  const { success, data: item } = yield call(getCatalogItemVersionByID, payload)
  if (success) {
    const catalog_item_version = new CombinedCatalogItem(item)

    yield put({
      type: 'marketplace/SET_STATE',
      payload: { active_item: catalog_item_version, loading: false },
    })
  } else {
    yield put({
      type: 'marketplace/SET_STATE',
      payload: { active_item: false },
    })
  }
  yield put({
    type: 'marketplace/SET_STATE',
    payload: { loading: false },
  })
}

export function* GET_CATALOG_ITEM_VERSION_BY_KEY({ payload }) {
  yield put({
    type: 'marketplace/SET_STATE',
    payload: { loading: true },
  })
  const { success, data: item } = yield call(getCatalogItemVersionByKey, payload)
  if (success) {
    const catalog_item_version = new CombinedCatalogItem(item)

    yield put({
      type: 'marketplace/SET_STATE',
      payload: { active_item: catalog_item_version, loading: false },
    })
  } else {
    yield put({
      type: 'marketplace/SET_STATE',
      payload: { active_item: false },
    })
  }
  yield put({
    type: 'marketplace/SET_STATE',
    payload: { loading: false },
  })
}
export function* ADD_SCENARIO_TO_MARKETPLACE({ payload }) {
  const { active_scenario } = yield select()
  const scenario_version_id = active_scenario.version_id
  const scenario_id = active_scenario.id

  yield put({
    type: 'marketplace/SET_STATE',
    payload: { processing: true },
  })

  // returns catalog item version !
  const { success, data } = yield call(addScenarioToCatalog, {
    ...payload,
    scenario_id,
    scenario_version_id,
  })

  if (success) {
    const { id, catalogitem } = data
    const { apt, credit } = payload
    const { success: publishSuccess } = yield call(publishCatalogItem, {
      id: catalogitem.id,
      catalog_item_version_id: id,
      apt,
      credit,
    })
    if (publishSuccess) {
      const data = {
        message: getIntl('notifications.catalog_items.add_scenario_to_market.success'),
        description: getIntl(
          'notifications.catalog_items.add_scenario_to_market.successDescription',
        ),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'success',
        },
      })
      localStorage.setItem('marketplace.newRequest.completeDocumentationWarning', true)
      history.push(`/market/${catalogitem.key}`)
    } else {
      const data = {
        message: getIntl('notifications.catalog_items.add_scenario_to_market.fail'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }
  } else {
    const data = {
      message: getIntl('notifications.catalog_items.add_scenario_to_market.fail'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }
  yield put({
    type: 'marketplace/SET_STATE',
    payload: { processing: false },
  })
}

export function* UPDATE_MARKET_ITEM({ payload, redirect }) {
  const { marketplace } = yield select()
  let { active_item } = marketplace

  // Remove logo property from uploading because its a file not a string value.
  delete active_item.icon

  yield put({
    type: 'marketplace/SET_STATE',
    payload: { loading: true },
  })

  if (payload) active_item = { ...active_item, ...payload }
  const { success, catalog_item } = yield call(updateCatalogItem, active_item)

  if (success) {
    if (redirect) {
      history.replace(redirect)
      yield put({
        type: 'marketplace/GET_CATALOG_ITEM_VERSION_BY_ID',
        payload: { id: active_item.catalogitemversion.id },
      })
    } else {
      yield put({
        type: 'marketplace/GET_CATALOG_ITEM_VERSION_BY_ID',
        payload: { id: active_item.catalogitemversion.id },
      })
    }
  }
  yield put({
    type: 'marketplace/SET_STATE',
    payload: { loading: false },
  })
}

export function* UPDATE_MARKET_ITEM_PHOTO({ payload }) {
  const {
    marketplace: { active_item },
  } = yield select()
  yield put({
    type: 'marketplace/SET_STATE',
    payload: {
      icon_loading: true,
    },
  })
  const { success, catalog_item } = yield call(updateCatalogItemIcon, payload)
  const data = { ...active_item, icon: catalog_item.icon }

  if (success) {
    yield put({
      type: 'marketplace/SET_STATE',
      payload: {
        active_item: data,
      },
    })
  }
  yield put({
    type: 'marketplace/SET_STATE',
    payload: {
      icon_loading: false,
    },
  })
}

export function* ADD_SCENARIO_TO_NEW_MARKET_ITEM_VERSION({ payload, redirect = false }) {
  const {
    item: { short_description: description, catalog_item: catalog_item_id, apt, credit, tags },
    cloud_url,
    is_default,
  } = payload
  const { active_scenario } = yield select()
  yield put({
    type: 'marketplace/SET_STATE',
    payload: { loading: true },
  })
  const { success, data } = yield call(addScenarioToNewCatalogItemVersion, {
    catalog_item_id,
    scenario_version_id: active_scenario.version_id,
    cloud_url,
  })
  if (success) {
    const { success: publishSuccess } = yield call(publishCatalogItem, {
      id: data.catalogitem.id,
      catalog_item_version_id: data.id,
      apt,
      credit,
      tags,
    })

    if (publishSuccess) {
      const data = {
        message: getIntl('notifications.marketplace.add_to_existing_marketitem.success'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'success',
        },
      })
      if (redirect)
        history.push(`/scenario-designer/?version=${active_scenario.version_id}&refresh=true`)
    } else {
      const data = {
        message: getIntl('notifications.marketplace.add_to_existing_marketitem.error'),
      }
      yield put({
        type: 'internal_notification/SET_STATE',
        payload: {
          data,
          type: 'error',
        },
      })
    }
  } else {
    const data = {
      message: getIntl('notifications.catalog_items.add_scenario_to_catalog.fail'),
    }
    yield put({
      type: 'internal_notification/SET_STATE',
      payload: {
        data,
        type: 'error',
      },
    })
  }

  yield put({
    type: 'marketplace/SET_STATE',
    payload: { loading: false },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPDATE_MARKET_ITEM, UPDATE_MARKET_ITEM),
    takeEvery(actions.GET_CATALOG_ITEM_VERSIONS, GET_CATALOG_ITEM_VERSIONS),
    takeEvery(actions.GET_CATALOG_ITEM_VERSIONS_MENU, GET_CATALOG_ITEM_VERSIONS_MENU),
    takeEvery(actions.GET_CATALOG_ITEM_VERSION_BY_ID, GET_CATALOG_ITEM_VERSION_BY_ID),
    takeEvery(actions.GET_CATALOG_ITEM_VERSION_BY_KEY, GET_CATALOG_ITEM_VERSION_BY_KEY),
    takeEvery(actions.ADD_SCENARIO_TO_MARKETPLACE, ADD_SCENARIO_TO_MARKETPLACE),
    takeEvery(
      actions.ADD_SCENARIO_TO_NEW_MARKET_ITEM_VERSION,
      ADD_SCENARIO_TO_NEW_MARKET_ITEM_VERSION,
    ),
    takeEvery(actions.UPDATE_MARKET_ITEM_PHOTO, UPDATE_MARKET_ITEM_PHOTO),
  ])
}
