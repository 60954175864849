export const MAX_TABLE_FILTER_INPUT_LENGTH = 64
export const MAX_SCENARIO_NAME_LENGTH = 64
export const MAX_CATALOG_NAME_LENGTH = 64
export const MAX_CATALOG_DESCRIPTION_LENGTH = 255
export const MAX_CATALOG_URL_LENGTH = 255
export const MAX_PARAMETER_NAME_LENGTH = 64
export const MAX_PARAMETER_VALUE_LENGTH = 128
export const MAX_ROBOT_NAME_LENGTH = 64
export const MAX_ORGANIZATION_NAME_LENGTH = 64
export const MAX_ORGANIZATION_WEBSITE_LENGTH = 64
export const MAX_QUEUE_SCHEMA_COLUMN_NAME_LENGTH = 64
export const MAX_DOCUMENT_TITLE_LENGTH = 128
export const MAX_DOCUMENT_DESCRIPTION_LENGTH = 255
export const MAX_DOCUMENT_SEO_TITLE_LENGTH = 255
export const MAX_DOCUMENT_SEO_DESCRIPTION_LENGTH = 255
export const MAX_DOCUMENT_SEO_SHORTURL_LENGTH = 128
export const MAX_DOCUMENT_SEO_LOGO_ALT_LENGTH = 255

export const ROBOT_MANAGER_WORKER_PORT = 5000
export const ROBOT_MANAGER_WORKER_URL = `http://localhost:${ROBOT_MANAGER_WORKER_PORT}`
