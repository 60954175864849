import React from 'react'
import { Layout } from 'antd'
import Cookie from 'components/system/Cookie'
import { connect } from 'react-redux'

const AuthLayout = ({ children }) => {
  return (
    <>
      <Layout>
        <Layout.Content>
          <div>{children}</div>
        </Layout.Content>
      </Layout>
      <Cookie />
    </>
  )
}
const mapStateToProps = ({ router }) => ({ router })
export default connect(mapStateToProps)(AuthLayout)
