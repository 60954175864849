import { Col, Form, Input, Row } from 'antd'
import ButtonGeneral from 'components/widgets/Buttons/ButtonGeneral'
import React from 'react'
import { connect } from 'react-redux'
import ConditionPicker from './ConditionPicker'
import { getIntl } from 'localization'

const RuleForm = ({ rule, locale, onAdd }) => {
  const [form] = Form.useForm()

  const inputs = Object.keys(rule.params).map(key => {
    let component = null
    const conditions =
      rule.params[key].conditions && rule.params[key].conditions.length > 0
        ? rule.params[key].conditions
        : null

    switch (rule.params[key].type) {
      case 'string':
        component = (
          <Input
            addonBefore={
              conditions && <ConditionPicker name={[key, 'condition']} conditions={conditions} />
            }
            width="100%"
          />
        )
        break
      case 'integer':
        component = (
          <Input
            type="number"
            addonBefore={
              conditions && <ConditionPicker name={[key, 'condition']} conditions={conditions} />
            }
          />
        )
        break
      case null:
        break
      default:
        break
    }
    return {
      key,
      component,
      conditions:
        rule.params[key].conditions && rule.params[key].conditions.length > 0
          ? rule.params[key].conditions
          : null,
    }
  })

  const ruleLabel = key => {
    if (
      rule &&
      rule.config &&
      rule.config.localization &&
      rule.config.localization.params &&
      rule.config.localization.params[key] &&
      rule.config.localization.params[key][locale]
    ) {
      return rule?.config?.localization?.params[key][locale]
    }
    return key
  }
  const handleRuleAdd = () => {
    form
      .validateFields()
      .then(values => {
        const { name, ...params } = values
        onAdd({ name, params })
      })
      .catch(e => {})
  }
  return (
    <Row>
      <Form form={form} layout="vertical">
        <Form.Item name="name" rules={[{ required: true }]} label={getIntl('Name')}>
          <Input />
        </Form.Item>
        {inputs.map(({ key, component, conditions }) => (
          <Col key={key} span={24}>
            {component ? (
              <Form.Item
                rules={[{ required: true }]}
                name={[key, 'value']}
                key={key}
                label={ruleLabel(key)}
              >
                {component}
              </Form.Item>
            ) : (
              <ConditionPicker
                conditions={conditions}
                key={key}
                name={[key, 'condition']}
                label={ruleLabel(key)}
              />
            )}
          </Col>
        ))}
      </Form>
      <Col span={24} className="text-right">
        <ButtonGeneral text={getIntl('Add')} onClick={handleRuleAdd} />
      </Col>
    </Row>
  )
}
const mapStateToProps = ({ settings }) => ({ locale: settings.locale })
export default connect(mapStateToProps)(RuleForm)
