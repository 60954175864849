import BaseModel from './BaseModel'

class Trigger extends BaseModel {
  constructor(props) {
    super(props)
    this.scenarioversion = props.scenarioversion
    this.vault = props.vault
    this.organization = props.organisation
    this.robots = props.robots
    this.received_by = props.received_by || '-'
    this.operator = props.operator // CHANGE AFTER API CHANGE
    this.execute_by_owner = props.execute_by_owner ? 'Yes' : 'No' // CHANGE AFTER API CHANGE
    this.control_interval = props.control_interval // CHANGE AFTER API CHANGE
    this.activities = props.activities.map(a => new TriggerActivity(a))
  }
}

class TriggerHandler extends BaseModel {
  constructor(props) {
    super(props)
    this.handler = null
  }
}
class TriggerRuleSet extends BaseModel {
  constructor(props) {
    super(props)
    this.handler = new TriggerHandler(props.handler)
    this.expression = props.expression
    this.params = props.params_model
    this.config = props.config
  }
}
class TriggerActivity extends BaseModel {
  constructor(props) {
    super(props)
    this.ruleset = props.ruleset
    this.expression = props.expression
    this.handler_name = props.handler_name
    this.params = props.params
  }
}

export { Trigger, TriggerHandler, TriggerRuleSet, TriggerActivity }
