const actions = {
  RESET: 'scenario_runner/RESET',
  SET_STATE: 'scenario_runner/SET_STATE',
  SET_JOBS_FILTERS: 'scenario_runner/SET_JOBS_FILTERS',
  DELETE_JOBS_FILTERS: 'scenario_runner/DELETE_JOBS_FILTERS',
  JOBS_SET_SELECTED_ROWS : 'scenario_runner/jobs/SET_SELECTED_ROWS',
  JOBS_SET_EXPANDED_ROWS : 'scenario_runner/jobs/SET_EXPANDED_ROWS',
  JOBRUNS_SET_SELECTED_ROWS : 'scenario_runner/jobruns/SET_SELECTED_ROWS',
  TASKRUNS_SET_SELECTED_ROWS : 'scenario_runner/taskruns/SET_SELECTED_ROWS',
}

export default actions
