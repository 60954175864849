/* eslint-disable import/no-cycle */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { App, Dropdown } from 'antd'
import { BorderOuterOutlined, CopyFilled, DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import { taskStateActions } from '../../TaskEditor'
import { scenarioStateActions } from '../../ScenarioEditor'
import { FormattedHTMLMessage } from 'react-intl'
import { isMac } from 'utils/osRelated'
import {
  handleAddNode,
  handleEscape,
  handleRename,
  handleCopy,
  handlePaste,
} from '../../EditorHelpers'
import { CanvasInnerWrapper } from '../StyledComponents'

import styles from './style.module.scss'

const CanvasInner = React.forwardRef((props, ref) => {
  const {
    children,
    scenario_editor: { clipboard, scene },
    active_scenario,
    router,
    onContextMenu,
    ...otherProps
  } = props

  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 })
  const { modal } = App.useApp()
  const { taskId } = router.location.query

  const chart =
    scene === 'task' && taskId
      ? active_scenario.content.chart.nodes[taskId].properties.chart
      : active_scenario.content.chart
  const actions = scene === 'task' ? taskStateActions : scenarioStateActions

  const handleKeyDown = e => {
    e.stopPropagation()
    e.preventDefault()
    if (e[isMac() ? 'metaKey' : 'ctrlKey'] && e.code === 'KeyA') {
      actions.onSelectAll()
    }
    if (e[isMac() ? 'metaKey' : 'ctrlKey'] && e.code === 'KeyC') {
      handleCopy(taskId)
    }
    if (e[isMac() ? 'metaKey' : 'ctrlKey'] && e.code === 'KeyV') {
      handlePaste(lastPosition, modal)
    }
    if (e.code === 'F2') {
      if (chart?.picked?.nodes.length === 1) {
        handleRename(true)
      }
    }
    if (e.code === 'Escape') {
      handleEscape()
    }
    if (e.code === 'Backspace' || e.code === 'Delete') {
      actions.onDeleteKey({})
    }
  }

  const handleContextMenuSelect = ({ key, domEvent }) => {
    domEvent.preventDefault()
    domEvent.stopPropagation()
    if (key.startsWith('condition-')) {
      const nodeType = key.split('condition-')[1]
      const nodeTypeWithScope = `${nodeType}_${scene === 'scenario' ? 'task' : 'step'}`
      handleAddNode(nodeTypeWithScope)
      return true
    }

    switch (key) {
      case 'newTask':
        handleAddNode()
        break
      case 'selectAll':
        actions.onSelectAll()
        break
      case 'copy':
        handleCopy(taskId)
        break
      case 'paste':
        handlePaste(lastPosition, modal)
        break
      case 'delete':
        actions.onDeleteKey({})
        break
      default:
        break
    }
  }

  const items = [
    {
      key: 'newTask',
      className: styles.menuItem,
      show: scene === 'scenario',
      icon: <PlusOutlined />,
      label: <FormattedHTMLMessage id="New Task" />,
    },
    {
      key: 'conditionNodes',
      icon: <i className="icmn-tree" />,
      label: <FormattedHTMLMessage id="Flow Controls" />,
      children: [
        {
          key: 'condition-if',
          label: 'If',
        },
        {
          key: 'condition-while',

          label: 'While',
        },
        {
          key: 'condition-tryCatch',
          label: 'Try - Catch',
        },
        {
          key: 'condition-forEach',
          label: 'For Each',
        },
      ],
    },
    {
      type: 'divider',
      show: chart?.picked?.nodes.length > 0 || (clipboard.data && clipboard.mode === scene),
    },
    {
      key: 'copy',
      show: chart?.picked?.nodes.length > 0,
      icon: <CopyFilled />,
      label: <FormattedHTMLMessage id="Copy" />,
    },
    {
      key: 'paste',
      show: !!clipboard?.data && clipboard.mode === scene,
      icon: <CopyFilled />,
      label: <FormattedHTMLMessage id="Paste" />,
    },
    {
      key: 'selectAll',
      icon: <BorderOuterOutlined />,
      label: <FormattedHTMLMessage id="Select All" />,
    },
    {
      key: 'delete',
      show: chart?.picked?.nodes.length > 0,
      icon: <DeleteOutlined />,
      label: <FormattedHTMLMessage id="Delete" />,
    },
  ]

  return (
    <Dropdown
      menu={{
        items: items.filter(item => item.show !== false).map(({ show, ...item }) => item),
        onClick: handleContextMenuSelect,
      }}
      trigger={['contextMenu']}
    >
      <CanvasInnerWrapper
        ref={ref}
        {...props}
        {...otherProps}
        onContextMenu={e => {
          const position = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }
          setLastPosition(position)
          onContextMenu(e)
        }}
        onKeyDown={handleKeyDown}
      >
        {React.Children.map(children, child => React.cloneElement(child, { ...props }))}
      </CanvasInnerWrapper>
    </Dropdown>
  )
})

const mapStateToProps = (state, ownProps) => ({
  active_scenario: state.active_scenario,
  scenario_editor: state.scenario_editor,
  router: state.router,
  ...ownProps,
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(CanvasInner)
