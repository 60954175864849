import { all, takeEvery, put, call, select, take } from 'redux-saga/effects'
import { getReportsData, getReportsTableData } from 'services/reports'
import actions from './actions'
import {
  calculateTimeGainEfficiencyRate,
  calculateProductEfficiency,
  convertSavingTimeToHour,
  convertSavingTimeToMinute,
  convertSavingTimeToMinute_2,
} from 'services/utilities'
import JobRun from 'models/JobRun'
import TaskRun from 'models/TaskRun'

export function* GET_REPORTS_TABLE_DATA({ payload }) {
  yield put({
    type: 'reports/SET_STATE',
    payload: { loading: true },
  })
  try {
    const data = yield call(getReportsTableData, payload)
    if (data) {
      const { next, previous, results } = data
      const url = next || previous
      const payloadD = {
        [payload.type]: {
          ...data,
          pagination_url: url ? `${url.split('page=')[0]}page=` : null,
          results:
            payload.type === 'jobruns'
              ? results.map(result => new JobRun(result))
              : results.map(result => new TaskRun(result)),
        },
      }
      yield put({
        type: 'reports/SET_STATE',
        payload: payloadD,
      })
    }
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: 'reports/SET_STATE',
    payload: { loading: false },
  })
}

export function* GET_REPORTS_DATA({ payload }) {
  yield put({
    type: 'reports/SET_STATE',
    payload: { loading: true },
  })

  try {
    const { success, data } = yield call(getReportsData, payload?.filters)

    if (success) {
      const { count, results, total_fte, total_duration, total_savings } = data
      const reportsPayload = {
        count,
        reports: {
          ...results,
          results: results.map(result => new JobRun(result)),
          count
        },
        total_fte: convertSavingTimeToMinute(total_fte),
        total_duration: convertSavingTimeToHour(total_duration),
        total_duration_minute: convertSavingTimeToMinute(total_duration),
        total_savings: convertSavingTimeToMinute_2(total_savings),
        efficiency: calculateTimeGainEfficiencyRate({
          total_fte,
          total_savings,
        }),
        production_efficiency: calculateProductEfficiency({
          total_job_count: count,
          total_robot_time: total_duration,
          total_fte,
        }),
        loading: false,
      }
      yield put({
        type: 'reports/SET_STATE',
        payload: reportsPayload ,
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put({
    type: 'reports/SET_STATE',
    payload: { loading: false },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_REPORTS_DATA, GET_REPORTS_DATA),
    takeEvery(actions.GET_REPORTS_TABLE_DATA, GET_REPORTS_TABLE_DATA),
  ])
}
