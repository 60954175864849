import React from 'react'
import { connect } from 'react-redux'
import { store } from 'index'
import { filterComponentsByPermissions, filterComponentsByAPIPermissions } from 'services/utilities'

const ACL = React.forwardRef((props, ref) => {
  const { children, allowed, type, user, creator, strict, notAuthorizedContent = null } = props
  let owner = false
  if (creator) {
    const { user: currentUser } = store.getState()
    owner = currentUser.id === creator.id
  }
  let authorized = false
  switch (type) {
    case 'role':
      authorized = filterComponentsByPermissions(allowed, user)
      break
    case 'api':
      authorized = filterComponentsByAPIPermissions(allowed, user)
      break
    default:
      authorized = filterComponentsByPermissions(allowed, user)
      break
  }

  const component = <span ref={ref}>{children}</span>

  if (strict) {
    if (user && user.roles[0].name === 'admin') return component // To have access even strict bound
    return authorized && owner ? component : notAuthorizedContent
  }
  if (owner) {
    return component
  }

  return authorized ? component : notAuthorizedContent
})

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  ...ownProps,
})

export default connect(mapStateToProps)(ACL)
