import BaseModel from "./BaseModel"

class Vault extends BaseModel {
  constructor(props) {
    const {
      identifier, organisation, payload, public_key
    } = props
    super(props)

    this.identifier = identifier
    this.organization = organisation
    this.payload = payload
    this.public_key = public_key
    this.created_by = this.created_by
  }

}

export default Vault
