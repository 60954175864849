import actions from './actions'

const initialState = {
  loading: false,
  actions: [],
  actionCount: 0,
  subscriptions : [],
  channels: [],
  channelSubscriptions : [],
  notifications: [],
  notificationsMenu: [],
  menuLoading: false,
  active_item: null,
  count: 0,
  unReadCount: 0,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
