import axios from 'utils/axiosGlobal'
import setAuthorizationToken from '../utils/setAuthorizationToken'
import jwt_decode from 'jwt-decode'
import { API_URL } from 'index'
import { createRecord, getOneByID, headers, updateRecord } from 'api/requests'
import { getOrganizationUsageData } from './organizations'
import { clearLocalStorage } from './utilities'

export const decodeAndPutTokens = ({ email, access, refresh }) => {
  const decoded_access_token = jwt_decode(access)
  const { user_id } = decoded_access_token
  const { exp } = decoded_access_token

  localStorage.setItem('access', access)
  localStorage.setItem('refresh', refresh)
  localStorage.setItem('exp', exp)
  localStorage.setItem('username', email)
  localStorage.setItem('user_id', user_id)

  setAuthorizationToken(access)
  return user_id
}
export const decodeAndSwitchTokens = tokenInfo => {
  if (!tokenInfo) {
    try {
      const original_info = JSON.parse(localStorage.getItem('original_user_params'))
      const { exp } = jwt_decode(original_info.access)

      localStorage.setItem('access', original_info.access)
      localStorage.setItem('refresh', original_info.refresh)
      localStorage.setItem('user_id', original_info.user_id)
      localStorage.setItem('username', original_info.username)
      localStorage.setItem('exp', exp)

      localStorage.removeItem('original_user_params')

      setAuthorizationToken(original_info.access)
      return original_info
    } catch (e) {
      console.log(e)
    }
  } else {
    const decoded_access_token = jwt_decode(tokenInfo.access)
    const { user_id, exp } = decoded_access_token

    const original_info = {
      access: localStorage.getItem('access'),
      refresh: localStorage.getItem('refresh'),
      username: localStorage.getItem('username'),
      user_id: localStorage.getItem('user_id'),
    }

    localStorage.setItem('original_user_params', JSON.stringify(original_info))

    localStorage.setItem('access', tokenInfo.access)
    localStorage.setItem('refresh', tokenInfo.refresh)
    localStorage.setItem('exp', exp)
    localStorage.setItem('username', tokenInfo.email)
    localStorage.setItem('user_id', user_id)

    setAuthorizationToken(tokenInfo.access)
    return user_id
  }
}

export async function verifyTokenRequest(token) {
  return new Promise(resolve => {
    const url = `${API_URL}/token/verify/`
    return axios(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
      data: JSON.stringify({
        token,
      }),
    })
      .then(result => {
        resolve(result.data)
      })
      .catch(() => resolve(false))
  })
}

export async function refreshTokenRequest(token) {
  return new Promise(resolve => {
    const url = `${API_URL}/token/refresh/`

    return axios(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
      data: JSON.stringify({
        refresh: token,
      }),
    })
      .then(result => {
        resolve(result.data.access)
      })
      .catch(() => resolve(false))
  })
}

export async function login({ email, password }) {
  return axios(`${API_URL}/token/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      username: email,
      password,
    }),
  })
    .then(result => {
      const { access, refresh } = result.data
      if (access) {
        const user_id = decodeAndPutTokens({ email, access, refresh })
        return { success: true, user_id }
      }

      return { success: false }
    })
    .catch(error => {
      return { success: false, username: email, error }
    })
}

export async function loginWithEmailToken({ email, token }) {
  const { success, data, error } = await createRecord('token/email/verify', { token }, '', true)
  if (success) {
    const { access, refresh } = data
    if (access) {
      const user_id = decodeAndPutTokens({ email, access, refresh })
      return { success: true, user_id }
    }
  }
  return { success: false, error }
}

export async function loginAsUser(email) {
  const data = await createRecord('token/login_as_user', { email }, '')
  return data
}

export async function loginAsUserTokenVerify(email, token, reset) {
  const { success, data, error } = await createRecord('token/login_as_user/verify', { token }, '')
  if (success) {
    const { access, refresh } = data
    if (access) {
      const user_id = decodeAndSwitchTokens({ email, access, refresh }, reset)
      return { success: true, user_id }
    }
  }
  return { success: false, error }
}

export async function getUser(id) {
  const result = await getOneByID('users', id)
  return result
}

export async function getPermissions() {
  const url = `${API_URL}/?format=openapi`

  return new Promise((resolve, reject) => {
    return axios(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
    })
      .then(result => {
        const { paths } = result.data
        resolve({ success: true, paths })
      })
      .catch(() => {
        resolve({ success: false })
      })
  })
}

export async function updateUser({ user_data }) {
  const data = await updateRecord('users', user_data.user_id, user_data)
  return data
}

export async function updatePassword({ user_data }) {
  const { old_password: current_password, new_password } = user_data
  const url = `${API_URL}/users/changepassword/`
  return axios(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
    data: {
      current_password,
      new_password,
      confirm_password: new_password,
    },
  })
    .then(result => {
      return { success: true, info: result.data }
    })
    .catch(error => {
      const { data } = error.response
      return { success: false, info: data }
    })
}

export async function updateUserPhoto({ user_id, photo }) {
  return new Promise(resolve => {
    const url = `${API_URL}/users/${user_id}/`
    const formData = new FormData()
    formData.append('userprofile.photo', photo)
    return axios(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
      data: formData,
    })
      .then(result => {
        resolve({ success: true, user_data: result.data })
      })
      .catch(() => resolve(false))
  })
}

export async function deleteUser({ user_id }) {
  return new Promise(resolve => {
    const url = `${API_URL}/users/${user_id}/`
    return axios(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
    })
      .then(result => {
        resolve({ success: true })
      })
      .catch(() => resolve(false))
  })
}

export async function createUser(user) {
  return new Promise(resolve => {
    const url = `${API_URL}/users/`
    return axios(url, {
      method: 'POST',
      data: user,
      headers: headers(),
    })
      .then(result => {
        resolve({ success: true, user: result.data })
      })
      .catch(() => resolve({ success: false }))
  })
}

export async function getProfilePhotoURL(user_id) {
  return new Promise(resolve => {
    const url = `${API_URL}/users/${user_id}`
    return axios(url, {
      method: 'GET',
      headers: headers(),
    })
      .then(result => {
        resolve(result.data)
      })
      .catch(() => resolve(false))
  })
}

export async function getCurrentUser() {
  const userId = localStorage.getItem('user_id')
  if (userId) {
    const { success, data } = await getUser(userId)
    if (success) {
      const { success: usageSuccess, data: usage } = await getOrganizationUsageData()
      if (usageSuccess) {
        return {
          success,
          user_data: data,
          usage,
        }
      }
    }
  }
  return { success: false }
}

export async function logout() {
  // localStorage.clear()
  clearLocalStorage()
}
