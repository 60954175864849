import React from 'react'
import {
  PortDefaultInner,
  PortDefaultOuter,
  PortElseText,
  PortIfInner,
  PortIfOuter,
  PortThenText,
} from './StyledComponents'

const PortCustom = props => {
  const { isLinkSelected, isLinkHovered, config, port } = props
  let component = (
    <PortDefaultOuter>
      <PortDefaultInner
        active={!config.readonly && (isLinkSelected || isLinkHovered)}
      />
    </PortDefaultOuter>
  )

  switch (port.id) {
    case 'then':
      component = (
        <PortIfOuter>
          <PortIfInner active={!config.readonly && (isLinkSelected || isLinkHovered)}>
            <PortThenText>Then</PortThenText>
          </PortIfInner>
        </PortIfOuter>
      )
      break
    case 'else':
      component = (
        <PortIfOuter>
          <PortIfInner active={!config.readonly && (isLinkSelected || isLinkHovered)}>
            <PortElseText>Else</PortElseText>
          </PortIfInner>
        </PortIfOuter>
      )
      break
    default:
      break
  }

  return component
}


export default PortCustom
