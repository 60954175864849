import React from 'react'
import { Row, Col, Spin } from 'antd'
import { getIntl } from 'localization'

const Loading = ({ load = '', message }) => {
  return (
    <Row justify="center" align="middle">
      <Col span={24} className="text-center">
        <Spin tip={message || `${getIntl('Loading')} ${load}`}>
          <div className="content" style={{ minHeight: 100, margin: 20 }} />
        </Spin>
      </Col>
    </Row>
  )
}

export default Loading
